import { primaryDark } from '../../../themes/colors';

export default {
  container: {
    transition: 'opacity 0.2s ease-in-out',
    '@media screen and (max-width: 600px)': {
      justifyContent: 'flex-start',
      marginBottom: '20px',
    },
    marginTop: 8,
  },
  root: {
    padding: 0,
  },
  icon: {
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    backgroundColor: '#fff',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,0.2), inset 0 -1px 0 rgba(16,22,26,0.1)',
  },
  checkedIcon: {
    '&:before': {
      content: '""',
      display: 'block',
      width: '8px',
      height: '8px',
      margin: '4px',
      borderRadius: '2px',
      background: primaryDark,
    },
  },
  label: {
    paddingLeft: '12px',
    maxWidth: '90%',
    color: '#333333',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    userSelect: 'none',
    cursor: 'pointer',
  },
};
