import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  postUserComplianceDocument: userId => makeAxiosRequest(
    `/users/${userId}/user-compliance-documents`,
    {
      method: 'post',
    },
    true,
  ),
  getUserComplianceDocument: userId => makeAxiosRequest(
    `/users/${userId}/user-compliance-documents`,
    {
      method: 'get',
    },
    true,
  ),
  deleteUserComplianceDocument: (userId, documentId) => makeAxiosRequest(
    `/users/${userId}/user-compliance-documents/${documentId}`,
    {
      method: 'delete',
    },
    true,
  ),
  getPreSignedUrls: params => makeAxiosRequest(
    `/get-s3-pre-signed-urls-for-compliance?${params}`,
    { method: 'get' },
    true,
  ),
};
