import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction('companyAdminDocuments/GET_PRE_SIGNED_URLS');
export const saveCompanyComplianceDocuments = asyncAction('companyAdminDocuments/SAVE_COMPANY_COMPLIANCE_DOCUMENTS');
export const getCompanyComplianceDocuments = asyncAction('companyAdminDocuments/GET_COMPANY_COMPLIANCE_DOCUMENTS');
export const deleteCompanyComplianceDocument = asyncAction('companyAdminDocuments/DELETE_COMPANY_COMPLIANCE_DOCUMENT');

// sync action
export const setAllAWSData = action('companyAdminDocuments/SET_ALL_AWS_DATA');
export const setAWSData = action('companyAdminDocuments/SET_AWS_DATA');
export const removeAWSDataFile = action('companyAdminDocuments/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('companyAdminDocuments/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('companyAdminDocuments/SET_UNLOADED_FILES_EXIST');
