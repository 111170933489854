import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';

// actions
import {
  getPreSignedUrls,
  saveNewMexicoDocuments,
  getNewMexicoDocuments,
  deleteNewMexicoDocument,
} from '../actions/new-mexico-documents';

// api methods
import Api from 'api/new-mexico';

import queryString from 'query-string';

function* ensureGetPreSignedUrls({ payload }) {
  const { filesName, filesMd5, filesSize, resolve, reject } = payload;
  try {
    const params = queryString.stringify(
      {
        filesName,
        filesMd5,
        filesSize,
      },
      { arrayFormat: 'bracket' }
    );
    const { data } = yield call(Api.getPreSignedUrls(params));
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* ensureGetNewMexicoDocuments() {
  try {
    const { data } = yield call(Api.getNewMexicoDocument());
    yield put({ type: getNewMexicoDocuments.success, payload: data });
  } catch (err) {
    yield put({ type: getNewMexicoDocuments.failure, err });
  }
}

function* ensureDeleteNewMexicoDocument({ payload: { documentId } }) {
  try {
    yield call(Api.deleteNewMexicoDocument(documentId));
    yield put(getNewMexicoDocuments());
  } catch (err) {
    yield put({ type: getNewMexicoDocuments.failure, err });
  }
}

function* saveNewMexicoUploadSaga({ payload }) {
  try {
    const documents = payload.AWSData.filter((doc) => !doc.id).map(
      ({ key, filename }) => ({
        key,
        filename,
      })
    );
    yield call(Api.postNewMexicoDocument(payload.companyId), {
      data: JSON.stringify({ documents }),
    });
    yield put(getNewMexicoDocuments({}));
    yield put({ type: saveNewMexicoDocuments.success });
  } catch (err) {
    yield put({ type: saveNewMexicoDocuments.failure, err });
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

function* watchFileUpload() {
  yield takeLatest(saveNewMexicoDocuments.type, saveNewMexicoUploadSaga);
  yield take(saveNewMexicoDocuments.success);
}

function* watchGetNewMexicoFiles() {
  yield takeLatest(getNewMexicoDocuments.type, ensureGetNewMexicoDocuments);
  yield take(getNewMexicoDocuments.success);
}

function* watchDeleteNewMexicoFiles() {
  yield takeLatest(deleteNewMexicoDocument.type, ensureDeleteNewMexicoDocument);
  yield take(deleteNewMexicoDocument.success);
}

export default function* editNewMexicoSagas() {
  yield all([
    fork(watchGetPreSignedUrls),
    fork(watchFileUpload),
    fork(watchGetNewMexicoFiles),
    fork(watchDeleteNewMexicoFiles),
  ]);
}
