import React from 'react';
import PropTypes from 'prop-types';
import {
  useRecordContext,
  DateField,
} from 'react-admin';
import get from 'lodash/get';
import {
  setLocalZone,
} from 'now-shared/helpers/time-helpers';

const DateFieldWithTimeZone = props => {
  const {
    source,
    timeZone,
    ...rest
  } = props;

  const record = useRecordContext(props);
  const value = get(record, source);

  const newRecord = (timeZone && value)
    ? {
      ...record,
      [source]: setLocalZone(new Date(value), timeZone),
    }
    : undefined;

  return (
    <DateField
      source={source}
      {...rest}
      {...newRecord && {
        record: newRecord,
      }}
    />
  );
};

DateFieldWithTimeZone.propTypes = {
  source: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
};

DateFieldWithTimeZone.defaultProps = {
  timeZone: undefined,
};

export default DateFieldWithTimeZone;
