import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import OtpVerify from './verifyOtp';

const styles = {
  main: { background: '#CECECE' },
  avatar: {
    margin: '0px',
  },
  icon: { display: 'none' },
};

const CustomOtpForm = withStyles({
  button: { background: '#F15922' },
})(OtpVerify);

const CustomLogin = props => (
  // eslint-disable-next-line react/jsx-no-undef
  <Login backgroundImage="" {...props}>
    <CustomOtpForm {...props} />
  </Login>
);

export default withStyles(styles)(CustomLogin);
