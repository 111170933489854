import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

import Api from 'api/seller-agreements';
import { getSellerAgreementTemplate } from 'store/actions/seller-agreement-template-actions';

function* ensureGetSellerAgreementTemplate({ payload }) {
  let resolve;
  let reject;
  if (payload) {
    ({ resolve, reject } = payload);
  }
  try {
    const { data } = yield call(Api.getSellerAgreementTemplate);
    yield put({
      type: getSellerAgreementTemplate.success,
      payload: {
        document: data.content,
        version: data.updatedAt,
      },
    });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({
      type: getSellerAgreementTemplate.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetSellerAgreementTemplate() {
  yield takeLatest(getSellerAgreementTemplate.type, ensureGetSellerAgreementTemplate);
  yield take(getSellerAgreementTemplate.success);
}

export default function* sellerAgreementTemplateSagas() {
  yield all([
    fork(watchGetSellerAgreementTemplate),
  ]);
}
