import React from 'react';
import {
  TextInput,
  List,
  Datagrid,
  FunctionField,
  BooleanField,
  TextField,
  Filter,
  NullableBooleanInput,
  EmailField,
} from 'react-admin';
import {
  makeStyles,
} from '@material-ui/core/styles';
import { CustomPagination } from '../components/pagination';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';

import { SortDirection } from 'now-shared/enums/sort-direction';
import { ComplianceTodoTypeTitle } from 'now-shared/enums/compliance-todo-type';

export const complianceTodosResourceName = 'compliance-todos';

const ComplianceTodosFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <NullableBooleanInput label="R.R. Needed" source="requiresRepresentativeAction" alwaysOn />
    <NullableBooleanInput label="C.S. Needed" source="requiresSupervisorAction" alwaysOn />
  </Filter>
);

const useStyles = makeStyles({
  complianceNotes: {
    whiteSpace: 'nowrap',
    maxWidth: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    direction: 'rtl',
  },
});

export const ComplianceTodoList = props => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<ComplianceTodosFilter />}
      sort={{
        field: 'createdAt',
        order: SortDirection.Descending,
      }}
      pagination={<CustomPagination />}
      exporter={false}
      bulkActionButtons={false}
      perPage={50}
    >
      <Datagrid
        rowClick={(_id, _resource, record) => record.uiActionLink}
      >
        <DateFieldWithTimeZone
          source="createdAt"
          label="Date & Time"
          timeZone={NONOPWELLS_TIME_ZONE}
          showTime
        />
        <FunctionField
          render={record => ComplianceTodoTypeTitle[record.todoType]}
          label="Action"
          sortable={false}
        />
        <TextField source="createdBy.firstName" label="First Name" />
        <TextField source="createdBy.lastName" label="Last Name" />
        <EmailField source="createdBy.email" label="Email" />
        <TextField source="company.fullLegalCompanyName" label="Company" sortBy="company.fullLegalCompanyName" />
        <BooleanField source="requiresRepresentativeAction" label="R.R. Needed" sortable={false} />
        <BooleanField source="requiresSupervisorAction" label="C.S. Needed" sortable={false} />
        <TextField
          source="company.complianceNotes"
          label="Notes"
          sortable={false}
          cellClassName={classes.complianceNotes}
        />
      </Datagrid>
    </List>
  );
};
