// actions
import * as A from '../actions/document-templates-actions';

const initialState = {
  documentTemplateIsSaving: false,
};

const reducer = {
  documentTemplates(state = initialState, action) {
    switch (action.type) {
      case A.reset.type:
        return initialState;

      case A.updateDocumentTemplate.type:
        return { ...state, documentTemplateIsSaving: true };

      case A.updateDocumentTemplate.failure:
        return { ...state, documentTemplateIsSaving: false };

      case A.updateDocumentTemplate.success:
        return {
          ...state,
          documentTemplateIsSaving: false,
        };

      default:
        return state;
    }
  },
};

export default reducer;
