import {
  all,
  fork,
  take,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

// actions
import {
  getAllRoles,
  createRole,
  updateRole,
} from '../actions/roles-actions';
import { getRolePermissions } from 'store/actions/permissions-actions';

// api methods
import Api from 'api/roles';

function* ensureGetAllRoles() {
  try {
    const { data } = yield call(Api.getAllRoles);
    yield put({ type: getAllRoles.success, payload: data });
  } catch (err) {
    yield put({ type: getAllRoles.failure, payload: err, error: true });
  }
}

function* ensureCreateRole({ payload: { title, type, permissionIdsToAdd } }) {
  try {
    yield call(Api.createRole({ title, type, permissionIdsToAdd }));
    yield put({ type: createRole.success });

    // After creating a role, refetch roles and role permissions
    yield put({ type: getAllRoles.type });
    if (permissionIdsToAdd?.length) {
      yield put({ type: getRolePermissions.type });
    }
  } catch (err) {
    yield put({ type: createRole.failure, payload: err, error: true });
  }
}

function* ensureUpdateRole({
  payload: {
    roleId,
    title,
    type,
    permissionIdsToAdd,
    permissionIdsToRemove,
    updateRoleUsersPermissions,
  },
}) {
  try {
    if (!title || !type) throw new Error('Title and Website type required');
    else {
      yield call(Api.updateRole({
        roleId,
        title,
        type,
        permissionIdsToAdd,
        permissionIdsToRemove,
        updateRoleUsersPermissions,
      }));
      yield put({ type: updateRole.success });

      if (title || type) {
        // Refetch roles after updating
        yield put({ type: getAllRoles.type });
      }

      if (permissionIdsToAdd?.length || permissionIdsToRemove?.length) {
        // Refetch role permissions after updating
        yield put({ type: getRolePermissions.type });
      }
    }
  } catch (err) {
    yield put({ type: updateRole.failure, payload: err, error: true });
  }
}

function* watchGetAllRoles() {
  yield takeLatest(getAllRoles.type, ensureGetAllRoles);
  yield take(getAllRoles.success);
}

function* watchCreateRole() {
  yield takeLatest(createRole.type, ensureCreateRole);
  yield take(createRole.success);
}

function* watchUpdateRole() {
  yield takeLatest(updateRole.type, ensureUpdateRole);
  yield take(updateRole.success);
}

export default function* rolesSagas() {
  yield all([
    fork(watchGetAllRoles),
    fork(watchCreateRole),
    fork(watchUpdateRole),
  ]);
}
