import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getAllPermissions: makeAxiosRequest('/admin/permissions', { method: 'get' }, true),

  getRolePermissions: makeAxiosRequest('/admin/permissions/role-permissions', { method: 'get' }, true),

  getUserPermissions: ({ userId }) => makeAxiosRequest(
    `/admin/permissions/${userId}`,
    { method: 'get' },
    true,
  ),

  addPermissionsByRole: ({ userId, roleId }) => makeAxiosRequest(
    `/admin/permissions/${userId}/add-by-role/${roleId}`,
    { method: 'post' },
    true,
  ),

  removePermissionsByRole: ({ userId, roleId }) => makeAxiosRequest(
    `/admin/permissions/${userId}/remove-by-role/${roleId}`,
    { method: 'delete' },
    true,
  ),

  updateUserPermissions: ({ userId, permissionIdsToAdd, permissionIdsToRemove }) => makeAxiosRequest(
    `/admin/permissions/${userId}/update-user-permissions`,
    { method: 'put', data: { permissionIdsToAdd, permissionIdsToRemove } },
    true,
  ),
};
