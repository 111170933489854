import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

import Api from 'api/document-templates';
import { updateDocumentTemplate } from 'store/actions/document-templates-actions';

function* ensureUpdateDocumentTemplate({ payload }) {
  const {
    templateType,
    resolve,
    reject,
    ...payloadRest
  } = payload;
  try {
    yield call(Api.updateDocumentTemplate(templateType), { data: payloadRest });
    yield put({ type: updateDocumentTemplate.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({
      type: updateDocumentTemplate.failure,
      err,
    });
    if (reject) {
      reject(err);
    }
  }
}

function* watchUpdateDocumentTemplate() {
  yield takeLatest(updateDocumentTemplate.type, ensureUpdateDocumentTemplate);
  yield take(updateDocumentTemplate.success);
}

export default function* documentTemplateSagas() {
  yield all([
    fork(watchUpdateDocumentTemplate),
  ]);
}
