import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  List,
  Datagrid,
  Create,
  FunctionField,
  NumberField,
  Edit,
  SimpleForm,
  TextField,
  Filter,
  linkToRecord,
  useNotify,
  DateInput,
  Labeled,
  useRefresh,
} from 'react-admin';
import { CustomPagination } from '../components/pagination';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DeleteButtonWithConfirmation from 'components/deleteButtonWithConfirmation';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserData } from 'auth/auth-helpers';
import {
  disabledWrapper,
  isUiValidationDisabled,
} from 'now-frontend-shared/helpers/ui-validation-toggle';
import { isFieldRequired } from 'now-shared/validation/company';
import FormSpyForDataProvider from 'components/FormSpyForDataProvider';
import FormDataConsumer from 'components/FormDataConsumer';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import { hasPermissionToEditField } from 'now-shared/validation/historical-listing-validation';
import CsvImportButton from 'components/importCsvButton';

export const HistoricalListingsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const HistoricalListingsList = (props) => {
  const refresh = useRefresh();
  return (
    <>
      <CsvImportButton refresh={refresh} />
      <h1>Historical Listings</h1>
      <List
        {...props}
        filters={<HistoricalListingsFilter />}
        sort={{
          field: 'id',
          order: SortDirection.Descending,
        }}
        pagination={<CustomPagination />}
        exporter={false}
        bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
        perPage={50}
      >
        <Datagrid rowClick="edit">
          <NumberField source="historicalId" label="ID" />
          <TextField source="basin" label="Basin" />
          <TextField source="county" label="County" />
          <TextField source="state" label="State" />
          <TextField source="grantorRaw" label="Grantor Raw" />
          <TextField source="grantorParent" label="Grantor Parent" />
          <TextField source="granterSignatory" label="Granter Signatory" />
          <TextField source="grantee" label="Grantee" />
          <TextField source="granteeParent" label="Grantee Parent" />
          <TextField source="granteeSignatory" label="Grantee Signatory" />
          <TextField source="recorded" label="Recorded" />
          <TextField source="effective" label="Effective" />
          <TextField source="letterAgreement" label="Letter Agreement" />
          <TextField source="transactionMonth" label="Transaction Month" />
          <TextField source="originalOperator" label="Original Operator" />
          <TextField source="currentOperator" label="Current Operator" />
          <TextField source="api" label="API" />
          <TextField source="wellName" label="Well Name" />
          <TextField source="surfaceLat" label="Surface Lat" />
          <TextField source="surfaceLong" label="Surface Long" />
          <TextField source="bottomLat" label="Bottom Lat" />
          <TextField source="bottomLong" label="Bottom Long" />
          <TextField source="interval" label="Interval" />
          <TextField source="status" label="Status" />
          <TextField source="spudDate" label="Spud Date" />
          <TextField source="completion" label="Completion" />
          <TextField source="prodMonth" label="Prod Month" />
          <TextField source="oilSpot" label="Oil Spot" />
          <TextField source="oilFourMonthFwd" label="Oil Four Month Fwd" />
          <TextField source="gasSpot" label="Gas Spot" />
          <TextField source="cashCarry" label="Cash/Carry" />
          <TextField source="assignedPortion" label="Assigned Portion" />
          <TextField source="carryPercent" label="Carry Percent" />
          <TextField source="WIPercent" label="WI Percent" />
          <TextField source="NRIPercent" label="NRI Percent" />
          <TextField source="notes" label="Notes" />
        </Datagrid>
      </List>
    </>
  );
};

const useHistoricalListingFormItems = ({
  // eslint-disable-next-line no-unused-vars
  formType,
}) => {
  const userData = useMemo(() => getUserData(), []);

  const hasPermissionToEdit = (values, field) =>
    hasPermissionToEditField(userData, values, field);

  const shouldShowField = useCallback((values, field, form) => {
    const fieldState = form?.getFieldState(field);
    let result;
    if (isUiValidationDisabled()) {
      result = true;
    } else if (fieldState?.dirty) {
      result = true;
    } else if (values[field]) {
      result = true;
    } else {
      result = true;
    }
    return result;
  }, []);

  const shouldDisableField = (values, field, checkCustom) =>
    disabledWrapper(() => {
      let result = false;
      if (!hasPermissionToEdit(values, field)) {
        result = true;
      } else if (checkCustom) {
        result = checkCustom(field, values);
      }
      return result;
    });

  return [
    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('historicalId', 'ID'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('basin', 'Basin'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('county', 'County'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('state', 'State'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('grantorRaw', 'Grantor Raw'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('grantorParent', 'Grantor Parent'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('granterSignatory', 'Granter Signatory'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('grantee', 'Grantee'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('granteeParent', 'Grantee Parent'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('granteeSignatory', 'Grantee Signatory'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('recorded', 'Recorded'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('effective', 'Effective'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('letterAgreement', 'Letter Agreement'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('transactionMonth', 'Transaction Month'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('originalOperator', 'Original Operator'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('currentOperator', 'Current Operator'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('api', 'API'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('wellName', 'Well Name'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('surfaceLat', 'Surface Lat'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('surfaceLong', 'Surface Long'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('bottomLat', 'Bottom Lat'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('bottomLong', 'Bottom Long'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('interval', 'Interval'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('status', 'Status'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('spudDate', 'Spud Date'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('completion', 'Completion'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('prodMonth', 'Prod Month'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('oilSpot', 'Oil Spot'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('oilFourMonthFwd', 'Oil Four Month Fwd'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('gasSpot', 'Gas Spot'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('cashCarry', 'Cash/Carry'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('assignedPortion', 'Assigned Portion'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('carryPercent', 'Carry Percent'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('WIPercent', 'WI Percent'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('NRIPercent', 'NRI Percent'),

    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('notes', 'Notes'),
  ];
};

const getSharedFormProps = ({ isForCreate, ...props }) => ({
  ...getStandardFormProps({ isForCreate, ...props }),
  redirect: '/historical-transactions',
});

const HistoricalListingEditForm = (propsAll) => {
  const { formType, ...props } = propsAll;

  const notify = useNotify();

  return (
    <>
      <Edit {...getStandardCreateEditProps({ notify, ...props })}>
        <SimpleForm {...getSharedFormProps({ ...props })}>
          <FormSpyForDataProvider />
          {useHistoricalListingFormItems(propsAll)}
          <Labeled label="Created At">
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
            />
          </Labeled>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.createdBy && (
                <Labeled label="Created By">
                  <FunctionField
                    render={(record) =>
                      record.createdBy && (
                        <LinkButton
                          label={getUserFullName(record.createdBy)}
                          buttonColor="clearGreen"
                          path={linkToRecord('/users', record.createdBy.id)}
                        />
                      )
                    }
                    {...rest}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.updatedAt !== formData.createdAt && (
                <Labeled label="Updated At">
                  <DateFieldWithTimeZone
                    {...rest}
                    source="updatedAt"
                    showTime
                    timeZone={NONOPWELLS_TIME_ZONE}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.updatedBy && (
                <Labeled label="Updated By">
                  <FunctionField
                    render={(record) =>
                      record.updatedBy && (
                        <LinkButton
                          label={getUserFullName(record.updatedBy)}
                          buttonColor="clearGreen"
                          path={linkToRecord('/users', record.updatedBy.id)}
                        />
                      )
                    }
                    {...rest}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};

const CreateEditComponent = (props) => {
  const { formType, ...propsRest } = props;

  const notify = useNotify();

  const formItems = useHistoricalListingFormItems(props);

  return formType === 'create' ? (
    <Create
      {...getStandardCreateEditProps({
        notify,
        isForCreate: true,
        ...propsRest,
      })}
    >
      <SimpleForm {...getSharedFormProps({ isForCreate: true, ...propsRest })}>
        <FormSpyForDataProvider />
        {formItems}
      </SimpleForm>
    </Create>
  ) : (
    <HistoricalListingEditForm {...props} />
  );
};

CreateEditComponent.propTypes = {
  formType: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export const HistoricalListingCreate = (props) => (
  <CreateEditComponent formType="create" {...props} />
);

export const HistoricalListingEdit = (props) => (
  <CreateEditComponent formType="edit" {...props} />
);
