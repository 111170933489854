import React from "react";
import { Route, Redirect } from "react-router-dom";
import ChangePasswordPage from "../pages/changingPassword";
import CloneListing from "../pages/CloneListing";
import CompanyComplianceDoc from "../pages/CompanyComplianceDoc";
import EditBuyerAgreementTemplate from "../pages/EditBuyerAgreementTemplate";
import EditSellerAgreementTemplate from "../pages/EditSellerAgreementTemplate";
import EmailCommunications from "pages/EmailCommunications";
import EmailLogs from "pages/EmailLogs";
import otpverifyModal from "pages/otpverifyModal";

export default [
  <Route
    exact
    path="/verifyOtp"
    component={otpverifyModal}
    key="otpVerify"
    noLayout
  />,
  <Route
    exact
    path="/change-password"
    component={ChangePasswordPage}
    key="changePassword"
  />,
  <Route
    exact
    path="/properties/:id/reopen"
    render={(props) => <CloneListing listingId={props.match.params.id} />}
    key="reopenListing"
  />,
  <Route
    exact
    path="/companies/:id/compliance-doc"
    render={(props) => (
      <CompanyComplianceDoc companyId={props.match.params.id} />
    )}
    noLayout
    key="companyComplianceDoc"
  />,
  <Route exact path="/seller-agreements" key="sellerAgreements">
    <Redirect to="/companies" />
  </Route>,
  <Route exact path="/buyer-agreements" key="buyerAgreements">
    <Redirect to="/companies" />
  </Route>,
  <Route
    exact
    path="/buyer-agreement-template/edit"
    component={EditBuyerAgreementTemplate}
    key="editBuyerAgreementTemplate"
  />,
  <Route
    exact
    path="/email-communications"
    component={EmailCommunications}
    key="editBuyerAgreementTemplate"
  />,
  <Route
    exact
    path="/email-logs"
    component={EmailLogs}
    key="emailCommunicationsTemplate"
  />,
  <Route
    exact
    path="/seller-agreement-template/edit"
    component={EditSellerAgreementTemplate}
    key="editSellerAgreementTemplate"
  />,
  <Route exact path="/bank-information" key="bankInformation">
    <Redirect to="/companies" />
  </Route>,
];
