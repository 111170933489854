// actions
import * as A from '../actions/listingAnalyticsActions';

const initialState = {
  analytics: undefined,
  analyticsIsLoading: false,
};

const reducer = {
  listingAnalytics(state = initialState, action) {
    switch (action.type) {
      case A.clearListingAnalyticsState.type:
        return initialState;

      case A.getListingAnalytics.type:
        return { ...state, analyticsIsLoading: true };

      case A.getListingAnalytics.failure:
        return { ...state, analyticsIsLoading: false };

      case A.getListingAnalytics.success: {
        return {
          ...state,
          analytics: {
            companies: action.payload,
          },
          analyticsIsLoading: false,
        };
      }

      case A.createCustomAnalytics.success: {
        return {
          ...state,
          currentAddedAnalytic: action.payload,
        };
      }

      case A.updateCustomAnalytics.success: {
        return {
          ...state,
          currentAddedAnalytic: action.payload,
        };
      }

      case A.deleteCustomAnalytics.success: {
        return {
          ...state,
          currentAddedAnalytic: action.payload,
        };
      }

      case A.getCustomListingAnalytics.success: {
        return {
          ...state,
          customAnalytics: action.payload,
        };
      }

      case A.getListingNotification.success: {
        return {
          ...state,
          listingNotifications: action.payload,
        };
      }

      default:
        return state;
    }
  },
};

export default reducer;
