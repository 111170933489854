import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox, Grid } from '@material-ui/core';
import ErrorField from 'now-frontend-shared/components/inputs/ErrorField';
import EmptyIcon from 'now-frontend-shared/components/Checkboxes/Checkbox/components/EmptyIcon';
import SquareIcon from 'now-frontend-shared/components/Checkboxes/Checkbox/components/SquareIcon';
import CheckIcon from 'now-frontend-shared/components/Checkboxes/Checkbox/components/CheckIcon';

// styles and components from material-ui

// styles
import styles from './styles';

const CheckboxInput = ({
  classes, meta, label, input, withSquareIcon, onChange, ...rest
}) => {
  const isError = meta.touched && meta.error;
  return (
    <Grid container alignItems="baseline" className={classes.container}>
      <MUICheckbox
        className={classes.root}
        color="primary"
        id={input.name}
        icon={<EmptyIcon />}
        checkedIcon={withSquareIcon ? <SquareIcon /> : <CheckIcon />}
        onChange={onChange}
        checked={Boolean(input.value)}
        {...input}
        {...rest}
      />

      <label htmlFor={input.name} className={classes.label}>
        {label}
      </label>

      <div className={classes.errorWrapper}>
        <ErrorField error={isError ? meta.error : null} />
      </div>
    </Grid>
  );
};

CheckboxInput.defaultProps = {
  withSquareIcon: false,
};

CheckboxInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  withSquareIcon: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(withStyles(styles), memo)(CheckboxInput);
