import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

// actions
import {
  getPreSignedUrls, saveUserComplianceDocuments, getUserComplianceDocuments, deleteUserComplianceDocument,
} from '../actions/user-admin-documents';

// api methods
import Api from 'api/users';

import queryString from 'query-string';

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });
    const { data } = yield call(Api.getPreSignedUrls(params));
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* ensureDeleteUserComplianceDocument({ payload: { userId, documentId } }) {
  try {
    yield call(Api.deleteUserComplianceDocument(userId, documentId));
    yield put(getUserComplianceDocuments({ userId }));
  } catch (err) {
    yield put({ type: deleteUserComplianceDocument.failure, err });
  }
}

function* ensureGetUserComplianceDocuments({ payload: { userId } }) {
  try {
    const { data } = yield call(Api.getUserComplianceDocument(userId));
    yield put({ type: getUserComplianceDocuments.success, payload: data });
  } catch (err) {
    yield put({ type: getUserComplianceDocuments.failure, err });
  }
}

function* saveUserUploadSaga({ payload }) {
  try {
    const documents = payload.AWSData.filter(doc => !doc.id).map(
      ({
        key,
        filename,
      }) => ({
        key,
        filename,
      }),
    );
    yield call(Api.postUserComplianceDocument(payload.userId), {
      data: JSON.stringify({ documents }),
    });
    yield put(getUserComplianceDocuments({ userId: payload.userId }));
    yield put({ type: saveUserComplianceDocuments.success });
  } catch (err) {
    yield put({ type: saveUserComplianceDocuments.failure, err });
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

function* watchFileUpload() {
  yield takeLatest(saveUserComplianceDocuments.type, saveUserUploadSaga);
  yield take(saveUserComplianceDocuments.success);
}

function* watchGetComplianceFiles() {
  yield takeLatest(getUserComplianceDocuments.type, ensureGetUserComplianceDocuments);
  yield take(getUserComplianceDocuments.success);
}

function* watchDeleteComplianceFile() {
  yield takeLatest(deleteUserComplianceDocument.type, ensureDeleteUserComplianceDocument);
  yield take(deleteUserComplianceDocument.success);
}

export default function* editListingSagas() {
  yield all([
    fork(watchGetPreSignedUrls),
    fork(watchFileUpload),
    fork(watchGetComplianceFiles),
    fork(watchDeleteComplianceFile),
  ]);
}
