import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import LinkButton from 'now-frontend-shared/components/LinkButton';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

// styles
import styles from './styles';

import {
  LocationOnOutlined,
  TimerOutlined,
  AttachMoney,
  Clear,
} from '@material-ui/icons';
import { toAuctionEventTime } from 'now-shared/helpers/time-helpers';
import Countdown from 'now-frontend-shared/components/Countdown';
import Watermark from '../../../Watermark';
import WellsInfoTable from './WellsInfo';
import {
  TransactionTypeTitles,
  TransactionTypes,
} from 'now-shared/enums/transaction-types';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { connect } from 'react-redux';

const InfoWindow = ({
  classes,
  isCluster,
  isEmbedded,
  pointCount,
  listings,
  handleToggleScrollwheel,
  handleCloseInfoWindow,
  userFullName,
  companyName,
}) => {
  const isMobile = useIsMobile();
  const transform = useMemo(() => {
    if (isCluster && !isEmbedded) {
      return `${
        isMobile ? 'translateX(-50%)' : 'translateX(-8px)'
      } translateY(-${10 + pointCount * 2}px)`;
    }
    return '';
  }, [isCluster, isMobile, isEmbedded, pointCount]);

  const getLocationString = listing => {
    const { stateTitle, countyTitle, basinTitle } = listing;
    return `${stateTitle}, ${countyTitle}, ${basinTitle}`;
  };

  const watermarkText = `${companyName} / ${userFullName}`;

  return (
    <div
      className={isEmbedded ? classes.infoWindowBase : classes.infoWindow}
      style={{ transform }}
      {...(handleToggleScrollwheel && {
        onMouseEnter: handleToggleScrollwheel,
        onMouseLeave: handleToggleScrollwheel,
        onTouchStartCapture: handleToggleScrollwheel,
      })}
    >
      {isCluster && (
        <Grid container>
          <span className={classes.infoHeading}>
            Multiple listings in this location/county...
          </span>
        </Grid>
      )}

      <Grid
        container
        className={cx(classes.infoContainer, {
          [classes.infoContainerOverflow]: pointCount > 4,
        })}
        style={{ width: '100%' }}
      >
        {listings.map(
          listing => (
            <Box key={listing.id} style={{ width: '100%' }}>
              <Box className={classes.propertyDescriptionContainer}>
                <Watermark text={watermarkText} rotate="-20" textSize={15}>
                  <Box className={classes.propertyDescriptionHeader}>
                    <Grid container>
                      <Grid item style={{ marginRight: 10 }}>
                        <span className={classes.propertyNameText}>
                          {`${listing.projectName}`}
                        </span>
                      </Grid>
                      <Grid item>
                        <LinkButton
                          label="View Listing"
                          path={`/listings/${listing.propertyId}`}
                          customStyles={{
                            color: 'white',
                            height: '35px',
                            display: 'flex',
                            alignItems: 'center',
                            margin: 'unset',
                            borderRadius: '8px',
                            fontWeight: 'normal',
                            width: 'auto',
                            padding: '15px',
                            backgroundColor: '#3D4F5F',
                            border: '1px solid white',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <Clear
                        onClick={handleCloseInfoWindow}
                        className={classes.closeIcon}
                      />
                    </Grid>
                  </Box>
                  <Grid container className={classes.propertyDescription}>
                    <Box className={classes.propertyDescriptionWrapper}>
                      <LocationOnOutlined
                        style={{ color: 'white', marginRight: 3 }}
                      />
                      <span style={{ color: '#E5E5E5' }}>
                        {getLocationString(listing)}
                      </span>
                    </Box>
                    <Box className={classes.propertyDescriptionWrapper}>
                      <TimerOutlined
                        style={{ color: 'white', marginRight: 3 }}
                      />
                      <Countdown
                        customStyles={{
                          color: '#E5E5E5',
                          fontWeight: 'unset',
                          marginTop: 4,
                          fontSize: 'unset',
                          lineHeight: 'unset',
                        }}
                        endTime={toAuctionEventTime(
                          new Date(listing.endTime),
                          'end',
                        )
                          .toJSDate()
                          .toISOString()}
                        withoutAdaptive
                      />
                    </Box>
                    <Box className={classes.propertyDescriptionWrapper}>
                      <AttachMoney style={{ color: 'white' }} />
                      <span style={{ color: '#E5E5E5' }}>
                        {TransactionTypeTitles[listing.transactionType]
                          === TransactionTypeTitles[TransactionTypes.Either]
                          ? 'Cash/Carry'
                          : TransactionTypeTitles[
                            listing.transactionType
                          ]}
                        {' '}
                        Transaction
                      </span>
                    </Box>
                  </Grid>
                </Watermark>
              </Box>
              <Watermark text={watermarkText} rotate="-20" textSize={15}>
                <Box className={classes.wellTableWrapper}>
                  <WellsInfoTable wells={listing.wells} />
                </Box>
              </Watermark>
            </Box>
          ),
        )}
      </Grid>

      {isCluster && (
        <Grid container direction="column">
          <span className={classes.infoCount}>
            There are
            {' '}
            <span
              className={classes.infoCountBold}
            >
              {`${pointCount} additional `}
            </span>
            listings at this location
          </span>

          <span className={classes.infoMessage}>
            Zoom in to see additional listings.
          </span>
        </Grid>
      )}
    </div>
  );
};

InfoWindow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCluster: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  pointCount: PropTypes.number,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  handleToggleScrollwheel: PropTypes.func,
  handleCloseInfoWindow: PropTypes.func,
  userFullName: PropTypes.string,
  companyName: PropTypes.string,
};

InfoWindow.defaultProps = {
  isCluster: false,
  isEmbedded: false,
  pointCount: 0,
  handleToggleScrollwheel: undefined,
  handleCloseInfoWindow: undefined,
  userFullName: '',
  companyName: '',
};

export default compose(
  withStyles(styles),
  connect(({ auth, company }) => ({
    userFullName: getUserFullName(auth.user),
    companyName: company.fullLegalCompanyName,
  })),
  memo,
)(InfoWindow);
