import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// TODO: Consider installing http-status-codes and using that to get the status code name

export default function ErrorHandler({ reduxStateName }) {
  const stateError = useSelector(state => state[reduxStateName]?.error);

  useEffect(() => {
    if (stateError) {
      const status = stateError.response?.status;
      const message = stateError.response?.data?.message || stateError.message;
      toast.error(`There was a status ${status} error while updating the ${reduxStateName} redux state: ${message}`);
    }
  }, [stateError]);

  return null; // This component doesn't render anything itself
}

ErrorHandler.propTypes = {
  reduxStateName: PropTypes.string.isRequired,
};
