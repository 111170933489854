export class RateLimiter {
  lastRunCompleteTime = 0
  delayInMillis;
  handler;
  nextRunTimer;
  immediateIsQueued = false;
  onlyHandleImmediates;
  isRunningImmediate = false;
  lastResult;
  startWithDelay;

  constructor(options) {
    this.delayInMillis = options.delayInMillis || 0;
    this.handler = options.handler;
    this.onlyHandleImmediates = options.onlyHandleImmediates;
    this.startWithDelay = options.startWithDelay;
  }

  clear() {
    this.immediateIsQueued = false;
    if (this.nextRunTimer !== undefined) {
      clearTimeout(this.nextRunTimer);
      this.nextRunTimer = undefined;
    }
  }

  destroy() {
    this.clear();
  }

  // eslint-disable-next-line no-underscore-dangle
  async _run(immediate) {
    if (this.handler) {
      if (immediate || !this.onlyHandleImmediates) {
        if (immediate) {
          this.clear();
          this.isRunningImmediate = true;
        }
        try {
          await this.handler(immediate);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        if (immediate) {
          this.lastRunCompleteTime = Date.now();
          this.isRunningImmediate = false;
          if (this.immediateIsQueued) {
            this.immediateIsQueued = false;
            this.run({ immediate: true });
          }
        }
      }
    }
  }

  async run(options) {
    const optionsConst = options || {};
    if (optionsConst.handler) {
      this.handler = optionsConst.handler;
    }
    if (optionsConst.startWithDelay !== undefined) {
      this.startWithDelay = optionsConst.startWithDelay;
    }
    if (!optionsConst.immediate || this.isRunningImmediate) {
      const millisSinceLastRender = Date.now() - this.lastRunCompleteTime;
      let waitTimeMillis = this.delayInMillis - millisSinceLastRender;
      if (waitTimeMillis <= 0) {
        if (this.startWithDelay) {
          waitTimeMillis = this.delayInMillis;
        } else {
          waitTimeMillis = 0;
        }
      }
      if (waitTimeMillis > 0 || this.isRunningImmediate) {
        if (this.nextRunTimer === undefined && !this.immediateIsQueued) {
          if (this.isRunningImmediate) {
            this.immediateIsQueued = true;
          } else {
            this.nextRunTimer = setTimeout(() => this.run({ immediate: true }), waitTimeMillis);
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        await this._run(false);
        return false;
      }
    }
    // eslint-disable-next-line no-underscore-dangle
    await this._run(true);
    return true;
  }
}
