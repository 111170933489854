import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction('userAdminDocuments/GET_PRE_SIGNED_URLS');
export const saveUserComplianceDocuments = asyncAction('userAdminDocuments/SAVE_USER_COMPLIANCE_DOCUMENTS');
export const getUserComplianceDocuments = asyncAction('userAdminDocuments/GET_USER_COMPLIANCE_DOCUMENTS');
export const deleteUserComplianceDocument = asyncAction('userAdminDocuments/DELETE_USER_COMPLIANCE_DOCUMENT');

// sync action
export const setAllAWSData = action('userAdminDocuments/SET_ALL_AWS_DATA');
export const setAWSData = action('userAdminDocuments/SET_AWS_DATA');
export const removeAWSDataFile = action('userAdminDocuments/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('userAdminDocuments/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('userAdminDocuments/SET_UNLOADED_FILES_EXIST');
