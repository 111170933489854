import { uniqBy, get } from 'lodash';

const sortByTitle = ((a, b) => {
  const nameA = a.title.toUpperCase();
  const nameB = b.title.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

function decompile(states = [], queryParams) {
  const countyId = queryParams.get('county');
  const basinId = queryParams.get('basin');
  const stateId = queryParams.get('state');
  let filteredStates = states.map(item => ({
    id: item.id,
    title: item.title,
    basinIds: item.basins.map(basin => basin.id),
  }));
  let basins = states.map(state => state.basins.map(basin => ({
    ...basin,
    state: {
      id: state.id,
      title: state.title,
    },
  }))).reduce((result, basins) => ([...result, ...basins]), []);

  let counties = basins.map(basin => basin.counties.map(county => ({
    ...county,
    basin: {
      id: basin.id,
      title: basin.title,
    },
    state: {
      id: basin.state.id,
      title: basin.state.title,
    },
  }))).reduce((result, counties) => ([...result, ...counties]), []);

  if (stateId) {
    counties = counties.filter(item => item.state.id === parseInt(stateId, 10));
    basins = basins.filter(item => item.state.id === parseInt(stateId, 10));
  } else {
    const filteredStateIds = basins.map(item => item.state.id);
    filteredStates = filteredStates.filter(item => filteredStateIds.includes(item.id));
  }

  if (basinId) {
    counties = counties.filter(item => item.basin.id === parseInt(basinId, 10));
    filteredStates = filteredStates.filter(item => item.basinIds.includes(parseInt(basinId, 10)));
  }

  if (countyId) {
    const county = counties.find(item => item.id === parseInt(countyId, 10));
    basins = basins
      .filter(item => item.id === get(county, 'basin.id'));
    filteredStates = [get(county, 'state')];
  }

  return {
    counties,
    basins: uniqBy(basins, 'id'),
    states: filteredStates,
  };
}

export function pureDecompile(states = [], values) {
  if (!values.state) {
    values.basin = undefined;
    values.county = undefined;
  } else if (!values.basin) {
    values.county = undefined;
  }

  const countyId = get(values, 'county');
  const basinId = get(values, 'basin');
  const stateId = get(values, 'state');
  const clearOption = {
    id: null,
    title: '-Clear Selection-',
    basinIds: [],
  };

  let filteredStates = (states || []).map(item => ({
    id: item.id,
    title: item.title,
    basinIds: item.basins.map(basin => basin.id),
  }));

  filteredStates.unshift(clearOption);

  let basins = (states || []).map(state => state.basins.map(basin => ({
    ...basin,
    state: {
      id: state.id,
      title: state.title,
    },
  }))).reduce((result, basins) => ([...result, ...basins]), []);

  let counties = basins.map(basin => basin.counties.map(county => ({
    ...county,
    basin: {
      id: basin.id,
      title: basin.title,
    },
    state: {
      id: basin.state.id,
      title: basin.state.title,
    },
  }))).reduce((result, counties) => ([...result, ...counties]), []);

  if (stateId) {
    counties = counties.filter(item => item.state.id === parseInt(stateId, 10));
    basins = basins.filter(item => item.state.id === parseInt(stateId, 10));
  } else {
    const filteredStateIds = basins.map(item => item.state.id);
    filteredStates = filteredStates.filter(item => filteredStateIds.includes(item.id));
  }

  if (basinId) {
    counties = counties.filter(item => item.basin.id === parseInt(basinId, 10));
    filteredStates = filteredStates.filter(item => item.basinIds.includes(parseInt(basinId, 10)));

    basins.unshift(clearOption);

    if (stateId) {
      filteredStates.unshift(clearOption);
    }
  }

  if (countyId) {
    const county = counties.find(item => item.id === parseInt(countyId, 10));
    counties.unshift(clearOption);

    basins = basins
      .filter(item => item.id === get(county, 'basin.id'));
    if (basinId) {
      basins.unshift(clearOption);
    }

    filteredStates = [get(county, 'state')];
    if (stateId) {
      filteredStates.unshift(clearOption);
    }
  }

  return {
    counties: counties?.sort(sortByTitle) || [],
    basins: uniqBy(basins, 'id')?.sort(sortByTitle) || [],
    states: filteredStates || [],
  };
}

export default decompile;
