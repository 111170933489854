import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction('editBuyerAgreement/GET_PRE_SIGNED_URLS');

// sync action
export const reset = action('editBuyerAgreement/RESET');
export const setAllAWSData = action('editBuyerAgreement/SET_ALL_AWS_DATA');
export const setAWSData = action('editBuyerAgreement/SET_AWS_DATA');
export const removeAWSDataFile = action('editBuyerAgreement/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('editBuyerAgreement/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('editBuyerAgreement/SET_UNLOADED_FILES_EXIST');
