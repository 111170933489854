import React from 'react';
import {
  AppBar, Layout, UserMenu, MenuItemLink, translate,
} from 'react-admin';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

import { ModalProvider } from 'now-frontend-shared/hooks/useModal';
import { ScrollToTopController } from 'now-frontend-shared/components/ScrollToTopController';

import { Menu } from './menu';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const CustomUserMenu = translate(props => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/change-password"
      primaryText="Profile"
      leftIcon={<AccountCircleIcon />}
    />
  </UserMenu>
));

const MyAppBar = withStyles(styles)(({ classes, ...props }) => (
  <AppBar userMenu={<CustomUserMenu />} {...props}>
    <Typography
      variant="h6"
      color="inherit"
      className={classes.title}
    />
  </AppBar>
));

const CustomLayout = props => (
  <ModalProvider>
    <ScrollToTopController />
    <ToastContainer />
    <Layout {...props} appBar={MyAppBar} menu={Menu} />
  </ModalProvider>
);

export default CustomLayout;
