import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { DateTime } from 'luxon';
import {
  Field,
  FieldArray,
  reduxForm,
  getFormValues,
  change,
  untouch,
} from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withProps from 'recompose/withProps';

import { TransactionTypes } from 'now-shared/enums/transaction-types';
import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';
import GeneralInformation, {
  generalInformationTypes,
} from './components/GeneralInformation';
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import LandingZone from './components/LandingZone';
import DatePicker from 'now-frontend-shared/components/DatePicker';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import EsriMap from 'now-frontend-shared/components/EsriMap';
import Button from 'now-frontend-shared/components/Button';
import Spinner from 'now-frontend-shared/components/Spinner';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { cyan } from '@material-ui/core/colors';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';

// validate
import {
  defaultStartTimeForListing,
  defaultEndTimeForListing,
  minEndTimeForListing,
  minStartTimeForListing,
  listingHasBeenApproved,
  createPropertyValidate,
} from 'now-shared/validation/listing-validation';
import {
  NONOPWELLS_TIME_ZONE,
  toAuctionEventTime,
  NONOPWELLS_TIME_ZONE_LABEL,
  calculateDifference,
} from 'now-shared/helpers/time-helpers';
import {
  coordinatesGenerator,
  existingPropertyDateRangeGenerator,
} from 'now-frontend-shared/utils/helpers';
import { maskedAmountToNumber } from 'now-shared/helpers/currency-helpers';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import {
  computeWellsNetAfe,
  computeWellsGrossAfe,
} from 'now-shared/helpers/listing-helpers';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import useWindowWidth from 'now-frontend-shared/hooks/useWindowWidth';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';
import DateTimePicker from '../DateTimePicker';

const ListingForm = ({
  AWSData,
  children,
  classes,
  clearAWSData,
  currentProperty,
  formName,
  formValues,
  getBasins,
  getCounties,
  getLandingZones,
  getPreSignedUrls,
  handleSubmit,
  isBasedOnExisting,
  isForCreate,
  landingZones,
  onSubmit,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states,
  unloadedFilesExist,
  listingOwner,
  keepSessionAlive,
}) => {
  const dispatch = useDispatch();

  const [filesAreProcessing, setFilesAreProcessing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadsStarted, setUploadsStarted] = useState(false);
  const [uploadsStopped, setUploadsStopped] = useState(undefined);
  const [newDocsExist, setNewDocsExist] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [transactionType, setTransactionType] = useState('cash'); // cash - carry - either
  const isCurrentStatusIsDraft = formValues?.status?.title === 'draft';
  const hasBeenApproved
    = !isForCreate && currentProperty && listingHasBeenApproved(currentProperty);

  const totalWellNetAFE
    = computeWellsNetAfe(
      formValues.wells?.map(well => ({
        wellNetAFE: maskedAmountToNumber(well.wellNetAFE),
      })),
    )
    ?? formValues.netAfe
    ?? 0;

  useEffect(() => {
    setTransactionType(formValues.transactionType ?? 'cash');
  }, [formValues.transactionType]);

  useEffect(
    () => setNewDocsExist(
      unloadedFilesExist || AWSData.some(document => !document.id),
    ),
    [unloadedFilesExist, AWSData],
  );

  useEffect(() => {
    if (uploading && !uploadsStarted) {
      setUploadsStarted(true);
    }
  }, [uploading, uploadsStarted]);

  useEffect(() => {
    if (uploadsStarted) {
      setUploadsStopped(uploading ? undefined : new Date().getTime());
    }
  }, [uploadsStarted, uploading]);

  useEffect(() => {
    // Keep the session alive if files have been uploaded
    let intervalHandle;
    const clear = () => {
      if (intervalHandle) {
        clearInterval(intervalHandle);
        intervalHandle = undefined;
      }
    };

    // allow 12 hours of session keep-alive after downloads have stopped or finished
    const inactiveThreshold = 12 * 60 * 60 * 1000;

    const keepAlive = async () => {
      if (
        filesAreProcessing
        || !uploadsStopped
        || new Date().getTime() - uploadsStopped <= inactiveThreshold
      ) {
        try {
          await keepSessionAlive();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        clear();
      }
    };

    if (
      keepSessionAlive
      && (filesAreProcessing || (newDocsExist && uploadsStarted))
    ) {
      // every 10 minutes
      const interval = 10 * 60 * 1000;
      intervalHandle = setInterval(keepAlive, interval);
      keepAlive();
    }
    return clear;
  }, [
    filesAreProcessing,
    keepSessionAlive,
    newDocsExist,
    uploadsStarted,
    uploadsStopped,
  ]);

  const renderTransactionTypeControl = useCallback(
    () => (
      <LabelLayout label="Transaction Type" space="small" isRequired>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          row
          name="controlled-radio-buttons-group"
          style={{ justifyContent: 'flex-end', gap: 50 }}
          value={transactionType}
          onChange={e => {
            const transactionTypeValue = e.target.value;

            setTransactionType(transactionTypeValue);
            dispatch(change(formName, 'transactionType', transactionTypeValue));

            if (transactionTypeValue === 'cash') {
              dispatch(change(formName, 'minimumBidCarry', ''));
              dispatch(untouch(formName, 'minimumBidCarry'));
            }

            if (transactionTypeValue === 'carry') {
              dispatch(change(formName, 'minimumBid', ''));
              dispatch(untouch(formName, 'minimumBid'));
            }
          }}
        >
          <FormControlLabel
            value="cash"
            control={(
              <Radio
                style={{
                  color: cyan[800],
                  '&.MuiChecked': {
                    color: cyan[600],
                  },
                }}
              />
            )}
            label="Cash"
          />
          <FormControlLabel
            value="carry"
            control={(
              <Radio
                style={{
                  color: cyan[800],
                  '&.MuiChecked': {
                    color: cyan[600],
                  },
                }}
              />
            )}
            label="Carry"
          />
          <FormControlLabel
            value="either"
            control={(
              <Radio
                style={{
                  color: cyan[800],
                  '&.MuiChecked': {
                    color: cyan[600],
                  },
                }}
              />
            )}
            label="Either"
          />
        </RadioGroup>
      </LabelLayout>
    ),
    [transactionType, formName, dispatch],
  );

  const { setModal } = useModalSetter();
  const windowWidth = useWindowWidth();
  const isLaptop = useMemo(() => windowWidth < 1130, [windowWidth]);
  const hasRequiredUpload = AWSData.some(
    document => document.type === ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT,
  );

  useEffect(
    () =>
      // TODO: [UX][INTEGRITY] also handle clearing previous data when changing core props but not unmounting
      () => dispatch(clearAWSData()),
    [clearAWSData, dispatch],
  );

  useEffect(() => {
    if (isBasedOnExisting && !isForCreate && currentProperty) {
      const { startTime, endTime } = existingPropertyDateRangeGenerator(
        currentProperty.startTime,
        currentProperty.endTime,
      );
      dispatch(change(formName, 'startTime', startTime));
      dispatch(change(formName, 'endTime', endTime));
    }
  }, [currentProperty, formName, isBasedOnExisting, isForCreate, dispatch]);

  useEffect(() => {
    setIsAnonymous(!!isBasedOnExisting && !!currentProperty?.isAnonymous);
  }, [currentProperty, isBasedOnExisting]);

  const handleToggleCheckbox = () => {
    setIsAnonymous(prevChecked => !prevChecked);
  };

  const updateDraftState = state => {
    dispatch(change(formName, 'isDraft', state));
  };

  const createOnSubmitHandler
    = ({ createWithPendingUpdate = false }) => async ({
      startTime,
      endTime,
      minimumBid,
      minimumBidCarry,
      wells,
      ...data
    }) => {
      function convertToCentralISO(input) {
        const date = new Date(input);

        if (isNaN(date)) {
          return DateTime.now().toISO();
        }

        const originalZone = DateTime.fromJSDate(date).zoneName;
        if (originalZone === 'America/Chicago') {
          return date.toISOString();
        }
        const centralTime
            = DateTime.fromJSDate(date).setZone('America/Chicago');
        const isoString = centralTime
          .toUTC()
          .toFormat("yyyy-MM-dd'T'HH:mm:00.000'Z'");
        return isoString;
      }

      const netAfe
        = computeWellsNetAfe(
          wells.map(well => ({
            wellNetAFE: maskedAmountToNumber(well.wellNetAFE),
          })),
        ) ?? currentProperty?.netAfe;

      if (!hasRequiredUpload && !formValues.isDraft) {
        handleOpenErrorModal();
        return;
      }

      if (unloadedFilesExist && !createWithPendingUpdate) {
        handleOpenConfirmModal();
        return;
      }

      let formData = {};
      const documents = AWSData.map(({
        id, filename, key, type,
      }, index) => {
        const doc = {
          id,
          filename,
          key,
          type,
        };
        const comment = data.documents?.[index]?.comment;
        if (comment !== undefined) {
          doc.comment = comment;
        }
        return doc;
      });

      if (hasBeenApproved) {
        formData = {
          documents: documents.filter(document => !document.id),
        };
      } else {
        formData = {
          ...data,
          id: isForCreate ? undefined : data.id,
          netAfe,
          startTime: toAuctionEventTime(new Date(startTime), 'start')
            .toJSDate()
            .toISOString(),
          endTime: convertToCentralISO(endTime),
          transactionType,
          documents,
          isAnonymous,
          wells: wells.map(item => ({
            ...item,
            id: isForCreate ? undefined : item.id,
            workingInterestPercentage:
              maskedAmountToNumber(item.workingInterestPercentage) ?? null,
            netRevenueInterestNumber:
              maskedAmountToNumber(item.netRevenueInterestNumber) ?? null,
            wellTotalVerticalDepth:
              maskedAmountToNumber(item.wellTotalVerticalDepth) ?? null,
            wellNetAFE: maskedAmountToNumber(item.wellNetAFE) ?? null,
            wellGrossAfe: maskedAmountToNumber(item.wellGrossAfe) ?? null,
            // treat 0 as null
            minimumBid:
              (transactionType !== TransactionTypes.Carry
                && maskedAmountToNumber(item.minimumBid))
              || null,
            // treat 0 as null
            minimumBidCarry:
              (transactionType !== TransactionTypes.Cash
                && maskedAmountToNumber(item.minimumBidCarry))
              || null,
            surfaceLatitude: maskedAmountToNumber(item.surfaceLatitude) ?? null,
            surfaceLongitude:
              maskedAmountToNumber(item.surfaceLongitude) ?? null,
            bottomLatitude: maskedAmountToNumber(item.bottomLatitude) ?? null,
            bottomLongitude: maskedAmountToNumber(item.bottomLongitude) ?? null,
          })),
        };
      }

      await onSubmit(formData);
    };

  const wells = formValues?.wells;
  const wellsCoordinates = useMemo(() => coordinatesGenerator(wells), [wells]);

  const [wellsAugmented, setWellsAugmented] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // TODO: add property data to well
      setWellsAugmented(wells?.map(well => ({ ...well })));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [wells]);

  const totalWellGrossAfe = useMemo(
    () => computeWellsGrossAfe(
        wells?.map(well => ({
          wellGrossAfe: maskedAmountToNumber(well.wellGrossAfe),
        })),
    ),
    [wells],
  );

  // TODO: [UX][INTEGRITY] update the minStartDate when the current day changes in the Non-Op Wells timezone
  const minStartDate = useMemo(() => {
    if (isForCreate) {
      return minStartTimeForListing();
    }
    return existingPropertyDateRangeGenerator(
      currentProperty?.startTime,
      currentProperty?.endTime,
    ).startTime;
  }, [currentProperty, isForCreate]);
  const maxStartDate = new Date(9999, 11, 31);

  const startTime = formValues?.startTime;
  const minEndDate = useMemo(
    () => minEndTimeForListing(startTime || minStartDate),
    [startTime, minStartDate],
  );
  const maxEndDate = new Date(9999, 11, 31);

  const endTime = formValues?.endTime;

  const newAuctionDuration = startTime && endTime && calculateDifference(startTime, endTime);

  const handleOpenConfirmModal = () => setModal(
    <SubmitConfirmationModal
      heading="Are you sure you want save, discarding one or more documents that have not been uploaded?"
      handleSubmit={handleSubmit(
        createOnSubmitHandler({ createWithPendingUpdate: true }),
      )}
    />,
  );

  const handleOpenErrorModal = () => setModal(
    <SubmitConfirmationModal
      heading="Missing Closing Document"
      message="Please upload the closing document in the required upload before saving again."
      submitButtonOnly
      confirmLabel="Close"
      handleSubmit={() => undefined}
    />,
  );

  if (isBasedOnExisting && !currentProperty) return <Spinner wrapped />;
  return (
    <FormLayout onSubmit={handleSubmit(createOnSubmitHandler({}))}>
      {children}

      <GeneralInformation
        isAnonymous={isAnonymous}
        handleToggleCheckbox={handleToggleCheckbox}
        AWSData={AWSData}
        currentPropertyHasBeenApproved={hasBeenApproved}
        currentPropertyDocuments={currentProperty?.documents}
        formValues={formValues}
        getBasins={getBasins}
        getCounties={getCounties}
        getLandingZones={getLandingZones}
        getPreSignedUrls={getPreSignedUrls}
        preSignedUrls={preSignedUrls}
        removeAWSDataFile={removeAWSDataFile}
        setAWSData={setAWSData}
        setBasinId={setBasinId}
        setStateId={setStateId}
        setUnloadedFilesExist={setUnloadedFilesExist}
        setUploading={setUploading}
        states={states}
        onSetAreFilesProcessing={setFilesAreProcessing}
      >
        <>
          {renderTransactionTypeControl()}

          <Grid className={classes.dateContainer}>
            <div style={{ width: '60%' }}>
              <div>
                <label htmlFor="startTime" className={classes.label}>
                  Start of Bidding
                  {' '}
                  <span>*</span>
                </label>
              </div>
              <label htmlFor="startTime" className={classes.description}>
                Bidding starts at
                {' '}
                {toAuctionEventTime(new Date(), 'start').toFormat('t ZZZZ')}
                {' '}
                on
                the selected date (or as soon as the listing is approved if that
                time has already passed)
              </label>
            </div>

            <div style={{ width: '152' }}>
              <Field
                name="startTime"
                component={DatePicker}
                props={{
                  minDate: minStartDate,
                  maxDate: maxStartDate,
                  timeZone: NONOPWELLS_TIME_ZONE,
                }}
              />
            </div>
          </Grid>

          <Grid className={classes.dateContainer}>
            <div style={{ width: '60%' }}>
              <div>
                <label htmlFor="endTime" className={classes.label}>
                  End of Bidding
                  {' '}
                  <span>*</span>
                </label>
              </div>
              <label htmlFor="endTime" className={classes.description}>
                {`Time is based on ${NONOPWELLS_TIME_ZONE_LABEL.full}`}
              </label>
            </div>
            <div style={{ width: '219px' }}>
              <Field
                name="endTime"
                component={DateTimePicker}
                props={{
                  minDate: minEndDate,
                  maxDate: maxEndDate,
                  timeZone: NONOPWELLS_TIME_ZONE,
                }}
              />
            </div>
          </Grid>

          {newAuctionDuration && (
            <Grid container alignItems="flex-start" className={classes.wrapper}>
              <Grid
                container
                item
                xs={false}
                sm={false}
                md={false}
                lg={4}
                xl={4}
              />
              <Grid container item xs={12} sm={10} md={10} lg={8} xl={8}>
                <label className={classes.label}>
                  Duration:
                  {' '}
                  {newAuctionDuration}
                </label>
              </Grid>
            </Grid>
          )}
        </>
      </GeneralInformation>

      {!hasBeenApproved && (
        <div className={classes.detailsContainer} style={{ minWidth: '' }}>
          <MainSection heading="Well Details" fullWidth>
            <hr className={classes.hr} />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className={classes.container}
              >
                <LabelLayout label="Operator Name" space="small" isRequired>
                  <Field
                    name="operatorName"
                    component={BaseInput}
                    props={{ placeholder: 'Operator Name' }}
                  />
                </LabelLayout>
              </Grid>
            </Grid>
            <Grid container>
              <div style={{ width: '50%', minHeight: '600px' }}>
                <Grid className={classes.container}>
                  <FieldArray
                    transactionType={transactionType}
                    component={LandingZone}
                    formValues={formValues}
                    landingZones={landingZones}
                    name="wells"
                    rerenderOnEveryChange={false}
                  />
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className={classes.container}
              >
                <Grid item className={classes.map}>
                  {isUsingEsriMaps() ? (
                    <EsriMap
                      wells={wellsAugmented}
                      isListingsMap
                      showNonopLayers={false}
                    />
                  ) : (
                    <GoogleMap wells={wellsCoordinates} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MainSection>
        </div>
      )}

      {!hasBeenApproved && (
        <div className={classes.detailsContainer}>
          <MainSection heading="Listing Details" fullWidth>
            <hr className={classes.hr} />
            <Grid style={{ width: '50%' }}>
              <LabelLayout
                label="Total Listing Gross AFE"
                space="small"
                multiple={isLaptop}
              >
                <AmountInput
                  disabled
                  input={{
                    'data-cy': 'totalListingGrossAfe',
                    value: totalWellGrossAfe ?? 0,
                    disabled: true,
                  }}
                />
              </LabelLayout>
              <LabelLayout
                label="Total Listing Net AFE"
                space="small"
                multiple={isLaptop}
                disabled
              >
                <Field
                  name="netAfe"
                  component={AmountInput}
                  disabled
                  input={{
                    'data-cy': 'totalListingNetAfe',
                    disabled: true,
                    value: totalWellNetAFE,
                  }}
                />
              </LabelLayout>
            </Grid>
            <Grid className={classes.buttonContainer}>
              <Button
                type="submit"
                fullWidth
                data-cy="listingSubmitButton"
                onClick={() => {
                  updateDraftState(false);
                  handleSubmit(createOnSubmitHandler({}));
                }}
                label={
                  uploading
                    ? 'Uploading, please wait'
                    : `${
                      isForCreate || isCurrentStatusIsDraft
                        ? 'Submit Listing For Approval'
                        : 'Update Listing'
                    }`
                }
                buttonColor="green"
                disabled={uploading}
              />
            </Grid>
            {(isForCreate || isCurrentStatusIsDraft) && (
              <Grid
                container
                alignItems="center"
                className={classes.buttonContainer}
                style={{ marginTop: -20 }}
              >
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    type="submit"
                    onClick={() => {
                      updateDraftState(true);
                      handleSubmit(createOnSubmitHandler({}));
                    }}
                    fullWidth
                    data-cy="listingSubmitButton"
                    label={
                      uploading
                        ? 'Uploading, please wait'
                        : `${
                          isForCreate ? 'Save As Draft' : 'Update Draft'
                        } Listing`
                    }
                    buttonColor="green"
                    disabled={uploading}
                  />
                </Grid>
              </Grid>
            )}
            <span className={classes.requiredText}>* Required field</span>
          </MainSection>
        </div>
      )}

      {hasBeenApproved && (
        <Grid
          container
          alignItems="flex-start"
          className={classes.buttonContainer}
        >
          <Grid container item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Button
              type="submit"
              data-cy="listingSubmitButton"
              label={
                uploading
                  ? 'Uploading, please wait'
                  : `${isForCreate ? 'Create' : 'Update'} Listing`
              }
              buttonColor="green"
              fullWidth
              disabled={uploading}
            />
          </Grid>
        </Grid>
      )}
    </FormLayout>
  );
};

ListingForm.propTypes = {
  listingOwner: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullLegalCompanyName: PropTypes.string.isRequired,
    }),
  ),
  currentProperty: PropTypes.object,
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  getBasins: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  getLandingZones: PropTypes.func.isRequired,
  /**
   * Injected by reduxForm for signaling form submission
   */
  handleSubmit: PropTypes.func,
  isBasedOnExisting: PropTypes.bool,
  isForCreate: PropTypes.bool,
  isLoadingCompanies: PropTypes.bool,
  landingZones: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string }),
  ),
  setBasinId: generalInformationTypes.setBasinId.isRequired,
  setStateId: generalInformationTypes.setStateId.isRequired,
  states: generalInformationTypes.states.isRequired,
  AWSData: generalInformationTypes.AWSData.isRequired,
  getPreSignedUrls: generalInformationTypes.getPreSignedUrls.isRequired,
  preSignedUrls: generalInformationTypes.preSignedUrls,
  removeAWSDataFile: generalInformationTypes.removeAWSDataFile.isRequired,
  setAWSData: generalInformationTypes.setAWSData.isRequired,
  setUnloadedFilesExist:
    generalInformationTypes.setUnloadedFilesExist.isRequired,
  unloadedFilesExist: PropTypes.bool,
  clearAWSData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  keepSessionAlive: PropTypes.func,
};

ListingForm.defaultProps = {
  preSignedUrls: undefined,
  listingOwner: undefined,
  keepSessionAlive: undefined,
};

const withForm = Component => props => {
  const {
    currentProperty, formName, isBasedOnExisting, isForCreate,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    let initialValues = {};
    if (isBasedOnExisting) {
      if (currentProperty) {
        const {
          wells, startTime, endTime, ...propertyData
        } = currentProperty;

        let formattedWells = [];
        const formatWell = ({ landingZone, ...wellData }) => ({
          landingZone: landingZone?.id,
          ...wellData,
        });
        if (isForCreate) {
          // omit the well id
          formattedWells = wells.map(({ id, ...wellData }) => formatWell(wellData));
        } else {
          formattedWells = wells.map(formatWell);
        }

        initialValues = {
          ...propertyData,
          wells: formattedWells,
          startTime: toAuctionEventTime(
            new Date(startTime),
            'start',
          ).toJSDate(),
          endTime: DateTime.fromJSDate(new Date(endTime)).toJSDate(),
          restrictedCompanyIds: [],
        };
      }
    }
    if (isForCreate) {
      const startTime = defaultStartTimeForListing();
      const endTime = defaultEndTimeForListing(startTime);

      initialValues = {
        ...initialValues,
        transactionType: 'cash',
        startTime,
        endTime,
      };
    }
    setInitialValues(initialValues);
  }, [isBasedOnExisting, isForCreate, currentProperty]);

  return <Component form={formName} initialValues={initialValues} {...props} />;
};

const Component = compose(
  withForm,
  withProps(({ isForCreate }) => ({
    validate: values => createPropertyValidate({
      ...values,
      isForCreate,
    }),
  })),
  reduxForm({
    enableReinitialize: true,
  }),
  warnAboutUnsavedForm,
  connect((state, { formName }) => ({
    formValues: getFormValues(formName)(state),
  })),
  withStyles(styles),
)(ListingForm);

export default Component;
