import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getStates: makeAxiosRequest('/states', { method: 'get' }, true),
  getBasins: makeAxiosRequest('/states/{`id`}/basins', { method: 'get' }, true),
  getCounties: makeAxiosRequest('/states/{`id`}/basins/{`basinId`}', { method: 'get' }, true),
  getLandingZones: makeAxiosRequest('/states/{`id`}/basins/{`basinId`}/counties/{`countyId`}', { method: 'get' }, true),
  sendPropertyData: makeAxiosRequest('/properties', { method: 'post' }),
  sendPropertyDocuments: makeAxiosRequest('/properties/{`id`}/documents', { method: 'post' }),
  getProperties: makeAxiosRequest('/properties', { method: 'get' }),
  getStatuses: makeAxiosRequest('/properties/statuses', { method: 'get' }, true),
  getPreSignedUrls: makeAxiosRequest('/get-s3-pre-signed-urls-for-listing-uploads', { method: 'get' }),
  getCurrentProperty: makeAxiosRequest('/properties/{`id`}', { method: 'get' }),
  updateCurrentProperty: makeAxiosRequest('/properties/{`id`}', { method: 'put' }),
  makeBet: makeAxiosRequest('/properties/{id}/bids', { method: 'post' }),
  editProperty: makeAxiosRequest('/properties/{`id`}', { method: 'put' }),
  getListingAnalytics: listingId => makeAxiosRequest(`/listings/${listingId}/analytics`, { method: 'get' }, true),
  createCustomAnalytics: makeAxiosRequest('/admin/listings/custom-analytics', { method: 'post' }, true),
  getCustomListingAnalytics: listingId => makeAxiosRequest(`/listings/${listingId}/custom-analytics`, { method: 'get' }, true),
  updateCustomListingAnalytics: listingId => makeAxiosRequest(`/admin/listings/${listingId}/custom-analytics`, { method: 'put' }, true),
  deleteCustomListingAnalytics: id => makeAxiosRequest(`/admin/listings/${id}/custom-analytics`, { method: 'delete' }, true),
  getListingNotifications: listingId => makeAxiosRequest(`/admin/listings/${listingId}/listing-notifications`, { method: 'get' }, true),
};
