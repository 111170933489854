/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate, useNotify, useSafeSetState,
} from 'ra-core';
import { AuthProvider } from 'providers';

import { clearOtpData } from 'auth/auth-helpers';

const useStyles = makeStyles(
  theme => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      backgroundColor: '#F15922',
      '&:hover': {
        backgroundColor: '#F15922',
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    containers: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minWidth: '400px',
    },
    container: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      background: 'whitesmoke',
      padding: '1rem',
      justifyContent: 'center',
      width: '100%',

    },
  }),
  { name: 'RaOtpVerify' },
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const OtpVerify = props => {
  // eslint-disable-next-line react/prop-types
  const { history } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = values => {
    const errors = { code: undefined };

    if (!values.code) {
      errors.code = translate('required');
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    AuthProvider.verifyOtp(values)
      .then(() => {
        setLoading(false);
        clearOtpData();
        // eslint-disable-next-line react/prop-types
        history.push('/users');
      })
      .catch(error => {
        notify(error.message, { type: 'warning' });
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.containers}>
          <div className={classes.container}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="code"
                  name="code"
                  component={Input}
                  label={translate('Code')}
                  disabled={loading}
                />
              </div>

            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
                )}
                {translate('Verify')}
              </Button>
            </CardActions>
          </div>
        </form>
      )}
    />
  );
};

export default OtpVerify;
