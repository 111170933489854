import { DateTime as BusinessDateTime } from 'luxon-business-days';
import { DateTime } from 'luxon';

const TZ_AMERICA_CHICAGO = 'America/Chicago';

export const NONOPWELLS_TIME_ZONE = TZ_AMERICA_CHICAGO;
export const NONOPWELLS_TIME_ZONE_LABEL = {
  full: 'Central Time',
  short: 'CT',
};

export const AUCTION_TIMES = {
  start: {
    hour: 0,
    min: 1,
    sec: 0,
  },
  closed: {
    hour: 0,
    min: 0,
    sec: 0,
  },
};

export function nonOpWellsDateTime(jsDate) {
  let dateTime;
  if (jsDate) {
    dateTime = DateTime.fromJSDate(jsDate);
  } else {
    dateTime = DateTime.local();
  }
  dateTime = dateTime.setZone(NONOPWELLS_TIME_ZONE);
  return dateTime;
}

export function calculateDifference(start, end) {
  const startDateTime = DateTime.fromJSDate(new Date(start));
  const endDateTime = DateTime.fromJSDate(new Date(end));
  const diff = endDateTime.diff(startDateTime, ['days', 'hours', 'minutes']);
  const formattedDiff = `${diff.days}D ${diff.hours}H ${diff.minutes}M`;
  return formattedDiff;
}

export function nonOpWellsBusinessDateTime(jsDate) {
  let dateTime;
  if (jsDate) {
    dateTime = BusinessDateTime.fromJSDate(jsDate);
  } else {
    dateTime = BusinessDateTime.local();
  }
  dateTime = dateTime.setZone(NONOPWELLS_TIME_ZONE);
  return dateTime;
}

const luxonIsoFormatWithoutZone = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export function setLocalZone(jsDate, timezone) {
  const dateWithoutZone = DateTime.fromJSDate(jsDate)
    .setZone(timezone)
    .toFormat(luxonIsoFormatWithoutZone);

  const localZonedDate = new Date(dateWithoutZone);

  return localZonedDate;
}

export function setOtherZone(jsDate, timezone) {
  const dateWithoutZone = DateTime.fromJSDate(jsDate).toFormat(
    luxonIsoFormatWithoutZone,
  );

  const dateWithOtherZone = DateTime.fromISO(dateWithoutZone, {
    zone: timezone,
  }).toJSDate();

  return dateWithOtherZone;
}

export function convertToCentralISO(inputDate) {
  const dateTime = DateTime.fromISO(inputDate);
  const convertedDate = DateTime.fromMillis(dateTime.ts);
  const otherZone = setOtherZone(
    convertedDate.toJSDate(),
    NONOPWELLS_TIME_ZONE,
  );
  return otherZone;
}

export const setDateToNonOpWellsZone = date => setLocalZone(new Date(date), NONOPWELLS_TIME_ZONE);

export function formatNonOpWellsSimpleDate(jsDate) {
  return nonOpWellsDateTime(jsDate).toFormat('MM/dd/yyyy');
}

export function toAuctionEventTime(jsDate, startOrEnd) {
  if (!startOrEnd || !AUCTION_TIMES[startOrEnd]) {
    return nonOpWellsDateTime(jsDate);
  }
  const dateTime = nonOpWellsDateTime(jsDate).set({
    hour: 0,
    minute: 1,
    second: 0,
    millisecond: 0,
  });
  return dateTime;
}

export function computeAuctionDurationInDays(
  startDate,
  endDate,
  relativeToNow,
) {
  let start = toAuctionEventTime(startDate, 'start');
  if (relativeToNow) {
    const now = nonOpWellsDateTime();
    if (now > start) {
      start = now;
    }
  }
  const end = toAuctionEventTime(endDate, 'end');
  return end.diff(start, ['days', 'hours', 'minutes']);
}

export function toDateString(date) {
  return new Date(date).toISOString().slice(0, 10);
}

export function getSignatureDateString() {
  return toDateString(new Date());
}
