// Changes to this file will need to result in a corresponding migration file

export const TransactionTypes = {
  Cash: 'cash',
  Carry: 'carry',
  Either: 'either',
};

export const TransactionTypeTitles = {
  [TransactionTypes.Cash]: 'Cash',
  [TransactionTypes.Carry]: 'Carry',
  [TransactionTypes.Either]: 'Either',
};
