import { parseServerError } from 'now-shared/helpers/server-errors';

export const FetchJSON = (url, options) => {
  if (options === void 0) {
    options = {};
  }

  const requestHeaders = options.headers
    || new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response => response.text()
      .then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })))
    .then(response => {
      const { status, headers, body } = response;
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }

      if ((status < 200 || status >= 300) && status !== 500) {
        return Promise.reject({
          message: parseServerError(json).friendlyMessage,
          status,
          json,
        });
      }

      if (status === 500) {
        return Promise.reject({
          message: 'Internal server error',
          status,
          json,
        });
      }

      return {
        status, headers, body, json,
      };
    });
};
