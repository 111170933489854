import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import {
  styles,
  lightGreen,
  lightRed,
  midGrey,
} from './styles';
import {
  Modal,
  TextField,
  Button,
  Box,
  Divider,
  withStyles,
  Typography,
  ButtonBase,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  ArrowBack as BackIcon,
} from '@material-ui/icons';
import {
  createRole,
  updateRole,
  // deleteRole,
} from 'store/actions/roles-actions';
import { StyledTooltip } from 'now-frontend-shared/components/Tooltips/index';
import StyledCheckbox from 'now-frontend-shared/components/Checkboxes/StyledCheckbox';
import {
  convertToCamelCase,
  camelCaseToUpperCaseSpaces,
  lowerCaseDashesToUpperCaseSpaces,
} from 'now-shared/helpers/text-helpers';
import {
  RoleDomain,
  getRoleDomains,
  getRoleIdByTitle,
  getRolePermissionIdsByTitle,
} from '../../helpers/roles-helpers';
import { toast } from 'react-toastify';

const EditRolesModal = ({
  classes,
  open,
  onClose,
  permissions,
  permissionCategories,
  roles,
  rolePermissions,
  adminSitePermissionIdsSet,
  rolesLoading,
  rolesUpdating,
  rolePermissionsLoading,
  rolePermissionsUpdating,
}) => {
  const dispatch = useDispatch();
  const roleDomains = getRoleDomains();
  const [editRoleMode, setEditRoleMode] = useState(false);
  const [createRoleMode, setCreateRoleMode] = useState(false);

  const [selectedRole, setSelectedRole] = useState('');
  const [roleTitle, setRoleTitle] = useState('');
  const [roleDomain, setRoleDomain] = useState('');
  const [roleTitleEditable, setRoleTitleEditable] = useState(false);
  const [roleDomainEditable, setRoleDomainEditable] = useState(false);
  const [permissionsForCurrentRole, setPermissionsForCurrentRole] = useState([]);

  const [categoryFilter, setCategoryFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [updateRoleUsersPermissions, setUpdateRoleUsersPermissions] = useState(true);

  const handleClearState = () => {
    setEditRoleMode(false);
    setCreateRoleMode(false);
    setSelectedRole('');
    setRoleTitle('');
    setRoleDomain('');
    setRoleTitleEditable(false);
    setRoleDomainEditable(false);
    setPermissionsForCurrentRole([]);
    setCategoryFilter('');
    setRoleFilter('');
    setSelectAll(false);
    setUpdateRoleUsersPermissions(true);
  };

  useEffect(() => {
    if (!open) handleClearState();
  }, [open]);

  const dataLoading = rolesLoading || rolePermissionsLoading;
  const dataUpdating = rolesUpdating || rolePermissionsUpdating;
  const adminSiteRoleDomain = convertToCamelCase(roleDomain) === RoleDomain.AdminSite;

  const selectedRolePermissionIds = useMemo(() => getRolePermissionIdsByTitle(roles, rolePermissions, selectedRole)
    || [], [roles, rolePermissions, selectedRole]);

  const roleFilterPermissionIds = useMemo(() => getRolePermissionIdsByTitle(roles, rolePermissions, roleFilter)
    || [], [roles, rolePermissions, roleFilter]);

  const categorizedPermissions = useMemo(() => {
    // Process permissions and add metadata
    const processedPermissions = permissions?.map(permission => {
      const permissionForCurrentRole = !!permissionsForCurrentRole?.find(
        permissionByRole => permissionByRole.id === permission.id,
      );
      const permissionToAdd = permissionForCurrentRole && !selectedRolePermissionIds?.includes(permission.id);
      const permissionToRemove = selectedRolePermissionIds?.includes(permission.id) && !permissionForCurrentRole;
      const adminSitePermission = !!(permissionForCurrentRole && adminSitePermissionIdsSet?.has(permission.id));

      return {
        ...permission,
        permissionForCurrentRole,
        permissionToAdd,
        permissionToRemove,
        adminSitePermission,
      };
    });

    // Categorize processed permissions
    return processedPermissions?.reduce((groups, permission) => (
      { ...groups, [permission.category]: [...(groups[permission.category] || []), permission] }
    ), {});
  }, [
    adminSitePermissionIdsSet,
    permissions,
    permissionsForCurrentRole,
    selectedRolePermissionIds,
  ]);

  const categorizedAndFilteredPermissions = useMemo(() => {
    let localCategorizedAndProcessedPermissions = { ...categorizedPermissions };

    // Filter by category
    if (categoryFilter) {
      localCategorizedAndProcessedPermissions = Object.keys(localCategorizedAndProcessedPermissions)
        .reduce((acc, categoryName) => {
          if (categoryName === categoryFilter) {
            return { ...acc, [categoryName]: localCategorizedAndProcessedPermissions[categoryName] };
          }
          return acc;
        }, {});
    }

    // Filter by role
    if (roleFilter) {
      localCategorizedAndProcessedPermissions = Object.keys(localCategorizedAndProcessedPermissions)
        .reduce((acc, categoryName) => {
          const filteredCategory = localCategorizedAndProcessedPermissions[categoryName]
            .filter(permission => roleFilterPermissionIds?.includes(permission.id));
          return filteredCategory.length > 0 ? { ...acc, [categoryName]: filteredCategory } : acc;
        }, {});
    }

    return localCategorizedAndProcessedPermissions;
  }, [
    categorizedPermissions,
    categoryFilter,
    roleFilter,
    roleFilterPermissionIds,
  ]);

  const selectedAdminPermissionForNonAdminRole = useMemo(
    () => Object.values(categorizedPermissions || {})
      .flat().some(permission => !adminSiteRoleDomain && permission.adminSitePermission),
    [categorizedPermissions, adminSiteRoleDomain],
  );

  const handleSelectedRoleChange = event => {
    // After selecting a role, enter the edit role mode and set the role permissions
    setSelectedRole(event.target.value);
    setRoleTitle(camelCaseToUpperCaseSpaces(event.target.value));
    const currentRoleDomainType = roles?.find(role => role.title === event.target.value)?.type;
    setRoleDomain(camelCaseToUpperCaseSpaces(currentRoleDomainType));
    const targetRolePermissionIds = getRolePermissionIdsByTitle(roles, rolePermissions, event.target.value);
    const selectedPermissionsByRole = permissions?.filter(permission => targetRolePermissionIds.includes(permission.id));
    setPermissionsForCurrentRole(selectedPermissionsByRole);
    setEditRoleMode(true);
  };

  const handleRoleTitleChange = event => setRoleTitle(event.target.value);
  const handleRoleDomainChange = event => setRoleDomain(event.target.value);

  const handleCategoryFilterChange = event => {
    setCategoryFilter(event.target.value === 'all' ? '' : event.target.value);
  };
  const handleRoleFilterChange = event => {
    setRoleFilter(event.target.value === 'all' ? '' : event.target.value);
  };

  const handleSelectAll = () => {
    const currentPermissionsDisplayed = Object.values(categorizedAndFilteredPermissions || {}).flat();
    if (!selectAll) {
      const missingSelectedPermissions = currentPermissionsDisplayed?.filter(
        permission => !permissionsForCurrentRole?.find(
          permissionForCurrentRole => permissionForCurrentRole.id === permission.id,
        ),
      );
      setPermissionsForCurrentRole([...permissionsForCurrentRole, ...missingSelectedPermissions]);
      setSelectAll(true);
    } else {
      const selectedPermissionsTrimmed = permissionsForCurrentRole?.filter(
        permission => !currentPermissionsDisplayed?.find(
          filteredPermission => filteredPermission.id === permission.id,
        ),
      );
      setPermissionsForCurrentRole(selectedPermissionsTrimmed);
      setSelectAll(false);
    }
  };

  const updateCheckBox = permission => {
    if (permissionsForCurrentRole?.find(rolePermission => rolePermission.id === permission.id)) {
      if (adminSiteRoleDomain && permission.adminSitePermission) {
        toast.error('Admin permissions cannot be removed from Admin Site roles.');
      } else {
        // uncheck box
        setPermissionsForCurrentRole(permissionsForCurrentRole?.filter(
          permissionForCurrentRole => permissionForCurrentRole.id !== permission.id,
        ));
      }
    } else {
      // check box
      setPermissionsForCurrentRole([...permissionsForCurrentRole, permission]);
    }
  };

  const handleCreateRole = async () => {
    if (!dataLoading && !dataUpdating) {
      if (!roleTitle.trim()) {
        toast.error('Role title cannot be empty');
      } else if (roles?.find(role => convertToCamelCase(role.title) === convertToCamelCase(roleTitle))) {
        toast.error('A role with that title already exists');
      } else {
        // Update rolePermissions and refetch them
        await dispatch(createRole({
          title: convertToCamelCase(roleTitle),
          type: convertToCamelCase(roleDomain) || RoleDomain.UserSite,
          permissionIdsToAdd: permissionsForCurrentRole.map(permission => permission.id),
        }));
        handleClearState();
        onClose();
      }
    }
  };

  const handleUpdateRole = async () => {
    if (!dataLoading && !dataUpdating) {
      const roleToUpdate = roles?.find(role => role.title === selectedRole);
      const roleTitleChanged = convertToCamelCase(roleTitle) !== convertToCamelCase(roleToUpdate?.title);

      if (!roleTitle.trim()) {
        toast.error('Role title cannot be empty');
      } else if (roleTitleChanged && roles?.find(role => convertToCamelCase(role.title) === convertToCamelCase(roleTitle))) {
        toast.error('A role with that title already exists');
      } else {
        const permissionIdsToAdd = Object.values(categorizedPermissions || {}).flat()
          .filter(permission => permission.permissionToAdd).map(permission => permission.id) || [];
        const permissionIdsToRemove = Object.values(categorizedPermissions || {}).flat()
          .filter(permission => permission.permissionToRemove).map(permission => permission.id) || [];

        // Update rolePermissions and refetch them
        await dispatch(updateRole({
          roleId: getRoleIdByTitle(roles, selectedRole),
          title: convertToCamelCase(roleTitle),
          type: convertToCamelCase(roleDomain) || RoleDomain.UserSite,
          permissionIdsToAdd,
          permissionIdsToRemove,
          updateRoleUsersPermissions,
        }));
        handleClearState();
        onClose();
      }
    }
  };

  /** @TODO Add delete role functionality */

  // const handleDeleteRole = async roleId => {
  //   try {
  //     if (!dataLoading && !dataUpdating) {
  //       // Add sufficient warnings and constraints
  //       await dispatch(deleteRole({ roleId }));
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const categoryOptions = permissionCategories?.map(
    categoryName => ({ value: categoryName, label: lowerCaseDashesToUpperCaseSpaces(categoryName) }),
  );

  const roleOptions = roles?.map(
    role => ({ value: role.title, label: camelCaseToUpperCaseSpaces(role.title) }),
  );

  const roleDomainOptions = roleDomains?.map(
    roleDomainOption => ({
      value: camelCaseToUpperCaseSpaces(roleDomainOption),
      label: camelCaseToUpperCaseSpaces(roleDomainOption),
    }),
  );

  const NON_ADMINSITE_ROLE_WITH_ADMIN_PERMISSION_MSG = '** Admin permission assigned to non-AdminSite role.';
  const UPDATE_USER_PERMISSIONS_MSG = 'Update user permissions for users with this role.';
  const UPDATE_USER_PERMISSIONS_WARNING_MSG = 'WARNING: User permissions will NOT be updated for users with this role.';

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={classes.modalContainer}>
        <div className={classes.sectionHeaderWrapper}>
          <Typography variant="h6" className={classes.sectionHeader}>Edit Roles</Typography>
          <IconButton onClick={onClose} style={{ margin: 0, padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.searchAndFilterWrapper}>

          {!editRoleMode && !createRoleMode ? (
            <>
              <Button className={classes.selectorFilter}>
                <Select
                  value={selectedRole}
                  onChange={handleSelectedRoleChange}
                  disableUnderline
                  displayEmpty
                  className={classes.selectInput}
                >
                  <MenuItem disabled value="">Select Role</MenuItem>
                  {roleOptions.map(option => (
                    <MenuItem key={`selectRole-${option.value}`} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </Button>
              <ButtonBase onClick={() => setCreateRoleMode(true)} className={classes.selectorFilter}>
                <IconButton style={{ margin: 0, padding: '0px 4px 0px 8px' }}>
                  <AddIcon />
                </IconButton>
                <Typography variant="body1" style={{ marginRight: '6px' }}>Add New Role</Typography>
              </ButtonBase>
            </>
          ) : (
            <>
              <ButtonBase
                onClick={handleClearState}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <IconButton style={{ margin: 0, padding: 0 }}>
                  <BackIcon />
                </IconButton>
                <Typography variant="body1">Back</Typography>
              </ButtonBase>
              <div className={classes.selectorFilter}>
                <TextField
                  className={classes.roleTitleField}
                  disabled={editRoleMode && !roleTitleEditable}
                  InputProps={{ disableUnderline: true }}
                  placeholder="Role Title"
                  value={roleTitle}
                  onChange={handleRoleTitleChange}
                />
                {editRoleMode && (
                  <IconButton
                    className={classes.editIcon}
                    onClick={() => setRoleTitleEditable(!roleTitleEditable)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
              <div className={classes.selectorFilter}>
                <Button className={classes.roleDomainField}>
                  <Select
                    disabled={editRoleMode && !roleDomainEditable}
                    value={roleDomain}
                    onChange={handleRoleDomainChange}
                    disableUnderline
                    displayEmpty
                    className={classes.selectInput}
                  >
                    <MenuItem disabled value="">
                      Website
                    </MenuItem>
                    {roleDomainOptions.map(option => (
                      <MenuItem key={`roleDomain-${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Button>
                {editRoleMode && (
                  <IconButton
                    className={classes.editIcon}
                    onClick={() => setRoleDomainEditable(!roleDomainEditable)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
              <Button className={classes.selectorFilter}>
                <Select
                  data-cy="categoryFilter"
                  value={categoryFilter}
                  onChange={handleCategoryFilterChange}
                  disableUnderline
                  displayEmpty
                  className={classes.selectInput}
                >
                  <MenuItem disabled value="">Filter By Category</MenuItem>
                  <MenuItem value="all">All</MenuItem>
                  {categoryOptions.map(option => (
                    <MenuItem key={`categoryFilter-${option.value}`} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </Button>
              <Button className={classes.selectorFilter}>
                <Select
                  data-cy="roleFilter"
                  value={roleFilter}
                  onChange={handleRoleFilterChange}
                  disableUnderline
                  displayEmpty
                  className={classes.selectInput}
                >
                  <MenuItem disabled value="">Filter By Existing Role Permissions</MenuItem>
                  <MenuItem value="all">All</MenuItem>
                  {roleOptions.map(option => (
                    <MenuItem key={`roleFilter-${option.value}`} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </Button>
              <Box className={classes.selectAll}>
                <StyledCheckbox
                  data-cy="selectAll"
                  disabled={!createRoleMode && !editRoleMode}
                  checked={selectAll}
                  onChange={() => handleSelectAll()}
                />
                <Typography style={{ whiteSpace: 'nowrap' }}>Select All</Typography>
              </Box>
            </>
          )}
        </div>
        <div className={classes.categorySectionsContainer}>
          {Object.entries(categorizedAndFilteredPermissions || {}).map(([categoryName, categoryPermissions]) => (
            <div key={`${categoryName}Section`} className={classes.categorySectionWrapper}>
              <Divider key={`${categoryName}Divider`} className={classes.categoryDivider} />
              <Typography variant="h6" className={classes.categorySectionHeader}>
                {categoryName}
              </Typography>
              <Box className={classes.permissionsContainer}>
                {categoryPermissions.map(permission => (
                  <StyledTooltip
                    data-cy={`tooltip-${permission.id}`}
                    key={`tooltip-${permission.id}`}
                    title={permission.description}
                  >
                    <Box className={classes.permissionWrapper}>
                      <StyledCheckbox
                        data-cy={`checkbox-${permission.id}`}
                        disabled={!createRoleMode && !editRoleMode}
                        checked={!!permission.permissionForCurrentRole}
                        onChange={() => updateCheckBox(permission)}
                        checkedColor={permission.permissionToAdd && lightGreen}
                        boxColor={permission.permissionToRemove && lightRed}
                      />
                      <Box display="flex" alignItems="center">
                        <Typography>{permission.permissionType.name}</Typography>
                        {!adminSiteRoleDomain && permission.AdminSitePermission && (
                          <Typography className={classes.highlightRed}> ** </Typography>
                        )}
                      </Box>
                    </Box>
                  </StyledTooltip>
                ))}
              </Box>
            </div>
          ))}
          <Divider key="bottomDivider" className={classes.categoryDivider} />
        </div>
        <div className={classes.footerWrapper}>
          <div className={classes.messagesContainer}>
            {selectedAdminPermissionForNonAdminRole && (
              <Typography className={classes.highlightRed}>
                {NON_ADMINSITE_ROLE_WITH_ADMIN_PERMISSION_MSG}
              </Typography>
            )}
          </div>
          <Box className={classes.updateRoleUsersPermissions}>
            <StyledCheckbox
              data-cy="updateRoleUsersPermissions"
              disabled={false}
              checked={updateRoleUsersPermissions}
              onChange={() => setUpdateRoleUsersPermissions(!updateRoleUsersPermissions)}
              checkedColor={midGrey}
              boxSize={22}
              boxWidth={30}
              boxHeight={30}
            />
            <Typography style={{ fontSize: '15px' }} className={!updateRoleUsersPermissions && classes.highlightRed}>
              {updateRoleUsersPermissions
                ? UPDATE_USER_PERMISSIONS_MSG
                : UPDATE_USER_PERMISSIONS_WARNING_MSG}
            </Typography>
          </Box>
          <Button
            className={classes.saveButton}
            disabled={rolePermissionsUpdating || rolePermissionsLoading}
            onClick={createRoleMode ? handleCreateRole : handleUpdateRole}
          >
            {createRoleMode ? 'Save New Role' : 'Save Changes'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditRolesModal.propTypes = {
  classes: PropTypes.shape({
    modalContainer: PropTypes.string,
    sectionHeaderWrapper: PropTypes.string,
    sectionHeader: PropTypes.string,
    categorySectionHeader: PropTypes.string,
    searchAndFilterWrapper: PropTypes.string,
    categorySectionWrapper: PropTypes.string,
    roleTitleField: PropTypes.string,
    roleDomainField: PropTypes.string,
    editIcon: PropTypes.string,
    selectorFilter: PropTypes.string,
    selectInput: PropTypes.string,
    selectAll: PropTypes.string,
    categorySectionsContainer: PropTypes.string,
    categoryDivider: PropTypes.string,
    permissionsContainer: PropTypes.string,
    permissionWrapper: PropTypes.string,
    footerWrapper: PropTypes.string,
    messagesContainer: PropTypes.string,
    updateRoleUsersPermissions: PropTypes.string,
    highlightBlue: PropTypes.string,
    highlightRed: PropTypes.string,
    saveButton: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    permissionType: { name: PropTypes.string },
    description: PropTypes.string,
  })),
  permissionCategories: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  rolePermissions: PropTypes.arrayOf(PropTypes.shape({
    roleId: PropTypes.number,
    permissionId: PropTypes.number,
  })),
  adminSitePermissionIdsSet: PropTypes.instanceOf(Set),
  rolesLoading: PropTypes.bool,
  rolesUpdating: PropTypes.bool,
  rolePermissionsLoading: PropTypes.bool,
  rolePermissionsUpdating: PropTypes.bool,
};

EditRolesModal.defaultProps = {
  permissions: [],
  permissionCategories: [],
  roles: [],
  rolePermissions: [],
  adminSitePermissionIdsSet: new Set([]),
  rolesLoading: false,
  rolesUpdating: false,
  rolePermissionsLoading: false,
  rolePermissionsUpdating: false,
};

export default compose(
  connect(({ roles }) => ({
    rolePermissionsUpdating: roles.rolePermissionsUpdating,
    rolePermissionsUpdated: roles.rolePermissionsUpdated,
  })),
  withStyles(styles),
)(EditRolesModal);
