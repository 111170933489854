// actions
import * as A from '../actions/roles-actions';

const initialState = {
  roles: [],
  rolesLoading: false,
  rolesLoaded: false,
  rolesUpdating: false,
  rolesUpdated: false,
  rolePermissionsUpdating: false,
  rolePermissionsUpdated: false,
  error: null,
};

export default {
  roles(state = initialState, action) {
    switch (action.type) {
      case A.getAllRoles.type:
        return {
          ...state,
          rolesLoading: true,
          error: null,
        };

      case A.getAllRoles.success:
        return {
          ...state,
          roles: action.payload,
          rolesLoading: false,
          rolesLoaded: true,
        };

      case A.getAllRoles.failure:
        return {
          ...state,
          rolesLoading: false,
          error: action.payload,
        };

      case A.createRole.type:
        return {
          ...state,
          rolesLoading: true,
          error: null,
        };

      case A.createRole.success:
        return {
          ...state,
          rolesLoading: false,
        };

      case A.createRole.failure:
        return {
          ...state,
          rolesLoading: false,
          error: action.payload,
        };

      case A.updateRole.type:
        return {
          ...state,
          rolesUpdating: true,
          rolePermissionsUpdating: true,
          error: null,
        };

      case A.updateRole.success:
        return {
          ...state,
          rolesUpdating: false,
          rolesUpdated: true,
          rolePermissionsUpdating: false,
          rolePermissionsUpdated: true,
        };

      case A.updateRole.failure:
        return {
          ...state,
          rolesUpdating: false,
          rolePermissionsUpdating: false,
          error: action.payload,
        };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
