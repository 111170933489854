import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Handlebars from 'handlebars/dist/handlebars';
import { parseEditableContent, getFullTemplate } from '../helpers/hbsParser';
import PropTypes from 'prop-types';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { EmailTemplateDummyData } from 'now-shared/enums/email-templates-variable';

const wellcomeTemplateVariables = [
  {
    label: 'Is Self Verified',
    value: false,
    variableName: 'isSelfVerified',
  },
  {
    label: 'Is AdminOrCompliance Person',
    value: false,
    variableName: 'isAdminOrCompliancePerson',
  },
  {
    label: 'Reset Password Link',
    value: false,
    variableName: 'resetPasswordLink',
  },
  {
    label: 'Has Company',
    value: false,
    variableName: 'hasCompany',
  },
  {
    label: 'Is Account Manager',
    value: false,
    variableName: 'isAccountManager',
  },
];

const preRegDashboardTemplateVariables = [
  {
    label: 'Is Account Manager',
    value: false,
    variableName: 'isAccountManager',
  },
];

const HandleBarEditor = ({
  isEditor, content, updateFinalTemplate, notificationType, updateDummyDataHandler,
}) => {
  const editableContent = parseEditableContent(content);
  const [template, setTemplate] = useState(editableContent);
  const [templateDummyData, updateDummyData] = useState(EmailTemplateDummyData[notificationType]);

  useEffect(() => {
    if (notificationType === NotificationTypeName.WelcomeEmail ||
      notificationType === NotificationTypeName.PreDashboardRegistrationApproved) {
      updateDummyDataHandler(templateDummyData);
    }
  }, [templateDummyData]);

  const handleTemplateChange = (value) => {
    setTemplate(value);
    updateFinalTemplate(getFullTemplate(content, value));
  };

  const renderTemplate = () => {
    const finalTemplate = getFullTemplate(content, template);
    const compiledTemplate = Handlebars.compile(finalTemplate);
    const renderedTemplate = compiledTemplate(templateDummyData);
    return { __html: renderedTemplate };
  };

  const Switch = ({ params }) => {
    const checked = templateDummyData[params.variableName];
    return (
      <SwitchButton
        checked={checked}
        label={params.label}
        onChangeNotificationsValue={async ({ value }) => {
          updateDummyData({ ...templateDummyData, [params.variableName]: value });
        }}
      />
    );
  };

  Switch.propTypes = {
    params: PropTypes.shape({
      variableName: PropTypes.string,
      label: PropTypes.string,
    }).isRequired,
  };

  return (
    <div>
      {isEditor && (
      <div>
        <ReactQuill
          value={template}
          onChange={handleTemplateChange}
          style={{
            border: '1px solid black',
            padding: '10px',
            minHeight: '200px',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <span style={{ marginTop: 20, fontStyle: 'italic' }}>
            {'Note: Please avoid modifying the content that is enclosed within the "{{ }}" brackets.'}
          </span>
        </div>
      </div>
      )}
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {!isEditor && <div dangerouslySetInnerHTML={renderTemplate()} />}
      </div>
      {notificationType === NotificationTypeName.WelcomeEmail && (
      <div>
        {wellcomeTemplateVariables.map((obj) => <Switch key={obj.variableName} params={obj} />)}
      </div>
      )}
      <>
        {notificationType === NotificationTypeName.PreDashboardRegistrationApproved && (
        <div>
          {preRegDashboardTemplateVariables.map((obj) => <Switch key={obj.variableName} params={obj} />)}
        </div>
        )}
      </>
    </div>
  );
};

HandleBarEditor.propTypes = {
  isEditor: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  updateFinalTemplate: PropTypes.func.isRequired,
  notificationType: PropTypes.string.isRequired,
  updateDummyDataHandler: PropTypes.func.isRequired,
};

export default HandleBarEditor;
