import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  getAllStates,
} from '../actions/states-actions';

// api methods
import Api from 'api/states';

function* ensureGetAllStates() {
  try {
    const { data } = yield call(Api.getAllStates);
    const states = data.map(state => ({ id: state.id, name: state.title }));

    yield put({ type: getAllStates.success, payload: states });
  } catch (err) {
    yield put({ type: getAllStates.failure, err });
  }
}

function* watchGetAllStates() {
  yield takeLatest(getAllStates.type, ensureGetAllStates);
  yield take(getAllStates.success);
}

export default function* statesSagas() {
  yield all([
    fork(watchGetAllStates),
  ]);
}
