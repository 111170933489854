import { useEffect } from 'react';
import { useForm } from 'react-final-form';

/**
 * Expose the `form` object within a Form to other components
 */
const FormGetter = props => {
  const {
    onForm,
  } = props;
  const form = useForm();

  useEffect(
    () => onForm(form),
    [onForm, form],
  );

  return null;
};

export default FormGetter;
