import React, { memo } from 'react';
import PropTypes from 'prop-types';

// components
import SelectWrapper from './components/SelectWrapper';
import SelectInput from './components/SelectInput';

// styled error field
import ErrorField from '../inputs/ErrorField';

// select arrow icon
import { ReactComponent as SelectArrow } from 'now-frontend-shared/assets/icons/select_arrow.svg';

// styles and components from material-ui
import { MenuItem, Grid } from '@material-ui/core';

const SelectForIdValue = ({
  input: selectProps, meta, options, disabled,
  ...propsRest
}) => {
  const isError = meta.touched && meta.error;

  return (
    <Grid container direction="column">
      <SelectWrapper
        input={(
          <SelectInput
            {...propsRest['data-cy'] && {
              'data-cy': propsRest['data-cy'],
            }}
          />
        )}
        IconComponent={SelectArrow}
        disabled={disabled}
        {...selectProps}
      >
        {options.map(item => (
          <MenuItem
            key={item?.id}
            value={item?.id}
            {...propsRest['data-cy'] && {
              'data-cy': `${propsRest['data-cy']}-MenuItem-${item?.id}`,
            }}
          >
            {item?.title}
          </MenuItem>
        ))}
      </SelectWrapper>

      <ErrorField error={isError ? meta.error : null} />
    </Grid>
  );
};

SelectForIdValue.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })),
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  'data-cy': PropTypes.string,
};

SelectForIdValue.defaultProps = {
  'data-cy': undefined,
};

export default memo(SelectForIdValue);
