import queryString from 'query-string';
import {
  apiAuthTokenQueryParamName,
} from 'now-shared/helpers/auth-helpers';

export function getAuthTokenQueryParam() {
  const params = queryString.parse(window.location.search);
  return params[apiAuthTokenQueryParamName];
}

export function getAuthTokenStored() {
  return localStorage.getItem('token') || undefined;
}

export function getAuthorizationHeader() {
  const token = getAuthTokenStored();
  return token && `Bearer ${token}`;
}

export function storeAuthToken(token) {
  return localStorage.setItem('token', token);
}

export function storeOTPtoken(token) {
  return localStorage.setItem('otpToken', token);
}

export function getOtpToken() {
  return localStorage.getItem('otpToken') || undefined;
}

export function getUserData() {
  return JSON.parse(localStorage.getItem('userData')) || undefined;
}

export function storeUserData({
  id,
  email,
  firstName,
  lastName,
  isAdmin,
  isSuperAdmin,
  isComplianceSupervisor,
  isRegisteredRepresentative,
}) {
  localStorage.setItem('userData', JSON.stringify({
    id,
    email,
    firstName,
    lastName,
    isAdmin,
    isSuperAdmin,
    isComplianceSupervisor,
    isRegisteredRepresentative,
  }));
}

export function clearUserData(args) {
  if (!args?.onlyUserData) {
    localStorage.removeItem('token');
  }
  localStorage.removeItem('userData');
}

export function clearOtpData() {
  localStorage.removeItem('otpToken');
}
