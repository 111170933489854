import { signatureStyle as signature } from 'now-frontend-shared/styles/signature-styles';

import { makeStyles } from '@material-ui/core/styles';

export default {
  root: {
    padding: '20px 0',
  },
  // TODO: [DRY][REFACTOR] these same style properties are shared by BuyersCertificateView and SellersCertificateView.
  // Reference the shared styles as a constant instead of copying them in different place
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '34px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    // TODO: [DRY][REFACTOR] this color `#171721` should be defined as a constant named
    // after the palette color it represents or the palette function it serves.
    // That way, if we want to change the palette we can easily change it everywhere it is used, from one place.
    color: '#171721',
    marginBottom: '30px',
  },
  body: {
    fontWeight: 'normal',
    fontSize: '24px',
    color: '#000000',
  },
  bodyContainer: {
    maxHeight: '572px',
    overflowY: 'scroll',
    // TODO: [DRY][REFACTOR] this color `#E0E0E0` should be defined as a constant named
    // after the palette color it represents or the palette function it serves.
    // That way, if we want to change the palette we can easily change it everywhere it is used, from one place.
    border: '1px solid #E0E0E0',
    padding: '14px',
    borderRadius: '8px',
  },
  inputContainer: {
    width: '100%',
    marginTop: '30px',
    marginRight: '220px',
  },
  dateContainer: {
    width: '100%',
    marginTop: '24px',
    marginRight: '220px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '20px',
    marginTop: '0',
    marginBottom: '30px',
    marginRight: '32px',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    border: '1px solid rgba(120, 144, 166, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '8px 22px',
    color: '#7890A6',
    fontWeight: '500',
    fontSize: '15px',
    textTransform: 'uppercase',
    minWidth: '200px',
    height: '56px',
  },
  signature,
  buttonContainerWithBack: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '34px',
  },
  backButton: {
    // TODO: [DRY][REFACTOR] define this color in frontend-shared as a template color named for the purpose
    // which it serves in the application and reference that constant here. This way, if we choose to change
    // specific value of this color later, we only have to change it in one place.
    color: '#7890A6',
    alignSelf: 'center',
    marginBottom: '30px',
  },
  downloadContainer: {
    width: '75%',
    textAlign: 'right',
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    justifyContent: 'end',
    textDecoration: 'none',
    color: '#3f51b5',
  },
};

export const useStyles = makeStyles({
  relativeContainer: {
    position: 'relative',
    flex: 1,
    width: '100%',
  },
  wrapper: {
    height: '100%',
    padding: '40px 75px 70px',

    '@media screen and (max-width: 0px)': {
      padding: '40px 20px',
    },
  },
  container: {
    position: 'relative',
    padding: '10px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '0',
    },
  },
  topPanel: {
    padding: '36px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '30px 0',
    },
  },
  currencyInput: {
    paddingLeft: 25,
  },
  currencyInputWrapper: {
    position: 'relative',
    '&:before': {
      width: 20,
      height: 20,
      display: 'block',
      content: '"$"',
      position: 'absolute',
      top: 23,
      left: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'center',
    },
  },
  percentInput: {
    paddingRight: 25,
  },
  percentInputWrapper: {
    position: 'relative',
    '&:before': {
      width: 20,
      height: 20,
      display: 'block',
      content: '"%"',
      position: 'absolute',
      top: 23,
      right: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'center',
    },
  },
  information: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  wellInput: {
    width: '256px',
  },
});

export const useIteratorStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  form: {
    width: '100%',
  },
  line: {
    border: 0,
  },
}));
