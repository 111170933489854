import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { primaryContrast } from 'now-frontend-shared/themes/colors';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { generateFetchOptions } from 'helpers/fetch';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';
import Spinner from 'now-frontend-shared/components/Spinner';
import { toast } from 'react-toastify';
import { EditEmailNotificationModal } from 'components/EditEmailNotificatioModal';

const EmailCommunications = () => {
  const [notifications, setNotifications] = useState(null);
  useEffect(() => {
    const getNotifications = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, generateFetchOptions('GET'));
      const data = await result.json();
      setNotifications(data);
    };
    getNotifications();
  }, []);

  const EditNotification = ({ notificationsType }) => {
    const { type } = notificationsType;
    return (
      <div>
        <EditEmailNotificationModal title="Edit" notificationType={type} />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Notification',
        minWidth: 350,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 160,
        sortable: false,
        renderCell: params => <Switch params={params} />,
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 160,
        sortable: false,
        renderCell: ({ row }) => <EditNotification notificationsType={row} />,
      },
    ],
    [],
  );

  const notificationsToDisplay = useMemo(
    () => [
      {
        title: 'Welcome Email',
        type: NotificationTypeName.WelcomeEmail,
      },
      {
        title: 'Admin Edited Your Listing',
        type: NotificationTypeName.AdminEditedYourListing,
      },
      {
        title: 'Listing Approved',
        type: NotificationTypeName.ListingApproved,
      },
      {
        title: 'Auction Ended to Seller',
        type: NotificationTypeName.AuctionEnded,
      },
      {
        title: 'Auction Ended To Buyer',
        type: NotificationTypeName.PropertyIsClosed,
      },
      {
        title: 'Admin Confirms Winning Bid - Seller Email',
        type: NotificationTypeName.AdminConfirmedWinningBidOnYourListing,
      },
      {
        title: 'Admin Confirms Winning Bid - Buyer Email',
        type: NotificationTypeName.AdminConfirmedYourWinningBid,
      },
      {
        title: 'Admin Archived Your Listing',
        type: NotificationTypeName.AdminArchivedYourListing,
      },
      {
        title: 'User Details Edited By Admin',
        type: NotificationTypeName.UserDetailsChanged,
      },
      {
        title: 'Seller Agreement Approved',
        type: NotificationTypeName.SellerAgreementApproved,
      },
      {
        title: 'Seller Agreement Rejected',
        type: NotificationTypeName.SellerAgreementRejected,
      },
      {
        title: 'Buyer Agreement Approved',
        type: NotificationTypeName.BuyerAgreementApproved,
      },
      {
        title: 'Buyer Agreement Rejected',
        type: NotificationTypeName.BuyerAgreementRejected,
      },
      // start from here
      {
        title: 'Buyer\'s Bid Confirmed',
        type: NotificationTypeName.BidConfirmed,
      },
      {
        title: 'Bank Information Approved',
        type: NotificationTypeName.BankInformationApproved,
      },
      {
        title: 'Listing has received a bid',
        type: NotificationTypeName.ListingHasReceivedBid,
      },
      {
        title: 'New listing in AOI',
        type: NotificationTypeName.NewListingInAOI,
      },
      {
        title: 'Reset Password',
        type: NotificationTypeName.ResetPassword,
      },
      {
        title: 'Pre Dashboard Registration Approved',
        type: NotificationTypeName.PreDashboardRegistrationApproved,
      },
    ],
    [],
  );

  const rows = useMemo(
    () => notificationsToDisplay.map(
      ({ title, type }) => ({
        id: title,
        status: {
          defaultValue: (
            notifications?.find(
              n => n.notificationType.name === type,
            )?.active || false
          ),
          type,
        },
        type,
      }),
    ),
    [notificationsToDisplay, notifications],
  );

  const Switch = ({ params }) => {
    const [checked, setChecked] = useState(params.value.defaultValue);

    const onUpdate = async option => {
      await fetch(`${process.env.REACT_APP_API_URL}/notification/${params.value.type}`, generateFetchOptions('PUT', { active: option }));
    };
    return (
      <SwitchButton
        checked={checked}
        label={checked ? 'Enabled' : 'Disabled'}
        onChangeNotificationsValue={async ({ value }) => {
          try {
            await onUpdate(value);
            setChecked(value);
          } catch (err) {
            toast.error(`There was an error updating the notification status: ${err.message}`);
          }
        }}
      />
    );
  };

  if (notifications === null) {
    return <Spinner backdrop />;
  }

  return (
    <>
      <CssBaseline />
      <Box
        style={{
          backgroundColor: primaryContrast,
        }}
      >
        <DataGrid
          style={{ margin: '15px 0' }}
          rows={rows}
          columns={columns}
          autoHeight
          autoPageSize
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

EmailCommunications.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.shape({
      defaultValue: PropTypes.bool,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default EmailCommunications;
