import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

// styles
import styles from './styles';

import { Clear } from '@material-ui/icons';

import Watermark from '../../../Watermark';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { connect } from 'react-redux';

const HistoricalInfoWindow = ({
  classes,
  isCluster,
  isEmbedded,
  pointCount,
  listings,
  handleToggleScrollwheel,
  handleCloseInfoWindow,
  userFullName,
  companyName,
}) => {
  const isMobile = useIsMobile();
  const transform = useMemo(() => {
    if (isCluster && !isEmbedded) {
      return `${
        isMobile ? 'translateX(-50%)' : 'translateX(-8px)'
      } translateY(-${10 + pointCount * 2}px)`;
    }
    return '';
  }, [isCluster, isMobile, isEmbedded, pointCount]);

  const fields = [
    {
      name: 'wellName',
      alias: 'Well Name',
    },
    {
      name: 'api',
      alias: 'API',
    },
    {
      name: 'status',
      alias: 'Status',
    },
    {
      name: 'currentOperator',
      alias: 'Current Operator',
    },
    {
      name: 'grantorRaw',
      alias: 'Grantor',
    },
    {
      name: 'grantee',
      alias: 'Grantee',
    },
  ];

  const watermarkText = `${companyName} / ${userFullName}`;

  return (
    <div
      className={isEmbedded ? classes.infoWindowBase : classes.infoWindow}
      style={{ transform }}
      {...(handleToggleScrollwheel && {
        onMouseEnter: handleToggleScrollwheel,
        onMouseLeave: handleToggleScrollwheel,
        onTouchStartCapture: handleToggleScrollwheel,
      })}
    >
      {isCluster && (
        <Grid container>
          <span className={classes.infoHeading}>
            Multiple listings in this location/county...
          </span>
        </Grid>
      )}

      <Grid
        container
        className={cx(classes.infoContainer, {
          [classes.infoContainerOverflow]: pointCount > 4,
        })}
        style={{ width: '100%' }}
      >
        {listings.map(listing => (
          <Box key={listing.id} style={{ width: '100%' }}>
            <Box className={classes.propertyDescriptionContainer}>
              <Watermark text={watermarkText} rotate="-20" textSize={15}>
                <Box className={classes.propertyDescriptionHeader}>
                  <Grid item className={classes.propertyNameWrapper}>
                    <span style={{ fontSize: 12 }}>Historical Transaction</span>
                    <span className={classes.propertyNameText}>
                      {`${listing.wellName}`}
                    </span>
                  </Grid>
                  <Grid>
                    <Clear
                      onClick={handleCloseInfoWindow}
                      className={classes.closeIcon}
                    />
                  </Grid>
                </Box>
              </Watermark>
            </Box>
            <Watermark text={watermarkText} rotate="-20" textSize={15}>
              <Box className={classes.infoContainerOverflow}>
                {fields.map((row, idx) => (
                  <Box
                    key={row.name}
                    className={classes.wellInfo}
                    style={{
                      background: idx % 2 === 0 ? '#182C3D' : '#3D4F5F',
                    }}
                  >
                    <span>{row.alias}</span>
                    <span>{listing[row.name]}</span>
                  </Box>
                ))}
              </Box>
            </Watermark>
          </Box>
        ))}
      </Grid>
    </div>
  );
};

HistoricalInfoWindow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCluster: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  pointCount: PropTypes.number,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  handleToggleScrollwheel: PropTypes.func,
  handleCloseInfoWindow: PropTypes.func,
  userFullName: PropTypes.string,
  companyName: PropTypes.string,
};

HistoricalInfoWindow.defaultProps = {
  isCluster: false,
  isEmbedded: false,
  pointCount: 0,
  handleToggleScrollwheel: undefined,
  handleCloseInfoWindow: undefined,
  userFullName: '',
  companyName: '',
};

export default compose(
  withStyles(styles),
  connect(({ auth, company }) => ({
    userFullName: getUserFullName(auth.user),
    companyName: company.fullLegalCompanyName,
  })),
  memo,
)(HistoricalInfoWindow);
