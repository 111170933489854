import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getNewMexicoDocument: () =>
    makeAxiosRequest(
      `/new-mexico/documents`,
      {
        method: 'get',
      },
      true
    ),
  getPreSignedUrls: (params) =>
    makeAxiosRequest(
      `/get-s3-pre-signed-urls-for-new-mexico-transactions?${params}`,
      { method: 'get' },
      true
    ),
  deleteNewMexicoDocument: (documentId) =>
    makeAxiosRequest(
      `/new-mexico/documents/${documentId}`,
      {
        method: 'delete',
      },
      true
    ),
  postNewMexicoDocument: () =>
    makeAxiosRequest(
      `/new-mexico/file-upload`,
      {
        method: 'post',
      },
      true
    ),
};
