import {
  useCallback,
  useRef,
} from 'react';
import { RateLimiter } from 'now-shared/helpers/rate-limiter';
import { triggerFormValidation } from 'components/standard-form-props';

const useRateLimitedFormValidator = ({
  validate,
  form,
  delayInMillis,
  startWithDelay,
}) => {
  const { current: rateLimiter } = useRef(
    new RateLimiter({
      delayInMillis: delayInMillis ?? 1000,
      startWithDelay: startWithDelay ?? true,
    }),
  );

  const lastValidationErrorsRef = useRef({});

  const validationCounterRef = useRef(-1);
  const validationCountToValidateRef = useRef(-1);

  const rateLimitedValidate = useCallback(
    record => {
      validationCounterRef.current += 1;

      if (validationCounterRef.current <= validationCountToValidateRef.current) {
        if (validationCounterRef.current === validationCountToValidateRef.current) {
          lastValidationErrorsRef.current = validate(record);
        }
      } else {
        rateLimiter.run({
          handler: immediate => {
            if (immediate) {
              validationCountToValidateRef.current = validationCounterRef.current + 2;
              try {
                triggerFormValidation(form);
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                validationCountToValidateRef.current = validationCounterRef.current;
              }
            }
          },
        });
      }

      return lastValidationErrorsRef.current;
    },
    [form, rateLimiter, validate],
  );
  return rateLimitedValidate;
};

export default useRateLimitedFormValidator;
