import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { reducer as formReducer } from 'redux-form';

import { UserCreate, UserEdit, UserList } from './resources/users';

import HttpClient from './http/httpClient';
import { AuthProvider, UserRoles } from './providers/authProvider';
import { DataProvider } from './providers/dataProvider';
import CustomRoutes from './routes/customRoutes';

import LoginPage from './pages/login';
import CustomLayout from './layouts/customLayout';
import { SellerAgreementEdit } from './resources/sellerAgreement';
import { BuyerAgreementEdit } from './resources/buyerAgreement';
import { BankInformationEdit } from './resources/bankInformation';
import { ContactUsEdit, ContactUsList } from './resources/contactUs';
import { AdminList } from './resources/admins';
import { CompanyCreate, CompanyEdit, CompanyList } from './resources/companies';
import {
  PropertyEdit,
  PropertyList,
  PropertyCreate,
} from './resources/properties';
import { StateBasinCountyLandingZoneList } from './resources/stateBasinCountyLandingZone';
import { BidEdit, BidList } from './resources/bids';

import editListingReducer from './store/reducers/editListingReducer';
import companyAdminDocuments from './store/reducers/companyAdminDocuments';
import userAdminDocuments from './store/reducers/userAdminDocuments';
import editListingSagas from './store/sagas/editListingSagas';
import companyAdminDocumentSagas from './store/sagas/companyAdminDocumentSagas';
import userAdminDocumentSagas from './store/sagas/userAdminDocumentSagas';
import documentTemplatesReducer from './store/reducers/documentTemplatesReducer';
import documentTemplatesSagas from './store/sagas/documentTemplatesSagas';
import editSellerAgreementReducer from './store/reducers/editSellerAgreementReducer';
import editSellerAgreementSagas from './store/sagas/editSellerAgreementSagas';
import editBuyerAgreementReducer from './store/reducers/editBuyerAgreementReducer';
import editBuyerAgreementSagas from './store/sagas/editBuyerAgreementSagas';
import buyerAgreementTemplateReducer from './store/reducers/buyerAgreementTemplateReducer';
import buyerAgreementTemplateSagas from './store/sagas/buyerAgreementTemplateSagas';
import sellerAgreementTemplateReducer from './store/reducers/sellerAgreementTemplateReducer';
import sellerAgreementTemplateSagas from './store/sagas/sellerAgreementTemplateSagas';
import cloneListingReducer from './store/reducers/cloneListingReducer';
import cloneListingSagas from './store/sagas/cloneListingSagas';
import companiesReducer from './store/reducers/companiesReducer';
import companiesSagas from './store/sagas/companiesSagas';
import notificationsSagas from './store/sagas/notificationsSagas';
import statesReducer from 'store/reducers/statesReducer';
import listingAnalytics from 'store/reducers/listingAnalyticsReducer';
import statesSagas from 'store/sagas/statesSagas';
import listingAnalyticsSagas from 'store/sagas/listingAnalyticsSagas';
import rolesReducer from 'store/reducers/rolesReducer';
import permissionsReducer from 'store/reducers/permissionsReducer';
import rolesSagas from 'store/sagas/rolesSagas';
import permissionsSagas from 'store/sagas/permissionsSagas';
import editNewMexicoSagas from 'store/sagas/newMexicoDocumentSagas';
import newMexicoDocuments from 'store/reducers/newMexicoDocuments';
import {
  ComplianceTodoList,
  complianceTodosResourceName,
} from 'resources/complianceTodos';
import {
  HistoricalListingCreate,
  HistoricalListingEdit,
  HistoricalListingsList,
} from 'resources/historicalListings';
import {
  NewMexicoListingCreate,
  NewMexicoListingEdit,
  NewMexicoListingsList,
} from 'resources/newMexicoListings';

const App = () => {
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    let inactivityTimer;

    const handleInactive = () => {
      const currentUrl = window.location.href.split('#')[1];
      if (currentUrl !== '/login') {
        setIsInactive(true);
        AuthProvider.logout();
      }
    };

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleInactive, 30 * 60 * 1000); // 30 minutes in milliseconds
    };

    const handleActivity = () => {
      setIsInactive(false);
      resetTimer();
    };

    // Initial setup
    resetTimer();

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, []);

  return (
    <Admin
      key={isInactive}
      dataProvider={DataProvider(process.env.API_URL, HttpClient)}
      authProvider={AuthProvider}
      loginPage={LoginPage}
      layout={CustomLayout}
      customReducers={{
        ...companyAdminDocuments,
        ...userAdminDocuments,
        ...editListingReducer,
        ...cloneListingReducer,
        ...companiesReducer,
        ...documentTemplatesReducer,
        ...editSellerAgreementReducer,
        ...editBuyerAgreementReducer,
        ...buyerAgreementTemplateReducer,
        ...sellerAgreementTemplateReducer,
        ...statesReducer,
        ...listingAnalytics,
        ...rolesReducer,
        ...permissionsReducer,
        ...newMexicoDocuments,
        form: formReducer,
      }}
      customRoutes={CustomRoutes}
      customSagas={[
        companyAdminDocumentSagas,
        userAdminDocumentSagas,
        editListingSagas,
        cloneListingSagas,
        companiesSagas,
        documentTemplatesSagas,
        editSellerAgreementSagas,
        editBuyerAgreementSagas,
        buyerAgreementTemplateSagas,
        sellerAgreementTemplateSagas,
        statesSagas,
        listingAnalyticsSagas,
        notificationsSagas,
        rolesSagas,
        permissionsSagas,
        editNewMexicoSagas,
      ]}
    >
      {(permissions) => [
        <Resource
          key="users"
          name="users"
          create={UserCreate}
          edit={UserEdit}
          list={UserList}
        />,
        <Resource
          key="admins"
          name="admins"
          list={AdminList}
          create={UserCreate}
          edit={UserEdit}
        />,
        <Resource
          key="companies"
          name="companies"
          list={CompanyList}
          create={CompanyCreate}
          edit={CompanyEdit}
        />,
        <Resource
          key="properties"
          name="properties"
          create={PropertyCreate}
          list={PropertyList}
          edit={PropertyEdit}
        />,
        <Resource
          key="historical-transactions"
          name="historical-transactions"
          list={HistoricalListingsList}
          create={HistoricalListingCreate}
          edit={HistoricalListingEdit}
        />,
        <Resource
          key="new-mexico-transactions"
          name="new-mexico-transactions"
          list={NewMexicoListingsList}
          create={NewMexicoListingCreate}
          edit={NewMexicoListingEdit}
        />,
        <Resource key="bids" name="bids" list={BidList} edit={BidEdit} />,
        <Resource
          key="stateBasinCountyLandingZone"
          name="stateBasinCountyLandingZone"
          list={StateBasinCountyLandingZoneList}
        />,
        <Resource
          key={complianceTodosResourceName}
          name={complianceTodosResourceName}
          list={ComplianceTodoList}
        />,
        <Resource
          key="contact-us"
          name="contact-us"
          list={ContactUsList}
          edit={ContactUsEdit}
        />,
        <Resource
          key="seller-agreements"
          name="seller-agreements"
          edit={SellerAgreementEdit}
        />,
        <Resource
          key="buyer-agreements"
          name="buyer-agreements"
          edit={BuyerAgreementEdit}
        />,
        <Resource
          key="bank-information"
          name="bank-information"
          edit={BankInformationEdit}
        />,
      ]}
    </Admin>
  );
};

export default App;
