export const parseEditableContent = (content) => {
  const firstStringToSearch = '<p>';
  const lastStringToSearch = '</p>';
  const firstIndex = content.indexOf(firstStringToSearch);
  const lastIndex = content.lastIndexOf(lastStringToSearch);
  const editableContent = content.substring(firstIndex, lastIndex + lastStringToSearch.length);

  return editableContent;
};

export const getFullTemplate = (fullContent, newCotent) => {
  const firstStringToSearch = '<p>';
  const lastStringToSearch = '</p>';
  const firstIndex = fullContent.indexOf(firstStringToSearch);
  const lastIndex = fullContent.lastIndexOf(lastStringToSearch);
  const replacedString = fullContent.substring(0, firstIndex)
  + newCotent + fullContent.substring(lastIndex + lastStringToSearch.length);

  return replacedString;
};
