import React from 'react';
import { ResourceToolbar } from './resourceToolbar';

export const getStandardFormProps = ({
  isForCreate,
  changeDetect,
  ...props
}) => {
  const {
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...propsForToolbar
  } = props;
  return {
    ...!isForCreate && {
      toolbar: (
        <ResourceToolbar
          {...propsForToolbar}
          deletingConfirmation
        />
      ),
    },
    mutators: {
      triggerValidation: (fieldsToValidate, state, { changeValue }) => {
        fieldsToValidate.forEach(fieldName => {
          changeValue(state, fieldName, value => value);
        });
      },
    },
    // eslint-disable-next-line no-nested-ternary
    warnWhenUnsavedChanges: changeDetect === undefined ? true : changeDetect,
  };
};

export const unusedFieldName = '_unused_field_';

export const triggerFormValidation = form => form.mutators.triggerValidation(unusedFieldName);

// TODO: [FEATURE][DRY] convert to hook or component and add custom save() prop for relaying
// field errors back to the form on submit.
// const save = useCallback(
//   async (values) => {
//     try {
//       await mutate({
//         type: 'create',
//         resource: 'users',
//         payload: { data: values },
//       }, { returnPromise: true });
//     } catch (error) {
//       if (error.body.errors) {
//         return error.body.errors;
//       }
//     }
//   },
//   [mutate],
// );
export const getStandardCreateEditProps = ({ notify, isForCreate, ...props }) => ({
  ...props,
  ...!isForCreate && {
    /**
     * Submit immediately and wait for success before leaving the form
     */
    mutationMode: 'pessimistic',
  },
  /**
   * Handle this to prevent the form from resetting after a submission error
   */
  onFailure: error => {
    notify(error.message, 'warning');
  },
});
