import React, { useState } from 'react';
import {
  Button,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { getAuthorizationHeader } from 'auth/auth-helpers';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '500px',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}));

const DeleteAllButton = ({ refresh }) => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  const handleImportClick = () => {
    setOpenModal(true);
  };

  const handleDeleteAll = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/new-mexico-transactions-delete-all`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      )
      .then(() => {
        setOpenModal(false);
        if (typeof refresh === 'function') {
          refresh();
        }
      })
      .catch((error) => {
        console.error(error);
        setOpenModal(false);
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleImportClick}
        style={{ marginTop: '20px', width: 'fit-content' }}
      >
        Delete All Listings
      </Button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">
            Are you sure you want to delete all listings?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '40px',
            }}
          >
            <Button
              style={{ cursor: 'pointer' }}
              variant="outlined"
              color="primary"
              onClick={handleDeleteAll}
            >
              Yes
            </Button>
            <Button
              style={{ cursor: 'pointer' }}
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
};
export default DeleteAllButton;
