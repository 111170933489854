import React, { Component } from 'react';
import {
  ReferenceManyField, Pagination, crudGetManyReference, withDataProvider,
} from 'react-admin';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SortDirection } from 'now-shared/enums/sort-direction';

class ReferenceManyList extends Component {
  render() {
    const {
      children,
      pagination,
      perPage,
      reference,
      resource,
      apiResource,
      sort,
      record,
      basePath,
    } = this.props;

    return (
      <ReferenceManyField
        pagination={pagination}
        perPage={perPage}
        reference={reference}
        target={apiResource || resource}
        sort={sort}
        addLabel={false}
        resource={resource}
        basePath={basePath}
        record={record}
        source={ this.props.source || 'id' }
      >
        {children}
      </ReferenceManyField>
    );
  }
}

ReferenceManyList.propTypes = {
  addLabel: PropTypes.bool,
  create: PropTypes.bool,
  searching: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  apiResource: PropTypes.string,
  filter: PropTypes.object,
  hasBulkActions: PropTypes.bool,
  label: PropTypes.string,
  perPage: PropTypes.number,
  exporter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
};

ReferenceManyList.defaultProps = {
  filter: {},
  hasBulkActions: false,
  create: false,
  exporter: false,
  searching: true,
  perPage: 50,
  sort: { field: 'id', order: SortDirection.Descending },
  source: 'id',
  pagination: <Pagination rowsPerPageOptions={[50, 25, 5]} />,
};

export default withDataProvider(connect(null, { crudGetManyReference })(ReferenceManyList));
