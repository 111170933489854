import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getAllRoles = asyncAction('roles/GET_ALL_ROLES');
export const createRole = asyncAction('roles/CREATE_ROLE');
export const updateRole = asyncAction('roles/UPDATE_ROLE');
export const deleteRole = asyncAction('roles/DELETE_ROLE');

// sync actions
export const clearState = action('roles/CLEAR_STATE');
