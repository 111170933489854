export const password = value => {
  if (value) {
    if (value.search(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%^&*()_+|~\-=`{}\[\]:";'<>?,.\/@#])[A-Za-z\d!$%^&*()_+|~\-=`{}\[\]:";'<>?,.\/@#]{8,}$/
    ) === -1) {
      return 'At least 8 digits and at least, 1 capital letter, 1 lowercase, 1 number and 1 symbol';
    }
  }
};
