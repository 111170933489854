export const coordinatesGenerator = wells => {
  const polylinesCoordinates = [];
  const markersCoordinates = [];

  wells && wells.forEach(zone => {
    if (zone) {
      const surface = zone.surfaceLatitude && zone.surfaceLongitude && {
        lat: +zone.surfaceLatitude,
        lng: +zone.surfaceLongitude,
      };
      const bottom = zone.bottomLatitude && zone.bottomLongitude && {
        lat: +zone.bottomLatitude,
        lng: +zone.bottomLongitude,
      };

      surface && markersCoordinates.push(surface);
      bottom && markersCoordinates.push(bottom);

      surface && bottom && polylinesCoordinates.push([surface, bottom]);
    }
  });

  return { polylinesCoordinates, markersCoordinates };
};
