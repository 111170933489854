import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogueContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';
import {
  Button,
  crudDelete,
  crudDeleteMany,
  useNotify,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';

import { generateFetchOptions } from '../helpers/fetch';

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

const DeleteUserButtonWithConfirmation = ({ selectedIds, ...props }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const options = generateFetchOptions('GET');
  const [showDialog, setShowDialog] = useState(false);
  const [userId, setUserId] = useState(undefined);
  const [userAssociatedData, setUserAssociatedData] = useState({ properties: [], bids: [] });
  const [fetched, setFetched] = useState(false);

  const getAssociatedData = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}/property-bid-data`, options);
    const data = await response.json();
    setUserAssociatedData(data);
    setFetched(true);
  }, [userId, options]);

  useEffect(() => {
    if (selectedIds.length === 1) {
      setUserId(selectedIds[0]);
      setFetched(false);
    } else {
      setUserId(undefined);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (userId && !fetched) {
      getAssociatedData();
    }
  }, [userId, getAssociatedData, fetched]);

  const createBidRows = bids => bids.map((bid, index) => ({
    id: index + 1,
    bidId: bid.id,
    propertyId: bid.propertyId,
    propertyName: bid.property.projectName,
    amount: bid.amount ? bid.amount : 'N/A',
    carry: bid.carryPercentage ? bid.carryPercentage : 'N/A',
  }));

  const createPropertyRows = properties => properties.map((property, index) => ({
    id: index + 1,
    propertyId: property.id,
    propertyName: property.projectName,
    seller: property.user.company.fullLegalCompanyName,
    status: property.status.title,
  }));

  const handleClick = async () => {
    if (userId) {
      setShowDialog(true);
    } else {
      notify('Please select only one User to delete', 'warning', { autoHideDuration: 0 });
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDelete = e => {
    e.preventDefault();
    setShowDialog(false);

    let {
      resource,
      record,
      basePath,
      redirect,
    } = props;

    if (!basePath.includes(resource)) {
      resource = basePath.replace('/', '');
    }

    const isSelectedIds = Array.isArray(selectedIds) && !!selectedIds.length;

    isSelectedIds
      ? dispatch(crudDeleteMany(resource, selectedIds, basePath, redirect))
      : dispatch(crudDelete(resource, record.id, record, basePath, redirect));
  };

  const { label, classes, className } = props;

  return (
    <>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
      >
        <ActionDelete />
      </Button>
      {(userAssociatedData.properties.length === 0 && userAssociatedData.bids.length === 0) && (
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
        >
          <DialogTitle>Are you sure you want to delete this User?</DialogTitle>
          <DialogActions>
            <Button
              onClick={handleDelete}
              label={label}
              className={classnames('ra-delete-button', classes.deleteButton, className)}
              key="button"
            >
              <ActionDelete />
            </Button>
            <Button
              label="ra.action.cancel"
              onClick={handleCloseClick}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {(userAssociatedData.properties.length > 0 || userAssociatedData.bids.length > 0) && (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>
          <Typography variant="h5" align="center">
            WARNING
          </Typography>
        </DialogTitle>
        <DialogTitle>
          <Typography variant="h6" align="center">
            The User you are trying to delete is associated with the following Properties and/or Bids
          </Typography>
        </DialogTitle>
        <DialogTitle>
          <Typography variant="h6" align="center">
            Are you sure you want to delete this User?
          </Typography>
        </DialogTitle>
        <DialogueContent>
          {userAssociatedData.properties.length > 0 && (
            <>
              <Typography variant="subtitle2">
                Properties
              </Typography>
              <DataGrid
                autoHeight
                autoPageSize
                pageSize={2}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnSelector
                rows={createPropertyRows(userAssociatedData.properties)}
                columns={[
                  {
                    field: 'propertyId',
                    headerName: 'ID',
                    flex: 1,
                  },
                  {
                    field: 'propertyName',
                    headerName: 'Property Name',
                    flex: 1,
                  },
                  {
                    field: 'seller',
                    headerName: 'Seller',
                    flex: 1,
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                  },
                ]}
              />
              <br />
            </>
          )}
          {userAssociatedData.bids.length > 0 && (
            <>
              <Typography variant="subtitle2">
                Bids
              </Typography>
              <DataGrid
                autoHeight
                autoPageSize
                pageSize={2}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnSelector
                rows={createBidRows(userAssociatedData.bids)}
                columns={[
                  {
                    field: 'bidId',
                    headerName: 'ID',
                    flex: 1,
                  },
                  {
                    field: 'propertyId',
                    headerName: 'Property ID',
                    flex: 1,
                  },
                  {
                    field: 'propertyName',
                    headerName: 'Property Name',
                    flex: 1,
                  },
                  {
                    field: 'amount',
                    headerName: 'Bid Amount',
                    flex: 1,
                  },
                  {
                    field: 'carry',
                    headerName: 'Carry Percentage',
                    flex: 1,
                  },
                ]}
              />
            </>
          )}
        </DialogueContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            label={label}
            className={classnames('ra-delete-button', classes.deleteButton, className)}
            key="button"
          >
            <ActionDelete />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
};

DeleteUserButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
};

DeleteUserButtonWithConfirmation.defaultProps = {
  redirect: 'list',
  label: 'ra.action.delete',
  classes: {},
  undoable: true,
};

export default withStyles(styles)(DeleteUserButtonWithConfirmation);
