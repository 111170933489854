/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate, useNotify, useSafeSetState,
} from 'ra-core';
import { updateUserDataFromLoginDualFatcor } from 'providers';
import { storeOTPtoken } from 'auth/auth-helpers';
import { generateFetchOptions } from 'helpers/fetch';

const useStyles = makeStyles(
  theme => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      background: '#F15922',
      '&:hover': {
        backgroundColor: '#F15922',
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    containers: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minWidth: '400px',
      background: 'whitesmoke',
    },
    container: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      background: 'whitesmoke',
      justifyContent: 'center',
      width: '100%',

    },
  }),
  { name: 'RaLoginForm' },

);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginForm = props => {
  const [loading, setLoading] = useSafeSetState(false);
  const { history } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = values => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('required');
    }
    if (!values.password) {
      errors.password = translate('required');
    }
    return errors;
  };

  const submit = async (values) => {
    setLoading(true);

    try {
      const options = {
        ...generateFetchOptions('POST', {
          email: values.username,
          password: values.password,
        }),
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, options);
      const json = await response.json();
      if (json.statusCode < 200 || json.statusCode >= 300) {
        setLoading(false);
        throw new Error(json.message);
      } else if (json.otpToken) {
        setLoading(false);
        notify(json.message, { type: 'info' });
        history.push('/verifyOtp');
        const { otpToken } = json;
        storeOTPtoken(otpToken);
      } else {
        setLoading(false);
        updateUserDataFromLoginDualFatcor(json);
        history.push('/users');
      }
    } catch (error) {
      notify(error.message, 'error');
      setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      className={classes.containers}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.containers}>
          <div className={classes.container}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="username"
                  name="username"
                  component={Input}
                  label={translate('ra.auth.username')}
                  disabled={loading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label={translate('ra.auth.password')}
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                />
              </div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
                )}
                {translate('ra.auth.sign_in')}
              </Button>
            </CardActions>
          </div>
        </form>
      )}
    />
  );
};

export default LoginForm;
