export function isOldNonPreFilledAgreement(agreement) {
  // agreement doesn't have a stored document (it used the old default one)
  return !agreement.document && !!agreement.wasSignedOnCreation;
}

export function isCustomAgreement(agreement) {
  // agreement has a stored document that wasn't signed upon creation.
  // This also implies it was uploaded by an Admin.
  return !!agreement.document && !agreement.wasSignedOnCreation;
}

export function isPreFilledAgreement(agreement) {
  // agreement document is stored and it was digitally signed by customer upon creation.
  // This implies the document was the default, pre-filled tiptap version, rendered dynamically
  // by the backend upon signing.
  return !!agreement.document && !!agreement.wasSignedOnCreation;
}

export function doesAgreementHaveDocument(agreement) {
  // if agreement was signed on creation, but missing an attached document, then the default
  // old non pre-filled document file constitutes the agreement document.
  return !!agreement.document || !!agreement.wasSignedOnCreation;
}

export const oldNonPreFilledBuyerAgreementVersion = '2022-08-01 generic';
export const oldNonPreFilledSellerAgreementVersion = '2022-08-01 generic';

export const agreementPageSettings = {
  /**
   * @readonly
   */
  width: '8.5in',
  /**
   * @readonly
   */
  height: '11in',
  /**
   * @readonly
   */
  margin: {
    /**
     * @readonly
     */
    top: '.75in',
    /**
     * @readonly
     */
    bottom: '.75in',
    /**
     * @readonly
     */
    left: '.75in',
    /**
     * @readonly
     */
    right: '.75in',
  },
};
