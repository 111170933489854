import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction(
  'newMexicoDocuments/GET_PRE_SIGNED_URLS'
);
export const saveNewMexicoDocuments = asyncAction(
  'newMexicoDocuments/SAVE_NEW_MEXICO_DOCUMENTS'
);
export const getNewMexicoDocuments = asyncAction(
  'newMexicoDocuments/GET_NEW_MEXICO_DOCUMENTS'
);
export const deleteNewMexicoDocument = asyncAction(
  'newMexicoDocuments/DELETE_NEW_MEXICO_DOCUMENT'
);

// sync action
export const setAllAWSData = action('newMexicoDocuments/SET_ALL_AWS_DATA');
export const setAWSData = action('newMexicoDocuments/SET_AWS_DATA');
export const removeAWSDataFile = action(
  'newMexicoDocuments/REMOVE_AWS_DATA_FILE'
);
export const clearAWSData = action('newMexicoDocuments/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action(
  'newMexicoDocuments/SET_UNLOADED_FILES_EXIST'
);
