import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

// actions
import {
  getPreSignedUrls, saveCompanyComplianceDocuments, getCompanyComplianceDocuments, deleteCompanyComplianceDocument,
} from '../actions/company-admin-documents';

// api methods
import Api from 'api/companies';

import queryString from 'query-string';

function* ensureGetPreSignedUrls({ payload }) {
  const {
    filesName,
    filesMd5,
    filesSize,
    resolve,
    reject,
  } = payload;
  try {
    const params = queryString.stringify({
      filesName,
      filesMd5,
      filesSize,
    }, { arrayFormat: 'bracket' });
    const { data } = yield call(Api.getPreSignedUrls(params));
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* ensureGetCompanyComplianceDocuments({ payload: { companyId } }) {
  try {
    const { data } = yield call(Api.getCompanyComplianceDocument(companyId));
    yield put({ type: getCompanyComplianceDocuments.success, payload: data });
  } catch (err) {
    yield put({ type: getCompanyComplianceDocuments.failure, err });
  }
}

function* ensureDeleteCompanyComplianceDocument({ payload: { companyId, documentId } }) {
  try {
    yield call(Api.deleteCompanyComplianceDocument(companyId, documentId));
    yield put(getCompanyComplianceDocuments({ companyId }));
  } catch (err) {
    yield put({ type: getCompanyComplianceDocuments.failure, err });
  }
}

function* saveCompanyUploadSaga({ payload }) {
  try {
    const documents = payload.AWSData.filter(doc => !doc.id).map(
      ({
        key,
        filename,
      }) => ({
        key,
        filename,
      }),
    );
    yield call(Api.postCompanyComplianceDocument(payload.companyId), {
      data: JSON.stringify({ documents }),
    });
    yield put(getCompanyComplianceDocuments({ companyId: payload.companyId }));
    yield put({ type: saveCompanyComplianceDocuments.success });
  } catch (err) {
    yield put({ type: saveCompanyComplianceDocuments.failure, err });
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

function* watchFileUpload() {
  yield takeLatest(saveCompanyComplianceDocuments.type, saveCompanyUploadSaga);
  yield take(saveCompanyComplianceDocuments.success);
}

function* watchGetComplianceFiles() {
  yield takeLatest(getCompanyComplianceDocuments.type, ensureGetCompanyComplianceDocuments);
  yield take(getCompanyComplianceDocuments.success);
}

function* watchDeleteComplianceFiles() {
  yield takeLatest(deleteCompanyComplianceDocument.type, ensureDeleteCompanyComplianceDocument);
  yield take(deleteCompanyComplianceDocument.success);
}

export default function* editListingSagas() {
  yield all([
    fork(watchGetPreSignedUrls),
    fork(watchFileUpload),
    fork(watchGetComplianceFiles),
    fork(watchDeleteComplianceFiles),
  ]);
}
