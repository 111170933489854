import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';
import { Button, crudDelete, crudDeleteMany } from 'react-admin';

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

const DeleteButtonWithConfirmation = props => {
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDelete = e => {
    e.preventDefault();
    setShowDialog(false);

    let {
      resource,
      record,
      basePath,
      selectedIds,
      redirect,
    } = props;

    if (!basePath.includes(resource)) {
      resource = basePath.replace('/', '');
    }

    const isSelectedIds = Array.isArray(selectedIds) && !!selectedIds.length;

    isSelectedIds
      ? dispatch(crudDeleteMany(resource, selectedIds, basePath, redirect))
      : dispatch(crudDelete(resource, record.id, record, basePath, redirect));
  };

  const { label, classes, className } = props;

  false && console.log('DeleteButton', props);

  return (
    <>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
      >
        <ActionDelete />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Are you sure you want to delete the record?</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDelete}
            label={label}
            className={classnames('ra-delete-button', classes.deleteButton, className)}
            key="button"
          >
            <ActionDelete />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
};

DeleteButtonWithConfirmation.defaultProps = {
  redirect: 'list',
  label: 'ra.action.delete',
  classes: {},
  undoable: true,
};

export default withStyles(styles)(DeleteButtonWithConfirmation);
