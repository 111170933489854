/**
 * @param {string|number|null|undefined} maskedValue
 * @returns {number|undefined}
 */
export const maskedAmountToNumber = (maskedValue, isReactAdmin) => {
  let result;

  if (typeof maskedValue === 'number') {
    result = maskedValue;
  } else if (maskedValue === '$ ' && isReactAdmin) {
    return 0;
  } else if (typeof maskedValue === 'string') {
    const numberString = maskedValue.replace(/[^0-9.-]+/g, '');
    if (numberString) {
      result = +numberString;
    }
  }
  if (Number.isNaN(result) || !Number.isFinite(result)) {
    result = undefined;
  }
  return result;
};

/**
 * @param {string|number|null|undefined} maskedValue
 * @returns {number | string |undefined}
 */
export const maskedAmountToNumberString = (maskedValue, isReactAdmin) => {
  let result;

  if (typeof maskedValue === 'number') {
    result = maskedValue;
  } else if (maskedValue === '$ ' && isReactAdmin) {
    return 0;
  } else if (typeof maskedValue === 'string') {
    const endsWithDot = maskedValue.endsWith('.0');
    let numberString = parseFloat(maskedValue.replace(/[^\d.]+/g, ''));
    if (endsWithDot) {
      numberString = numberString.toFixed(1);
    }

    if (typeof maskedValue === 'string') {
      const dotIndex = maskedValue.indexOf('.');
      if (dotIndex < maskedValue.length - 1) {
        result = numberString;
      } else {
        result = `${numberString}.`;
      }
    }
  }
  if ((Number.isNaN(result) || !Number.isFinite(result)) && !maskedValue.includes('.')) {
    result = undefined;
  }
  return result;
};

/**
 * TODO: [CLARIFY] convert the second arg `noSpace` into an arg called `options`, and make
 * `noSpace` an optional property of that arg. This will help people reading the code where this
 * function is called to quickly understand what this boolean is for (they'll see `noSpace: true` instead
 * of just `true`, which will be a lot more descriptive).
 */
export const formatNumberToCurrency = (number, noSpace) => {
  const finalNumber = (number === 'undefined' || Number.isNaN(number)) ? 0 : number;
  const formatted = `$ ${Number(finalNumber || 0).toLocaleString('en-US')}`;
  if (noSpace) {
    return formatted.replace(/\s+/g, '');
  }
  return formatted;
};

/**
 * TODO: [CLARIFY] convert the second arg `noSpace` into an arg called `options`, and make
 * `noSpace` an optional property of that arg. This will help people reading the code where this
 * function is called to quickly understand what this boolean is for (they'll see `noSpace: true` instead
 * of just `true`, which will be a lot more descriptive).
 * it format the number and add dot later if exist
 */
export const formatNumberToCurrencyDecimal = (number, noSpace) => {
  const finalNumber = (number === 'undefined' || Number.isNaN(number)) ? 0 : number;
  const formatted = `$ ${Number(finalNumber || 0).toLocaleString(
    'en-US',
    {
      maximumFractionDigits: 2,
    },
  )}`;
  let result;

  if (typeof number === 'string') {
    const dotIndex = number.indexOf('.');
    if (dotIndex < number.length - 1) {
      if (number.endsWith('.0')) {
        result = `${formatted}.0`;
      } else {
        result = formatted;
      }
    } else {
      result = `${formatted}.`;
    }
  } else {
    result = formatted;
  }

  if (noSpace) {
    return result.replace(/\s+/g, '');
  }
  return result;
};

/**
 * @param {string} formatted
 * @returns {string}
 */
export const formattedCurrencyToNumberString = (formatted, isReactAdmin) => `${maskedAmountToNumber(formatted, isReactAdmin)}`;
