// actions
import * as A from '../actions/seller-agreement-template-actions';

const initialState = {
  sellerAgreementTemplate: undefined,
  sellerAgreementTemplateIsLoading: false,
};

const reducer = {
  sellerAgreementTemplate(state = initialState, action) {
    switch (action.type) {
      case A.reset.type:
        return initialState;

      case A.getSellerAgreementTemplate.type:
        return { ...state, sellerAgreementTemplateIsLoading: true };

      case A.getSellerAgreementTemplate.failure:
        return { ...state, sellerAgreementTemplateIsLoading: false };

      case A.getSellerAgreementTemplate.success:
        return {
          ...state,
          sellerAgreementTemplate: action.payload,
          sellerAgreementTemplateIsLoading: false,
        };

      default:
        return state;
    }
  },
};

export default reducer;
