import Box from '@material-ui/core/Box';
import React from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const WellInfo = ({ well, classes }) => {
  const {
    wellName,
    wellAPINumber,
    bottomLatitude,
    bottomLongitude,
    surfaceLongitude,
    surfaceLatitude,
  } = well;
  return (
    <Box className={classes.wellInfoContainer}>
      <Typography className={classes.wellHeader}>{wellName}</Typography>
      <div
        className={classes.wellsContainer}
      >
        <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
          <div>API Number</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
          <div>Surface Hole</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
          <div>Bottom Hole</div>
        </Grid>
      </div>
      <div
        className={classes.wellsContainer}
      >
        <Grid item className={classes.bottomSectionHeading}>
          <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
            {wellAPINumber || '-'}
          </div>
        </Grid>
        <Grid item className={classes.bottomSectionHeading}>
          <div className={classes.bottomSectionDescription}>
            <div style={{ borderBottom: 'solid 2px #E5E5E5' }}>
              <div style={{ padding: 6 }}>
                <span className={classes.latLongText}>LAT</span>
                {surfaceLatitude || '-'}
              </div>
            </div>
            <div style={{ padding: 6 }}>
              <span className={classes.latLongText}>LNG</span>
              {surfaceLongitude || '-'}
            </div>
          </div>
        </Grid>
        <Grid item className={classes.bottomSectionHeading}>
          <div className={classes.bottomSectionDescription}>
            <div style={{ borderBottom: 'solid 2px #E5E5E5' }}>
              <div style={{ padding: 6 }}>
                <span className={classes.latLongText}>LAT</span>
                {bottomLatitude || '-'}
              </div>
            </div>
            <div style={{ padding: 6 }}>
              <span className={classes.latLongText}>LNG</span>
              {bottomLongitude || '-'}
            </div>
          </div>
        </Grid>
      </div>
    </Box>
  );
};

WellInfo.propTypes = {
  well: PropTypes.shape({
    id: PropTypes.number,
    wellName: PropTypes.string,
    wellAPINumber: PropTypes.string,
    surfaceLatitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    surfaceLongitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    bottomLatitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    bottomLongitude: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const WellsTable = ({ wells, classes }) => (
  <Box className={classes.mainContainer}>
    <Box className={classes.wellsWrapper}>
      {wells?.map(well => (<WellInfo key={well.id} well={well} classes={classes} />))}
    </Box>
  </Box>
);

WellsTable.propTypes = {
  wells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      wellName: PropTypes.string,
      surfaceLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      surfaceLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }).isRequired,
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles))(WellsTable);
