const infoWindowBaseStyles = {
  backgroundColor: '#3D4F5F',
};

export default {
  infoWindowBase: infoWindowBaseStyles,
  infoWindow: {
    ...infoWindowBaseStyles,
    position: 'relative',
    top: 0,
    left: 0,
    width: '700px',
    height: 'auto',
    borderRadius: '10px',
    transform: 'translateX(-8px) translateY(-10px)',
    zIndex: 100,
    marginLeft: 10,
    marginBottom: 4,
    borderTopLeftRadius: 0,

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      border: '14px solid transparent',
      borderBottomColor: '#3D4F5F',
      borderTop: 0,
      borderLeft: 0,
      transform: 'translate3d(0px, -12px, 0)',

      '@media screen and (max-width: 960px)': {
        transform: 'translate3d(174px, -12px, 0)',
      },

      '@media screen and (max-width: 600px)': {
        transform: 'translate3d(140px, -12px, 0)',
      },
    },

    '@media screen and (max-width: 960px)': {
      left: '-165px',
    },

    '@media screen and (max-width: 600px)': {
      width: '280px',
      left: 0,
      transform: 'translateX(-50%) translateY(-10px)',
    },
  },
  infoContainer: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#4F4F4F',
  },
  infoContainerOverflow: {
    maxHeight: '250px',
    overflow: 'auto',

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #fff',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },

    '@media screen and (max-width: 600px)': {
      maxHeight: '76px',
    },
  },
  infoHeading: {
    marginBottom: '20px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',

    '@media screen and (max-width: 600px)': {
      textAlign: 'center',
      marginBottom: '10px',
    },
  },
  propertyInfo: {
    margin: '4px 0',
  },
  propertyId: {
    color: '#BDBDBD !important',
  },
  infoCount: {
    width: '100%',
    margin: '12px 0',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  infoCountBold: {
    fontWeight: 'bold',
  },
  infoMessage: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#2F80ED',
  },
  propertyDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  propertyDescriptionHeader: {
    height: '100%',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  propertyNameWrapper: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  propertyNameText: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '160%',
  },
  closeIcon: {
    cursor: 'pointer',
    height: 30,
    width: 30,
  },
  propertyDescription: {
    color: '#E5E5E5',
    marginTop: 10,
  },

  wellInfo: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    padding: 10,
    fontSize: 18,
  },
};
