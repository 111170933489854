import React from 'react';
import { Card, CardHeader } from '@material-ui/core';
import {
  TextInput, SimpleForm, required, useNotify, useRefresh,
} from 'react-admin';
import { asyncCurrentPassword, password, isPasswordMatch } from '../validations';
import { generateFetchOptions } from '../helpers/fetch';
import { getUserData } from 'auth/auth-helpers';
import { parseServerError, ServerError } from 'now-shared/helpers/server-errors';

export default () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = values => {
    const { password, confirmPassword } = values;
    const options = generateFetchOptions('PUT', { password, confirmPassword });

    fetch(`${process.env.REACT_APP_API_URL}/save-new-password`, options)
      .then(async response => {
        if (response.status === 200) {
          notify('Password changed');
          refresh();
        } else {
          let message;
          try {
            message = parseServerError(await response.json()).friendlyMessage;
          } catch (error) {
            message = ServerError.Generic;
          }
          notify(message, 'warning');
        }
      });
  };

  const userData = getUserData();

  return (
    <Card>
      <CardHeader title="Profile" />
      {userData && (
      <SimpleForm save={handleSubmit}>
        <TextInput
          label="First Name"
          source="firstName"
          defaultValue={userData.firstName}
          options={{ disabled: true }}
        />
        <TextInput
          label="Last Name"
          source="lastName"
          defaultValue={userData.lastName}
          options={{ disabled: true }}
        />
        <TextInput
          label="Email"
          source="email"
          defaultValue={userData.email}
          options={{ disabled: true }}
        />
        <TextInput
          label="Current Password"
          source="oldPassword"
          validate={[required(), asyncCurrentPassword]}
          type="password"
        />
        <TextInput
          label="Password"
          source="password"
          validate={[required(), password]}
          type="password"
        />
        <TextInput
          label="Password Confirmation"
          source="confirmPassword"
          validate={[required(), password, isPasswordMatch]}
          type="password"
        />
      </SimpleForm>
      )}
    </Card>
  );
};
