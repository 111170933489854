/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Checkbox from '@material-ui/core/Checkbox';
import { nonOpWellsDateTime } from 'now-shared/helpers/time-helpers';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  FunctionField,
  TextField,
  ChipField,
  SelectInput,
  required,
  Edit,
  Button,
  TopToolbar,
  FormTab,
  NumberField,
  TabbedForm,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  linkToRecord,
  useRedirect,
  addField,
  AutocompleteArrayInput,
  ArrayField,
  SingleFieldList,
  useNotify,
  NullableBooleanInput,
  useRefresh,
  usePermissions,
  Create,
  SimpleForm,
  AutocompleteInput,
  Link,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import { CustomPagination } from '../components/pagination';
import withCustomData from '../wrappers/withCustomData';
import ReferenceManyList from '../components/referenceManyList';
import { MakeWinnerButton } from '../components/makeWinnerButton';
import { generateFetchOptions } from '../helpers/fetch';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import DraftsIcon from '@material-ui/icons/Drafts';
import PauseCircleIcon from '@material-ui/icons/PauseCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentButton } from 'now-frontend-shared/components/DocumentButton';
import GoogleMapField from '../components/mapField';
import EsriMapField from '../components/esriMapField';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import UpdateIcon from '@material-ui/icons/Update';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DropZone from 'now-frontend-shared/components/DropZone';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import { AnalyticsBarGraph } from 'now-frontend-shared/components/AnalyticsBarGraph';
import {
  canApproveListing,
  canArchiveListing,
  canCloseListing,
  canRejectListing,
  canReopenListing,
  canMakeListingADraft,
  canMakeListingPending,
  listingErrors,
  listingHasBeenActive,
  listingHasBeenClosedOrArchived,
  listingIsArchived,
  listingNotYetActive,
  listingNotYetClosedNorArchived,
  minEndTimeForListing,
  minStartTimeForListing,
  createPropertyValidate,
} from 'now-shared/validation/listing-validation';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setCurrentProperty,
  setUnloadedFilesExist,
  updateCurrentProperty,
} from 'store/actions/edit-listing-actions';
import { bidStatusIsHistorical } from 'now-shared/validation/bid-validation';
import {
  getAllCompanies,
  findUsersByCompany,
  getFullyOnBoardedBuyersList,
} from 'store/actions/companies-actions';
import { getStates } from 'store/actions/clone-listing-actions';
import {
  toAuctionEventTime,
  NONOPWELLS_TIME_ZONE,
} from 'now-shared/helpers/time-helpers';
import DateInputWithTimeZone from 'components/dateInputWithTimeZone';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import {
  ApprovalStatus,
  isRejectionReasonRequired,
} from 'now-shared/validation/approval-status';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import {
  parseServerError,
  ServerError,
} from 'now-shared/helpers/server-errors';
import {
  doesListingHaveTransactionInProgress,
  isListingTransactionCanceled,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import FormGetter from 'components/FormGetter';
import useRateLimitedFormValidator from 'hooks/useRateLimitedFormValidator';
import { createAdminResourceRejectionReasonValidator } from 'validations/validateRejectionReason';
import { validateWrapper } from 'now-frontend-shared/helpers/ui-validation-toggle';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import { apiBaseUrl, getAuthQueryParamObject } from 'utils/apiMethods';
import {
  computeWellsGrossAfe,
  computeWellsNetAfe,
} from 'now-shared/helpers/listing-helpers';
import FormDataConsumer from 'components/FormDataConsumer';
import { setFormFieldValue } from 'helpers/form-helpers';
import {
  shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellRankOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellMinimumBidOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellApiNumberOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellMinimumBidCarryOnListingThatHasBeenClosedOrArchived,
  shouldAllowSuperAdminToChangeListingCompany,
  shouldAllowChangeListingOwnerOnListingThatHasBeenClosedOrArchived,
  shouldAllowSuperAdminToChangeDraftListingCompany,
} from 'now-shared/config/admin-settings';
import {
  clearListingAnalyticsState,
  getListingAnalytics,
  createCustomAnalytics,
  getCustomListingAnalytics,
  updateCustomAnalytics,
  deleteCustomAnalytics,
  getListingNotification,
} from 'store/actions/listingAnalyticsActions';
import {
  HighLevelStatCard,
  minSpaceBetweenStatCardsHalf,
} from 'now-frontend-shared/components/StatCard';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Spinner from 'now-frontend-shared/components/Spinner';
import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';
import {
  TransactionTypes,
  TransactionTypeTitles,
} from 'now-shared/enums/transaction-types';
import { enhanceListingAnalytics } from 'now-frontend-shared/utils/helpers';
import { UserRoles } from 'providers';
import { useHistory } from 'react-router-dom';
import SelectCommon from 'now-frontend-shared/components/Select';
import { customInputStyles } from './styles';
import { EditEmailNotificationModal } from 'components/EditEmailNotificatioModal';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';
import {
  formatNumberToCurrency,
  formatNumberToCurrencyDecimal,
  maskedAmountToNumberString,
} from 'now-shared/helpers/currency-helpers';
import { formattedCurrencyToNumberString } from 'now-shared/helpers/currency-helpers';
import validateFormattedCurrency from 'now-shared/validation/validateFormattedCurrency';
import {
  useIteratorStyle,
  useStyles,
} from 'now-frontend-shared/styles/document-view-styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const SenetizeBox = ({ children, style, ...props }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  return <Box style={style}>{childrenWithProps}</Box>;
};

const urls = {
  statuses: `${process.env.REACT_APP_API_URL}/properties/statuses`,
};

const urlsForEdit = {
  statesWithBasinsCountiesLandingZones: `${process.env.REACT_APP_API_URL}/states`,
};

function compare(a, b) {
  let res = 0;

  if (a.name < b.name) {
    res = -1;
  }

  if (a.name > b.name) {
    res = 1;
  }

  return res;
}

const generateSelectOptions = (array, field) =>
  array.map((item) => ({ id: item.id, name: item[field] })).sort(compare);

const formatRestrictedCompanyIds = (restrictedCompanies) =>
  restrictedCompanies?.map((c) => c.id);

const PropertiesFilter = (props) => {
  const statuses = props.statuses || [];

  return (
    <Filter {...props}>
      <TextInput label="Name / Company" source="search" alwaysOn />
      <NullableBooleanInput label="Archived" source="archived" alwaysOn />
      <SelectInput
        label="Status"
        allowEmpty
        emptyValue=""
        source="status"
        alwaysOn
        choices={statuses.map(({ id, title }) => ({ id, name: title }))}
      />
    </Filter>
  );
};

const PropertyEditActions = (props) => {
  const { data, form } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();

  const isSuperAdmin =
    permissionsLoaded && permissions.includes(UserRoles.SuperAdmin);

  const canMakeADraft = data && canMakeListingADraft(data);
  const canMakePending = data && canMakeListingPending(data);
  const canReject = data && canRejectListing(data);
  const canApprove = data && canApproveListing(data);
  const canCloseAuction = data && canCloseListing(data);
  const canArchive = data && canArchiveListing(data);
  const canBeReopened = data && canReopenListing(data);

  const onStatusChange = ({ status, rejectionReason = null }) => {
    const options = generateFetchOptions('PUT', { status, rejectionReason });

    fetch(
      `${process.env.REACT_APP_API_URL}/properties/${data.id}/change-status`,
      options
    ).then(async (response) => {
      if (response.status === 200) {
        notify('Status changed');
        refresh();
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    });
  };

  const onReopen = () => {
    if (data) {
      if (!isSuperAdmin) {
        notify('Only Super Admins have permission', 'error');
      } else {
        redirect(`/properties/${data.id}/reopen`);
      }
    }
  };

  const generateApproveButtonText = (startTime) =>
    moment().isAfter(
      toAuctionEventTime(new Date(startTime), 'start').toJSDate()
    )
      ? 'APPROVE (AND OPEN)'
      : 'APPROVE';

  return (
    <TopToolbar>
      {canMakeADraft && (
        <Button
          label="CONVERT TO DRAFT"
          onClick={() =>
            onStatusChange({
              status: 'draft',
            })
          }
          disabled={!form}
        >
          <DraftsIcon />
        </Button>
      )}
      {canMakePending && (
        <Button
          label="CONVERT TO PENDING"
          onClick={() =>
            onStatusChange({
              status: 'pending',
            })
          }
          disabled={!form}
        >
          <PauseCircleIcon />
        </Button>
      )}
      {canApprove && (
        <Button
          label={generateApproveButtonText(data.startTime)}
          onClick={() => onStatusChange({ status: 'approved' })}
        >
          <CheckIcon />
        </Button>
      )}
      {canReject && (
        <Button
          label="REJECT"
          onClick={() =>
            onStatusChange({
              status: ApprovalStatus.Rejected,
              rejectionReason: form.getFieldState('rejectionReason').value,
            })
          }
          disabled={!form}
        >
          <HighlightOffIcon />
        </Button>
      )}
      {canCloseAuction && (
        <Button
          label="CLOSE AUCTION"
          onClick={() => onStatusChange({ status: 'closed' })}
          data-cy="closeAuctionButton"
        >
          <TimerOffIcon />
        </Button>
      )}
      {canBeReopened && (
        <Button label="REOPEN AS NEW LISTING" onClick={onReopen}>
          <UpdateIcon />
        </Button>
      )}
      {data && doesListingHaveTransactionInProgress(data) && (
        <Button
          label="MARK TRANSACTION COMPLETE"
          onClick={() => onStatusChange({ status: 'transactionComplete' })}
          data-cy="markTransactionCompleteButton"
        >
          <DoneAllIcon />
        </Button>
      )}
      {canArchive && (
        // TODO: [REFACTOR] rename DTO status value to 'archived'
        <Button
          label="ARCHIVE"
          onClick={() => onStatusChange({ status: 'deleted' })}
        >
          <DeleteIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

export const PropertyList = withCustomData(urls)((props) => (
  <List
    {...props}
    filters={<PropertiesFilter statuses={props.statuses} />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    perPage={50}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="Listing ID" />
      <FunctionField
        render={(record) =>
          `${record.projectName}, ${record.wellCount} well${pluralize(
            's',
            record.wellCount
          )}`
        }
        label="Name"
        sortBy="projectName"
      />
      <FunctionField
        render={(record) => getUserFullName(record.user)}
        label="Seller"
        sortBy="sellerPersonName"
      />
      <TextField
        source="user.company.fullLegalCompanyName"
        label="Company"
        sortBy="company"
      />
      <FunctionField
        render={(record) => formatNumberToCurrency(record.netAfe, true)}
        label="Net AFE"
      />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="archivedAt"
        label="Archival Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="startTime"
        label="Start Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="endTime"
        label="End Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <ChipField source="status.title" label="Status" sortBy="status" />
      <NumberField source="bidCount" label="Bids" sortable={false} />
      <FunctionField
        label="Commission"
        render={(record) =>
          record.winningBid
            ? `${formatNumberToCurrency(record.winningBid.commission, true)}`
            : '-'
        }
      />
    </Datagrid>
  </List>
));

const mapStateToProps = ({ editListing }) => ({
  AWSData: editListing.AWSData,
  AWSDataIsSet: editListing.AWSDataIsSet,
  currentListing: editListing.currentProperty,
  setCurrentListing: setCurrentProperty,
  getPreSignedUrls,
  preSignedUrls: editListing.preSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
});

export const EditDocuments = compose(
  addField,
  connect(mapStateToProps)
)((props) => {
  const {
    documentType,
    AWSData,
    AWSDataIsSet,
    currentListing,
    dispatch,
    editable,
    getPreSignedUrls,
    input,
    label,
    preSignedUrls,
    record,
    removeAWSDataFile,
    setAllAWSData,
    setAWSData,
    setUnloadedFilesExist,
    setCurrentListing,
  } = props;

  const inputDocuments = input.value || [];
  const { onChange } = input;
  const recordDocuments = record.documents || [];
  const listingId = record.id;

  const getDocumentKeys = (docs) => docs.map((doc) => doc.key).join(',');

  const [dropzoneDocumentKeys, setDropzoneDocumentKeys] = useState(undefined);
  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] =
    useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] =
    useState(false);

  const uploadIsPending =
    dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending, setUnloadedFilesExist]);

  useEffect(() => {
    if (record && record.id) {
      dispatch(setAllAWSData(recordDocuments));
    }
    setDropzoneDocumentKeys(getDocumentKeys(recordDocuments));
    dispatch(setCurrentListing(record));
  }, [dispatch, record, recordDocuments, setAllAWSData, setCurrentListing]);

  const dropzoneKey = useMemo(() => {
    const key = `${
      currentListing ? currentListing.id : 'empty'
    }-${getDocumentKeys(recordDocuments)}`;
    return key;
  }, [currentListing, recordDocuments]);

  const inputDocumentKeys = useMemo(
    () => getDocumentKeys(inputDocuments),
    [inputDocuments]
  );

  useEffect(() => {
    if (AWSDataIsSet && currentListing && currentListing.id === listingId) {
      setDropzoneDocumentKeys(getDocumentKeys(AWSData));
    }
  }, [AWSData, AWSDataIsSet, currentListing, listingId]);

  useEffect(() => {
    if (
      !AWSDataIsSet ||
      !currentListing ||
      currentListing.id !== listingId ||
      dropzoneDocumentKeys === undefined
    ) {
      return;
    }
    if (inputDocumentKeys === dropzoneDocumentKeys) {
      return;
    }
    onChange(AWSData);
  }, [
    onChange,
    AWSData,
    AWSDataIsSet,
    currentListing,
    dropzoneDocumentKeys,
    inputDocumentKeys,
    listingId,
  ]);

  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const updatedDocumentType = () => {
    if (String(url).includes('create')) {
      return documentType.includes(
        ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT
      )
        ? ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT
        : ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT;
    }

    return documentType.includes(
      ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT
    )
      ? ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT
      : ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT;
  };

  return (
    <div style={{ width: '100%' }}>
      {editable && currentListing && currentListing.id === listingId && (
        <React.Fragment key={dropzoneKey}>
          <DropZone
            AWSData={AWSData}
            preSignedUrls={preSignedUrls}
            savedDocuments={recordDocuments.filter((d) =>
              documentType.includes(d.type)
            )}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            removeAWSDataFile={removeAWSDataFile}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            /**
             * TODO: [FEATURE] be able to set the document type using a control
             */
            documentType={updatedDocumentType()}
            placeholderPrompt="Please upload"
            placeholder="Title, AFE, Plats, JOA documents"
            isSimpleView
          />
        </React.Fragment>
      )}
      {!!inputDocuments.length && (
        <DocumentButton
          label="DOWNLOAD ALL (AS ZIP)"
          useDirectLink
          urlGetter={() =>
            queryString.stringifyUrl(
              {
                url: `${apiBaseUrl}/properties/${listingId}/documentsAsArchive`,
                query: getAuthQueryParamObject(),
              },
              {
                arrayFormat: 'bracket',
              }
            )
          }
        />
      )}
    </div>
  );
});

const findArrayItemById = (array, id) => array.find((item) => item.id === id);

const usePropertyEditCommonInputs = ({
  isDraft,
  isLoadingCompanies,
  companies,
  setSelectedCompany,
  allUsersInCompany,
  isLoadingAllUsersInCompany,
  onLocationChange,
  states,
  currentState,
  setCurrentState,
  currentBasin,
  setCurrentBasin,
  currentCounty,
  setCurrentCounty,
  isSuperAdmin,
  classes,
}) => [
  <Box style={{ width: '100%' }}>
    <h3 style={{ fontWeight: '500' }}>Information</h3>
    <Box
      style={{
        display: 'flex',
        width: '100%',
        gap: '25px',
      }}
    >
      <Box className={classes.wellInput}>
        <FormDataConsumer key="company">
          {({ formData, form, ...rest }) =>
            !companies || isLoadingCompanies ? (
              <Labeled label="Ref Company" isRequired>
                <div>loading...</div>
              </Labeled>
            ) : (
              <div>
                <AutocompleteInput
                  validate={[required()]}
                  source="refCompany"
                  label="Ref Company"
                  choices={companies}
                  onSelect={(e) => setSelectedCompany(e)}
                  optionText={getCompanyName}
                  allowEmpty
                  suggestionLimit={10}
                  style={{ width: '100%' }}
                  disabled={
                    formData.status &&
                    (!isSuperAdmin ||
                      (isDraft
                        ? !shouldAllowSuperAdminToChangeDraftListingCompany
                        : !shouldAllowSuperAdminToChangeListingCompany) ||
                      (listingHasBeenClosedOrArchived(formData) &&
                        !shouldAllowChangeListingOwnerOnListingThatHasBeenClosedOrArchived))
                  }
                  {...rest}
                />
              </div>
            )
          }
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="user">
          {({ formData, form, ...rest }) =>
            !companies || isLoadingCompanies || isLoadingAllUsersInCompany ? (
              <Labeled label="Ref User" isRequired>
                <div>loading...</div>
              </Labeled>
            ) : (
              <AutocompleteInput
                validate={[required()]}
                source="refUser"
                label="Ref User"
                choices={allUsersInCompany}
                optionText={(user) => getUserFullName(user)}
                allowEmpty
                suggestionLimit={10}
                disabled={
                  formData.status &&
                  (!isSuperAdmin ||
                    (listingHasBeenClosedOrArchived(formData) &&
                      !shouldAllowChangeListingOwnerOnListingThatHasBeenClosedOrArchived))
                }
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="manualSellerCompanyName">
          {({ formData, form, ...rest }) => (
            <TextInput
              source="manualSellerCompanyName"
              label="Custom Company"
              isRequired={false}
              disabled={
                formData.status && listingHasBeenClosedOrArchived(formData)
              }
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="projectName">
          {({ formData, form, ...rest }) => (
            <TextInput
              source="projectName"
              label="Name"
              isRequired
              disabled={
                formData.status && listingHasBeenClosedOrArchived(formData)
              }
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="operatorName">
          {({ formData, form, ...rest }) => (
            <TextInput
              source="operatorName"
              label="Operator"
              isRequired={!isDraft}
              disabled={
                formData.status && listingHasBeenClosedOrArchived(formData)
              }
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="restrictedCompanies">
          {({ formData, form, ...rest }) =>
            (!isDraft && formData.restrictedCompanies === undefined) ||
            isLoadingCompanies ||
            (formData.status && listingHasBeenClosedOrArchived(formData)) ? (
              <Labeled label="Restricted Companies">
                {!formData.restrictedCompanies?.length ? (
                  formData.restrictedCompanies === undefined ? (
                    <div>loading...</div>
                  ) : (
                    <div>-</div>
                  )
                ) : (
                  <ArrayField source="restrictedCompanies" {...rest}>
                    <SingleFieldList linkType={false}>
                      <ChipField source="fullLegalCompanyName" />
                    </SingleFieldList>
                  </ArrayField>
                )}
              </Labeled>
            ) : (
              <AutocompleteArrayInput
                source="restrictedCompanies"
                label="Restricted Companies"
                choices={companies?.filter(
                  (company) =>
                    company.id !== formData.refCompany &&
                    company.id !== formData.user?.company?.id
                )}
                format={
                  // eslint-disable-next-line no-shadow
                  (companies) => companies?.map((company) => company.id)
                }
                optionText={getCompanyName}
                parse={(ids) => ids?.map((id) => ({ id }))}
                shouldRenderSuggestions={(value) => value.trim().length > 0}
                suggestionLimit={10}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="isAnonymous">
          {({ formData, form, ...rest }) => (
            <BooleanInput
              source="isAnonymous"
              label="Is Anonymous"
              disabled={
                formData.status && listingHasBeenClosedOrArchived(formData)
              }
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
    </Box>
  </Box>,
  <Box style={{ width: '100%' }}>
    <h3 style={{ fontWeight: '500' }}>Location</h3>
    <Box
      style={{
        display: 'flex',
        width: '40%',
      }}
    >
      <Box className={classes.wellInput}>
        <FormDataConsumer key="state">
          {({ formData, form, ...rest }) => {
            if (!currentState) {
              const newState = findArrayItemById(states, formData.state?.id);
              if (newState) {
                setCurrentState(newState);
              }
            }

            return (
              <SelectInput
                source="state"
                label="State"
                format={(state) => state?.id || state}
                choices={generateSelectOptions(states, 'title')}
                allowEmpty
                onChange={onLocationChange('state', form)}
                isRequired={!isDraft}
                disabled={
                  formData.status && listingHasBeenClosedOrArchived(formData)
                }
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="basin">
          {({ formData, form, ...rest }) => {
            // TODO: move this into a useEffect block
            if (currentState && !currentBasin) {
              const newBasin = findArrayItemById(
                currentState.basins,
                formData.basin?.id
              );
              if (newBasin) {
                setCurrentBasin(newBasin);
              }
            }

            return (
              <SelectInput
                source="basin"
                label="Basin"
                format={(basin) => basin?.id || basin}
                choices={
                  currentState
                    ? generateSelectOptions(currentState.basins, 'title')
                    : []
                }
                allowEmpty
                onChange={onLocationChange('basin', form)}
                isRequired={!isDraft}
                disabled={
                  formData.status && listingHasBeenClosedOrArchived(formData)
                }
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="county">
          {({ formData, form, ...rest }) => {
            // TODO: move this into a useEffect block
            if (currentBasin && !currentCounty) {
              const newCounty = findArrayItemById(
                currentBasin.counties,
                formData.county?.id
              );
              if (newCounty) {
                setCurrentCounty(newCounty);
              }
            }

            return (
              <SelectInput
                source="county"
                label="County"
                format={(basin) => basin?.id || basin}
                choices={
                  currentBasin
                    ? generateSelectOptions(currentBasin.counties, 'title')
                    : []
                }
                allowEmpty
                onChange={onLocationChange('county', form)}
                isRequired={!isDraft}
                disabled={
                  formData.status && listingHasBeenClosedOrArchived(formData)
                }
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
    </Box>
  </Box>,
  <Box style={{ width: '100%' }}>
    <h3 style={{ fontWeight: '500' }}>Financials</h3>
    <Box
      style={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: '25px' }}
    >
      <Box className={classes.wellInput}>
        <FormDataConsumer key="totalWellGrossAfe">
          {({ formData, form, ...rest }) => {
            const definedWells = Array.isArray(formData.wells)
              ? formData.wells.filter((well) => !!well)
              : undefined;
            const computedGrossWellAfe = computeWellsGrossAfe(definedWells);
            if (formData.totalWellGrossAfe !== computedGrossWellAfe) {
              setFormFieldValue(
                form,
                'totalWellGrossAfe',
                computedGrossWellAfe
              );
            }
            return (
              <NumberInput
                className={classes.wellInput}
                source="totalWellGrossAfe"
                label="Total Well Gross AFE Amount"
                type="text"
                disabled
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  value: formatNumberToCurrency(
                    computedGrossWellAfe ??
                      (definedWells?.length
                        ? form.getFieldState('totalWellGrossAfe')?.initial
                        : null) ??
                      ''
                  ),
                }}
                parse={formattedCurrencyToNumberString}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="netAfe">
          {({ formData, form, ...rest }) => {
            const definedWells = Array.isArray(formData.wells)
              ? formData.wells.filter((well) => !!well)
              : undefined;
            const computedWellsNetAfe = computeWellsNetAfe(definedWells);
            if (formData.netAfe !== computedWellsNetAfe) {
              setFormFieldValue(form, 'netAfe', computedWellsNetAfe);
            }
            return (
              <NumberInput
                className={classes.wellInput}
                source="netAfe"
                label="Total Well Net AFE"
                type="text"
                disabled
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  value: formatNumberToCurrency(
                    computedWellsNetAfe ??
                      (definedWells?.length
                        ? form.getFieldState('netAfe')?.initial
                        : null) ??
                      ''
                  ),
                }}
                parse={formattedCurrencyToNumberString}
                {...rest}
              />
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="transactionType">
          {({ formData, form, ...rest }) => (
            <SelectInput
              className={classes.wellInput}
              source="transactionType"
              label="Transaction Type"
              allowEmpty
              emptyText="--"
              emptyValue={null}
              choices={[
                {
                  id: 1,
                  value: TransactionTypes.Cash,
                  name: TransactionTypeTitles[TransactionTypes.Cash],
                },
                {
                  id: 2,
                  value: TransactionTypes.Carry,
                  name: TransactionTypeTitles[TransactionTypes.Carry],
                },
                {
                  id: 3,
                  value: TransactionTypes.Either,
                  name: TransactionTypeTitles[TransactionTypes.Either],
                },
              ]}
              optionText="name"
              optionValue="value"
              isRequired={!isDraft}
              disabled={formData.status && listingIsArchived(formData)}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="minimumBid">
          {({ formData, form, ...rest }) =>
            ((formData.minimumBid !== null &&
              formData.minimumBid !== undefined) ||
              (form.getFieldState('minimumBid')?.initial !== null &&
                form.getFieldState('minimumBid')?.initial !== undefined)) && (
              <Labeled label="Deprecated. Please transfer to individual well(s) and set blank here">
                <NumberInput
                  source="minimumBid"
                  onWheel={(e) => e.target.blur()}
                  label={`Minimum Bid, USD${
                    formData?.minimumBid !== null ? ' (please set blank)' : ''
                  }`}
                  {...rest}
                />
              </Labeled>
            )
          }
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="minimumBidCarry">
          {({ formData, form, ...rest }) =>
            ((formData.minimumBidCarry !== null &&
              formData.minimumBidCarry !== undefined) ||
              (form.getFieldState('minimumBidCarry')?.initial !== null &&
                form.getFieldState('minimumBidCarry')?.initial !==
                  undefined)) && (
              <Labeled label="Deprecated. Please transfer to individual well(s) and set blank here">
                <NumberInput
                  source="minimumBidCarry"
                  onWheel={(e) => e.target.blur()}
                  label={`Minimum Bid, Carry${
                    formData?.minimumBidCarry !== null
                      ? ' (please set blank)'
                      : ''
                  }`}
                  {...rest}
                />
              </Labeled>
            )
          }
        </FormDataConsumer>
      </Box>
    </Box>
  </Box>,
  <Box style={{ width: '100%' }}>
    <h3 style={{ fontWeight: '500' }}>Duration</h3>
    <Box
      style={{
        display: 'flex',
      }}
    >
      <Box className={classes.wellInput}>
        <FormDataConsumer key="startTime">
          {({ formData, form, ...rest }) => (
            <Labeled label="Start Time" isRequired={!isDraft}>
              <DateInputWithTimeZone
                data-cy="listingStartTime"
                disabled={formData.status && listingIsArchived(formData)}
                source="startTime"
                minDate={
                  formData.status && listingIsArchived(formData)
                    ? undefined
                    : minStartTimeForListing()
                }
                timeZone={NONOPWELLS_TIME_ZONE}
                isRequired={!isDraft}
                {...rest}
              />
            </Labeled>
          )}
        </FormDataConsumer>
      </Box>
      <Box className={classes.wellInput}>
        <FormDataConsumer key="endTime">
          {({ formData, form, ...rest }) => (
            <Labeled label="End Time" isRequired={!isDraft}>
              <DateInputWithTimeZone
                data-cy="listingEndTime"
                withTime={true}
                disabled={
                  formData.status && listingHasBeenClosedOrArchived(formData)
                }
                source="endTime"
                minDate={
                  formData.status && listingHasBeenClosedOrArchived(formData)
                    ? undefined
                    : minEndTimeForListing(formData.startTime)
                }
                timeZone={NONOPWELLS_TIME_ZONE}
                isRequired={!isDraft}
                {...rest}
              />
            </Labeled>
          )}
        </FormDataConsumer>
      </Box>
    </Box>
  </Box>,
];

const usePropertyDocumentAndMap = ({
  isDraft,
  isLoadingCompanies,
  companies,
  setSelectedCompany,
  allUsersInCompany,
  isLoadingAllUsersInCompany,
  onLocationChange,
  states,
  currentState,
  setCurrentState,
  currentBasin,
  setCurrentBasin,
  currentCounty,
  setCurrentCounty,
  isSuperAdmin,
  classes,
}) => [
  <FormDataConsumer>
    {({ formData, from, input, ...rest }) => {
      const files = useSelector((state) => state.editListing.AWSData);
      const dispatch = useDispatch();
      return (
        <>
          <Grid container spacing={2} style={{ width: '100%' }}>
            <Grid item sm={6} md={6} lg={6}>
              <h3 style={{ marginBottom: 10, fontWeight: '500' }}>
                Closing Document
              </h3>
              <EditDocuments
                editable
                source="documents"
                fullWidth
                documentType={[
                  ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT,
                  ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT,
                ]}
                {...rest}
              />
            </Grid>
            <Grid item sm={6} md={6} lg={6}>
              <h3 style={{ marginBottom: 10, fontWeight: '500' }}>
                Other Document
              </h3>
              <EditDocuments
                editable
                source="documents"
                fullWidth
                documentType={[
                  ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT,
                  ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT,
                ]}
                {...rest}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Uploaded At</TableCell>
                  <TableCell>Download</TableCell>
                  <TableCell>Seller Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files?.map((file) => (
                  <TableRow key={file.key}>
                    <TableCell>{file.filename}</TableCell>
                    <TableCell>
                      {file.createdAt
                        ? nonOpWellsDateTime(new Date(file.createdAt)).toFormat(
                            'MM / dd / yyyy / t ZZZZ'
                          )
                        : ''}
                    </TableCell>
                    <TableCell>
                      {file.downloadUrl && (
                        <DocumentButton
                          key={file.key}
                          record={file}
                          useDirectLink
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        style={{
                          ...customInputStyles.fieldStyles,
                        }}
                        value={file.comment}
                        onChange={(e) => {
                          const updatedFile = {
                            ...file,
                            comment: e.target.value,
                          };
                          dispatch(
                            setAllAWSData(
                              files.map((f) =>
                                f.key === file.key ? updatedFile : f
                              )
                            )
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </>
      );
    }}
  </FormDataConsumer>,

  <FormDataConsumer key="wells">
    {({ formData, form, ...rest }) => {
      const canEdit =
        !formData.status || listingNotYetClosedNorArchived(formData);
      const minimumBidIsApplicable =
        formData.transactionType !== TransactionTypes.Carry;
      const minimumBidCarryIsApplicable =
        formData.transactionType !== TransactionTypes.Cash;
      /* Fix: "During editing, loading multiple wells with an 'undefined' value is causing a rendering
      issue where only the last well is being displayed. To fix this issue, we need to handle the 'undefined' case */
      if (formData?.id && formData.wells === undefined) {
        return 'Loading...';
      }
      return (
        <ArrayInput
          source="wells"
          label="Wells"
          fullWidth
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
          {...rest}
        >
          <SimpleFormIterator
            inline
            disableAdd={!canEdit}
            disableRemove={!canEdit}
            className={useIteratorStyle}
          >
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                const canEdit =
                  !formData.status || listingNotYetClosedNorArchived(formData);
                return (
                  <Grid container>
                    <Grid
                      item
                      direction="row"
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.information}
                    >
                      <SelectInput
                        className={classes.wellInput}
                        source={getSource('landingZone')}
                        format={(landingZone) =>
                          landingZone && landingZone.id
                            ? landingZone.id
                            : landingZone
                        }
                        choices={
                          currentCounty
                            ? generateSelectOptions(
                                currentCounty.landingZones,
                                'title'
                              )
                            : []
                        }
                        allowEmpty
                        label="Landing Zone"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('wellAPINumber')}
                        onWheel={(e) => e.target.blur()}
                        type="text"
                        label="Well API Number"
                        disabled={
                          (!canEdit &&
                            !shouldAllowAdminToEditWellMinimumBidOnListingThatHasBeenClosedOrArchived) ||
                          (!minimumBidIsApplicable &&
                            // allow setting the value to null
                            scopedFormData?.minimumBid === null)
                        }
                        // format={(value) => formatNumberToCurrency(value)}
                        // parse={(value) => formattedCurrencyToNumberString(value, true)}
                      />
                      <TextInput
                        className={classes.wellInput}
                        source={getSource('wellName')}
                        label="Well Name"
                        isRequired={!isDraft}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived
                        }
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('rank')}
                        label="Rank"
                        isRequired={!isDraft}
                        onWheel={(e) => e.target.blur()}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellRankOnListingThatHasBeenClosedOrArchived
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      direction="row"
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.information}
                    >
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('wellGrossAfe')}
                        label="Well Gross AFE Amount"
                        type="text"
                        isRequired={!isDraft}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived
                        }
                        format={(value) => formatNumberToCurrencyDecimal(value)}
                        parse={(value) =>
                          maskedAmountToNumberString(value, true)
                        }
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('wellNetAFE')}
                        label="Well Net AFE"
                        type="text"
                        isRequired={!isDraft}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived
                        }
                        format={(value) => formatNumberToCurrencyDecimal(value)}
                        parse={(value) =>
                          maskedAmountToNumberString(value, true)
                        }
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('minimumBid')}
                        type="text"
                        label={`Minimum Bid, USD${
                          !minimumBidIsApplicable &&
                          scopedFormData?.minimumBid !== null
                            ? ' (please set blank)'
                            : ''
                        }`}
                        disabled={
                          (!canEdit &&
                            !shouldAllowAdminToEditWellMinimumBidOnListingThatHasBeenClosedOrArchived) ||
                          (!minimumBidIsApplicable &&
                            // allow setting the value to null
                            scopedFormData?.minimumBid === null)
                        }
                        format={(value) => formatNumberToCurrency(value)}
                        parse={(value) =>
                          formattedCurrencyToNumberString(value, true)
                        }
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('minimumBidCarry')}
                        label={`Minimum Bid, Carry${
                          !minimumBidCarryIsApplicable &&
                          scopedFormData?.minimumBidCarry !== null
                            ? ' (please set blank)'
                            : ''
                        }`}
                        disabled={
                          (!canEdit &&
                            !shouldAllowAdminToEditWellMinimumBidCarryOnListingThatHasBeenClosedOrArchived) ||
                          (!minimumBidCarryIsApplicable &&
                            // allow setting the value to null
                            scopedFormData?.minimumBidCarry === null)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      direction="row"
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.information}
                    >
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('surfaceLatitude')}
                        label="Surface Latitude"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('surfaceLongitude')}
                        label="Surface Longitude"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('bottomLatitude')}
                        label="Bottom Latitude"
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('bottomLongitude')}
                        label="Bottom Longitude"
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid
                      item
                      direction="row"
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.information}
                    >
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('workingInterestPercentage')}
                        label="Working Interest Percentage"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('netRevenueInterestNumber')}
                        label="Net Revenue Interest Number"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <NumberInput
                        className={classes.wellInput}
                        source={getSource('wellTotalVerticalDepth')}
                        label="Total Vertical Depth"
                        isRequired={!isDraft}
                        disabled={!canEdit}
                        onWheel={(e) => e.target.blur()}
                      />
                      <BooleanInput
                        className={classes.wellInput}
                        source={getSource('depthConfirmedWithOperator')}
                        label="Depth Confirmed With Operator"
                        disabled={!canEdit}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      );
    }}
  </FormDataConsumer>,
  <FormDataConsumer key="map">
    {({ formData, form, ...rest }) =>
      isUsingEsriMaps() ? (
        <EsriMapField {...rest} record={formData} />
      ) : (
        <GoogleMapField {...rest} record={formData} />
      )
    }
  </FormDataConsumer>,
];

export const PropertyCreate = compose(
  connect(({ companies, cloneListing, editListing }) => ({
    companies: companies.companies,
    isLoadingCompanies: companies.companiesLoading,
    allUsersInCompany: companies.allUsersInCompany,
    isLoadingAllUsersInCompany: companies.isLoadingAllUsersInCompany,
    statesWithBasinsCountiesLandingZones: cloneListing?.states,
    AWSData: editListing.AWSData,
  }))
)(
  ({
    companies,
    states: allStates,
    isLoadingCompanies,
    statesWithBasinsCountiesLandingZones,
    allUsersInCompany,
    isLoadingAllUsersInCompany,
    AWSData,
    ...props
  }) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const history = useHistory();
    const { loaded: permissionsLoaded, permissions } = usePermissions();

    const isSuperAdmin =
      permissionsLoaded && permissions.includes(UserRoles.SuperAdmin);

    const [states, setStates] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [currentState, setCurrentState] = useState(null);
    const [currentBasin, setCurrentBasin] = useState(null);
    const [currentCounty, setCurrentCounty] = useState(null);

    const isDraft = true;

    useEffect(() => {
      // Reseting Aws data
      dispatch(setAllAWSData([]));
      // Geting all companies ref selection
      dispatch(getAllCompanies());
      // Getting all states with basins and counties
      dispatch(getStates());
    }, [dispatch]);

    useEffect(() => {
      if (selectedCompany?.id) {
        dispatch(findUsersByCompany({ companyId: selectedCompany.id }));
      }
    }, [selectedCompany, dispatch]);

    useEffect(() => {
      if (statesWithBasinsCountiesLandingZones?.length && !states.length) {
        setStates(statesWithBasinsCountiesLandingZones);
      }
    }, [statesWithBasinsCountiesLandingZones, states.length]);

    const onLocationChange = useCallback(
      (locationType, form) => (e) => {
        const locationMapping = {
          state: {
            array: states,
            changeMethod: setCurrentState,
          },
          basin: {
            array: currentState ? currentState.basins : [],
            changeMethod: setCurrentBasin,
          },
          county: {
            array: currentBasin ? currentBasin.counties : [],
            changeMethod: setCurrentCounty,
          },
        };

        const nextLocation = findArrayItemById(
          locationMapping[locationType].array,
          e.target.value
        );
        locationMapping[locationType].changeMethod(nextLocation);

        setFormFieldValue(form, 'landingZone', null);

        if (locationType !== 'county') {
          setCurrentCounty(null);
          setFormFieldValue(form, 'county', null);
          if (locationType !== 'basin') {
            setCurrentBasin(null);
            setFormFieldValue(form, 'basin', null);
          }
        }
      },
      [states, currentBasin, currentState]
    );

    const { current: validateRejectionReason } = useRef(
      createAdminResourceRejectionReasonValidator('status', canRejectListing)
    );

    const { current: validateListing } = useRef((record) => {
      const errors = createPropertyValidate({
        ...record,
        isDraft,
        isForAdminPanel: true,
        isForCreate: true,
      });

      errors.rejectionReason = validateRejectionReason(
        record.rejectionReason,
        record
      );

      return errors;
    });

    const doRateLimitFormValidation = false;

    const [finalForm, setFinalForm] = useState(undefined);

    const rateLimitedValidate = useRateLimitedFormValidator({
      validate: validateListing,
      form: finalForm,
    });

    const validateForm = doRateLimitFormValidation
      ? rateLimitedValidate
      : validateListing;

    const formatDocumentsData = (docs) => {
      const result = docs.map((d) => ({
        ...d,
        createdAt: undefined,
      }));
      return result;
    };

    const handleFormSave = async (values) => {
      if (!isDraft) {
        throw new Error('Non-draft listing cannot be created');
      }

      const { restrictedCompanies, refCompany, wells, ...valuesRest } = values;

      // Get option for post request
      const options = generateFetchOptions('POST', {
        ...valuesRest,
        totalWellGrossAfe: undefined,
        wells: wells?.map((value) => value ?? {}),
        restrictedCompanyIds: formatRestrictedCompanyIds(restrictedCompanies),
        documents: formatDocumentsData(AWSData),
      });
      // Make post request
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/properties/draft`,
        options
      );
      // Generate notification
      if (response.status === 201) {
        notify('Property created');
        history.push('/properties');
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    };
    const classes = useStyles();
    return (
      <Create {...getStandardCreateEditProps({ isForCreate: true, ...props })}>
        <SimpleForm
          validate={validateWrapper(validateForm)}
          save={handleFormSave}
        >
          <FormGetter onForm={setFinalForm} />
          {usePropertyEditCommonInputs({
            isDraft,
            isLoadingCompanies,
            companies,
            setSelectedCompany,
            allUsersInCompany,
            isLoadingAllUsersInCompany,
            onLocationChange,
            states,
            currentState,
            setCurrentState,
            currentBasin,
            setCurrentBasin,
            currentCounty,
            setCurrentCounty,
            isSuperAdmin,
            classes,
          })}
          {usePropertyDocumentAndMap({
            isDraft,
            isLoadingCompanies,
            companies,
            setSelectedCompany,
            allUsersInCompany,
            isLoadingAllUsersInCompany,
            onLocationChange,
            states,
            currentState,
            setCurrentState,
            currentBasin,
            setCurrentBasin,
            currentCounty,
            setCurrentCounty,
            isSuperAdmin,
            classes,
          })}
        </SimpleForm>
      </Create>
    );
  }
);

export const PropertyEdit = compose(
  withCustomData(urlsForEdit),
  connect(({ companies, listingAnalytics, editListing }) => ({
    companies: companies.companies,
    isLoadingCompanies: companies.companiesLoading,
    allUsersInCompany: companies.allUsersInCompany,
    isLoadingAllUsersInCompany: companies.isLoadingAllUsersInCompany,
    isLoadedAllUsersInCompany: companies.isLoadedAllUsersInCompany,
    listingAnalytics,
    onboardedBuyerCompanies: companies.onboardedBuyerCompanies,
    commonInfo: editListing.currentProperty,
  }))
)(
  ({
    companies,
    onboardedBuyerCompanies,
    isLoadingCompanies,
    allUsersInCompany,
    isLoadingAllUsersInCompany,
    isLoadedAllUsersInCompany,
    statesWithBasinsCountiesLandingZones,
    listingAnalytics: {
      analytics,
      analyticsIsLoading,
      currentAddedAnalytic,
      customAnalytics,
      listingNotifications,
    },
    commonInfo,
    ...props
  }) => {
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const notify = useNotify();
    const { loaded: permissionsLoaded, permissions } = usePermissions();
    const isSuperAdmin =
      permissionsLoaded && permissions.includes(UserRoles.SuperAdmin);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [changeDetect, setChangeDetect] = useState(false);
    const [states, setStates] = useState([]);
    const [currentState, setCurrentState] = useState(null);
    const [currentBasin, setCurrentBasin] = useState(null);
    const [currentCounty, setCurrentCounty] = useState(null);
    const [filterByCompany, setFilterByCompany] = React.useState(null);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [finalForm, setFinalForm] = useState(undefined);
    const [companiesLoadedListingId, setCompaniesLoadedListingId] =
      useState(undefined);
    const [companyUsersLoadedListingId, setCompanyUsersLoadedListingId] =
      useState(undefined);
    const classes = useStyles();

    const lastCompaniesRef = useRef(companies);
    const lastCompanyUsersRef = useRef(allUsersInCompany);

    const isDraft = commonInfo?.status?.title === 'draft';

    const listingId = +props.id;

    const onChangeDetect = (initial, formData) => {
      const inititalData = {
        wells: initial?.wells,
        documents: initial?.documents?.map((docs) => ({
          comment: docs.comment,
          createdAt: docs.createdAt,
          downloadUrl: docs.downloadUrl,
          filename: docs.filename,
          id: docs.id,
          key: docs.key,
          type: docs.type,
        })),
        rejectionReason: initial?.rejectionReason,
      };
      const changeData = {
        wells: formData?.wells?.map((well) => ({
          ...well,
          rank: `${well?.rank}`,
          wellAPINumber: `${well?.wellAPINumber}`,
          wellNetAFE: `${well?.wellNetAFE}`,
          wellGrossAfe: `${well?.wellGrossAfe}`,
          workingInterestPercentage: `${well?.workingInterestPercentage}`,
          wellTotalVerticalDepth: `${well?.wellTotalVerticalDepth}`,
          netRevenueInterestNumber: `${well?.netRevenueInterestNumber}`,
        })),
        documents: formData?.documents,
        rejectionReason:
          formData?.rejectionReason == undefined
            ? null
            : formData?.rejectionReason,
      };

      if (
        changeData.documents !== undefined &&
        changeData.wells !== undefined
      ) {
        const areEqual = _.isEqual(inititalData, changeData);
        setChangeDetect(!areEqual);
      }
    };

    useEffect(() => {
      dispatch(getAllCompanies());
      dispatch(getListingAnalytics({ listingId }));
      dispatch(getCustomListingAnalytics({ listingId }));
      dispatch(getListingNotification({ listingId }));
      dispatch(getFullyOnBoardedBuyersList());
      setSelectedCompany(null);
      setCompaniesLoadedListingId(undefined);
      setCompanyUsersLoadedListingId(undefined);
      return () => dispatch(clearListingAnalyticsState());
    }, [dispatch, listingId]);

    useEffect(() => {
      if (
        companies &&
        companies !== lastCompaniesRef.current &&
        !isLoadingCompanies
      ) {
        setCompaniesLoadedListingId(listingId);
        lastCompaniesRef.current = companies;
      }
    }, [companies, isLoadingCompanies, listingId]);

    useEffect(() => {
      if (finalForm && commonInfo && companiesLoadedListingId === listingId) {
        const company = companies.find(
          (d) => d.id === commonInfo.user?.company?.id
        );
        if (company) {
          setFormFieldValue(finalForm, 'refCompany', company.id);
          setSelectedCompany(company);
        } else {
          notify(
            `Ref Company (${
              getCompanyName(commonInfo.user?.company) || '<unknown>'
            }) not found`,
            'warning',
            { autoHideDuration: 0 }
          );
        }
      }
    }, [
      notify,
      listingId,
      companiesLoadedListingId,
      finalForm,
      companies,
      commonInfo,
    ]);

    useEffect(() => {
      if (selectedCompany?.id) {
        dispatch(findUsersByCompany({ companyId: selectedCompany.id }));
      }
    }, [selectedCompany?.id, listingId, dispatch]);

    useEffect(() => {
      if (
        companiesLoadedListingId === listingId &&
        allUsersInCompany !== lastCompanyUsersRef.current &&
        isLoadedAllUsersInCompany &&
        !isLoadingAllUsersInCompany
      ) {
        setCompanyUsersLoadedListingId(listingId);
        lastCompanyUsersRef.current = allUsersInCompany;
      }
    }, [
      companiesLoadedListingId,
      allUsersInCompany,
      isLoadedAllUsersInCompany,
      isLoadingAllUsersInCompany,
      listingId,
    ]);

    useEffect(() => {
      if (
        finalForm &&
        typeof commonInfo?.user?.id === 'number' &&
        companiesLoadedListingId === listingId &&
        companyUsersLoadedListingId === listingId
      ) {
        const user = lastCompanyUsersRef.current.find(
          (u) => u.id === commonInfo.user.id
        );
        if (user) {
          setFormFieldValue(finalForm, 'refUser', user.id);
          if (
            !commonInfo.user.active ||
            commonInfo.user.approvalStatus?.title !== ApprovalStatus.Approved
          ) {
            notify(
              `Ref User (${
                getUserFullName(commonInfo.user) || '<unknown>'
              }) not active or not approved`,
              'warning',
              { autoHideDuration: 0 }
            );
          }
        } else {
          notify(
            `Ref User (${
              getUserFullName(commonInfo.user) || '<unknown>'
            }) not found in company's user list`,
            'warning',
            { autoHideDuration: 0 }
          );
        }
      }
    }, [
      notify,
      finalForm,
      listingId,
      commonInfo,
      companiesLoadedListingId,
      companyUsersLoadedListingId,
    ]);

    useEffect(() => {
      if (statesWithBasinsCountiesLandingZones.length && !states.length) {
        setStates(statesWithBasinsCountiesLandingZones);
      }
    }, [statesWithBasinsCountiesLandingZones, states.length]);

    const { dateRange, enhancedAnalytics } = useMemo(
      () => enhanceListingAnalytics({ listing: commonInfo, analytics }),
      [analytics, commonInfo]
    );

    const onLocationChange = useCallback(
      (locationType, form) => (e) => {
        const locationMapping = {
          state: {
            array: states,
            changeMethod: setCurrentState,
          },
          basin: {
            array: currentState ? currentState.basins : [],
            changeMethod: setCurrentBasin,
          },
          county: {
            array: currentBasin ? currentBasin.counties : [],
            changeMethod: setCurrentCounty,
          },
        };

        const nextLocation = findArrayItemById(
          locationMapping[locationType].array,
          e.target.value
        );
        locationMapping[locationType].changeMethod(nextLocation);

        setFormFieldValue(form, 'landingZone', null);

        if (locationType !== 'county') {
          setCurrentCounty(null);
          setFormFieldValue(form, 'county', null);
          if (locationType !== 'basin') {
            setCurrentBasin(null);
            setFormFieldValue(form, 'basin', null);
          }
        }
      },
      [states, currentBasin, currentState]
    );

    const { current: validateRejectionReason } = useRef(
      createAdminResourceRejectionReasonValidator('status', canRejectListing)
    );

    const { current: validateListing } = useRef((record) => {
      const errors = createPropertyValidate({
        ...record,
        isDraft: record.status.title === 'draft',
        isForAdminPanel: true,
      });
      if (!listingIsArchived(record)) {
        const listingStartDate = toAuctionEventTime(
          new Date(record.startTime || undefined),
          'start'
        ).toJSDate();

        if (listingNotYetActive(record)) {
          if (moment(listingStartDate).isBefore(minStartTimeForListing())) {
            errors.startTime = listingErrors.beforeMinStartTime;
          }
        }

        const listingEndDate = toAuctionEventTime(
          new Date(record.endTime || undefined),
          'end'
        ).toJSDate();

        if (listingNotYetClosedNorArchived(record)) {
          if (
            moment(listingEndDate).isBefore(
              minEndTimeForListing(listingStartDate)
            )
          ) {
            errors.endTime = listingErrors.beforeMinEndTime;
          } else if (moment(listingEndDate).isBefore(minEndTimeForListing())) {
            errors.endTime = listingErrors.beforeMinEndTimeDefault;
          }
        }
      }

      errors.rejectionReason = validateRejectionReason(
        record.rejectionReason,
        record
      );

      return errors;
    });

    // NOTE: disabled for now, as it seems performance is actually better with it turned off.
    const doRateLimitFormValidation = false;

    const rateLimitedValidate = useRateLimitedFormValidator({
      validate: validateListing,
      form: finalForm,
    });

    const validateForm = doRateLimitFormValidation
      ? rateLimitedValidate
      : validateListing;

    const customInputFields = {
      id: 0,
      isEditable: true,
    };

    const [analyticsValues, updateAnalyticsValue] = useState({});
    const [analyticsData, updateAnalyticsData] = useState([]);
    const [updatableAnalyticsValue, updateUpdatableAnalyticsValue] = useState(
      {}
    );
    const [avalyticFormValidationError, updateValidationError] = useState({});

    useEffect(() => {
      if (customAnalytics) {
        updateAnalyticsData(customAnalytics);
      }
    }, [customAnalytics]);

    const analyticsFieldChangeHandler = (field, value) => {
      updateAnalyticsValue({ ...analyticsValues, [field]: value });
    };

    const analyticsUpdateFieldChangeHandler = (field, value) => {
      updateUpdatableAnalyticsValue({
        ...updatableAnalyticsValue,
        [field]: value,
      });
    };

    const analyticFormValidation = (formValues, record) => {
      const {
        companyName,
        isNotified,
        isBided,
        noOfDownloads,
        noOfViews,
        ...rest
      } = formValues;
      if (!companyName) {
        notify('Company name is required', 'error');
        updateValidationError({ companyName: record.id });
        return false;
      }

      if (noOfViews && parseInt(noOfViews, 10) < 0) {
        notify('No of views should be positive number', 'error');
        updateValidationError({ noOfViews: record.id });
        return false;
      }

      if (noOfDownloads && parseInt(noOfDownloads, 10) < 0) {
        notify('No of downloads should be positive number', 'error');
        updateValidationError({ noOfDownloads: record.id });
        return false;
      }

      return {
        companyName,
        isNotified: isNotified ?? false,
        isBided: isBided ?? false,
        noOfDownloads: noOfDownloads || 0,
        noOfViews: noOfViews || 0,
        ...rest,
      };
    };

    const addAnalyticsHandler = (record) => {
      updateValidationError({});
      const validationResult = analyticFormValidation(analyticsValues, record);
      if (validationResult) {
        dispatch(createCustomAnalytics({ ...validationResult, listingId }));
      }
    };

    const updateAnalyticsRecord = (record) => {
      updateValidationError({});
      const validationResult = analyticFormValidation(
        updatableAnalyticsValue,
        record
      );
      if (validationResult) {
        const {
          id,
          companyName,
          isBided,
          isNotified,
          noOfViews,
          noOfDownloads,
        } = validationResult;
        dispatch(
          updateCustomAnalytics({
            id,
            companyName,
            isBided,
            isNotified,
            noOfViews,
            noOfDownloads,
            listingId,
          })
        );
      }
    };

    const deleteAnalyticsHandler = (record) => {
      dispatch(deleteCustomAnalytics(record));
    };

    useEffect(() => {
      if (currentAddedAnalytic?.id) {
        dispatch(getCustomListingAnalytics({ listingId }));
        dispatch(getListingAnalytics({ listingId }));
        notify('Analytics updated successfully');
        updateAnalyticsValue({});
        updateUpdatableAnalyticsValue({});
      }
    }, [
      currentAddedAnalytic,
      updateAnalyticsValue,
      updateUpdatableAnalyticsValue,
      notify,
      dispatch,
      listingId,
    ]);

    const toggleEditAnalyticsHandler = (record) => {
      updateUpdatableAnalyticsValue(record);
      const analyticsDataToUpdate = analyticsData.map((r) =>
        r.id === record.id
          ? { ...r, isEditable: true }
          : { ...r, isEditable: undefined }
      );
      updateAnalyticsData(analyticsDataToUpdate);
    };

    const toggleCancelHandler = (record) => {
      const analyticsDataToUpdate = analyticsData.map((r) =>
        r.id === record.id ? { ...r, isEditable: undefined } : r
      );
      updateAnalyticsData(analyticsDataToUpdate);
      updateUpdatableAnalyticsValue({});
    };

    const customInput = (record) => {
      const { id, field, type } = record;
      const isFieldEditable = updatableAnalyticsValue.id === id;

      return (
        <input
          type={type}
          name={field}
          style={{
            ...customInputStyles.fieldStyles,
            borderColor: avalyticFormValidationError[field] === id ? 'red' : '',
          }}
          placeholder={`${record.placeholder}`}
          value={
            isFieldEditable
              ? updatableAnalyticsValue[field]
              : analyticsValues[field]
          }
          onChange={(e) =>
            isFieldEditable
              ? analyticsUpdateFieldChangeHandler(field, e.target.value)
              : analyticsFieldChangeHandler(field, e.target.value)
          }
        />
      );
    };

    const customActions = (record) => {
      const { id } = record;
      const isFieldEditable = updatableAnalyticsValue.id === id;
      if (isFieldEditable) {
        return (
          <>
            <Button
              variant="outlined"
              color="secondary"
              label="Update"
              onClick={() => updateAnalyticsRecord(record)}
              style={{ margin: 5 }}
            />
            <Button
              variant="outlined"
              color="primary"
              label="Cancel"
              onClick={() => toggleCancelHandler(record)}
            />
          </>
        );
      }
      if (!record.isEditable) {
        return (
          <>
            <Button
              variant="outlined"
              color="secondary"
              label="Edit"
              onClick={() => toggleEditAnalyticsHandler(record)}
              style={{ margin: 5 }}
            />
            <Button
              variant="outlined"
              color="primary"
              label="Delete"
              onClick={() => deleteAnalyticsHandler(record)}
            />
          </>
        );
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          label="Add"
          onClick={() => addAnalyticsHandler(record)}
        />
      );
    };

    const yesNoCustomSelect = (record) => {
      const { id, field } = record;
      const isFieldEditable = updatableAnalyticsValue.id === id;
      return (
        <div
          style={{
            height: '98%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingTop: 15,
          }}
        >
          <SelectCommon
            input={{
              name: field,
              value: isFieldEditable
                ? updatableAnalyticsValue[field]
                : analyticsValues[field],
              onChange: (e) =>
                isFieldEditable
                  ? analyticsUpdateFieldChangeHandler(field, e.target.value)
                  : analyticsFieldChangeHandler(field, e.target.value),
            }}
            options={[
              { id: 1, value: true, label: 'Yes' },
              { id: 2, value: false, label: 'No' },
            ]}
            meta={{ touched: true, error: '' }}
          />
        </div>
      );
    };

    const handleFormSave = async (values, redirect) => {
      dispatch(updateCurrentProperty({ ...values, redirect, isDraft, notify }));
    };

    const [inactiveBuyersList, updateInactiveBuyersList] = useState([]);
    const [isProcessing, updateProcessing] = useState(false);

    useEffect(() => {
      const activeBuyers = enhancedAnalytics?.companies;
      // filtering inactive and non-restricted buyers
      const inactiveBuyersList = onboardedBuyerCompanies
        ?.filter(
          (c) =>
            c &&
            c.id &&
            c.companyName &&
            !activeBuyers?.find((ac) => ac.oId === c.id) && // remove active company from the list
            !commonInfo?.restrictedCompanies?.find((rc) => rc.id === c.id) && // remove restricted company from the list
            commonInfo?.user?.company?.id !== c.id // remove own company from the list
        )
        ?.map((iab) => {
          return {
            ...iab,
            views: [],
            downloads: [],
            isPushEmailChecked: false,
            noOfSentNotification: listingNotifications?.filter(
              (n) => n.companyId === iab.id
            ),
          };
        });
      updateInactiveBuyersList(inactiveBuyersList || []);
    }, [enhancedAnalytics, onboardedBuyerCompanies, listingNotifications]);

    const pushEmailCheckBoxHandler = (id) => {
      const updatedInactiveBuyerList = inactiveBuyersList.map((b) =>
        b.id === id ? { ...b, isPushEmailChecked: !b.isPushEmailChecked } : b
      );
      updateInactiveBuyersList(updatedInactiveBuyerList);
    };

    const pushEmailCheckbox = (row) => {
      const { isPushEmailChecked, id } = row;
      return (
        <Checkbox
          edge="start"
          checked={isPushEmailChecked}
          tabIndex={-1}
          onChange={(e) => pushEmailCheckBoxHandler(id)}
          disableRipple
          style={{ color: '#3D4F5F', opacity: 0.8, cursor: 'pointer' }}
          inputProps={{ 'aria-labelledby': 1 }}
        />
      );
    };

    const pushListingNotificationHandler = async () => {
      updateProcessing(true);
      const companiesIds = inactiveBuyersList
        .filter((b) => b.isPushEmailChecked)
        .map((b) => b.id);

      // Get option for POST request
      const options = generateFetchOptions('POST', {
        companiesIds,
        listingId: commonInfo.id,
      });

      // Make POST request
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listings/push-new-listing-notification`,
        options
      );

      // Generate notification based on response
      if (response.status === 204) {
        updateProcessing(false);
        notify('All selected companies have been successfully notified');
        dispatch(getListingNotification({ listingId }));
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        updateProcessing(false);
        notify(message, 'warning');
      }
    };

    return (
      <Edit
        {...getStandardCreateEditProps({ notify, ...props })}
        actions={<PropertyEditActions form={finalForm} />}
      >
        <TabbedForm
          {...getStandardFormProps({ changeDetect, ...props })}
          validate={validateWrapper(validateForm)}
          save={handleFormSave}
        >
          <FormTab label="Main">
            <FormGetter onForm={setFinalForm} />
            <FormDataConsumer>
              {({ formData, form, ...rest }) => {
                if (!currentState) {
                  const newState = findArrayItemById(
                    states,
                    formData.state?.id
                  );
                  if (newState) {
                    setCurrentState(newState);
                  }
                }
                if (currentState && !currentBasin) {
                  const newBasin = findArrayItemById(
                    currentState.basins,
                    formData.basin?.id
                  );
                  if (newBasin) {
                    setCurrentBasin(newBasin);
                  }
                }
                if (currentBasin && !currentCounty) {
                  const newCounty = findArrayItemById(
                    currentBasin.counties,
                    formData.county?.id
                  );
                  if (newCounty) {
                    setCurrentCounty(newCounty);
                  }
                }
                const canEdit =
                  !formData.status || listingNotYetClosedNorArchived(formData);
                return (
                  <SenetizeBox onChange={onChangeDetect(commonInfo, formData)}>
                    <Box style={{ marginBottom: 10 }}>
                      <h4>Information</h4>
                    </Box>
                    <SenetizeBox
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: '25px',
                      }}
                    >
                      <TextInput
                        className={classes.wellInput}
                        source={'projectName'}
                        label="Listing Name"
                        isRequired={!isDraft}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived
                        }
                      />
                      <Labeled label="Listing ID">
                        <NumberField source="id" />
                      </Labeled>
                      <Labeled label="Status">
                        <ChipField
                          source="status.title"
                          label="Status"
                          style={{
                            background: '#ECFDF3',
                            border: '0px green solid',
                            color: 'green',
                          }}
                        />
                      </Labeled>
                      <Labeled label="Company">
                        <FunctionField
                          label="Seller Company"
                          render={(record) =>
                            typeof record.user?.company?.id === 'number' ? (
                              <Link to={`/companies/${record.user.company.id}`}>
                                {getCompanyName(record.user.company)}
                              </Link>
                            ) : (
                              getCompanyName(record.user?.company)
                            )
                          }
                        />
                      </Labeled>
                      <TextInput
                        className={classes.wellInput}
                        source={'manualSellerCompanyName'}
                        label="Custom Company Name"
                        isRequired={false}
                        disabled={
                          !canEdit &&
                          !shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived
                        }
                      />
                      <FormDataConsumer key="user">
                        {({ formData, form, ...rest }) =>
                          !companies ||
                          isLoadingCompanies ||
                          isLoadingAllUsersInCompany ? (
                            <Labeled label="Ref User" isRequired>
                              <div>loading...</div>
                            </Labeled>
                          ) : (
                            <div>
                              <AutocompleteInput
                                validate={[required()]}
                                source="refUser"
                                label="User"
                                choices={allUsersInCompany}
                                optionText={(user) => getUserFullName(user)}
                                allowEmpty
                                suggestionLimit={10}
                                disabled={
                                  formData.status &&
                                  (!isSuperAdmin ||
                                    (listingHasBeenClosedOrArchived(formData) &&
                                      !shouldAllowChangeListingOwnerOnListingThatHasBeenClosedOrArchived))
                                }
                                {...rest}
                              />
                            </div>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer key="operatorName">
                        {({ formData, form, ...rest }) => (
                          <TextInput
                            source="operatorName"
                            label="Operator"
                            isRequired={!isDraft}
                            disabled={
                              formData.status &&
                              listingHasBeenClosedOrArchived(formData)
                            }
                            {...rest}
                          />
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer key="restrictedCompanies">
                        {({ formData, form, ...rest }) =>
                          (!isDraft &&
                            formData.restrictedCompanies === undefined) ||
                          isLoadingCompanies ||
                          (formData.status &&
                            listingHasBeenClosedOrArchived(formData)) ? (
                            <Labeled label="Restricted Companies">
                              {!formData.restrictedCompanies?.length ? (
                                formData.restrictedCompanies === undefined ? (
                                  <div>loading...</div>
                                ) : (
                                  <div>-</div>
                                )
                              ) : (
                                <ArrayField
                                  source="restrictedCompanies"
                                  {...rest}
                                >
                                  <SingleFieldList linkType={false}>
                                    <ChipField source="fullLegalCompanyName" />
                                  </SingleFieldList>
                                </ArrayField>
                              )}
                            </Labeled>
                          ) : (
                            <div>
                              <AutocompleteArrayInput
                                source="restrictedCompanies"
                                label="Restricted Companies"
                                choices={companies?.filter(
                                  (company) =>
                                    company.id !== formData.refCompany &&
                                    company.id !== formData.user?.company?.id
                                )}
                                format={
                                  // eslint-disable-next-line no-shadow
                                  (companies) =>
                                    companies?.map((company) => company.id)
                                }
                                optionText={getCompanyName}
                                parse={(ids) => ids?.map((id) => ({ id }))}
                                shouldRenderSuggestions={(value) =>
                                  value.trim().length > 0
                                }
                                suggestionLimit={10}
                                {...rest}
                              />
                            </div>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer key="isAnonymous">
                        {({ formData, form, ...rest }) => (
                          <BooleanInput
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                            source="isAnonymous"
                            label="Is Anonymous"
                            disabled={
                              formData.status &&
                              listingHasBeenClosedOrArchived(formData)
                            }
                            {...rest}
                          />
                        )}
                      </FormDataConsumer>
                    </SenetizeBox>
                    <SenetizeBox>
                      <Box style={{ marginBottom: 10 }}>
                        <h4>Location</h4>
                      </Box>
                      <SenetizeBox
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexWrap: 'wrap',
                          gap: '25px',
                        }}
                      >
                        <FormDataConsumer key="state">
                          {({ formData, form, ...rest }) => {
                            // TODO: move this into a useEffect block
                            if (!currentState) {
                              const newState = findArrayItemById(
                                states,
                                formData.state?.id
                              );
                              if (newState) {
                                setCurrentState(newState);
                              }
                            }

                            return (
                              <SelectInput
                                source="state"
                                label="State"
                                format={(state) => state?.id || state}
                                choices={generateSelectOptions(states, 'title')}
                                allowEmpty
                                onChange={onLocationChange('state', form)}
                                isRequired={!isDraft}
                                disabled={
                                  formData.status &&
                                  listingHasBeenClosedOrArchived(formData)
                                }
                              />
                            );
                          }}
                        </FormDataConsumer>
                        <FormDataConsumer key="basin">
                          {({ formData, form, ...rest }) => {
                            // TODO: move this into a useEffect block
                            if (currentState && !currentBasin) {
                              const newBasin = findArrayItemById(
                                currentState.basins,
                                formData.basin?.id
                              );
                              if (newBasin) {
                                setCurrentBasin(newBasin);
                              }
                            }

                            return (
                              <SelectInput
                                source="basin"
                                label="Basin"
                                format={(basin) => basin?.id || basin}
                                choices={
                                  currentState
                                    ? generateSelectOptions(
                                        currentState.basins,
                                        'title'
                                      )
                                    : []
                                }
                                allowEmpty
                                onChange={onLocationChange('basin', form)}
                                isRequired={!isDraft}
                                disabled={
                                  formData.status &&
                                  listingHasBeenClosedOrArchived(formData)
                                }
                              />
                            );
                          }}
                        </FormDataConsumer>
                        <FormDataConsumer key="county">
                          {({ formData, form, ...rest }) => {
                            // TODO: move this into a useEffect block
                            if (currentBasin && !currentCounty) {
                              const newCounty = findArrayItemById(
                                currentBasin.counties,
                                formData.county?.id
                              );
                              if (newCounty) {
                                setCurrentCounty(newCounty);
                              }
                            }

                            return (
                              <SelectInput
                                source="county"
                                label="County"
                                format={(basin) => basin?.id || basin}
                                choices={
                                  currentBasin
                                    ? generateSelectOptions(
                                        currentBasin.counties,
                                        'title'
                                      )
                                    : []
                                }
                                allowEmpty
                                onChange={onLocationChange('county', form)}
                                isRequired={!isDraft}
                                disabled={
                                  formData.status &&
                                  listingHasBeenClosedOrArchived(formData)
                                }
                                {...rest}
                              />
                            );
                          }}
                        </FormDataConsumer>
                      </SenetizeBox>
                    </SenetizeBox>
                    <SenetizeBox>
                      <Box style={{ marginBottom: 10, marginTop: 10 }}>
                        <h4>Financials</h4>
                      </Box>
                      <SenetizeBox style={{ display: 'flex', width: '100%' }}>
                        <Labeled label="Total Listing Net AFE">
                          <FunctionField
                            render={(record) => {
                              const definedWells = Array.isArray(record.wells)
                                ? record.wells.filter((well) => !!well)
                                : undefined;
                              const computedWellsNetAfe =
                                computeWellsNetAfe(definedWells);
                              return (
                                <span>
                                  {formatNumberToCurrency(computedWellsNetAfe)}
                                </span>
                              );
                            }}
                          />
                        </Labeled>
                        <Labeled label="Total Listing Gross AFE">
                          <FunctionField
                            render={(record) => {
                              const definedWells = Array.isArray(record.wells)
                                ? record.wells.filter((well) => !!well)
                                : undefined;
                              const computedGrossWellAfe =
                                computeWellsGrossAfe(definedWells);
                              return (
                                <span>
                                  {formatNumberToCurrency(computedGrossWellAfe)}
                                </span>
                              );
                            }}
                          />
                        </Labeled>
                        <FormDataConsumer key="transactionType">
                          {({ formData, form, ...rest }) => (
                            <SelectInput
                              source="transactionType"
                              label="Transaction Type"
                              allowEmpty
                              emptyText="--"
                              emptyValue={null}
                              choices={[
                                {
                                  id: 1,
                                  value: TransactionTypes.Cash,
                                  name: TransactionTypeTitles[
                                    TransactionTypes.Cash
                                  ],
                                },
                                {
                                  id: 2,
                                  value: TransactionTypes.Carry,
                                  name: TransactionTypeTitles[
                                    TransactionTypes.Carry
                                  ],
                                },
                                {
                                  id: 3,
                                  value: TransactionTypes.Either,
                                  name: TransactionTypeTitles[
                                    TransactionTypes.Either
                                  ],
                                },
                              ]}
                              optionText="name"
                              optionValue="value"
                              isRequired={!isDraft}
                              disabled={
                                formData.status && listingIsArchived(formData)
                              }
                              {...rest}
                            />
                          )}
                        </FormDataConsumer>
                      </SenetizeBox>
                    </SenetizeBox>
                    <SenetizeBox>
                      <Box style={{ marginBottom: 10, marginTop: 10 }}>
                        <h4>Duration</h4>
                      </Box>
                      <SenetizeBox
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexWrap: 'wrap',
                          gap: '25px',
                        }}
                      >
                        <Labeled label="Created On">
                          <DateFieldWithTimeZone
                            source="createdAt"
                            showTime
                            timeZone={NONOPWELLS_TIME_ZONE}
                            {...rest}
                          />
                        </Labeled>
                        {formData.closedAt && (
                          <Labeled label="Auction Closed At">
                            <DateFieldWithTimeZone
                              source="closedAt"
                              showTime
                              timeZone={NONOPWELLS_TIME_ZONE}
                              {...rest}
                            />
                          </Labeled>
                        )}
                        <FormDataConsumer key="startTime">
                          {({ formData, form, ...rest }) => (
                            <Labeled label="Start Time" isRequired={!isDraft}>
                              <DateInputWithTimeZone
                                data-cy="listingStartTime"
                                disabled={
                                  formData.status && listingIsArchived(formData)
                                }
                                source="startTime"
                                minDate={
                                  formData.status && listingIsArchived(formData)
                                    ? undefined
                                    : minStartTimeForListing()
                                }
                                timeZone={NONOPWELLS_TIME_ZONE}
                                isRequired={!isDraft}
                                {...rest}
                              />
                            </Labeled>
                          )}
                        </FormDataConsumer>
                        <FormDataConsumer key="endTime">
                          {({ formData, form, ...rest }) => (
                            <Labeled label="End Time" isRequired={!isDraft}>
                              <DateInputWithTimeZone
                                data-cy="listingEndTime"
                                withTime={true}
                                disabled={
                                  formData.status &&
                                  listingHasBeenClosedOrArchived(formData)
                                }
                                source="endTime"
                                minDate={
                                  formData.status &&
                                  listingHasBeenClosedOrArchived(formData)
                                    ? undefined
                                    : minEndTimeForListing(formData.startTime)
                                }
                                timeZone={NONOPWELLS_TIME_ZONE}
                                isRequired={!isDraft}
                                {...rest}
                              />
                            </Labeled>
                          )}
                        </FormDataConsumer>
                      </SenetizeBox>
                    </SenetizeBox>
                    <SenetizeBox
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {/* <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.reopenedBy && (
                            <Labeled label="Reopened By">
                              <FunctionField
                                render={(record) =>
                                  record.reopenedBy && (
                                    <LinkButton
                                      className={classes.wellInput}
                                      label={getUserFullName(record.reopenedBy)}
                                      buttonColor="clearGreen"
                                      path={linkToRecord(
                                        '/users',
                                        record.reopenedBy.id,
                                      )}
                                    />
                                  )
                                }
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.reopenedFromPropertyId && (
                            <Labeled label="Reopened From Listing">
                              <FunctionField
                                render={(record) =>
                                  record.reopenedFromPropertyId && (
                                    <LinkButton
                                      className={classes.wellInput}
                                      label={record.reopenedFromPropertyId}
                                      buttonColor="clearGreen"
                                      path={linkToRecord(
                                        '/properties',
                                        record.reopenedFromPropertyId,
                                      )}
                                    />
                                  )
                                }
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer> */}
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.closedBy && (
                            <Labeled label="Auction Closed By">
                              <FunctionField
                                render={(record) =>
                                  record.closedBy && (
                                    <LinkButton
                                      className={classes.wellInput}
                                      label={getUserFullName(record.closedBy)}
                                      buttonColor="clearGreen"
                                      path={linkToRecord(
                                        '/users',
                                        record.closedBy.id
                                      )}
                                    />
                                  )
                                }
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.soldAt && (
                            <Labeled label="Sold At">
                              <DateFieldWithTimeZone
                                source="soldAt"
                                className={classes.wellInput}
                                showTime
                                timeZone={NONOPWELLS_TIME_ZONE}
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.status.title === 'sold' && (
                            <Labeled label="Transaction Status">
                              <FunctionField
                                render={(record) => (
                                  <span
                                    data-cy="transactionStatusTitle"
                                    className={classes.wellInput}
                                  >
                                    {(isListingTransactionComplete(record) &&
                                      'Complete') ||
                                      (isListingTransactionCanceled(record) &&
                                        'Canceled') ||
                                      'In Progress'}
                                  </span>
                                )}
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData }) =>
                          formData.status.title === 'sold' && (
                            <Labeled label="Commission">
                              <FunctionField
                                className={classes.wellInput}
                                label="Prospective Commission"
                                render={(record) =>
                                  record.winningBid
                                    ? `${record.winningBid.commission}`
                                    : '-'
                                }
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          !!formData.transactionCompletedAt && (
                            <Labeled label="Transaction Completed At">
                              <DateFieldWithTimeZone
                                className={classes.wellInput}
                                data-cy="transactionCompletedAt"
                                source="transactionCompletedAt"
                                showTime
                                timeZone={NONOPWELLS_TIME_ZONE}
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          listingIsArchived(formData) && (
                            <Labeled label="Archived At">
                              <DateFieldWithTimeZone
                                className={classes.wellInput}
                                source="archivedAt"
                                showTime
                                timeZone={NONOPWELLS_TIME_ZONE}
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.archivedBy && (
                            <Labeled label="Archived By">
                              <FunctionField
                                render={(record) =>
                                  record.archivedBy && (
                                    <LinkButton
                                      className={classes.wellInput}
                                      label={getUserFullName(record.archivedBy)}
                                      data-cy="archivedBy"
                                      buttonColor="clearGreen"
                                      path={linkToRecord(
                                        '/users',
                                        record.archivedBy.id
                                      )}
                                    />
                                  )
                                }
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer>
                      {/* <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          formData.reopenedPropertyId && (
                            <Labeled label="Reopened As Listing">
                              <FunctionField
                                render={(record) =>
                                  record.reopenedPropertyId && (
                                    <LinkButton
                                      className={classes.wellInput}
                                      label={record.reopenedPropertyId}
                                      buttonColor="clearGreen"
                                      path={linkToRecord(
                                        "/properties",
                                        record.reopenedPropertyId
                                      )}
                                    />
                                  )
                                }
                                {...rest}
                              />
                            </Labeled>
                          )
                        }
                      </FormDataConsumer> */}
                      <FormDataConsumer>
                        {({ formData, form, ...rest }) =>
                          (canRejectListing(formData) ||
                            formData.status.title ===
                              ApprovalStatus.Rejected) && (
                            <TextInput
                              {...rest}
                              className={classes.wellInput}
                              source="rejectionReason"
                              label="Reason for Rejection"
                              isRequired={isRejectionReasonRequired(
                                formData,
                                'status'
                              )}
                              disabled={
                                // TODO: [INTEGRITY][REQUIREMENTS] don't allow changing rejection reason once it
                                // has been saved?
                                formData.status.title !==
                                  ApprovalStatus.Rejected &&
                                !canRejectListing(formData)
                              }
                              fullWidth
                              multiline
                              minRows={1}
                              maxRows={8}
                            />
                          )
                        }
                      </FormDataConsumer>
                    </SenetizeBox>
                  </SenetizeBox>
                );
              }}
            </FormDataConsumer>
            {/* flex */}
            {usePropertyDocumentAndMap({
              isDraft,
              isLoadingCompanies,
              companies,
              setSelectedCompany,
              allUsersInCompany,
              isLoadingAllUsersInCompany,
              onLocationChange,
              states,
              currentState,
              setCurrentState,
              currentBasin,
              setCurrentBasin,
              currentCounty,
              setCurrentCounty,
              isSuperAdmin,
              classes,
            })}
          </FormTab>
          <FormTab label="Bids">
            <ReferenceManyList
              {...props}
              reference="bids"
              apiResource="properties"
            >
              <Datagrid rowClick="edit">
                <FunctionField
                  render={(record) => getUserFullName(record.user)}
                  label="Buyer"
                  sortable={false}
                />
                <TextField
                  source="user.company.fullLegalCompanyName"
                  label="Company"
                  sortable={false}
                />
                <FunctionField
                  label="Amount"
                  render={(record) =>
                    record.bidPerWells?.map((bidPerWell, index) =>
                      bidPerWell?.amount ? (
                        <div>{`Well ${index + 1}: ${formatNumberToCurrency(
                          bidPerWell.amount,
                          true
                        )}`}</div>
                      ) : (
                        '-'
                      )
                    )
                  }
                />
                <FunctionField
                  label="Carry"
                  render={(record) =>
                    record.bidPerWells?.map((bidPerWell, index) =>
                      bidPerWell?.carryPercentage ? (
                        <div>{`Well ${index + 1}: ${
                          bidPerWell.carryPercentage
                        }%`}</div>
                      ) : (
                        '-'
                      )
                    )
                  }
                />
                <FunctionField
                  label="Prospective Commission"
                  render={(record) =>
                    `${formatNumberToCurrency(record.commission, true)}`
                  }
                />
                <FunctionField
                  hidden
                  render={(record) => (
                    <span data-cy={`bidStatus-${record?.id}`}>
                      {record?.status.title}
                    </span>
                  )}
                />
                <ChipField
                  source="status.title"
                  label="Status"
                  sortable={false}
                />
                <FunctionField
                  label="Closing Method"
                  render={(record) =>
                    record.property?.closingMethod?.methodName || '-'
                  }
                />
                <DateFieldWithTimeZone
                  source="createdAt"
                  label="Created"
                  showTime
                  timeZone={NONOPWELLS_TIME_ZONE}
                />
                <DateFieldWithTimeZone
                  source="archivedAt"
                  label="Archival Date"
                  showTime
                  timeZone={NONOPWELLS_TIME_ZONE}
                />
                <FunctionField
                  render={(record) =>
                    !record.archivedAt &&
                    [
                      'closed',
                      'winner selected',
                      // allow 'sold' so the admin can change the winner if needed
                      'sold',
                    ].includes(record.status.title) && (
                      <MakeWinnerButton
                        record={record}
                        onSuccess={() => {
                          refresh();
                        }}
                      />
                    )
                  }
                  label="Make Winner"
                />
                <FunctionField
                  render={(record) =>
                    !bidStatusIsHistorical(record) && (
                      <DeleteButtonWithConfirmation
                        basePath="bids"
                        record={record}
                        redirect={false}
                      />
                    )
                  }
                  label="Delete"
                />
              </Datagrid>
            </ReferenceManyList>
          </FormTab>
          <FormTab label="Analytics">
            <Grid container className={classes.relativeContainer}>
              {analyticsIsLoading && <Spinner backdrop />}
              <Grid container direction="column" className={classes.wrapper}>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{
                      paddingLeft: 32 - minSpaceBetweenStatCardsHalf,
                      paddingRight: 32 - minSpaceBetweenStatCardsHalf,
                      paddingTop: 32,
                      paddingBottom: 32,
                    }}
                  >
                    <HighLevelStatCard
                      data-cy="listingAnalyticsTotalViews"
                      title="Total Views"
                      value={enhancedAnalytics?.totalViews}
                    />
                    <HighLevelStatCard
                      data-cy="listingAnalyticsTotalDownloads"
                      title="Total Downloads"
                      value={enhancedAnalytics?.totalDownloads}
                    />
                    <HighLevelStatCard
                      data-cy="listingAnalyticsTotalBids"
                      title="Total Bids"
                      value={enhancedAnalytics?.totalBids}
                    />
                  </Box>
                </Grid>
                <Grid direction="row">
                  {!enhancedAnalytics?.companies.length ? (
                    <Grid container justify="center">
                      <span className={classes.message}>No activity</span>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <AnalyticsBarGraph
                          filterByCompany={filterByCompany}
                          analytics={enhancedAnalytics}
                          dateRange={dateRange}
                          setFilterByCompany={setFilterByCompany}
                          setSelectionModel={setSelectionModel}
                        />
                      </Grid>
                      <DataGrid
                        style={{ margin: '15px 0' }}
                        rows={enhancedAnalytics.companies.filter(
                          (r) => !r.isCustomAnalytic
                        )}
                        onRowClick={({ id, row }) =>
                          setFilterByCompany({
                            id,
                            name: row.fullLegalCompanyName,
                          })
                        }
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        columns={[
                          {
                            field: 'companyName',
                            headerName: 'COMPANY',
                            flex: 1,
                            valueGetter: ({ row }) => getCompanyName(row),
                          },
                          {
                            field: 'isNotified',
                            headerName: 'NOTIFIED',
                            flex: 1,
                            valueGetter: ({ row }) =>
                              row.views.length ? 'Yes' : 'No',
                          },
                          {
                            field: 'views',
                            headerName: 'VIEWS',
                            flex: 1,
                            valueGetter: ({ row }) => row.views.length,
                          },
                          {
                            field: 'downloads',
                            headerName: 'DOWNLOADS',
                            flex: 1,
                            valueGetter: ({ row }) => row.downloads.length,
                          },
                          {
                            field: 'hasBid',
                            headerName: 'BIDS',
                            flex: 1,
                            valueFormatter: ({ value }) =>
                              value ? 'Yes' : 'No',
                          },
                        ]}
                        autoHeight
                        autoPageSize
                        disableColumnMenu
                        disableColumnSelector
                      />
                    </>
                  )}
                  <div style={{ marginTop: 50 }}>
                    <h3>Custom Analytics:</h3>
                  </div>
                  <div data-cy="customAnalyticsTable">
                    <DataGrid
                      style={{ margin: '15px 0' }}
                      rows={[...analyticsData, customInputFields].map(
                        (row) => ({ ...row })
                      )}
                      columns={[
                        {
                          field: 'companyName',
                          headerName: 'COMPANY',
                          flex: 1,
                          renderCell: ({ row, field }) =>
                            row.isEditable
                              ? customInput({
                                  ...row,
                                  field,
                                  placeholder: 'Company name...',
                                  type: 'text',
                                })
                              : row.companyName,
                        },
                        {
                          field: 'isNotified',
                          headerName: 'NOTIFIED',
                          flex: 1,
                          renderCell: ({ row, field }) =>
                            row.isEditable
                              ? yesNoCustomSelect({
                                  ...row,
                                  field,
                                  placeholder: 'Notified',
                                })
                              : row.isNotified
                              ? 'Yes'
                              : 'No',
                        },
                        {
                          field: 'noOfViews',
                          headerName: 'VIEWS',
                          flex: 1,
                          renderCell: ({ row, field }) =>
                            row.isEditable
                              ? customInput({
                                  ...row,
                                  field,
                                  placeholder: 'No of views...',
                                  type: 'number',
                                })
                              : row.views,
                        },
                        {
                          field: 'noOfDownloads',
                          headerName: 'DOWNLOADS',
                          flex: 1,
                          renderCell: ({ row, field }) =>
                            row.isEditable
                              ? customInput({
                                  ...row,
                                  field,
                                  placeholder: 'No of downloads...',
                                  type: 'number',
                                })
                              : row.downloads,
                        },
                        {
                          field: 'isBided',
                          headerName: 'BIDS',
                          flex: 1,
                          renderCell: ({ row, field }) =>
                            row.isEditable
                              ? yesNoCustomSelect({
                                  ...row,
                                  field,
                                  placeholder: 'Bids',
                                })
                              : row.isBided
                              ? 'Yes'
                              : 'No',
                        },
                        {
                          field: 'action',
                          headerName: 'ACTION',
                          flex: 1,
                          valueFormatter: ({ value }) => value,
                          renderCell: ({ row }) => customActions(row),
                        },
                      ]}
                      autoHeight
                      autoPageSize
                      disableColumnMenu
                      disableColumnSelector
                    />
                  </div>
                  <div style={{ marginTop: 50 }}>
                    <h3>Buyers List:</h3>
                  </div>
                  <div data-cy="buyersListTable">
                    <DataGrid
                      style={{ margin: '15px 0' }}
                      rows={inactiveBuyersList?.map((row) => ({ ...row }))}
                      columns={[
                        {
                          field: 'companyName',
                          headerName: 'COMPANY',
                          flex: 1,
                        },
                        {
                          field: 'isNotified',
                          headerName: 'NOTIFIED',
                          flex: 1,
                          valueGetter: ({ row }) =>
                            row?.noOfSentNotification?.length ? 'Yes' : 'No',
                        },
                        {
                          field: 'views',
                          headerName: 'VIEWS',
                          flex: 1,
                          valueGetter: ({ row }) => row.views?.length,
                        },
                        {
                          field: 'downloads',
                          headerName: 'DOWNLOADS',
                          flex: 1,
                          valueGetter: ({ row }) => row.downloads?.length,
                        },
                        {
                          field: 'hasBid',
                          headerName: 'BIDS',
                          flex: 1,
                          valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
                        },
                        {
                          field: 'noOfSentNotification',
                          headerName: 'SENT EMAIL COUNT',
                          flex: 1,
                          valueGetter: ({ row }) =>
                            row?.noOfSentNotification?.length,
                        },
                        {
                          field: 'action',
                          headerName: 'PUSH NOTIFICATION',
                          flex: 1,
                          valueFormatter: ({ value }) => value,
                          renderCell: ({ row }) => pushEmailCheckbox(row),
                        },
                      ]}
                      autoHeight
                      autoPageSize
                      disableColumnMenu
                      disableColumnSelector
                      disableDensitySelector
                      disableSelectionOnClick
                    />
                  </div>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <EditEmailNotificationModal
                      notificationType={NotificationTypeName.NewListingInAOI}
                    />
                    <Button
                      disabled={isProcessing}
                      variant="outlined"
                      color="primary"
                      label="Push Email Notification"
                      onClick={pushListingNotificationHandler}
                      style={{ margin: 5 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
);
