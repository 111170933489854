import {
  Datagrid,
  Edit,
  EmailField,
  List,
  NumberField,
  SimpleForm,
  TextField,
  BooleanField,
  useNotify,
  useRefresh,
  TopToolbar,
  Button,
  FunctionField,
  Labeled,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import React from 'react';
import { CustomPagination } from '../components/pagination';
import { ResourceToolbar } from '../components/resourceToolbar';
import { generateFetchOptions } from '../helpers/fetch';
import DoneIcon from '@material-ui/icons/Done';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { parseServerError, ServerError } from 'now-shared/helpers/server-errors';
import { humanReadablePhoneNumber } from 'now-shared/helpers/phone-number-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';

const ContactUsActions = props => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onButtonClick = () => {
    const options = generateFetchOptions('PUT', { processed: true });

    fetch(`${process.env.REACT_APP_API_URL}/contact-us/${props.data.id}`, options)
      .then(async response => {
        if (response.status === 200) {
          notify('Successfully processed');
          refresh();
        } else {
          let message;
          try {
            message = parseServerError(await response.json()).friendlyMessage;
          } catch (error) {
            message = ServerError.Generic;
          }
          notify(message, 'warning');
        }
      });
  };

  return (
    <TopToolbar>
      {props.data && !props.data.processed && (
      <Button label="Mark as processed" onClick={onButtonClick}>
        <DoneIcon />
      </Button>
      )}
    </TopToolbar>
  );
};

export const ContactUsList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    exporter={false}
    perPage={50}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" />
      <TextField source="name" label="Name" sortable={false} />
      <EmailField source="email" label="Email" sortable={false} />
      <TextField source="inquiryType.name" label="Inquiry Type" sortable={false} />
      <TextField source="subject" label="Subject" sortable={false} />
      <BooleanField source="processed" label="Processed" />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created At"
        showTime
        timeZone={NONOPWELLS_TIME_ZONE}
      />
    </Datagrid>
  </List>
);

export const ContactUsEdit = props => (
  <Edit {...props} actions={<ContactUsActions />}>
    <SimpleForm toolbar={<ResourceToolbar disableSave deletingConfirmation {...props} />}>
      <TextField source="name" label="Name" />
      <EmailField source="email" label="Email" />
      <FunctionField
        label="Phone Number"
        render={record => humanReadablePhoneNumber(record.phoneNumber)}
      />
      <TextField source="subject" label="Subject" />
      <TextField source="text" label="Message" component="pre" />
      <BooleanField source="processed" label="Processed" />
      <Labeled
        label="Created At"
      >
        <DateFieldWithTimeZone
          source="createdAt"
          showTime
          timeZone={NONOPWELLS_TIME_ZONE}
        />
      </Labeled>
    </SimpleForm>
  </Edit>
);
