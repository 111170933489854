import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';
import {
  midGrey,
  darkBlue,
} from 'now-frontend-shared/themes/colors';

const MuiCheckbox = props => <Checkbox {...props} />;

export const StyledCheckbox = withStyles(() => ({
  root: {
    color: props => props.boxColor || midGrey,
    '& .MuiSvgIcon-root': {
      fontSize: props => props.boxSize || 28,
    },
    width: props => props.boxWidth || 40,
    height: props => props.boxHeight || 40,
  },
  checked: {
    color: props => `${props.checkedColor || darkBlue} !important`,
  },
}))(MuiCheckbox);

export default StyledCheckbox;
