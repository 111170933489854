import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';

// styled error field
import ErrorField from '../ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const masksConfig = {
  money: {
    placeholder: '$',
    options: {
      prefix: '$ ',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
  },
  moneyWithDecimal: {
    placeholder: '$',
    options: {
      prefix: '$ ',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      decimalSymbol: '.',
      decimalLimit: 2,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
  },
  percent: {
    placeholder: '%',
    options: {
      prefix: '',
      suffix: ' %',
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalSymbol: '.',
      decimalLimit: 10,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
  },
  feet: {
    placeholder: 'ft',
    options: {
      prefix: '',
      suffix: ' ft',
      includeThousandsSeparator: false,
      allowDecimal: false,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
  },
  negative: {
    placeholder: '-',
    options: {
      prefix: '-',
      suffix: '',
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalSymbol: '.',
      decimalLimit: 6,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
  },
};

const AmountInput = ({
  classes,
  'data-cy': dataCy,
  meta,
  input,
  maskType = 'money',
  placeholder,
  disabled,
}) => {
  const maskConfig = masksConfig[maskType];
  const inputMask = createNumberMask(maskConfig.options);
  const isError = meta && meta.touched && meta.error;
  const withoutErrorField = meta && meta.withoutErrorField;

  return (
    <Grid container direction="column">
      <MaskedInput
        data-cy={dataCy}
        placeholder={placeholder ?? maskConfig.placeholder}
        mask={inputMask}
        showMask={!!input.value}
        {...input}
        className={cx(classes.fieldStyles, { [classes.disabled]: disabled })}
      />
      {/* Fix: display only when required to prevent it from occupying additional space
      and causing distortion in the UI when it is rendered inside the grid view */}
      {!withoutErrorField && <ErrorField error={isError ? meta.error : null} />}
    </Grid>
  );
};

AmountInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  'data-cy': PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  maskType: PropTypes.oneOf(['money', 'moneyWithDecimal', 'percent', 'feet', 'negative']),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    withoutErrorField: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
};

AmountInput.defaultProps = {
  'data-cy': '',
  disabled: false,
  meta: {},
  maskType: 'money',
  placeholder: '',
};

export default compose(withStyles(styles), memo)(AmountInput);
