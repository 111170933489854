import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getAllCompanies = asyncAction('companies/GET_ALL_COMPANIES');
export const getCompanyComplianceInfo = asyncAction('companies/GET_COMPANY_COMPLIANCE_INFO');
export const findUsersByCompany = asyncAction('companies/FIND_USERS_BY_COMPANY');
export const getUserCompany = asyncAction('companies/GET_USER_COMPANY');
export const getNonOpWellsSignatureDetails = asyncAction('companies/GET_NONOPWELLS_SIGNATURE_DETAILS');
export const getFullyOnBoardedBuyersList = asyncAction('companies/GET_FULLY_ON_BOARDED_BUYER_COMPANIES');

// sync actions
export const clearState = action('companies/CLEAR_STATE');
export const clearCompanyComplianceInfo = action('auth/CLEAR_COMPANY_COMPLIANCE_INFO');
export const clearUserCompany = action('auth/CLEAR_USER_COMPANY');
