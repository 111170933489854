import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
  Document,
  Page as ReactPdfPage,
} from 'react-pdf/dist/esm/entry.webpack';
import useDetectPrint from 'react-detect-print';

import {
  formatNonOpWellsSimpleDate,
  NONOPWELLS_TIME_ZONE_LABEL,
} from 'now-shared/helpers/time-helpers';
import {
  pageInitiallyRenderedClassName,
  pageNotLoadedClassName,
} from 'now-shared/helpers/webdriver-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';

// components
import Spinner from 'now-frontend-shared/components/Spinner';

import buyerAgreementPdf from 'now-shared/assets/docs/buyers-agreement.pdf';
import sellerAgreementPdf from 'now-shared/assets/docs/seller-agreement.pdf';

import { signatureStyle } from 'now-frontend-shared/styles/signature-styles';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';

// styles and components from material-ui
import { createMuiTheme } from '@material-ui/core';
import {
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';
import { documentTypeTitles } from 'now-shared/helpers/document-types';

// store
import {
  getCompanyComplianceInfo,
  getNonOpWellsSignatureDetails,
} from 'store/actions/companies-actions';

import { OptionBestSuitsEntity1Label, OptionBestSuitsEntity2Label } from 'now-shared/enums/company-entities';
import { getSellerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/seller-agreement-pdf';
import { doesAgreementHaveDocument, isCustomAgreement } from 'now-shared/helpers/agreement-helpers';
import { getBuyerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/buyer-agreement-pdf';
import { pdfViewerDefaultWidth, renderAttachment } from 'now-frontend-shared/components/PdfViewer';

function toYesOrNoFromBool(bool) {
  return bool ? 'Yes' : 'No';
}

const noNameText = '<no-name>';

// 8.5x11 "letter" page size
const pixelsPerCssInch = 96;
const pageWidth = 8.5 * pixelsPerCssInch;
const pageHeight = 11 * pixelsPerCssInch;
const pageMarginTop = 0.25 * pixelsPerCssInch;
const pageMarginBottom = 0.25 * pixelsPerCssInch;
const pageMarginLeft = 0.25 * pixelsPerCssInch;
const pageMarginRight = 0.25 * pixelsPerCssInch;
const pagePaddingHorizontal = '0.3in';
const pagePaddingVertical = '0.15in';
const smallFontSize = '.1in';
const formFieldFontSize = 12;

const pageBorderColor = 'blue';
const pageBorder = `1px solid ${pageBorderColor}`;
const fieldUnderlineBorder = '1px solid gray';

const pageBorderStyle = {
  border: pageBorder,
  boxSizing: 'content-box',
};
const pageBorderStyleUnset = {
  border: 'unset',
  boxSizing: 'unset',
};

const styles = () => ({
  pdfPage: {
    width: pageWidth,
    height: pageHeight,
    ...pageBorderStyle,
    '@media print': {
      ...pageBorderStyleUnset,
    },
  },
});

const pdfTheme = createMuiTheme({
  typography: {
    fontSize: formFieldFontSize,
  },
});

const Page = props => {
  const {
    classes,
    children,
  } = props;

  return (
    <Box
      display="flex"
      className={classes.pdfPage}
    >
      <Box
        display="flex"
        style={{
          width: '100%',
          height: '100%',
          paddingLeft: pageMarginLeft,
          paddingRight: pageMarginRight,
          paddingTop: pageMarginTop,
          paddingBottom: pageMarginBottom,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            border: '1px solid black',
            padding: `${pagePaddingVertical} ${pagePaddingHorizontal}`,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const FormLabel = props => {
  const { value } = props;
  return (
    <Box
      display="flex"
      style={{
        height: formFieldFontSize * 2.44,
        alignItems: 'center',
      }}
    >
      <Typography
        style={{
          fontWeight: 500,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const FormValue = props => {
  const {
    value,
    helperText,
    inputProps,
    ...propsRest
  } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      {...propsRest}
    >
      {(
        (typeof value === 'string' || value === null || value === undefined)
          ? (
            <TextField
              value={value}
              helperText={helperText}
              variant="standard"
              contentEditable={false}
              inputProps={{
                ...inputProps,
                readOnly: true,
              }}
            />
          )
          : value
      )}
    </Box>
  );
};

const FormField = props => {
  const {
    label,
    value,
    style,
    inputProps,
    ...propsRest
  } = props;
  return (
    <Box
      display="flex"
      style={{
        width: '100%',
        padding: '.05in 0',
        ...style,
      }}
      {...propsRest}
    >
      <FormLabel value={label} />
      <div
        style={{
          width: '.15in',
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <FormValue
          value={value}
          inputProps={inputProps}
        />
      </Box>
    </Box>
  );
};

const PdfViewer = props => {
  const {
    file,
    size,
    classes,
    attachments,
  } = props;

  const [pages, setPages] = useState([]);
  const [rendered, setRendered] = useState(false);

  const onFileLoadSuccess = ({ numPages }) => {
    setPages(Array.from(new Array(numPages)).map((_, index) => ({
      loadedPage: undefined,
      pageNumber: index + 1,
      scale: 1,
      rendered: false,
    })));
    if (!numPages) {
      setRendered(true);
    }
  };

  const onPageLoad = (loadedPage, index) => {
    setPages(
      pages.map((page, i) => (
        i === index
          ? {
            ...page,
            loadedPage,
            scale: Math.min(pageWidth / loadedPage.originalWidth, pageHeight / loadedPage.originalHeight),
          }
          : page
      )),
    );
  };

  const onPageRendered = index => {
    const targetPage = pages[index];
    if (targetPage) {
      targetPage.rendered = true;
      if (pages.every(page => page.rendered)) {
        setRendered(true);
      }
    }
  };

  return (
    <>
      {!rendered && (
        <div className={pageNotLoadedClassName}>Loading/Rendering PDF document...</div>
      )}
      <Document
        file={file}
        onLoadSuccess={onFileLoadSuccess}
        renderMode="svg"
        loading={(
          <div className={pageNotLoadedClassName}>Loading PDF...</div>
        )}
      >
        {pages.map(({ pageNumber, scale }, index) => (
          <ReactPdfPage
            key={`page_${pageNumber}_scale_${scale}`}
            pageNumber={pageNumber}
            className={classes.pdfPage}
            size={size}
            scale={scale}
            loading={(
              <div className={pageNotLoadedClassName}>Loading page...</div>
            )}
            onLoadSuccess={loadedPage => onPageLoad(loadedPage, index)}
            onRenderSuccess={() => onPageRendered(index)}
          >
            {!!attachments?.length && (
              <>
                {renderAttachment(attachments, index)}
              </>
            )}
          </ReactPdfPage>
        ))}
      </Document>
    </>
  );
};

PdfViewer.defaultProps = {
  size: 'letter',
  scale: 1,
};

const CompanyComplianceDoc = ({
  classes,
  companyId,
  company,
  isLoadingComplianceInfo,
  signer2Name,
  signer2Title,
}) => {
  const dispatch = useDispatch();
  const isPrinting = useDetectPrint();
  const isLoading = isLoadingComplianceInfo;

  const buyerAgreement = company?.buyerAgreement;
  const sellerAgreement = company?.sellerAgreement;

  // const bankInformation = company?.bankInformation;

  const companyEntityDescription = (
    OptionBestSuitsEntity1Label[company?.optionBestSuitsEntity1]
    || OptionBestSuitsEntity2Label[company?.optionBestSuitsEntity2]
  );

  const businessTypes = {
    sole_proprietorship: 'Sole Proprietorship',
    corporation: 'Corporation',
    general_partnership: 'General Partnership',
    limited_liability_company: 'Limited Liability Company',
    limited_partnership_or_limited_liability_partnership: 'Limited Partnership or Limited Liability Partnership',
  };

  useEffect(() => {
    dispatch(getCompanyComplianceInfo({
      companyId,
    }));
  }, [dispatch, companyId]);
  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, []);

  const accountManagers = company?.users.filter(user => user.isAccountManager) || [];
  const certification = company?.certification;
  const beneficialOwners = [
    // TODO: [FEATURE] allow more than one beneficial owner associated with a company
    ...company?.BO25PercentOfCompanyOrMore ? [{
      firstName: company.BOFirstName,
      lastName: company.BOLastName,
      jobTitle: company.BOTitle,
      SSNorTIN: company.BOSSNorTin,
      dateOfBirth: company.BOBirthDate,
      percentCompanyOwnership: company.BOPercentageOwnership,
      IDNumber: company.BOIDNumber,
      placeOfIssuance: company.BOPlaceOfIssuance,
    }] : [],
  ];
  // TODO: [ARCHITECTURE][REQUIREMENTS][NOTIFICATIONS] what if there are multiple account managers? Is that an
  // application exception?
  // TODO: [ARCHITECTURE][INTEGRITY] what if the account manager has changed after
  // the initial registration flow was completed? Should we show the original account manager?
  const accountManager = accountManagers[0];

  // TODO: [CLEANUP] remove if this variable won't be used
  // eslint-disable-next-line no-unused-vars
  const authorizedSigners = company?.users.filter(user => user.isAuthorizedSigner);

  const officers = company?.officers.map(officer => ({
    firstName: officer.name,
    jobTitle: officer.title,
    dateOfBirth: officer.dateOfBirth,
    SSNorTIN: officer.socialSecurityNumber,
    IDNumber: officer.governmentIssuedId,
    placeOfIssuance: officer.placeOfIssuance,
  }));

  const attachmentScale = pageWidth / pdfViewerDefaultWidth();

  const sellerAgreementAttachments = (
    !sellerAgreement
    || !doesAgreementHaveDocument(sellerAgreement)
    || isCustomAgreement(sellerAgreement)
  )
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getSellerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getSellerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getSellerAgreementAttachmentOffsets.offsetTop,
      // TODO: [INTEGRITY] Should we store and show the original signature and title (if these values have changed
      // since the document was digitally signed)?
      signer1Name: getUserFullName(sellerAgreement.createdBy) || noNameText,
      signer1Title: getUserFullName(sellerAgreement.createdBy) || noNameText,
      signer2Name,
      signer2Title,
      scale: attachmentScale,
    });

  const buyerAgreementAttachments = (
    !buyerAgreement
    || !doesAgreementHaveDocument(buyerAgreement)
    || isCustomAgreement(buyerAgreement)
  )
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getBuyerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getBuyerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getBuyerAgreementAttachmentOffsets.offsetTop,
      // TODO: [INTEGRITY] Should we store and show the original signature and title (if these values have changed
      // since the document was digitally signed)?
      signer1Name: getUserFullName(buyerAgreement.createdBy) || noNameText,
      signer1Title: getUserFullName(buyerAgreement.createdBy) || noNameText,
      signer2Name,
      signer2Title,
      scale: attachmentScale,
    });

  const noBeneficialText = 'No beneficial owner';

  return (
    <ThemeProvider theme={pdfTheme}>
      <Box
        display="flex"
        flexDirection="column"
      >
        {isLoading && <Spinner className={pageNotLoadedClassName} backdrop />}
        {company && (
          <>
            <Page classes={classes}>
              <div>
                <Typography
                  variant="h4"
                >
                  PEP Advisory LLC
                </Typography>
              </div>
              <div>
                <Typography
                  variant="h5"
                >
                  Institutional or Legal Entity New Account Form
                </Typography>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '100%',
                  border: '1px solid black',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'lightGray',
                    paddingLeft: '0.5in',
                    paddingRight: '0.5in',
                    paddingTop: '0.01in',
                    paddingBottom: '0.01in',
                  }}
                >
                  <Typography
                    variant="h6"
                  >
                    Customer Information
                  </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: '100%',
                    padding: '0.2in 0.5in',
                  }}
                >
                  <FormField
                    label="Full Legal Entity Name:"
                    value={company.fullLegalCompanyName}
                  />
                  <FormField
                    label="Business Entity Type:"
                    value={businessTypes[company.role]}
                  />
                  <FormField
                    inputProps={{
                      style: { fontSize: '10px' },
                    }}
                    label="Business Entity Description:"
                    value={companyEntityDescription}
                  />
                  <FormField
                    label="Address:"
                    value={(
                      <>
                        <FormValue
                          value={company.businessStreetAddress}
                          helperText="No. / Street"
                        />
                        <FormValue
                          value={company.businessStreetAddress2}
                          helperText="Address Line 2"
                        />
                        <Box
                          display="flex"
                          style={{
                            width: '100%',
                          }}
                        >
                          <FormValue
                            value={company.city}
                            helperText="City"
                            flexGrow={1}
                          />
                          <FormValue
                            value={company.state?.title}
                            helperText="State"
                            flexGrow={1}
                          />
                          <FormValue
                            value={company.zipCode}
                            helperText="Zip"
                            flexGrow={1}
                          />
                        </Box>
                      </>
                    )}
                  />
                  <FormField
                    label="Is Publicly Traded:"
                    value={(
                      <Box
                        display="flex"
                        style={{
                          width: '100%',
                        }}
                      >
                        <FormValue
                          value={toYesOrNoFromBool(company.isPublicCompany)}
                          style={{
                            width: formFieldFontSize * 8,
                          }}
                        />
                        <FormValue
                          value={company.stockTicker}
                          helperText="Stock Ticker"
                          flexGrow={1}
                        />
                        <FormValue
                          value={company.stockExchange}
                          helperText="Exchange"
                          flexGrow={1}
                        />
                      </Box>
                    )}
                  />
                  <FormField
                    label="Account Manager E-mail:"
                    value={accountManager?.email}
                  />
                  <div>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>
                        Identity of Account Manager
                      </span>
                      <br />
                      <i>(Attach copy of Corporate Resolutions or Operating Agreement etc.).</i>
                    </Typography>
                  </div>
                  {[-1, 0].map(index => {
                    const isHeader = index === -1;
                    const user = isHeader
                      ? {
                        jobTitle: 'Title',
                        SSNorTIN: 'SSN/TIN',
                        IDNumber: 'Gov ID No.',
                        placeOfIssuance: 'Issuance',
                        dateOfBirth: 'Date of Birth',
                      }
                      : accountManagers[index];
                    return (
                      <Box
                        key={index}
                        display="flex"
                        style={{
                          width: '100%',
                          height: '.3in',
                          alignItems: 'center',
                          ...isHeader
                            ? {
                              fontWeight: 500,
                            }
                            : {
                              borderBottom: fieldUnderlineBorder,
                            },
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={10}
                          flexBasis={10}
                        >
                          <Typography
                            data-cy="complianceDocUserName"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {isHeader ? 'Name' : (getUserFullName(user) || noNameText)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={10}
                          flexBasis={10}
                        >
                          <Typography
                            data-cy="complianceDocUserTitle"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {user?.jobTitle}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocUserSSN"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {user?.SSNorTIN}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocUserIDNumber"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {user?.IDNumber}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocUserState"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {user?.placeOfIssuance}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocUserDOB"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {(
                              isHeader
                                ? user.dateOfBirth
                                : (user?.dateOfBirth && moment(user.dateOfBirth).format('MM/DD/YYYY'))
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                  <div>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>
                        Identity of Corporate Officers. CEO, CFO, or Managing Member or General Partner
                      </span>
                      <br />
                      <i>(Attach copy of Corporate Resolutions or Operating Agreement etc.).</i>
                    </Typography>
                  </div>
                  {[-1, 0, 1].map(index => {
                    const isHeader = index === -1;
                    const officer = isHeader
                      ? {
                        jobTitle: 'Title',
                        SSNorTIN: 'SSN/TIN',
                        IDNumber: 'Gov ID No.',
                        placeOfIssuance: 'Issuance',
                        dateOfBirth: 'Date of Birth',
                      }
                      : officers[index];
                    return (
                      <Box
                        key={index}
                        display="flex"
                        style={{
                          width: '100%',
                          height: '.3in',
                          alignItems: 'center',
                          ...isHeader
                            ? {
                              fontWeight: 500,
                            }
                            : {
                              borderBottom: fieldUnderlineBorder,
                            },
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={10}
                          flexBasis={10}
                        >
                          <Typography
                            data-cy="complianceDocOfficerName"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {isHeader ? 'Name' : (getUserFullName(officer) || noNameText)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={10}
                          flexBasis={10}
                        >
                          <Typography
                            data-cy="complianceDocOfficerTitle"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {officer?.jobTitle}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOfficerDOB"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {(
                              isHeader
                                ? officer.dateOfBirth
                                : (officer?.dateOfBirth && moment(officer.dateOfBirth).format('MM/DD/YYYY'))
                            )}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOfficerSSN"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {officer?.SSNorTIN}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOfficerIDNumber"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {officer?.IDNumber}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOfficerState"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {officer?.placeOfIssuance}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                  <div>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>
                        Identity of each Owner who owns directly or indirectly owns 25% of
                        {' '}
                        the equity interests of the customer.
                      </span>
                      <i> (Attach copy of Corporate Resolutions or Operating Agreement etc.).</i>
                    </Typography>
                  </div>
                  {[-1, 0, 1].map(index => {
                    const isHeader = index === -1;
                    const smallFontSize = '.1in';
                    const owner = isHeader
                      ? {
                        percentCompanyOwnership: 'Percent',
                        SSNorTIN: 'SSN/TIN',
                        IDNumber: 'Gov ID No.',
                        placeOfIssuance: 'Issuance',
                        dateOfBirth: 'Date of Birth',
                      }
                      : beneficialOwners[index];
                    return (
                      <Box
                        key={index}
                        display="flex"
                        style={{
                          width: '100%',
                          height: '.3in',
                          alignItems: 'center',
                          ...isHeader
                            ? {
                              fontWeight: 500,
                            }
                            : {
                              borderBottom: fieldUnderlineBorder,
                            },
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={10}
                          flexBasis={10}
                        >
                          <Typography
                            data-cy="complianceDocOwnerName"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {isHeader ? 'Name' : (getUserFullName(owner) || noBeneficialText)}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={4}
                          flexBasis={4}
                        >
                          <Typography
                            data-cy="complianceDocOwnerPercent"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {(
                              isHeader
                                ? owner.percentCompanyOwnership
                                : (
                                  owner?.percentCompanyOwnership !== null
                                    && owner?.percentCompanyOwnership !== undefined
                                    && `${owner.percentCompanyOwnership}%`
                                )
                            )}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOwnerSSN"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {owner?.SSNorTIN}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOwnerIDNumber"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {owner?.IDNumber}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOwnerState"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {owner?.placeOfIssuance}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexGrow={8}
                          flexBasis={8}
                        >
                          <Typography
                            data-cy="complianceDocOwnerDOB"
                            style={{
                              ...!isHeader && {
                                fontSize: smallFontSize,
                              },
                            }}
                          >
                            {(
                              isHeader
                                ? owner.dateOfBirth
                                : (
                                  owner?.dateOfBirth
                                    && moment(owner.dateOfBirth).format('MM/DD/YYYY')
                                )
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Page>
            <Page classes={classes}>
              <div>
                <Typography
                  variant="h4"
                >
                  PEP Advisory LLC
                </Typography>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '100%',
                  border: '1px solid black',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'lightGray',
                    paddingLeft: '0.5in',
                    paddingRight: '0.5in',
                    paddingTop: '0.01in',
                    paddingBottom: '0.01in',
                  }}
                >
                  <Typography
                    variant="h6"
                  >
                    Customer Information Continued
                  </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: '100%',
                    padding: '0.2in 0.5in',
                  }}
                >
                  <div style={{ marginTop: '15px' }}>
                    <Typography>
                      <span style={{ fontWeight: 500 }}>
                        Company Documents
                      </span>
                    </Typography>
                  </div>
                  <ol>
                    {company.documents.length ? company.documents.map(document => (
                      <li
                        key={`${document.type}-${document.key}`}
                        style={{ listStyle: 'initial' }}
                      >
                        <Typography
                          data-cy={`complianceDoc${documentTypeTitles[document.type]}`}
                          style={{ fontSize: smallFontSize }}
                        >
                          <span style={{ fontWeight: '500' }}>
                            {documentTypeTitles[document.type]}
                          </span>
                          :
                          {' '}
                          {(
                            isPrinting
                              ? document.filename
                              : (
                                <a
                                  href={document.downloadUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {document.filename}
                                </a>
                              )
                          )}
                          {' '}
                          <span>
                            <em>{document.key}</em>
                          </span>
                        </Typography>
                      </li>
                    )) : (
                      <li>
                        <Typography
                          data-cy="complianceDocNoDocuments"
                          style={{ fontSize: smallFontSize }}
                        >
                          No documents
                        </Typography>
                      </li>
                    )}
                  </ol>
                </Box>
              </Box>
            </Page>
            <Page classes={classes}>
              <div>
                <Typography
                  variant="h4"
                >
                  PEP Advisory LLC
                </Typography>
              </div>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '100%',
                  border: '1px solid black',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    // TODO: [DRY] use palette color constant instead of literal color value
                    // See `frontend-shared/theme/colors.js`
                    backgroundColor: 'lightGray',
                    paddingLeft: '0.5in',
                    paddingRight: '0.5in',
                    paddingTop: '0.01in',
                    paddingBottom: '0.01in',
                  }}
                >
                  <Typography
                    variant="h6"
                  >
                    US Patriot Act Requirements
                  </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: '100%',
                    padding: '0.2in 0.5in',
                  }}
                >
                  <Typography
                    variant="h7"
                  >
                    Is the holder, or a person with an interest in this account, either (1) a senior military, governmental, or political official in a non-U.S Country or (2) closely associated with or an immediate family member of such an official?
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel control={<Checkbox />} label="Yes" />
                    <FormControlLabel control={<Checkbox />} label="No" />
                  </div>
                  <Typography
                    variant="h7"
                  >
                    If Yes, identify the name of the official, office held and country:
                  </Typography>
                  <FormField />
                  <Typography
                    variant="h7"
                    style={{ marginTop: '10px' }}
                  >
                    Has a review of http://www.finra.org/ofac been completed?
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel control={<Checkbox />} label="Yes" />
                    <FormControlLabel control={<Checkbox />} label="No" />
                    <FormField label="By Whom" />
                  </div>
                  <Typography
                    variant="h7"
                    style={{ marginTop: '10px' }}
                  >
                    Did your review identify any entity and/or person associated with this potential new client?
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel control={<Checkbox />} label="Yes" />
                    <FormControlLabel control={<Checkbox />} label="No" />
                    <FormField label="If Yes, Please describe" />
                  </div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '100%',
                  // TODO: [DRY] use palette color constant instead of literal color value
                  // See `frontend-shared/theme/colors.js`
                  border: '1px solid black',
                  margin: '25px 0',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'lightGray',
                    paddingLeft: '0.5in',
                    paddingRight: '0.5in',
                    paddingTop: '0.01in',
                    paddingBottom: '0.01in',
                  }}
                >
                  <Typography
                    variant="h6"
                  >
                    Certification of Complete and Correct Information
                  </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: '100%',
                    padding: '0.2in 0.5in',
                  }}
                >
                  <Typography
                    variant="h7"
                  >
                    I, (name of natural person opening the account), hereby certify, to the best of my knowledge,
                    that the information
                    provided is complete and correct.
                  </Typography>
                  <FormField
                    label="Printed Name:"
                    value={certification?.certifierFullName || ''}
                  />
                  <FormField
                    label="Signature:"
                    inputProps={{
                      style: signatureStyle,
                    }}
                    value={certification?.certifierSignature || ''}
                  />
                  <FormField
                    label="Date:"
                    value={(
                      certification
                        ? `${
                          formatNonOpWellsSimpleDate(new Date(certification.updatedAt))
                        } (${
                          NONOPWELLS_TIME_ZONE_LABEL.full
                        } Zone)`
                        : ''
                    )}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '100%',
                  border: '1px solid black',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'lightGray',
                    paddingLeft: '0.5in',
                    paddingRight: '0.5in',
                    paddingTop: '0.01in',
                    paddingBottom: '0.01in',
                  }}
                >
                  <Typography
                    variant="h6"
                  >
                    Compliance
                  </Typography>
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: '100%',
                    padding: '0.2in 0.5in',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormField label="Signature of Registered Representative" />
                    <FormField label="Date" style={{ width: '30%' }} />
                  </div>
                  <FormField label="Print Name of RR" />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormField label="Signature of Appropriate Supervisor:" />
                    <FormField label="Date" style={{ width: '30%' }} />
                  </div>
                  <FormField label="Print Name of Appropriate Supervisor" />
                </Box>
              </Box>
            </Page>
            {sellerAgreement && doesAgreementHaveDocument(sellerAgreement) && (
              <>
                <PdfViewer
                  file={
                    sellerAgreement.document
                      ? sellerAgreement.document.downloadUrl
                      : sellerAgreementPdf
                  }
                  classes={classes}
                  attachments={sellerAgreementAttachments}
                />
                {sellerAgreement.wasSignedOnCreation && (
                  <Page classes={classes}>
                    <p>
                      Seller Agreement signed by SELLER:
                      {' '}
                      {getUserFullName(sellerAgreement.createdBy) || noNameText}
                    </p>
                    <p>
                      On:
                      {' '}
                      {formatNonOpWellsSimpleDate(new Date(sellerAgreement.createdAt))}
                      {` (${NONOPWELLS_TIME_ZONE_LABEL.full} Zone)`}
                    </p>
                  </Page>
                )}
              </>
            )}
            {buyerAgreement && doesAgreementHaveDocument(buyerAgreement) && (
              <>
                <PdfViewer
                  file={
                    buyerAgreement.document
                      ? buyerAgreement.document.downloadUrl
                      : buyerAgreementPdf
                  }
                  classes={classes}
                  attachments={buyerAgreementAttachments}
                />
                {buyerAgreement.wasSignedOnCreation && (
                  <Page classes={classes}>
                    <p>
                      Buyer Agreement signed by buyer:
                      {' '}
                      {getUserFullName(buyerAgreement.createdBy) || noNameText}
                    </p>
                    <p>
                      On:
                      {' '}
                      {formatNonOpWellsSimpleDate(new Date(buyerAgreement.createdAt))}
                      {` (${NONOPWELLS_TIME_ZONE_LABEL.full} Zone)`}
                    </p>
                  </Page>
                )}
              </>
            )}
            <div className={pageInitiallyRenderedClassName} />
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

CompanyComplianceDoc.propTypes = {
  companyId: PropTypes.number.isRequired,
  company: PropTypes.shape({
    // TODO: [TYPE] fill in missing fields

    certification: PropTypes.shape({
      certifierFullName: PropTypes.string.isRequired,
      certifierSignature: PropTypes.string.isRequired,
      certifier: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      updatedAt: PropTypes.string.isRequired,
    }),
  }),
  signer2Name: PropTypes.string.isRequired,
  signer2Title: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isLoadingComplianceInfo: PropTypes.bool.isRequired,
};

CompanyComplianceDoc.defaultProps = {
  company: undefined,
};

export default compose(
  connect(({ companies }) => ({
    company: companies.companyComplianceInfo,
    isLoadingComplianceInfo: companies.isLoadingCompanyComplianceInfo,
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',

  })),
  withStyles(styles),
  memo,
)(CompanyComplianceDoc);
