import {
  INPUT_BORDER_FOCUS_COLOR,
  INPUT_BORDER_HOVER_COLOR,
  INPUT_BORDER_COLOR,
} from 'now-frontend-shared/themes/colors';

export default {
  emailInputField: {
    width: '100%',
    padding: '6px 8px',
    fontWeight: '500',
    fontSize: '15px',
    border: `1px solid ${INPUT_BORDER_COLOR}`,
    borderRadius: '4px',
    outline: 'none',
    marginRight: 10,
    textAlign: 'left',
    color: '#000000',
    background: '#fff',
    '&:hover': {
      border: `1px solid ${INPUT_BORDER_HOVER_COLOR}`,
    },
    '&:focus': {
      border: `1px solid ${INPUT_BORDER_FOCUS_COLOR}`,
      boxShadow: `0px 0px 0px 1px ${INPUT_BORDER_FOCUS_COLOR} inset`,
    },
    '&::placeholder': {
      fontWeight: '400',
      fontSize: '10px',
    },
  },
  emailWrapper: {
    display: 'flex',
    width: '90%',
    justifyContent: 'flex-start',
    margin: 10,
    height: 30,
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'start',
  },

  dialogContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
};
