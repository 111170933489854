import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import DatePicker from 'now-frontend-shared/components/DatePicker';
import DateTimePicker from 'now-frontend-shared/components/DateTimePicker';

const DateInputWithTimeZone = (props) => {
  const {
    disabled,
    minDate,
    parse,
    timeZone,
    'data-cy': dataCy,
    withTime,
  } = props;

  const {
    input: { name, value, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const dateValue = useMemo(() => {
    const parsedValue = parse(value);
    return parsedValue && new Date(parsedValue);
  }, [parse, value]);

  return withTime ? (
    <DateTimePicker
      data-cy={dataCy}
      input={{
        name,
        value: dateValue,
        onChange,
      }}
      meta={{
        touched,
        error,
      }}
      minDate={minDate}
      timeZone={timeZone}
      required={isRequired}
      disabled={disabled}
    />
  ) : (
    <DatePicker
      data-cy={dataCy}
      input={{
        name,
        value: dateValue,
        onChange,
      }}
      meta={{
        touched,
        error,
      }}
      minDate={minDate}
      timeZone={timeZone}
      required={isRequired}
      disabled={disabled}
    />
  );
};

DateInputWithTimeZone.propTypes = {
  'data-cy': PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  parse: PropTypes.func,
  timeZone: PropTypes.string,
  withTime: PropTypes.bool,
};

DateInputWithTimeZone.defaultProps = {
  'data-cy': undefined,
  disabled: false,
  minDate: undefined,
  parse: (value) => value,
  timeZone: undefined,
  withTime: false,
};

export default DateInputWithTimeZone;
