import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import PlusButton from '../PlusButton';
import SelectForIdValue from 'now-frontend-shared/components/Select/SelectForIdValue';
import DeleteConfirmationModal from 'now-frontend-shared/components/modals/DeleteConfirmationModal';
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import CheckboxInput from 'now-frontend-shared/components/inputs/CheckboxInput';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// constants
import { defaultLandingZoneValue } from '../../constants';

// helper functions
import { checkWellsFields, decimalCount } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

// styles
import styles from './styles';

const LandingZone = ({
  classes, fields, landingZones, formValues, transactionType,
}) => {
  const [zoneFieldsError, setZoneFieldsError] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(0);
  const { setModal } = useModalSetter();

  useEffect(() => {
    if (fields.length === 0) {
      fields.push(defaultLandingZoneValue);
    }
  }, [fields]);

  useEffect(() => {
    if (formValues?.wells) {
      let allZoneFieldsNotEmpty = true;

      formValues.wells.forEach(zone => {
        const allFieldsFilled = checkWellsFields(zone);
        if (!allFieldsFilled) {
          allZoneFieldsNotEmpty = false;
        }
      });

      if (allZoneFieldsNotEmpty && zoneFieldsError) {
        setZoneFieldsError(false);
      }
    }
    // eslint-disable-next-line
  }, [formValues?.wells, zoneFieldsError]);

  const eightsCalculations = useCallback(index => {
    const well = formValues.wells?.[index];
    if (!well) {
      return '';
    }

    const workingInterestPercentage = parseFloat(well?.workingInterestPercentage);
    const netRevenueInterestNumber = parseFloat(well?.netRevenueInterestNumber);

    if (!workingInterestPercentage || !netRevenueInterestNumber) {
      return '';
    }

    const decimals = Math.min(Math.max(
      decimalCount(workingInterestPercentage),
      decimalCount(netRevenueInterestNumber),
    ), 10);

    return ((netRevenueInterestNumber / workingInterestPercentage) * 100).toFixed(decimals || 1);
  }, [formValues.wells]);

  const handleOneMoreWell = useCallback(() => {
    let allZoneFieldsNotEmpty = true;

    const properties = fields.getAll();

    properties.forEach(zone => {
      const allFieldsFilled = checkWellsFields(zone);
      if (!allFieldsFilled) {
        allZoneFieldsNotEmpty = false;
      }
    });

    if (allZoneFieldsNotEmpty) {
      setZoneFieldsError(false);
      fields.push(defaultLandingZoneValue);
      setAccordionExpanded(fields.length);
    } else {
      setZoneFieldsError(true);
    }
  }, [fields]);

  const handleRemoveWell = useCallback(index => fields.remove(index), [fields]);

  const openConfirmationModal = index => {
    setModal(
      <DeleteConfirmationModal
        heading="Are you sure you want to delete this well?"
        handleRemoveItem={handleRemoveWell}
        index={index}
      />,
    );
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      {fields.map((zone, index) => (
        <Accordion
          expanded={accordionExpanded === index}
          onChange={handleAccordionChange(index)}
          key={index}
          elevation={0}
          style={{
            padding: '11px',
            background: '#F9F9F9',
            border: 'solid 1px #EBEBEB',
            borderRadius: '8px',
            marginBottom: '10px',
          }}
        >
          <AccordionSummary data-cy={`expandWell${index + 1}Details`} expandIcon={<ExpandMoreIcon />}>
            <span className={classes.accordionTitle}>{`Well ${index + 1}`}</span>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <Grid container key={index}>
              <div className={classes.subHeader}>
                Well Info
                <hr className={classes.hr} />
              </div>
              <Grid container className={classes.container}>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={4}
                  direction="column"
                >
                  <label htmlFor={index} className={classes.label}>
                    Well Name *
                  </label>
                </Grid>

                <Grid item xs={12} sm={10} md={8}>
                  <Field
                    name={`${zone}.wellName`}
                    component={BaseInput}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={4}
                  direction="column"
                >
                  <label htmlFor={index} className={classes.label}>
                    Well API Number
                  </label>
                </Grid>

                <Grid item xs={12} sm={10} md={8}>
                  <Field
                    name={`${zone}.wellAPINumber`}
                    component={BaseInput}
                  />
                </Grid>

                <Grid container item xs={12} sm={10} md={4} lg={4} xl={4} justify="space-between">
                  <Grid
                    item
                    xs={10}
                    sm={11}
                    md={10}
                    lg={fields.length > 1 ? 10 : 12}
                    xl={fields.length > 1 ? 10 : 12}
                    direction="column"
                  >
                    <label htmlFor={index} className={classes.label}>
                      Landing Zone *
                    </label>

                    <label htmlFor={index} className={classes.description}>
                      {`(${index + 1})`}
                    </label>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={10} md={8} lg={8} xl={8}>
                  <Field
                    name={`${zone}.landingZone`}
                    component={SelectForIdValue}
                    props={{ options: landingZones || [], disabled: !landingZones }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={4}
                direction="column"
              >
                <label htmlFor={index} className={classes.label}>
                  Well Total Vertical Depth *
                </label>
              </Grid>

              <Grid item xs={12} sm={10} md={8}>
                <Field
                  name={`${zone}.wellTotalVerticalDepth`}
                  component={AmountInput}
                  props={{ maskType: 'feet' }}
                />
              </Grid>
              <Grid container alignItems="flex-start" className={classes.wrapper}>
                <Grid item xs={false} sm={false} md={false} lg={4} xl={4} />
                <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
                  <Field
                    name={`${zone}.depthConfirmedWithOperator`}
                    label="Landing Zone and Total Vertical Depth confirmed with operator?"
                    component={CheckboxInput}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.zonesWrapper}>
                <Grid container direction="column">
                  <Grid container direction="row" justify="space-between" className={classes.container}>

                    <Grid item xs={12} sm={10} md={4} lg={4}>
                      <span className={classes.coordinatesHeading}>Surface Hole Location *</span>
                    </Grid>

                    <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.bottomCoordinates}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5}
                        lg={5}
                        xl={5}
                        direction="column"
                        justify="flex-start"
                        className={classes.labelContainer}
                      >
                        <label htmlFor={index} className={classes.label}>
                          Latitude
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                        <Field
                          name={`${zone}.surfaceLatitude`}
                          component={BaseInput}
                          props={{ placeholder: '67.531422' }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.surfaceCoordinates}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5}
                        lg={5}
                        xl={5}
                        direction="column"
                        justify="flex-start"
                        className={classes.labelContainer}
                      >
                        <label htmlFor={index} className={classes.label}>
                          Longitude
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                        <Field
                          name={`${zone}.surfaceLongitude`}
                          component={AmountInput}
                          props={{ placeholder: '-23.324231', maskType: 'negative' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between" className={classes.container}>
                    <Grid item xs={12} sm={10} md={4} lg={4}>
                      <span className={classes.coordinatesHeading}>Bottom Hole Location</span>
                    </Grid>

                    <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.bottomCoordinates}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5}
                        lg={5}
                        xl={5}
                        direction="column"
                        justify="flex-start"
                        className={classes.labelContainer}
                      >
                        <label htmlFor={index} className={classes.label}>
                          Latitude
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                        <Field
                          name={`${zone}.bottomLatitude`}
                          component={BaseInput}
                          props={{ placeholder: '67.531422' }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={10} md={4} lg={4} className={classes.surfaceCoordinates}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={5}
                        lg={5}
                        xl={5}
                        direction="column"
                        justify="flex-start"
                        className={classes.labelContainer}
                      >
                        <label htmlFor={index} className={classes.label}>
                          Longitude
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={8} md={7} lg={7} xl={7}>
                        <Field
                          name={`${zone}.bottomLongitude`}
                          component={AmountInput}
                          props={{ placeholder: '-23.324231', maskType: 'negative' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <div className={classes.subHeader}>
                    WELL FINANCIALS
                    <hr className={classes.hr} />
                  </div>

                  <Grid>
                    <LabelLayout label="Working Interest" space="small" multiple isRequired>
                      <Field name={`${zone}.workingInterestPercentage`} component={AmountInput} props={{ maskType: 'percent' }} />
                    </LabelLayout>

                    <LabelLayout label="Net Revenue Interest" space="small" multiple isRequired>
                      <Field name={`${zone}.netRevenueInterestNumber`} component={AmountInput} props={{ maskType: 'percent' }} />
                    </LabelLayout>

                    <LabelLayout label="8/8ths Calculation" space="small">
                      <AmountInput
                        disabled
                        input={{
                          'data-cy': `well${index + 1}EightsCalculation`,
                          value: eightsCalculations(index),
                          disabled: true,
                        }}
                        maskType="percent"
                      />
                    </LabelLayout>
                  </Grid>

                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={4}
                direction="column"
              >
                <label htmlFor={index} className={classes.label}>
                  Well Gross AFE *
                </label>
              </Grid>

              <Grid item xs={12} sm={10} md={8}>
                <Field
                  name={`${zone}.wellGrossAfe`}
                  component={AmountInput}
                  maskType="moneyWithDecimal"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={10}
                md={4}
                direction="column"
              >
                <label htmlFor={index} className={classes.label}>
                  Well Net AFE *
                </label>
              </Grid>
              <Grid item xs={12} sm={10} md={8}>
                <Field
                  name={`${zone}.wellNetAFE`}
                  component={AmountInput}
                  props={{ maskType: 'moneyWithDecimal' }}
                />
              </Grid>

              {['cash', 'either'].includes(transactionType) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={4}
                    direction="column"
                  >
                    <label htmlFor={index} className={classes.label}>
                      Minimum Bid, USD
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Field
                      name={`${zone}.minimumBid`}
                      component={AmountInput}
                      props={{
                        disabled: transactionType === 'carry',
                      }}
                    />
                  </Grid>
                </>
              )}

              {['carry', 'either'].includes(transactionType) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={4}
                    direction="column"
                  >
                    <label htmlFor={index} className={classes.label}>
                      Minimum Bid, Carry
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Field
                      name={`${zone}.minimumBidCarry`}
                      component={AmountInput}
                      props={{
                        maskType: 'percent',
                        disabled: transactionType === 'cash',
                      }}
                    />
                  </Grid>
                </>
              )}

              {fields.length > 1 && (
                <Grid style={{ width: '100%' }}>
                  <button
                    type="button"
                    onClick={() => openConfirmationModal(index)}
                    className={classes.removeZoneButton}
                  >
                    Remove Well
                  </button>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      {fields.length < 20 && (
      <Grid className={classes.buttonContainer}>
        <PlusButton error={zoneFieldsError} onClick={handleOneMoreWell} />
      </Grid>
      )}
    </>
  );
};

LandingZone.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fields: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  landingZones: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })),
};

export default compose(
  withStyles(styles),
)(LandingZone);
