import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const Spinner = ({
  classes,
  wrapped,
  /**
   * For backdrop to work properly, there needs to be an ancestor container with position: relative
   * that the spinner backdrop will position itself relative to.
   * See https://stackoverflow.com/questions/14217783/set-height-100-on-absolute-div
   */
  backdrop,
}) => (
  <div style={{ width: wrapped && '100%' }} className={cx(classes.container, { [classes.backdrop]: backdrop })}>
    <div
      className={classes.spinner}
      data-cy="loadingSpinner"
    />
    Loading...
  </div>
);

Spinner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  wrapped: PropTypes.bool,
  backdrop: PropTypes.bool,
};

export default compose(withStyles(styles), memo)(Spinner);
