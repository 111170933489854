import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const MyTooltip = props => <Tooltip placement="top" arrow {...props} />;

// NOTE: These custom tooltip components can accept props to override the default styles.

export const StyledTooltip = withStyles(theme => ({
  tooltip: props => ({
    backgroundColor: props.backgroundColor || theme.palette.primary.contrastText,
    color: props.color || theme.palette.common.black,
    boxShadow: props.boxShadow || theme.shadows[17],
    fontSize: props.fontSize || 13,
    textAlign: 'center',
    maxWidth: props.maxWidth || '300px',
    paddingTop: props.paddingTop || 4,
    paddingBottom: props.paddingBottom || 4,
    paddingLeft: props.paddingLeft || 8,
    paddingRight: props.paddingRight || 8,
    lineHeight: props.lineHeight || '18px',
    letterSpacing: props.letterSpacing || 0.16,
    border: props.border || 'none',
  }),
  arrow: props => ({
    color: props.arrowColor || theme.palette.primary.contrastText,
  }),
}))(MyTooltip);

export const FormulaTooltip = withStyles(theme => ({
  tooltip: props => ({
    backgroundColor: props.backgroundColor || 'rgba(30, 30, 30, 0.76)',
    color: props.color || theme.palette.common.white,
    boxShadow: props.boxShadow || '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    fontSize: props.fontSize || 13,
    textAlign: 'center',
    maxWidth: props.maxWidth || '300px',
    paddingTop: props.paddingTop || 4,
    paddingBottom: props.paddingBottom || 4,
    paddingLeft: props.paddingLeft || 8,
    paddingRight: props.paddingRight || 8,
    lineHeight: props.lineHeight || '18px',
    letterSpacing: props.letterSpacing || 0.16,
    border: props.border || 'none',
  }),
  arrow: props => ({
    color: props.arrowColor || 'rgba(30, 30, 30, 0.76)',
  }),
}))(MyTooltip);
