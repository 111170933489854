import React, { useEffect, useState, useMemo } from 'react';
import PropTypes, { element } from 'prop-types';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { primaryContrast } from 'now-frontend-shared/themes/colors';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { generateFetchOptions } from 'helpers/fetch';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';
import Spinner from 'now-frontend-shared/components/Spinner';
import { toast } from 'react-toastify';
import { useNotify } from 'react-admin';
import {
  parseServerError, ServerError,
} from 'now-shared/helpers/server-errors';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const UserEmailNotifications = ({ userId, classes }) => {
  const [notifications, setNotifications] = useState(null);
  const [userNotifications, setUserNotifications] = useState(null);

  const [userBasins, setUserBasins] = useState(null);
  const [allBasins, setAllBasins] = useState(null);

  const notify = useNotify();

  const getUserNotifications = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/user-notification/${userId}`, generateFetchOptions('GET'));
    const data = await result.json();
    setUserNotifications(data);
  };

  const getNotifications = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, generateFetchOptions('GET'));
    const data = await result.json();
    setNotifications(data);
  };

  const getUserBasins = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/user-basin/${userId}`, generateFetchOptions('GET'));
    const data = await result.json();
    setUserBasins(data);
  };

  const getAllBasins = async () => {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/basins`, generateFetchOptions('GET'));
    const data = await result.json();
    setAllBasins(data);
  };

  const [activeUserBasins, setActiveUserBasins] = useState([]);

  const findIsBasinSelected = (basinId) => {
    const isSelected = userBasins.find((ub) => ub.basin.id === basinId);
    return isSelected;
  };

  const getActiveUserBasins = () => {
    if (allBasins && userBasins) {
      const activeUserBasin = allBasins.map(basin => {
        const isSelected = findIsBasinSelected(basin.id);
        return isSelected
          ? { ...basin, isBasinSelected: true } : { ...basin, isBasinSelected: false };
      });
      setActiveUserBasins(activeUserBasin);
    }
  };

  useEffect(() => {
    getActiveUserBasins();
  }, [allBasins, userBasins]);

  useEffect(() => {
    getNotifications();
    getUserNotifications();
    getUserBasins();
    getAllBasins();
  }, []);

  const userNotificationsToDisplay = useMemo(
    () => [
      {
        title: 'User Details Edited By Admin',
        type: NotificationTypeName.UserDetailsChanged,
      },
      {
        title: 'Seller Agreement Approved',
        type: NotificationTypeName.SellerAgreementApproved,
      },
      {
        title: 'Seller Agreement Rejected',
        type: NotificationTypeName.SellerAgreementRejected,
      },
      {
        title: 'Buyer Agreement Approved',
        type: NotificationTypeName.BuyerAgreementApproved,
      },
      {
        title: 'Buyer Agreement Rejected',
        type: NotificationTypeName.BuyerAgreementRejected,
      },
      {
        title: 'Buyer\'s Bid Confirmed',
        type: NotificationTypeName.BidConfirmed,
      },
      {
        title: 'Bank Information Approved',
        type: NotificationTypeName.BankInformationApproved,
      },
      {
        title: 'Listing has received a bid',
        type: NotificationTypeName.ListingHasReceivedBid,
      },
      {
        title: 'Admin Archived Your Listing',
        type: NotificationTypeName.AdminArchivedYourListing,
      },
      {
        title: 'New listing in AOI',
        type: NotificationTypeName.NewListingInAOI,
      },
    ],
    [],
  );

  const checkIsAdminNotificationActive = (type) => {
    const adminNotification = notifications?.find(
      n => n.notificationType.name === type,
    )?.active || false;

    return adminNotification;
  };

  const getCheckedStatus = (notificationType) => {
    const adminNotification = notifications?.find(
      n => n.notificationType.name === notificationType,
    )?.active || false;

    const userNotification = userNotifications?.find(
      n => n.notificationType.name === notificationType,
    );

    // Using admin notification as default setting.
    if (userNotification === undefined) {
      return adminNotification;
    }

    // User notification is only considered valid if the admin notification is active.
    if (adminNotification && userNotification) {
      return userNotification.active;
    }

    return false;
  };

  const rows = useMemo(
    () => userNotificationsToDisplay.map(
      ({ title, type }) => ({
        title,
        status: {
          defaultValue: getCheckedStatus(type),
          type,
        },
      }),
    ),
    [notifications, userNotifications],
  );

  const UserBasinCheckbox = (args) => {
    const { basin } = args;
    const { id, isBasinSelected } = basin;

    const selectHandler = async () => {
      const updatedBasin = activeUserBasins.map(b => (b.id === id ? { ...b, isBasinSelected: !isBasinSelected } : b));
      await fetch(`${process.env.REACT_APP_API_URL}/user-basin/${userId}`, generateFetchOptions('PUT', { basinId: id, isBasinSelected: !isBasinSelected }));
      setActiveUserBasins(updatedBasin);
    };

    return (
      <Checkbox
        edge="start"
        checked={isBasinSelected}
        onChange={selectHandler}
        disableRipple
        style={{ color: '#3D4F5F', opacity: 0.8, cursor: 'pointer' }}
        inputProps={{ 'aria-labelledby': 1 }}
      />
    );
  };

  const AreaOfInterest = () => (
    <Box className={classes.basinsContainer}>
      <Box className={classes.aoiTextWrapper}>
        <span className={classes.aioText}>Area of Interest list :</span>
      </Box>
      <Box className={classes.basinListContainerOverflow}>
        {activeUserBasins?.map((basinInfo) => (
          <Box key={basinInfo.id} className={classes.basinRowWraper}>
            <Box className={classes.basinContainer}>
              <UserBasinCheckbox basin={basinInfo} />
              <span>{basinInfo.title}</span>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );

  const Switch = ({ params }) => {
    const [checked, setChecked] = useState(params.status.defaultValue);

    const onUpdate = async option => {
      let response;
      const isAdminNotificationActive = notifications?.find(
        n => n.notificationType.name === params.status.type,
      )?.active || false;

      if (isAdminNotificationActive) {
        response = await fetch(`${process.env.REACT_APP_API_URL}/user-notification/${params.status.type}`, generateFetchOptions('PUT', { active: option, userId: parseInt(userId, 10) }));
      } else {
        notify(`Please enable "${params.title}" in the email communications settings first`, 'error');
        return;
      }

      if (response?.status === 200) {
        notify('Setting has been updated');
      } else {
        let message;
        try {
          message = parseServerError(await response?.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    };
    return (
      <Box className={classes.notificationWrapper}>
        <SwitchButton
          checked={checked}
          disabled={!checkIsAdminNotificationActive(params.status.type)}
          label={params.title}
          onChangeNotificationsValue={async ({ value }) => {
            try {
              await onUpdate(value);
              setChecked(value);
            } catch (err) {
              toast.error(`There was an error updating the notification status: ${err.message}`);
            }
          }}
        />
      </Box>
    );
  };

  Switch.propTypes = {
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
      status: PropTypes.shape({
        defaultValue: PropTypes.bool,
        type: PropTypes.string,
      }),
    }).isRequired,
  };

  if (notifications === null || userNotifications === null || userBasins === null) {
    return <Spinner backdrop />;
  }

  return (
    <>
      <CssBaseline />
      <Box
        style={{
          backgroundColor: primaryContrast,
        }}
        className={classes.mainContainer}
      >
        {rows.map((rowValue) => <Switch params={rowValue} key={rowValue.status.type} />)}
        <AreaOfInterest />
      </Box>
    </>
  );
};

UserEmailNotifications.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  userId: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
)(UserEmailNotifications);
