import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';
import { Button } from 'react-admin';

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
});

const DeleteButtonWithDialog = props => {
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleDelete = e => {
    e.preventDefault();
    setShowDialog(false);
    dispatch(
      props.onDelete(),
    );
  };

  return (
    <>
      <ActionDelete style={{ cursor: 'pointer' }} className={classnames('ra-delete-button', props.classes.deleteButton, props.className)} onClick={handleClick} />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>Are you sure you want to delete this document? You are not able to undo this</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDelete}
            label="Delete"
            className={classnames('ra-delete-button', props.classes.deleteButton, props.className)}
            key="button"
          >
            <ActionDelete />
          </Button>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButtonWithDialog.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  document: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteButtonWithDialog.defaultProps = {
  redirect: 'list',
  label: 'ra.action.delete',
  classes: {},
};

export default withStyles(styles)(DeleteButtonWithDialog);
