import { generateFetchOptions } from '../helpers/fetch';

export const asyncCurrentPassword = async value => {
  if (value) {
    const options = generateFetchOptions('POST', { password: value });
    const response = await fetch(`${process.env.REACT_APP_API_URL}/check-password`, options);

    if (response.status === 400) {
      return 'Current password is invalid';
    }
  }
};
