import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { styles } from './styles';
import {
  CssBaseline,
  Box,
  Grid,
  Typography,
  withStyles,
  Divider,
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Spinner from 'now-frontend-shared/components/Spinner';
import { getAllRoles } from 'store/actions/roles-actions';
import {
  getAllPermissions,
  getRolePermissions,
  getUserPermissions,
} from 'store/actions/permissions-actions';
import RoleToggleSwitch from './RoleToggleSwitch';
import EditRolesModal from '../EditRolesModal';
import EditPermissionsModal from '../EditPermissionsModal';
import { RoleDomain, sortRolesByDomain } from '../../helpers/roles-helpers';
import ReduxErrorHandler from 'components/ReduxErrorHandler';
import { camelCaseToUpperCaseSpaces } from 'now-shared/helpers/text-helpers';
import { getUserData } from '../../auth/auth-helpers';

function UserRolesAndPermissions({
  classes,
  userId,
  roles,
  permissions,
  permissionCategories,
  rolePermissions,
  satisfiedRolePermissions,
  userPermissions,
  adminSitePermissionIdsSet,
  rolesLoading,
  rolesLoaded,
  rolesUpdating,
  rolesUpdated,
  permissionsLoaded,
  rolePermissionsLoaded,
  userPermissionsLoading,
  userPermissionsLoaded,
  userPermissionsUpdating,
  satisfiedRolePermissionsLoaded,
}) {
  const dispatch = useDispatch();
  const [openRolesModal, setOpenRolesModal] = useState(false);
  const [openPermissionsModal, setOpenPermissionsModal] = useState(false);
  const [hasPermissionNotAssociatedWithRole, setHasPermissionNotAssociatedWithRole] = useState(false);
  const [nonAdminRoleWithAdminPermission, setNonAdminRoleWithAdminPermission] = useState(false);
  const currentUserData = getUserData();
  const currentUserId = currentUserData?.id;

  const dataLoaded = !!(rolesLoaded && permissionsLoaded && rolePermissionsLoaded
  && userPermissionsLoaded && satisfiedRolePermissionsLoaded);

  const fetchStaticData = useCallback(() => {
    dispatch(getAllRoles());
    dispatch(getAllPermissions());
    dispatch(getRolePermissions());
  }, [dispatch]);

  useEffect(() => {
    fetchStaticData();
  }, [fetchStaticData]);

  const fetchPermissionsData = useCallback(() => {
    // Fetch userPermissions and update satisfiedRolePermissions state
    dispatch(getUserPermissions({ userId }));
  }, [userId, dispatch]);

  useEffect(() => {
    fetchPermissionsData();
  }, [userId, fetchPermissionsData]);

  // Roles sorted by type into even and odd indexes
  const sortedRolesByType = useMemo(() => sortRolesByDomain(roles), [roles]);

  const roleTogglesData = useMemo(() => {
    if (!userPermissionsLoaded || !satisfiedRolePermissionsLoaded) return [];
    const roleToggles = sortedRolesByType.map(role => {
      const roleToggle = {
        roleId: role.id,
        type: role.type,
        title: role.title,
        titleDisplayed: camelCaseToUpperCaseSpaces(role.title),
        defaultValue: satisfiedRolePermissions?.some(
          rolePermission => rolePermission.roleId === role.id,
        ) || false,
      };
      return roleToggle;
    });
    return roleToggles;
  }, [
    sortedRolesByType,
    satisfiedRolePermissions,
    userPermissionsLoaded,
    satisfiedRolePermissionsLoaded,
  ]);

  const adminRolePermissionsSatisfied = roleTogglesData?.find(
    roleToggle => roleToggle.type === RoleDomain.AdminSite && roleToggle.defaultValue,
  ) || false;

  const userPermissionsColumns = useMemo(
    () => [
      {
        field: 'name',
        renderHeader: () => (
          <Typography className={classes.dataGridColumnHeader}>
            Permission
          </Typography>
        ),
        minWidth: 400,
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: params => (
          <Box key={`permission-${params.row.id}`} display="flex" alignItems="center">
            <Typography>
              {params.value}
            </Typography>
            {!params.row.associatedWithSatisfiedRole && (
              <Typography className={classes.highlightBlue}>
                **
              </Typography>
            )}
            {!adminRolePermissionsSatisfied && adminSitePermissionIdsSet?.has(params.row.id) && (
              <Typography className={classes.highlightRed}>
                ***
              </Typography>
            )}
          </Box>
        ),
      },
      {
        field: 'description',
        renderHeader: () => (
          <Typography className={classes.dataGridColumnHeader}>
            Description
          </Typography>
        ),
        minWidth: 400,
        flex: 1,
        sortable: false,
        hideable: false,
        renderCell: params => (
          <Typography key={`permission-${params.row.id}-description`}>
            {params.value}
          </Typography>
        ),
      },
    ],
    [
      adminSitePermissionIdsSet,
      adminRolePermissionsSatisfied,
      classes.dataGridColumnHeader,
      classes.highlightBlue,
      classes.highlightRed,
    ],
  );

  const userPermissionsData = useMemo(
    () => {
      if (!userPermissionsLoaded || !satisfiedRolePermissionsLoaded) return [];
      let permissionNotAssociatedWithRole = false;
      let adminPermissionForNonAdminRole = false;
      const userPermissionRows = userPermissions?.map(
        ({
          id,
          category,
          action,
          permissionType,
          description,
        }) => {
          const { name } = permissionType;
          const associatedWithSatisfiedRole = satisfiedRolePermissions?.some(
            rolePermission => rolePermission.permissionId === id,
          ) || false;
          if (!associatedWithSatisfiedRole) permissionNotAssociatedWithRole = true;

          const isAdminPermission = adminSitePermissionIdsSet?.has(id);
          if (!adminRolePermissionsSatisfied && isAdminPermission) adminPermissionForNonAdminRole = true;
          return {
            id,
            category,
            action,
            name,
            description,
            // permissions that are not associated with a role have an asterisk
            associatedWithSatisfiedRole,
          };
        },
      );

      setHasPermissionNotAssociatedWithRole(permissionNotAssociatedWithRole);
      setNonAdminRoleWithAdminPermission(adminPermissionForNonAdminRole);
      return userPermissionRows;
    },
    [
      userPermissions,
      satisfiedRolePermissions,
      userPermissionsLoaded,
      satisfiedRolePermissionsLoaded,
      adminSitePermissionIdsSet,
      adminRolePermissionsSatisfied,
    ],
  );

  const CUSTOM_PERMISSION_MSG = "** Permission is not included within any of the user's active roles' permissions.";
  const NON_ADMIN_WITH_ADMIN_PERMISSION_MSG = '*** Admin role permission assigned to user without admin role.';

  return (
    <>
      <ReduxErrorHandler reduxStateName="roles" />
      <ReduxErrorHandler reduxStateName="permissions" />
      <CssBaseline />
      <Box className={classes.sectionContainer}>
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="h6" className={classes.sectionHeader}>Roles</Typography>
          <Button
            className={classes.button}
            disabled={!dataLoaded}
            onClick={() => setOpenRolesModal(true)}
            style={{ float: 'right', color: 'white' }}
          >
            Edit Roles
          </Button>
          {dataLoaded && (
            <EditRolesModal
              open={openRolesModal}
              onClose={() => {
                setOpenRolesModal(false);
                fetchPermissionsData();
              }}
              permissions={permissions}
              permissionCategories={permissionCategories}
              roles={roles}
              rolesLoading={rolesLoading}
              rolesUpdating={rolesUpdating}
              rolesUpdated={rolesUpdated}
              rolePermissions={rolePermissions}
              adminSitePermissionIdsSet={adminSitePermissionIdsSet}
              userPermissionsLoading={userPermissionsLoading}
              userPermissionsUpdating={userPermissionsUpdating}
              userPermissionsLoaded={userPermissionsLoaded}
            />
          )}
        </Box>
        {!dataLoaded ? (
          <Spinner backdrop />
        ) : (
          <>
            {roleTogglesData.map((roleToggle, index) => (
              <React.Fragment key={`row-${roleToggle.roleId}`}>
                {index % 2 === 0 && (
                  <Grid container className={classes.gridRowContainer}>
                    {/* Role toggle even indexes in left column. Odd indexes in right column */}
                    <Box className={classes.gridColumn}>
                      <RoleToggleSwitch
                        userId={userId}
                        userPermissionsLoaded={userPermissionsLoaded}
                        userPermissionsUpdating={userPermissionsUpdating}
                        roleToggle={roleToggle}
                      />
                    </Box>
                    {roleTogglesData[index + 1] && (
                      <Box className={classes.gridColumn}>
                        <RoleToggleSwitch
                          userId={userId}
                          currentUserId={currentUserId}
                          userPermissionsLoaded={userPermissionsLoaded}
                          userPermissionsUpdating={userPermissionsUpdating}
                          roleToggle={roleTogglesData[index + 1]}
                        />
                      </Box>
                    )}
                  </Grid>
                )}
                {index % 2 !== 0 && <Divider key={`divider-${roleToggle.roleId}`} className={classes.divider} />}
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
      <Box className={classes.sectionContainer}>
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="h6" className={classes.sectionHeader}>Permissions</Typography>
          <Button
            className={classes.button}
            disabled={!dataLoaded || userPermissionsLoading}
            onClick={() => setOpenPermissionsModal(true)}
            style={{ float: 'right', color: 'white' }}
          >
            Edit Permissions
          </Button>
          {dataLoaded && (
            <EditPermissionsModal
              userId={userId}
              open={openPermissionsModal}
              onClose={() => setOpenPermissionsModal(false)}
              currentUserId={currentUserId}
              permissions={permissions}
              permissionCategories={permissionCategories}
              roles={roles}
              rolePermissions={rolePermissions}
              userPermissionsData={userPermissionsData}
              adminSitePermissionIdsSet={adminSitePermissionIdsSet}
              userPermissionsLoading={userPermissionsLoading}
              userPermissionsUpdating={userPermissionsUpdating}
              userPermissionsLoaded={userPermissionsLoaded}
              CUSTOM_PERMISSION_MSG={CUSTOM_PERMISSION_MSG}
              adminRolePermissionsSatisfied={adminRolePermissionsSatisfied}
              NON_ADMIN_WITH_ADMIN_PERMISSION_MSG={NON_ADMIN_WITH_ADMIN_PERMISSION_MSG}
            />
          )}
        </Box>
        {!dataLoaded || userPermissionsLoading ? (
          <Spinner backdrop />
        ) : (
          <>
            <DataGrid
              className={classes.dataGrid}
              rows={userPermissionsData}
              columns={userPermissionsColumns}
              autoHeight
              autoPageSize
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              disablePagination
            />
            {/* Footer component shows message if there's any unsatisfied permission */}
            {hasPermissionNotAssociatedWithRole && (
              <Box className={classes.footerContainer}>
                <Typography className={classes.highlightBlue}>
                  {CUSTOM_PERMISSION_MSG}
                </Typography>
              </Box>
            )}
            {nonAdminRoleWithAdminPermission && (
              <Typography className={classes.highlightRed}>
                {NON_ADMIN_WITH_ADMIN_PERMISSION_MSG}
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
}

UserRolesAndPermissions.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    sectionContainer: PropTypes.string,
    sectionHeader: PropTypes.string,
    sectionHeaderContainer: PropTypes.string,
    gridRowContainer: PropTypes.string,
    gridColumn: PropTypes.string,
    divider: PropTypes.string,
    dataGrid: PropTypes.string,
    dataGridColumnHeader: PropTypes.string,
    highlightBlue: PropTypes.string,
    highlightRed: PropTypes.string,
    footerContainer: PropTypes.string,
  }).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    permissionType: { name: PropTypes.string },
    description: PropTypes.string,
  })),
  permissionCategories: PropTypes.arrayOf(PropTypes.string),
  permissionsLoaded: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  rolesLoading: PropTypes.bool,
  rolesLoaded: PropTypes.bool,
  rolesUpdating: PropTypes.bool,
  rolesUpdated: PropTypes.bool,
  rolePermissions: PropTypes.arrayOf(PropTypes.shape({
    roleId: PropTypes.number,
    permissionId: PropTypes.number,
  })),
  rolePermissionsLoaded: PropTypes.bool,
  satisfiedRolePermissions: PropTypes.arrayOf(PropTypes.shape({
    roleId: PropTypes.number,
    permissionId: PropTypes.number,
  })),
  satisfiedRolePermissionsLoaded: PropTypes.bool,
  adminSitePermissionIdsSet: PropTypes.instanceOf(Set),
  userPermissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    permissionType: { name: PropTypes.string },
    description: PropTypes.string,
  })),
  userPermissionsLoaded: PropTypes.bool,
  userPermissionsLoading: PropTypes.bool,
  userPermissionsUpdating: PropTypes.bool,
  userId: PropTypes.number.isRequired,
};

UserRolesAndPermissions.defaultProps = {
  permissions: [],
  permissionCategories: [],
  roles: [],
  rolePermissions: [],
  satisfiedRolePermissions: [],
  userPermissions: [],
  adminSitePermissionIdsSet: new Set([]),
  permissionsLoaded: false,
  rolesLoading: false,
  rolesLoaded: false,
  rolesUpdating: false,
  rolesUpdated: false,
  rolePermissionsLoaded: false,
  satisfiedRolePermissionsLoaded: false,
  userPermissionsLoaded: false,
  userPermissionsLoading: false,
  userPermissionsUpdating: false,
};

export default compose(
  connect(({ roles, permissions }) => ({
    permissions: permissions.permissions,
    permissionCategories: permissions.permissionCategories,
    permissionsLoaded: permissions.permissionsLoaded,
    roles: roles.roles,
    rolesLoading: roles.rolesLoading,
    rolesUpdating: roles.rolesUpdating,
    rolesLoaded: roles.rolesLoaded,
    rolePermissions: permissions.rolePermissions,
    rolePermissionsLoading: permissions.rolePermissionsLoading,
    rolePermissionsLoaded: permissions.rolePermissionsLoaded,
    satisfiedRolePermissions: permissions.satisfiedRolePermissions,
    satisfiedRolePermissionsLoaded: permissions.satisfiedRolePermissionsLoaded,
    adminSitePermissionIdsSet: permissions.adminSitePermissionIdsSet,
    userPermissions: permissions.userPermissions,
    userPermissionsLoading: permissions.userPermissionsLoading,
    userPermissionsLoaded: permissions.userPermissionsLoaded,
    userPermissionsUpdating: permissions.userPermissionsUpdating,
  })),
  withStyles(styles),
)(UserRolesAndPermissions);
