import { getAuthorizationHeader } from 'auth/auth-helpers';
import { BooleanString } from 'now-shared/enums/boolean-string';
import React, { Component } from 'react';

const WithCustomData = urls => function (WrapperComponent) {
  return class WrappedComponent extends Component {
      state = Object.keys(urls).reduce((acc, field) => {
        acc[field] = [];
        return acc;
      }, {});

      componentDidMount() {
        Object.keys(urls).forEach(field => {
          if (urls[field].includes(':id')) {
            const paramsId = this.props.location.hash.replace('#', '');
            urls[field] = urls[field].replace(':id', paramsId);
          }

          fetch(urls[field], {
            headers: {
              Authorization: getAuthorizationHeader(),
              Accept: 'application/json',
            },
          })
            .then(response => {
              if (response.status !== 200) {
                sessionStorage.setItem('failResponse', BooleanString.True);
              }
              sessionStorage.removeItem('failResponse');
              return response.json();
            })
            .then(response => {
              this.setState({ [field]: response });
            });
        });
      }

      render() {
        return sessionStorage.getItem('failResponse')
          ? null
          : <WrapperComponent {...this.props} {...this.state} />;
      }
  };
};

export default WithCustomData;
