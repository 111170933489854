import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import {
  styles,
  midGrey,
  lightGreen,
  lightRed,
} from './styles';
import {
  Modal,
  TextField,
  Button,
  Box,
  Divider,
  withStyles,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  updateUserPermissions,
} from 'store/actions/permissions-actions';
import { StyledTooltip } from 'now-frontend-shared/components/Tooltips/index';
import StyledCheckbox from 'now-frontend-shared/components/Checkboxes/StyledCheckbox';
import {
  camelCaseToUpperCaseSpaces,
  lowerCaseDashesToUpperCaseSpaces,
} from 'now-shared/helpers/text-helpers';
import {
  getRolePermissionIdsByTitle,
} from '../../helpers/roles-helpers';
import { toast } from 'react-toastify';

const EditPermissionsModal = ({
  classes,
  userId,
  open,
  onClose,
  currentUserId,
  permissions,
  permissionCategories,
  roles,
  rolePermissions,
  userPermissionsData,
  adminSitePermissionIdsSet,
  userPermissionsLoading,
  userPermissionsUpdating,
  CUSTOM_PERMISSION_MSG,
  adminRolePermissionsSatisfied,
  NON_ADMIN_WITH_ADMIN_PERMISSION_MSG,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [editedUserPermissions, setEditedUserPermissions] = useState([]);

  const handleClearState = () => {
    setSearchValue('');
    setCategoryFilter('');
    setRoleFilter('');
    setSelectAll(false);
    setEditedUserPermissions([]);
  };

  useEffect(() => {
    if (open) setEditedUserPermissions(userPermissionsData);
    if (!open) handleClearState();
  }, [userPermissionsData, open]);

  const roleFilterPermissionIds = useMemo(() => getRolePermissionIdsByTitle(roles, rolePermissions, roleFilter)
    || [], [roles, rolePermissions, roleFilter]);

  const userPermissionIds = useMemo(() => userPermissionsData?.map(permission => permission.id)
  || [], [userPermissionsData]);

  const categorizedPermissions = useMemo(() => {
    // Process permissions and add metadata
    const processedPermissions = permissions?.map(permission => {
      const currentUserPermission = editedUserPermissions?.find(
        userPermission => userPermission.id === permission.id,
      );
      const permissionToAdd = currentUserPermission && !userPermissionIds?.includes(permission.id);
      const permissionToRemove = userPermissionIds?.includes(permission.id) && !currentUserPermission;
      const notAssociatedWithSatisfiedRole = currentUserPermission && !currentUserPermission.associatedWithSatisfiedRole;
      const adminSitePermission = !!(currentUserPermission && adminSitePermissionIdsSet?.has(permission.id));

      return {
        ...permission,
        currentUserPermission,
        permissionToAdd,
        permissionToRemove,
        notAssociatedWithSatisfiedRole,
        adminSitePermission,
      };
    });

    // Categorize processed permissions
    return processedPermissions?.reduce((groups, permission) => (
      { ...groups, [permission.category]: [...(groups[permission.category] || []), permission] }
    ), {});
  }, [
    permissions,
    editedUserPermissions,
    userPermissionIds,
    adminSitePermissionIdsSet,
  ]);

  const categorizedAndFilteredPermissions = useMemo(() => {
    let localCategorizedAndProcessedPermissions = { ...categorizedPermissions };

    // Filter by categoryFilter
    if (categoryFilter) {
      localCategorizedAndProcessedPermissions = Object.keys(localCategorizedAndProcessedPermissions)
        .reduce((acc, categoryName) => {
          if (categoryName === categoryFilter) {
            return { ...acc, [categoryName]: localCategorizedAndProcessedPermissions[categoryName] };
          }
          return acc;
        }, {});
    }

    // Filter by roleFilter
    if (roleFilter) {
      localCategorizedAndProcessedPermissions = Object.keys(localCategorizedAndProcessedPermissions)
        .reduce((acc, categoryName) => {
          const filteredCategory = localCategorizedAndProcessedPermissions[categoryName]
            .filter(permission => roleFilterPermissionIds.includes(permission.id));
          return filteredCategory.length > 0 ? { ...acc, [categoryName]: filteredCategory } : acc;
        }, {});
    }

    // Filter by search value
    localCategorizedAndProcessedPermissions = Object.keys(localCategorizedAndProcessedPermissions)
      .reduce((acc, categoryName) => {
        const filteredCategory = localCategorizedAndProcessedPermissions[categoryName]
          .filter(permission => permission.permissionType.name.includes(searchValue));
        return filteredCategory.length > 0 ? { ...acc, [categoryName]: filteredCategory } : acc;
      }, {});

    return localCategorizedAndProcessedPermissions;
  }, [
    categorizedPermissions,
    categoryFilter,
    roleFilter,
    roleFilterPermissionIds,
    searchValue,
  ]);

  const selectedPermissionNotAssociatedWithRole = Object.values(categorizedAndFilteredPermissions || {})
    .flat().some(permission => permission.notAssociatedWithSatisfiedRole);

  const selectedAdminPermissionForNonAdmin = Object.values(categorizedAndFilteredPermissions || {})
    .flat().some(permission => !adminRolePermissionsSatisfied && permission.adminSitePermission);

  const handleSearchChange = event => setSearchValue(event.target.value);
  const handleCategoryFilterChange = event => {
    setCategoryFilter(event.target.value === 'all' ? '' : event.target.value);
  };
  const handleRoleFilterChange = event => {
    setRoleFilter(event.target.value === 'all' ? '' : event.target.value);
  };

  const handleSelectAll = () => {
    const currentPermissionsDisplayed = Object.values(categorizedAndFilteredPermissions || {}).flat();
    if (!selectAll) {
      const missingSelectedPermissions = currentPermissionsDisplayed?.filter(
        permission => !editedUserPermissions?.find(userPermission => userPermission.id === permission.id),
      );
      setEditedUserPermissions([...editedUserPermissions, ...missingSelectedPermissions]);
      setSelectAll(true);
    } else {
      const selectedPermissionsTrimmed = editedUserPermissions?.filter(
        permission => !currentPermissionsDisplayed?.find(
          filteredPermission => filteredPermission.id === permission.id,
        ),
      );
      setEditedUserPermissions(selectedPermissionsTrimmed);
      setSelectAll(false);
    }
  };

  const updateCheckBox = permission => {
    if (editedUserPermissions?.find(userPermission => userPermission.id === permission.id)) {
      if (userId === currentUserId && adminRolePermissionsSatisfied && permission.adminSitePermission) {
        toast.error('Admins cannot remove their own admin permissions.');
      } else {
        // uncheck box
        setEditedUserPermissions(editedUserPermissions?.filter(userPermission => userPermission.id !== permission.id));
      }
    } else {
      // check box
      const permissionWithMetadata = userPermissionsData?.find(userPermission => userPermission.id === permission.id)
        || permission;
      setEditedUserPermissions([...editedUserPermissions, permissionWithMetadata]);
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (!userPermissionsUpdating && !userPermissionsLoading) {
        const permissionIdsToAdd = Object.values(categorizedPermissions || {}).flat()
          .filter(permission => permission.permissionToAdd).map(permission => permission.id) || [];
        const permissionIdsToRemove = Object.values(categorizedPermissions || {}).flat()
          .filter(permission => permission.permissionToRemove).map(permission => permission.id) || [];

        // Update userPermissions and refetch them
        await dispatch(updateUserPermissions({
          userId,
          permissionIdsToAdd,
          permissionIdsToRemove,
        }));
        handleClearState();
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const categoryOptions = permissionCategories?.map(
    categoryName => ({ value: categoryName, label: lowerCaseDashesToUpperCaseSpaces(categoryName) }),
  );

  const roleOptions = roles?.map(
    role => ({ value: role.title, label: camelCaseToUpperCaseSpaces(role.title) }),
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={classes.modalContainer}>
        <div className={classes.sectionHeaderWrapper}>
          <Typography variant="h6" className={classes.sectionHeader}>Edit Permissions</Typography>
          <IconButton onClick={onClose} style={{ margin: 0, padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.searchAndFilterWrapper}>
          <TextField
            className={classes.textField}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: midGrey }} />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
          <Button className={classes.selectorFilter}>
            <Select
              value={categoryFilter}
              onChange={handleCategoryFilterChange}
              disableUnderline
              displayEmpty
              className={classes.selectInput}
            >
              <MenuItem disabled value="">Filter By Category</MenuItem>
              <MenuItem value="all">All</MenuItem>
              {categoryOptions.map(option => (
                <MenuItem key={`categoryFilter-${option.value}`} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </Button>
          <Button className={classes.selectorFilter}>
            <Select
              value={roleFilter}
              onChange={handleRoleFilterChange}
              disableUnderline
              displayEmpty
              className={classes.selectInput}
            >
              <MenuItem disabled value="">Filter By Role</MenuItem>
              <MenuItem value="all">All</MenuItem>
              {roleOptions.map(option => (
                <MenuItem key={`roleFilter-${option.value}`} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </Button>
          <Box className={classes.selectorFilter}>
            <StyledCheckbox
              data-cy="selectAll"
              checked={selectAll}
              onChange={() => handleSelectAll()}
            />
            <Typography style={{ whiteSpace: 'nowrap' }}>Select All</Typography>
          </Box>
        </div>
        <div className={classes.categorySectionsContainer}>
          {Object.entries(categorizedAndFilteredPermissions || {}).map(([categoryName, categoryPermissions]) => (
            <div key={`${categoryName}Section`} className={classes.categorySectionWrapper}>
              <Divider key={`${categoryName}Divider`} className={classes.categoryDivider} />
              <Typography variant="h6" className={classes.categorySectionHeader}>
                {categoryName}
              </Typography>
              <Box className={classes.permissionsContainer}>
                {categoryPermissions.map(permission => (
                  <StyledTooltip
                    data-cy={`tooltip-${permission.id}`}
                    key={`tooltip-${permission.id}`}
                    title={permission.description}
                  >
                    <Box className={classes.permissionWrapper}>
                      <StyledCheckbox
                        data-cy={`checkbox-${permission.id}`}
                        checked={!!permission.currentUserPermission}
                        onChange={() => updateCheckBox(permission)}
                        checkedColor={permission.permissionToAdd && lightGreen}
                        boxColor={permission.permissionToRemove && lightRed}
                      />
                      <Box display="flex" alignItems="center">
                        <Typography>{permission.permissionType.name}</Typography>
                        {permission.notAssociatedWithSatisfiedRole && (
                          <Typography className={classes.highlightBlue}> ** </Typography>
                        )}
                        {!adminRolePermissionsSatisfied && permission.adminSitePermission && (
                          <Typography className={classes.highlightRed}> *** </Typography>
                        )}
                      </Box>
                    </Box>
                  </StyledTooltip>
                ))}
              </Box>
            </div>
          ))}
          <Divider key="bottomDivider" className={classes.categoryDivider} />
        </div>
        <div className={classes.footerWrapper}>
          <div className={classes.messagesContainer}>
            {selectedPermissionNotAssociatedWithRole && (
              <Typography className={classes.highlightBlue}>
                {CUSTOM_PERMISSION_MSG}
              </Typography>
            )}
            {selectedAdminPermissionForNonAdmin && (
              <Typography className={classes.highlightRed}>
                {NON_ADMIN_WITH_ADMIN_PERMISSION_MSG}
              </Typography>
            )}
          </div>
          <Button
            className={classes.saveButton}
            disabled={userPermissionsUpdating || userPermissionsLoading}
            onClick={() => handleSaveChanges()}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditPermissionsModal.propTypes = {
  classes: PropTypes.shape({
    modalContainer: PropTypes.string,
    sectionHeaderWrapper: PropTypes.string,
    sectionHeader: PropTypes.string,
    categorySectionHeader: PropTypes.string,
    searchAndFilterWrapper: PropTypes.string,
    categorySectionWrapper: PropTypes.string,
    textField: PropTypes.string,
    selectorFilter: PropTypes.string,
    selectInput: PropTypes.string,
    categorySectionsContainer: PropTypes.string,
    categoryDivider: PropTypes.string,
    permissionsContainer: PropTypes.string,
    permissionWrapper: PropTypes.string,
    footerWrapper: PropTypes.string,
    messagesContainer: PropTypes.string,
    highlightBlue: PropTypes.string,
    highlightRed: PropTypes.string,
    saveButton: PropTypes.string,
  }).isRequired,
  CUSTOM_PERMISSION_MSG: PropTypes.string.isRequired,
  adminRolePermissionsSatisfied: PropTypes.bool.isRequired,
  NON_ADMIN_WITH_ADMIN_PERMISSION_MSG: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    permissionType: { name: PropTypes.string },
    description: PropTypes.string,
  })),
  permissionCategories: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  })),
  rolePermissions: PropTypes.arrayOf(PropTypes.shape({
    roleId: PropTypes.number,
    permissionId: PropTypes.number,
  })),
  userPermissionsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    category: PropTypes.string,
    action: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    associatedWithSatisfiedRole: PropTypes.bool,
  })),
  adminSitePermissionIdsSet: PropTypes.instanceOf(Set),
  userPermissionsLoading: PropTypes.bool,
  userPermissionsUpdating: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

EditPermissionsModal.defaultProps = {
  permissions: [],
  permissionCategories: [],
  roles: [],
  rolePermissions: [],
  userPermissionsData: [],
  adminSitePermissionIdsSet: new Set(),
  userPermissionsLoading: false,
  userPermissionsUpdating: false,
};

export default compose(
  withStyles(styles),
)(EditPermissionsModal);
