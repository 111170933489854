import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

// actions
import {
  getCurrentProperty,
  getStates,
  getBasins,
  getCounties,
  getLandingZones,
  getPreSignedUrls,
  updateCurrentProperty,
} from "../actions/edit-listing-actions";

// api methods
import Api from "api/properties";

import { getAuthorizationHeader } from "../../auth/auth-helpers";
import queryString from "query-string";

function* ensureGetStates() {
  try {
    const { data } = yield call(Api.getStates);
    yield put({ type: getStates.success, payload: data });
  } catch (err) {
    yield put({ type: getStates.failure, err });
  }
}

function* watchGetState() {
  yield takeLatest(getStates.type, ensureGetStates);
  yield take(getStates.success);
}

function* ensureGetBasins() {
  const stateId = yield select(({ editListing }) => editListing.stateId);
  try {
    const { data } = yield call(Api.getBasins, {
      url: `/states/${stateId}/basins`,
    });
    yield put({ type: getBasins.success, payload: data });
  } catch (err) {
    yield put({ type: getBasins.failure, err });
  }
}

function* watchGetBasins() {
  yield takeLatest(getBasins.type, ensureGetBasins);
  yield take(getBasins.success);
}

function* ensureGetCounties() {
  const stateId = yield select(({ editListing }) => editListing.stateId);
  const basinId = yield select(({ editListing }) => editListing.basinId);
  try {
    const { data } = yield call(Api.getCounties, {
      url: `/states/${stateId}/basins/${basinId}`,
    });
    yield put({ type: getCounties.success, payload: data });
  } catch (err) {
    yield put({ type: getCounties.failure, err });
  }
}

function* watchGetCounties() {
  yield takeLatest(getCounties.type, ensureGetCounties);
  yield take(getCounties.success);
}

function* ensureGetLandingZones() {
  const stateId = yield select(({ editListing }) => editListing.stateId);
  const basinId = yield select(({ editListing }) => editListing.basinId);
  const countyId = yield select(({ editListing }) => editListing.countyId);
  try {
    const { data } = yield call(Api.getLandingZones, {
      url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
    });
    yield put({ type: getLandingZones.success, payload: data });
  } catch (err) {
    yield put({ type: getLandingZones.failure, err });
  }
}

function* watchGetLandingZones() {
  yield takeLatest(getLandingZones.type, ensureGetLandingZones);
  yield take(getLandingZones.success);
}

function* ensureGetCurrentProperty({ payload }) {
  try {
    const {
      data: {
        state: { id: stateId },
        basin: { id: basinId },
        county: { id: countyId },
        ...restData
      },
    } = yield call(Api.getCurrentProperty, {
      url: `/properties/${payload}`,
      headers: { Authorization: getAuthorizationHeader() },
    });

    const {
      states: { data: statesData },
      basins: { data: basinsData },
      counties: { data: countiesData },
      landingZones: { data: landingZonesData },
    } = yield all({
      states: call(Api.getStates),
      basins: call(Api.getBasins, { url: `/states/${stateId}/basins` }),
      counties: call(Api.getCounties, {
        url: `/states/${stateId}/basins/${basinId}`,
      }),
      landingZones: call(Api.getLandingZones, {
        url: `/states/${stateId}/basins/${basinId}/counties/${countyId}`,
      }),
    });

    const refactoredData = {
      state: stateId,
      basin: basinId,
      county: countyId,
      ...restData,
    };

    yield put({
      type: getCurrentProperty.success,
      payload: {
        data: refactoredData,
        states: statesData,
        basins: basinsData,
        counties: countiesData,
        landingZones: landingZonesData,
        stateId,
        basinId,
        countyId,
      },
    });
  } catch (err) {
    yield put({ type: getCurrentProperty.failure, err });
  }
}

function* ensureUpdateCurrentProperty({ payload }) {
  try {
    const { restrictedCompanies, wells, ...transformValues } = payload;
    const formatRestrictedCompanyIds = (restrictedCompanies) =>
      restrictedCompanies?.map((c) => c.id);

    const AWSData = yield select(({ editListing }) => editListing.AWSData);

    const updates = {
      ...transformValues,
      documents: AWSData,
      restrictedCompanyIds: formatRestrictedCompanyIds(restrictedCompanies),
      wells: wells?.map((value) => value ?? {}),
    };

    const url = `${process.env.REACT_APP_API_URL}/properties${
      payload.isDraft ? "/draft" : ""
    }/${payload.id}`;

    yield call(Api.updateCurrentProperty, {
      url,
      headers: { Authorization: getAuthorizationHeader() },
      data: JSON.stringify(updates),
    });
    payload.notify("Property updated");
    payload.redirect();
  } catch (err) {
    yield put({ type: updateCurrentProperty.failure, err });
  }
}

function* watchGetCurrentProperty() {
  yield takeLatest(getCurrentProperty.type, ensureGetCurrentProperty);
  yield take(getCurrentProperty.success);
}

function* watchUpdateCurrentProperty() {
  yield takeLatest(updateCurrentProperty.type, ensureUpdateCurrentProperty);
  yield take(updateCurrentProperty.success);
}

function* ensureGetPreSignedUrls({ payload }) {
  const { filesName, filesMd5, filesSize, resolve, reject } = payload;
  try {
    const params = queryString.stringify(
      {
        filesName,
        filesMd5,
        filesSize,
      },
      { arrayFormat: "bracket" }
    );
    const { data } = yield call(Api.getPreSignedUrls, {
      url: `/get-s3-pre-signed-urls-for-listing-uploads?${params}`,
      headers: { Authorization: getAuthorizationHeader() },
    });
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* editListingSagas() {
  yield all([
    fork(watchGetCurrentProperty),
    fork(watchUpdateCurrentProperty),
    fork(watchGetState),
    fork(watchGetBasins),
    fork(watchGetCounties),
    fork(watchGetLandingZones),
    fork(watchGetPreSignedUrls),
  ]);
}
