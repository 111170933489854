import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getAllPermissions = asyncAction('permissions/GET_ALL_PERMISSIONS');
export const getRolePermissions = asyncAction('roles/GET_ROLE_PERMISSIONS');
export const getUserPermissions = asyncAction('permissions/GET_USER_PERMISSIONS');
export const addPermissionsByRole = asyncAction('permissions/ADD_PERMISSIONS_BY_ROLE');
export const removePermissionsByRole = asyncAction('permissions/REMOVE_PERMISSIONS_BY_ROLE');
export const updateUserPermissions = asyncAction('permissions/UPDATE_USER_PERMISSIONS');

// sync actions
export const checkSatisfiedRolePermissions = action('roles/CHECK_SATISFIED_ROLE_PERMISSIONS');
export const updateSatisfiedRolePermissions = action('roles/UPDATE_SATISFIED_ROLE_PERMISSIONS');
export const clearState = action('permissions/CLEAR_STATE');
