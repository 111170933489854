import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import LoginForm from './customLogin';

const styles = {
  main: { background: '#CECECE' },
  avatar: {
    background: 'whitesmoke',
    margin: '0',
  },
  icon: { display: 'none' },
};

const CustomLoginForm = withStyles({
  button: { background: '#F15922' },
})(LoginForm);

const CustomLogin = props => (
  <Login backgroundImage="" {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default withStyles(styles)(CustomLogin);
