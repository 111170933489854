import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { useDispatch } from 'react-redux';
import {
  addPermissionsByRole,
  removePermissionsByRole,
} from 'store/actions/permissions-actions';
import { RoleDomain } from '../../helpers/roles-helpers';

const RoleToggleSwitch = ({
  userId,
  currentUserId,
  userPermissionsUpdating,
  roleToggle,
}) => {
  const { roleId, defaultValue, type } = roleToggle;
  const [checked, setChecked] = useState(defaultValue);

  const dispatch = useDispatch();
  const adminSiteRoleDomain = type === RoleDomain.AdminSite;

  useEffect(() => {
    setChecked(defaultValue);
  }, [roleToggle]);

  const handleToggleUpdate = async toggleValue => {
    // Update user permissions, fetch userPermissions, and update satisfiedRolePermissions state
    if (toggleValue) await dispatch(addPermissionsByRole({ userId, roleId }));
    if (!toggleValue) await dispatch(removePermissionsByRole({ userId, roleId }));
  };

  return (
    <SwitchButton
      checked={checked}
      disabled={userPermissionsUpdating || (currentUserId === userId && adminSiteRoleDomain && checked)}
      label={roleToggle.titleDisplayed}
      name={roleToggle.title}
      onChangeNotificationsValue={async ({ value }) => handleToggleUpdate(value)}
    />
  );
};

RoleToggleSwitch.propTypes = {
  userId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userPermissionsUpdating: PropTypes.bool.isRequired,
  roleToggle: PropTypes.shape({
    roleId: PropTypes.number.isRequired,
    defaultValue: PropTypes.bool,
    title: PropTypes.string.isRequired,
    titleDisplayed: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

RoleToggleSwitch.defaultProps = {
  roleToggle: {
    defaultValue: false,
  },

};

export default RoleToggleSwitch;
