import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Underline from '@tiptap/extension-underline';
import Text from '@tiptap/extension-text';
import { Node } from '@tiptap/core';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

export const ExtensionName = {
  Company: 'company',
  Date: 'date',
  AccountManager: 'accountManager',
  BusinessAddressLine1: 'businessAddressLine1',
  BusinessAddressLine2: 'businessAddressLine2',
};

const extensionsDefaultData = {
  [ExtensionName.Company]: {
    company: undefined,
  },
  [ExtensionName.AccountManager]: {
    accountManager: undefined,
  },
  [ExtensionName.BusinessAddressLine1]: {
    businessAddressLine1: undefined,
  },
  [ExtensionName.BusinessAddressLine2]: {
    businessAddressLine2: undefined,
  },
};

const CompanyNode = Node.create({
  name: ExtensionName.Company,
  group: 'inline',
  inline: true,
  atom: true,

  addStorage() {
    return extensionsDefaultData[ExtensionName.Company];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      HTMLAttributes,
      this.storage.company?.fullLegalCompanyName || '[COMPANY]',
    ];
  },
});

const DateNode = Node.create({
  name: ExtensionName.Date,
  group: 'inline',
  inline: true,
  atom: true,

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      HTMLAttributes,
      moment().format('YYYY-MM-DD'),
    ];
  },
});

const BusinessAddressLine1Node = Node.create({
  name: ExtensionName.BusinessAddressLine1,
  group: 'inline',
  inline: true,
  atom: true,

  addStorage() {
    return extensionsDefaultData[ExtensionName.BusinessAddressLine1];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      HTMLAttributes,
      this.storage.businessAddressLine1 || '[BUSINESS_ADDRESS_LINE_1]',
    ];
  },
});

const BusinessAddressLine2Node = Node.create({
  name: ExtensionName.BusinessAddressLine2,
  group: 'inline',
  inline: true,
  atom: true,

  addStorage() {
    return extensionsDefaultData[ExtensionName.BusinessAddressLine2];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      HTMLAttributes,
      this.storage.businessAddressLine2 || '[BUSINESS_ADDRESS_LINE_2]',
    ];
  },
});

const AccountManagerNode = Node.create({
  name: ExtensionName.AccountManager,
  group: 'inline',
  inline: true,
  atom: true,

  addStorage() {
    return extensionsDefaultData[ExtensionName.AccountManager];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      HTMLAttributes,
      this.storage.accountManager || '[ACCOUNT_MANAGER]',
    ];
  },
});

const MenuBar = ({ editor, onSave, onLoad }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      {onSave && (
        <button
          type="button"
          onClick={() => {
            const json = editor.getJSON();
            onSave(json);
          }}
        >
          save
        </button>
      )}
      {onLoad && (
        <button
          type="button"
          onClick={onLoad}
        >
          load
        </button>
      )}
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
      >
        left
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
      >
        center
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
      >
        right
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
        className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
      >
        justify
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().unsetTextAlign().run()}
      >
        unsetTextAlign
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        bold
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        italic
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        strike
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        code
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
      >
        clear marks
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().clearNodes().run()}
      >
        clear nodes
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        paragraph
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        h1
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        h2
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h3
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h4
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        h5
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >
        h6
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        bullet list
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        ordered list
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        code block
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        blockquote
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        horizontal rule
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setHardBreak().run()}
      >
        hard break
      </button>
      <button
        type="button"
        onClick={
          () => editor.commands.insertContent({
            type: 'date',
          })
        }
      >
        date
      </button>
      <button
        type="button"
        onClick={
          () => editor.commands.insertContent({
            type: 'company',
          })
        }
      >
        company
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().undo().run()}
      >
        undo
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().redo().run()}
      >
        redo
      </button>
    </>
  );
};

MenuBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object,
  onSave: PropTypes.func,
  onLoad: PropTypes.func,
};

MenuBar.defaultProps = {
  editor: undefined,
  onSave: undefined,
  onLoad: undefined,
};

const TipTap = ({
  extensionData,
  content,
  editable,
  onRendered,
  onSave,
  onLoad,
}) => {
  const emptyContentJson = '{ "type": "doc", "content": [] }';
  const defaultContentJson = '{ "type": "doc", "content": [ { "type": "paragraph", "attrs": { "textAlign": "left" }, "content": [ { "type": "text", "text": "Edit your document here!" } ] } ] }';

  const editor = useEditor({
    editable,
    extensions: [
      StarterKit,
      Document,
      OrderedList,
      ListItem,
      Paragraph,
      Underline,
      Text,
      DateNode,
      CompanyNode,
      AccountManagerNode,
      BusinessAddressLine1Node,
      BusinessAddressLine2Node,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      FontFamily,
      // TODO [UI] Add color to 'Autofilled' TipTap data
      Color,
    ],
    content,
  });

  const [inputJson, setInputJson] = React.useState(defaultContentJson);

  React.useEffect(
    () => {
      if (editor) {
        editor.commands.setContent(content);
        if (onRendered) {
          onRendered(editor);
        }
      }
    },
    [editor, content, onRendered],
  );

  React.useEffect(
    () => {
      if (editor) {
        editor.setEditable(editable);
      }
    },
    [editor, editable],
  );

  React.useEffect(
    () => {
      if (editor) {
        Object.entries(extensionsDefaultData).forEach(([extensionName, defaultData]) => {
          Object.entries(defaultData).forEach(([propName, defaultValue]) => {
            editor.storage[extensionName][propName] = extensionData?.[extensionName]?.[propName] ?? defaultValue;
          });
        });
        // re-render content
        const cachedContent = editor.getJSON();
        editor.commands.clearContent();
        editor.commands.setContent(cachedContent);
        if (onRendered) {
          onRendered(editor);
        }
      }
    },
    [extensionData, editor, onRendered],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={1}
    >
      {editable && (
        <>
          <Box
            display="flex"
          >
            <MenuBar
              editor={editor}
              onSave={onSave}
              onLoad={onLoad}
            />
          </Box>
          <Box><b>TipTap Document Editable Content</b></Box>
        </>
      )}
      <EditorContent
        editor={editor}
      />
      {editable && (
        <>
          <Box><b>TipTap Document Output as JSON</b></Box>
          <button
            type="button"
            onClick={() => navigator.clipboard.writeText(JSON.stringify(editor?.getJSON(), null, 2))}
            style={{ width: '200px' }}
          >
            Copy to Clipboard
          </button>
          <Box>
            <pre>{JSON.stringify(editor?.getJSON(), null, 2)}</pre>
          </Box>
          <Box><b>TipTap Document Input JSON</b></Box>
          <Box>
            <button
              type="button"
              onClick={() => setInputJson('')}
              style={{ width: '200px' }}
            >
              Clear
            </button>
            <button
              type="button"
              onClick={() => setInputJson(defaultContentJson)}
              style={{ width: '200px' }}
            >
              Default
            </button>
            <button
              type="button"
              onClick={() => editor?.commands.setContent(JSON.parse(inputJson || emptyContentJson))}
              style={{ width: '200px' }}
            >
              Load
            </button>
            <TextField
              fullWidth
              multiline
              value={inputJson}
              onChange={e => setInputJson(e.target.value)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

TipTap.propTypes = {
  /**
   * TODO: [TYPE] specify type as object or string
   */
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  extensionData: PropTypes.shape({
    [ExtensionName.Company]: PropTypes.shape({
      company: PropTypes.shape({
        fullLegalCompanyName: PropTypes.string.isRequired,
      }),
      accountManager: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      businessAddressLine1: PropTypes.string,
      businessAddressLine2: PropTypes.string,
    }),
  }),
  editable: PropTypes.bool,
  onRendered: PropTypes.func,
  onSave: PropTypes.func,
  onLoad: PropTypes.func,
};

TipTap.defaultProps = {
  content: '',
  extensionData: undefined,
  editable: false,
  onRendered: undefined,
  onSave: undefined,
  onLoad: undefined,
};

export default TipTap;
