import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';

import {
  Filter,
  List,
  Datagrid,
  SelectInput,
  TextInput,
  AutocompleteInput,
  FunctionField,
  useNotify,
  useRefresh,
  Button,
  TopToolbar,
  useListContext,
  useDataProvider,
  SelectArrayInput,
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button as MuiButton,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { generateFetchOptions } from '../helpers/fetch';

const useStyles = makeStyles({
  label: {
    paddingLeft: 0,
  },
  selectArrayInputContainer: {
    width: '100%',
    '& RaSelectArrayInputroot': {
      width: '100%',
    },
  },
});

const GeographicalFiltersContext = createContext();

export const GeographicalFiltersProvider = ({ children }) => {
  const [states, setStates] = useState([]);
  const [basins, setBasins] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/states`, generateFetchOptions('GET'));
      const data = await result.json();
      const transformedData = data.map(state => ({ id: state.id, name: state.title }));
      setStates(transformedData);
    };
    const getBasins = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/basins`, generateFetchOptions('GET'));
      const data = await result.json();
      const transformedData = data.map(basin => ({ id: basin.id, name: basin.title }));
      setBasins(transformedData);
    };
    const getCounties = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/counties`, generateFetchOptions('GET'));
      const data = await result.json();
      const transformedData = data.map(county => ({ id: county.id, name: county.title }));
      setCounties(transformedData);
    };
    getStates();
    getBasins();
    getCounties();
  }, []);

  return (
    <GeographicalFiltersContext.Provider value={{ states, basins, counties }}>
      {children}
    </GeographicalFiltersContext.Provider>
  );
};

export const useGeographicalFilters = () => useContext(GeographicalFiltersContext);

const CustomActions = ({ filterValues }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [landingZones, setLandingZones] = useState([]);
  const { states, basins, counties } = useGeographicalFilters();

  useEffect(() => {
    setInitialValues({
      stateId: filterValues.stateId,
      basinId: filterValues.basinId,
      countyId: filterValues.countyId 
        ? (Array.isArray(filterValues.countyId) ? filterValues.countyId : [filterValues.countyId])
        : [],
    });
  }, [filterValues]);

  useEffect(() => {
    const getLandingZones = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/landing-zones`, generateFetchOptions('GET'));
      const data = await result.json();
      const transformedData = data.map(landingZone => ({ id: landingZone.id, name: landingZone.title }));
      setLandingZones(transformedData);
    };
    getLandingZones();
  }, []);

  const handleClick = () => {
    setShowCreateDialog(true);
  };

  const handleClose = () => {
    setShowCreateDialog(false);
  };

  const handleUpdateClick = async () => {
    setShowUpdateDialog(true);

    const result = await fetch(`${process.env.REACT_APP_API_URL}/landing-zones`, generateFetchOptions('GET'));
    const data = await result.json();
    const transformedData = data.map(landingZone => ({ id: landingZone.id, name: landingZone.title }));
    setLandingZones(transformedData);
  };

  const handleUpdateClose = () => {
    setShowUpdateDialog(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setShowDeleteDialog(false);
  };

  const onSubmit = values => {
    dataProvider
      .create('landing-zones', { data: values })
      .then(() => {
        notify('ra.notification.created', 'info', { smart_count: 1 });
      })
      .catch(error => {
        notify('ra.notification.http_error', 'warning');
        console.error(error);
      });

    handleClose();
    setTimeout(refresh, 2000);
  };

  const onUpdate = values => {
    const updatedValues = {
      ...values,
      // remove the array for stateId and basinId when the ability to add multiple states and basins is added
      stateId: [values.stateId],
      basinId: [values.basinId],
      countyId: values.countyId,
    };
    dataProvider
      .update('landing-zones', { id: updatedValues.landingZoneId, data: updatedValues })
      .then(() => {
        notify('ra.notification.updated', 'info', { smart_count: 1 });
      })
      .catch(error => {
        notify('ra.notification.http_error', 'warning');
        console.error(error);
      });

    handleUpdateClose();
    setTimeout(refresh, 2000);
  };

  const onDelete = values => {
    dataProvider
      .delete('landing-zones', { id: values.landingZoneId })
      .then(() => {
        notify('ra.notification.deleted', 'info', { smart_count: 1 });
      })
      .catch(error => {
        if (error.status === 409) {
          notify('This Landing Zone is associated with a Listing. Deletion is not permitted.', 'warning');
        } else {
          notify('ra.notification.http_error', 'warning');
          console.error(error);
        }
      });

    handleDeleteClose();
    setTimeout(refresh, 2000);
  };

  return (
    <>
      <GeographicalFiltersProvider>
        <Button
          onClick={handleClick}
          label="Create New Landing Zone"
          variant="contained"
          color="primary"
          classes={classes}
          style={{ width: 'fit-content', marginLeft: 'auto', marginRight: '5px' }}
        />
        <Button
          onClick={handleUpdateClick}
          label="Update Landing Zones"
          variant="contained"
          color="primary"
          classes={classes}
          style={{ width: 'fit-content', marginLeft: '5px', marginRight: 'auto' }}
        />
        <Button
          onClick={handleDeleteClick}
          label="Delete Landing Zones"
          variant="contained"
          classes={classes}
          style={{
            width: 'fit-content',
            marginLeft: '5px',
            marginRight: 'auto',
            backgroundColor: '#f44336',
            color: 'white',
          }}
        />

        <Dialog
          open={showCreateDialog}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Landing Zone</DialogTitle>
          <DialogContent>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="landingZoneTitle">
                      {props => (
                        <div>
                          <TextInput label="Landing Zone" source="landingZoneId" choices={landingZones} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="stateId">
                      {props => (
                        <div>
                          <SelectInput label="State" source="stateId" choices={states} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="basinId">
                      {props => (
                        <div>
                          <SelectInput label="Basin" source="basinId" choices={basins} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="countyId">
                      {props => (
                        <div>
                          <SelectArrayInput className={classes.selectArrayInputContainer} label="County" source="countyId" choices={counties} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <MuiButton type="submit" disabled={submitting || pristine}>
                      Submit
                    </MuiButton>
                    <MuiButton
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </MuiButton>
                  </div>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={showUpdateDialog}
          onClose={handleUpdateClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Update Landing Zones</DialogTitle>
          <DialogContent>
            <Form
              onSubmit={onUpdate}
              initialValues={initialValues}
              render={({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="landingZoneId">
                      {props => (
                        <div>
                          <AutocompleteInput label="Landing Zone" source="landingZoneId" choices={landingZones} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="stateId">
                      {props => (
                        <div>
                          <SelectInput label="State" source="stateId" choices={states} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="basinId">
                      {props => (
                        <div>
                          <SelectInput label="Basin" source="basinId" choices={basins} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="countyId">
                      {props => (
                        <div>
                          <SelectArrayInput className={classes.selectArrayInputContainer} label="County" source="countyId" choices={counties} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <MuiButton type="submit" disabled={submitting || pristine}>
                      Submit
                    </MuiButton>
                    <MuiButton
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </MuiButton>
                  </div>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={showDeleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Delete Landing Zone</DialogTitle>
          <DialogContent>
            <Form
              onSubmit={onDelete}
              render={({ handleSubmit, form, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="landingZoneId">
                      {props => (
                        <div>
                          <AutocompleteInput label="Landing Zone" source="landingZoneId" choices={landingZones} {...props} />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <MuiButton type="submit" disabled={submitting} style={{ color: '#f44336' }}>
                      Delete
                    </MuiButton>
                    <MuiButton
                      type="button"
                      onClick={handleDeleteClose}
                      disabled={submitting}
                    >
                      Cancel
                    </MuiButton>
                  </div>
                </form>
              )}
            />
          </DialogContent>
        </Dialog>
      </GeographicalFiltersProvider>
    </>
  );
};

const LandingZonesFilter = props => {
  const { states, basins, counties } = useGeographicalFilters();

  return (
    <GeographicalFiltersProvider>
      <Filter {...props}>
        <SelectInput
          label="State"
          allowEmpty
          emptyValue=""
          source="stateId"
          alwaysOn
          choices={states}
        />
        <SelectInput
          label="Basin"
          allowEmpty
          emptyValue=""
          source="basinId"
          alwaysOn
          choices={basins}
        />
        <SelectInput
          label="County"
          allowEmpty
          emptyValue=""
          source="countyId"
          alwaysOn
          choices={counties}
        />
        <CustomActions />
      </Filter>
    </GeographicalFiltersProvider>
  );
};

export default LandingZonesFilter;

const CustomActionsAndFilters = () => {
  const { filterValues } = useListContext();

  return (
    <TopToolbar>
      <CustomActions filterValues={filterValues} />
    </TopToolbar>
  );
};

export const StateBasinCountyLandingZoneList = props => (
  <GeographicalFiltersProvider>
    <List
      {...props}
      filters={<LandingZonesFilter />}
      actions={<CustomActionsAndFilters />}
      // temporarily remove ability to delete landing zones
      bulkActionButtons={false}
    >
      <Datagrid>
        <FunctionField label="Landing Zone" render={record => `${record.landingZone.title}`} />
        <FunctionField label="Basin" render={record => `${record.basin.title}`} />
        <FunctionField label="State" render={record => record.state.title} />
        <FunctionField label="County" render={record => `${record.county.title}`} />
      </Datagrid>
    </List>
  </GeographicalFiltersProvider>
);
