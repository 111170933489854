import React, { useState } from 'react';
import {
  Button,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { getAuthorizationHeader } from 'auth/auth-helpers';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '500px',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
}));

const CsvImportButton = ({ refresh }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const classes = useStyles();
  const handleImportClick = () => {
    setOpenModal(true);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileSubmit = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    axios
      .post(`${process.env.REACT_APP_API_URL}/historical-transactions/upload`, formData, {
        headers: {
          'Content-Type': 'text/csv',
          Accept: 'text/csv',
          Authorization: getAuthorizationHeader(),
        },
      })
      .then(() => {
        setOpenModal(false);
        if (typeof refresh === 'function') {
          refresh();
        }
      })
      .catch((error) => {
        console.error(error);
        setOpenModal(false);
      });
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleImportClick} style={{ marginTop: '20px', width: 'fit-content', marginLeft: 'auto' }}>
        Import csv data
      </Button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">Please upload a csv file</Typography>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          <Button
            style={{ cursor: 'pointer'}}
            variant="contained"
            color="primary"
            onClick={handleFileSubmit}
            disabled={!selectedFile}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </>
  );
};
export default CsvImportButton;
