import Api from "api/properties-admin";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { SubmissionError } from "redux-form";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useNotify } from "react-admin";
import { reset } from "redux-form";

// components
import ListingForm from "now-frontend-shared/components/ListingForm";

// layouts
import HeadSection from "now-frontend-shared/layouts/AuthSections/HeadSection";

// store
import {
  clearAWSData,
  getBasins,
  getCounties,
  getCurrentProperty,
  getLandingZones,
  getPreSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
} from "store/actions/clone-listing-actions";
import { getAllCompanies } from "store/actions/companies-actions";

// helpers
import { getAuthorizationHeader } from "../../auth/auth-helpers";

const formName = "cloneListing";

const buttonStyle = {
  active: {
    backgroundColor: "green",
    color: "white",
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  inactive: {
    backgroundColor: "gray",
    color: "white",
    padding: "10px 20px",
    margin: "5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
};

const CloneListing = ({ listingId, ...restOfProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const [isToggled, setIsToggled] = useState(true);

  const { currentProperty } = restOfProps;

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
    dispatch(getAllCompanies());
  }, [listingId, dispatch]);

  const onSubmit = useCallback(
    async (formData) => {
      if (!currentProperty) {
        throw new Error();
      }
      formData = {
        ...formData,
        originalListingId: currentProperty.id,
        shouldArchive: isToggled,
      };
      try {
        const {
          data: { id },
        } = await Api.sendPropertyData({
          data: JSON.stringify(formData),
          headers: { Authorization: getAuthorizationHeader() },
        });

        dispatch(reset(formName));

        history.replace(`/properties/${id}`);
      } catch (err) {
        console.error(err);
        const message = "Something went wrong";
        notify(message);
        throw new SubmissionError(message);
      }
    },
    [currentProperty, history, notify, dispatch, isToggled]
  );

  return (
    <ListingForm
      formName={formName}
      isBasedOnExisting
      isForCreate
      onSubmit={onSubmit}
      {...restOfProps}
    >
      <div style={buttonStyle.container}>
        <HeadSection
          heading="Create New Listing"
          path={`/properties/${listingId}`}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p style={{ width: "max-content", marginRight: "10px" }}>
            Archive original listing?
          </p>
          <button
            style={isToggled ? buttonStyle.active : buttonStyle.inactive}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsToggled(true);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            style={!isToggled ? buttonStyle.active : buttonStyle.inactive}
            onClick={(e) => {
              e.preventDefault();
              setIsToggled(false);
            }}
          >
            No
          </button>
        </div>
      </div>
    </ListingForm>
  );
};

CloneListing.propTypes = {
  listingId: PropTypes.string.isRequired,
  // TODO: [TYPE] add all missing property types
};

const mapStateToProps = ({ cloneListing, companies }) => ({
  AWSData: cloneListing.AWSData,
  basins: cloneListing.basins,
  clearAWSData,
  companies: companies.companies,
  counties: cloneListing.counties,
  currentProperty: cloneListing.currentProperty,
  getLandingZones,
  getBasins,
  getCounties,
  getPreSignedUrls,
  isLoadingCompanies: companies.isLoadingCompanies,
  landingZones: cloneListing.landingZones,
  preSignedUrls: cloneListing.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states: cloneListing.states,
  unloadedFilesExist: cloneListing.unloadedFilesExist,
});

export default compose(connect(mapStateToProps))(CloneListing);
