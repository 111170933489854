// NOTE: this file is duplicated in `frontend-main`, so any changes made to this file should also be made in
// that file as well.
//
// TODO: [DRY][REFACTOR] remove the duplicate file in `frontend-main` and refactor references to that file
// to refer to this file instead (NOW-938)
export const toRgbString = rgb => `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
export const toRgbaString = rgba => `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;
export const changeRgbaOpacity = (rgba, newOpacity) => `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${newOpacity})`;

export const white = '#FFFFFF';
export const offWhite = '#F6F8F9';
export const grey = '#4F4F4F';
export const midGrey = '#B0BABF';
export const lightGrey = '#A4A4A4';
export const lighterGrey = '#E6E6E6';
export const red = '#F44336';
export const lightRed = '#FF4F66';
export const green = '#4CAF50';
export const lightGreen = '#6FCF97';
export const blue = '#2196F3';
export const navyBlue = '#2D9CDB';
export const darkBlue = '#3f51b5';
export const cyan = '#56CCF2';
export const yellow = '#F2C94C';

export const primaryMainRgba = [120, 144, 166, 1];
export const primaryMain = toRgbaString(primaryMainRgba);
export const primaryMainHalfTone = changeRgbaOpacity(primaryMainRgba, 0.5);
export const primaryLight = 'rgba(224, 229, 235, 1)';
export const primaryDarkRgba = [75, 99, 119, 1];
export const primaryDark = toRgbaString(primaryDarkRgba);
export const primaryDarkHalfTone = changeRgbaOpacity(primaryDarkRgba, 0.5);
export const primaryContrast = 'rgba(255, 255, 255, 1)';

export const secondaryMain = 'rgba(178, 157, 121, 1)';
export const secondaryLight = 'rgba(229, 206, 168, 1)';
export const secondaryDark = 'rgba(130, 111, 77, 1)';
export const secondaryContrast = 'rgba(255, 255, 255, 1)';

export const textPrimary = 'rgba(0, 0, 0, 0.87)';
export const textSecondary = 'rgba(0, 0, 0, 0.6)';
export const textDisabled = 'rgba(0, 0, 0, 0.38)';

export const landingPageLargeText = '#1a3248';

export const mapAreaOfInterestFillRgba = [191, 191, 191, 0.5];
export const mapAreaOfInterestOutline = {
  strokeColorRgb: [primaryDarkRgba[0], primaryDarkRgba[1], primaryDarkRgba[2]],
  strokeOpacity: 1,
  strokeWeight: 2,
};

export const mapWellIconColorRgb = [107, 107, 193];
export const historicalWellIconColorRgb = [50, 205, 50];
export const newMexicoWellIconColorRgb = [255, 127, 127];

export const mapWellIconStyle = {
  fillColorRgba: [255, 255, 255, 0.6],
  lineColorRgba: [...mapWellIconColorRgb, 0.75],
  font: {
    weight: 'bold',
    family: 'Noto Sans',
    size: '12px',
  },
  haloSize: 1,
  haloColorRgba: [255, 255, 255, 0.75],
};

export const historicalWellIconStyle = {
  ...mapWellIconStyle,
  lineColorRgba: [...historicalWellIconColorRgb, 0.75],
};

export const newMexicoWellIconStyle = {
  ...mapWellIconStyle,
  lineColorRgba: [...newMexicoWellIconColorRgb, 0.75],
};

export const mapWellAreaLineStyle = {
  colorRgba: [...mapWellIconColorRgb, 0.75],
  width: 1,
  style: 'dash',
};

export const INPUT_BORDER_COLOR = 'rgba(0,0,0,0.5)';

export const INPUT_BORDER_HOVER_COLOR = 'rgba(0,0,0,1)';

export const INPUT_BORDER_FOCUS_COLOR = darkBlue;

export const MAP_LIGHT_COLOR = '#3D4F5F';
export const MAP_DARK_COLOR = '#182C3D';
