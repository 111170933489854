export const shouldIncludeAdminsInUsersList = false;

export const shouldIncludeSuperAdminsInAdminsList = true;
export const shouldIncludeComplianceUsersInAdminsList = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add individual well net afes on listings that were closed or archived before
 * NOW-1153 was deployed.
 */
export const shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add individual well gross afes on listings that were closed or archived before
 * NOW-1154 was deployed.
 */
export const shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add individual well net afes on listings that were closed or archived before
 * NOW-1222 was deployed.
 */
export const shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add/edit this well field to listings that were closed or archived historically.
 */
export const shouldAllowAdminToEditWellApiNumberOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add/edit this well field to listings that were closed or archived historically.
 */
export const shouldAllowAdminToEditWellRankOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add/edit this well field to listings that were closed or archived historically.
 */
export const shouldAllowAdminToEditWellMinimumBidOnListingThatHasBeenClosedOrArchived = true;

/**
 * This is allowed so that Admins can edit historical listings in BETA and PROD to
 * add/edit this well field to listings that were closed or archived historically.
 */
export const shouldAllowAdminToEditWellMinimumBidCarryOnListingThatHasBeenClosedOrArchived = true;

export const shouldAllowSuperAdminToChangeListingCompany = false;

export const shouldAllowSuperAdminToChangeDraftListingCompany = shouldAllowSuperAdminToChangeListingCompany;

export const shouldAllowChangeListingOwnerOnListingThatHasBeenClosedOrArchived = false;
