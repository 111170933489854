import { HttpStatus } from '../helpers/https-status';
import { isAdminOrSuperAdmin, isCompliancePerson } from './admin-upsert-user';
import {
  ApprovalStatus,
  getRejectionReasonValidators,
  toStatusName,
  validateIsApprovalStatus,
} from './approval-status';
import {
  createFormValidator,
  hasValue,
  maxCharCount,
  minCharCount,
  REQUIRED,
  trimmed,
} from './validation-rules';

export const companyAgreementAcceptFormats = [
  // pdf
  'application/pdf',
  '.pdf',
];

export const getRejectionReasonNotRejectedError = complianceRoleTitle => ({
  message: `${complianceRoleTitle} has not rejected`,
  code: HttpStatus.UnprocessableEntity,
});

export const representativeHasNotReviewedError = {
  message: 'Must wait for Registered Representative review first',
  code: HttpStatus.UnprocessableEntity,
};

export const defaultValuesForCreateCompany = {
  active: true,
};

// permission to edit fields

export const fieldsForRegisteredRepresentative = [
  'representativeRejectionReason',
  'representativeApprovalStatus',
];

export const fieldsForComplianceSupervisor = [
  'supervisorRejectionReason',
  'supervisorApprovalStatus',
];

export const fieldsForCompliancePerson = [
  'complianceNotes',
];

export const addressFieldsRequired = [
  'businessStreetAddress',
  'city',
  'stateId',
  'zipCode',
];

export const addressFieldsOptional = [
  'businessStreetAddress2',
];

export const addressFields = [
  ...addressFieldsRequired,
  ...addressFieldsOptional,
];

export const basicFieldsRequired = [
  'fullLegalCompanyName',
  ...addressFieldsRequired,
];

export const basicFieldsOptional = [
  'operatingName',
  ...addressFieldsOptional,
];

export const fieldsOnlyEditableByAdmin = [
  'active',
  ...basicFieldsRequired,
  ...basicFieldsOptional,
];

export const fieldsOnlyEditableBySuperAdmin = [
  'approved',
];

export const fieldsForCompliance = [
  ...fieldsForRegisteredRepresentative,
  ...fieldsForComplianceSupervisor,
  ...fieldsForCompliancePerson,
];

export const rejectionReasonApprovalStatusField = {
  representativeRejectionReason: 'representativeApprovalStatus',
  supervisorRejectionReason: 'supervisorApprovalStatus',
};

export const approvalStatusFields = Object.values(rejectionReasonApprovalStatusField);

export const fieldsNotForCreate = [
  ...fieldsForCompliance,
  'approved',
];

export const validateCanSupervisorEditApproval = (value, values, field) => {
  let result;
  if (
    field === 'supervisorApprovalStatus'
    // TODO: [FEATURE] `&& savedValue === Pending (hasn't made an approval or rejection yet)`
    && toStatusName(values.representativeApprovalStatus) === ApprovalStatus.Pending
  ) {
    result = representativeHasNotReviewedError;
  }
  return result;
};

export const canSupervisorEditApproval = values => validateCanSupervisorEditApproval(values) === undefined;

/**
 * NOTE: logic here must be in sync with
 * {@link backend/src/modules/companies/company.service.ts#findForComplianceTodoList}
 */
export const validateSupervisorApprovalRequired = (value, values) => {
  let result;
  if (
    !hasValue(value)
    && toStatusName(values.representativeApprovalStatus) === ApprovalStatus.Approved
  ) {
    result = {
      message: REQUIRED,
      code: HttpStatus.UnprocessableEntity,
    };
  }
  return result;
};

export const isSupervisorApprovalRequired = values => validateSupervisorApprovalRequired(values) !== undefined;

export const validateHasPermissionToEditCompanyField = (editor, fieldOptional) => (
  value,
  values,
  fieldFromValidator,
) => {
  const field = fieldFromValidator ?? fieldOptional;
  let result;

  const runCheck = check => {
    if (result === undefined) {
      check();
    }
  };

  runCheck(() => {
    if (
      !editor.isRegisteredRepresentative
      && fieldsForRegisteredRepresentative.includes(field)
    ) {
      result = {
        message: 'Only editable by the Registered Representative',
        code: HttpStatus.Forbidden,
      };
    }
  });

  runCheck(() => {
    if (
      field === 'representativeRejectionReason'
      && toStatusName(values.representativeApprovalStatus) !== ApprovalStatus.Rejected
      && hasValue(value)
    ) {
      result = getRejectionReasonNotRejectedError('Registered Representative');
    }
  });

  runCheck(() => {
    if (
      !editor.isComplianceSupervisor
      && fieldsForComplianceSupervisor.includes(field)
    ) {
      result = {
        message: 'Only editable by the Compliance Supervisor',
        code: HttpStatus.Forbidden,
      };
    }
  });

  runCheck(() => {
    if (
      field === 'supervisorRejectionReason'
      && toStatusName(values.supervisorApprovalStatus) !== ApprovalStatus.Rejected
      && hasValue(value)
    ) {
      result = getRejectionReasonNotRejectedError('Compliance Supervisor');
    }
  });

  runCheck(() => {
    if (
      !isCompliancePerson(editor)
      && fieldsForCompliancePerson.includes(field)
    ) {
      result = {
        message: 'Only editable by a compliance person',
        code: HttpStatus.Forbidden,
      };
    }
  });

  runCheck(() => {
    if (
      !isAdminOrSuperAdmin(editor)
      && fieldsOnlyEditableByAdmin.includes(field)
    ) {
      result = {
        message: 'Only editable by an Admin',
        code: HttpStatus.Forbidden,
      };
    }
  });

  runCheck(() => {
    if (
      !editor.isSuperAdmin
      && fieldsOnlyEditableBySuperAdmin.includes(field)
    ) {
      result = {
        message: 'Only editable by a Super Admin',
        code: HttpStatus.Forbidden,
      };
    }
  });

  runCheck(() => {
    if (fieldsForComplianceSupervisor.includes(field)) {
      result = validateCanSupervisorEditApproval(value, values, field);
    }
  });

  return result;
};

export const hasPermissionToEditCompanyField = (editor, values, field) => (
  validateHasPermissionToEditCompanyField(editor)(values[field || ''], values, field) === undefined
);

// representativeApprovalStatus

export const validateRepresentativeApprovalStatus = approvalStatus => (
  validateIsApprovalStatus(approvalStatus)
);

export const canRepresentativeApprovalStatusBeValue = (approvalStatus, values) => (
  validateRepresentativeApprovalStatus(approvalStatus, values) === undefined
);

// supervisorApprovalStatus

export const validateSupervisorApprovalStatus = approvalStatus => validateIsApprovalStatus(approvalStatus);

export const canSupervisorApprovalStatusBeValue = (approvalStatus, values) => (
  validateSupervisorApprovalStatus(approvalStatus, values) === undefined
);

export const validateFieldIsRequired = (value, values, field) => {
  let result = false;
  if (!hasValue(value)) {
    if (basicFieldsRequired.includes(field)) {
      result = true;
    } else if (field === 'supervisorApprovalStatus') {
      result = validateSupervisorApprovalRequired(value, values);
    } else if (
      approvalStatusFields.includes(field)
      && values.id
    ) {
      result = true;
    } else if (
      Object.keys(rejectionReasonApprovalStatusField).includes(field)
      && toStatusName(values[rejectionReasonApprovalStatusField[field]]) === ApprovalStatus.Rejected
    ) {
      result = true;
    }
  }

  if (result === true) {
    result = {
      message: REQUIRED,
      code: HttpStatus.UnprocessableEntity,
    };
  } else if (result === false) {
    result = undefined;
  }
  return result;
};

export const isFieldRequired = (values, field) => validateFieldIsRequired(undefined, values, field) !== undefined;

export const complianceNotesMinCharCount = 2;

/**
 * About 5 large paragraphs.
 *
 * NOTE: updates to this must be accompanied by a database migration
 */
export const complianceNotesMaxCharCount = 3000;

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const adminUpsertCompanyFieldValidators = {
  active: () => [
    validateFieldIsRequired,
  ],
  approved: () => [
    validateFieldIsRequired,
  ],
  fullLegalCompanyName: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),
    ] : [],
  ],
  operatingName: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),
    ] : [],
  ],
  businessStreetAddress: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),

    ] : [],
  ],
  businessStreetAddress2: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),

    ] : [],
  ],
  city: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(50),

    ] : [],
  ],
  stateId: () => [
    validateFieldIsRequired,
  ],
  zipCode: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(10),
    ] : [],
  ],
  representativeApprovalStatus: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateRepresentativeApprovalStatus,
    ] : [],
  ],
  representativeRejectionReason: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      ...getRejectionReasonValidators(),
    ] : [],
  ],
  supervisorApprovalStatus: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateSupervisorApprovalStatus,
    ] : [],
  ],
  supervisorRejectionReason: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      ...getRejectionReasonValidators(),
    ] : [],
  ],
  complianceNotes: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      minCharCount(complianceNotesMinCharCount),
      maxCharCount(complianceNotesMaxCharCount),
    ] : [],
  ],
};

export const validateAdminUpsertCompany = createFormValidator(adminUpsertCompanyFieldValidators);
