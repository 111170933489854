import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';
import { generateFetchOptions } from 'helpers/fetch';
import moment from 'moment-timezone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import Spinner from 'now-frontend-shared/components/Spinner';

const EmailLogs = () => {
  const [data, setData] = useState({
    status: 'loading',
    data: [],
  });

  useEffect(() => {
    const getEmailLogs = async () => {
      try {
        const resultJSON = await fetch(
          `${process.env.REACT_APP_API_URL}/email-logs`,
          generateFetchOptions('GET')
        );
        const result = await resultJSON.json();
        setData({
          status: 'success',
          data: result.data
            .map((item) => ({
              id: item.Timestamp,
              Send:
                Number(item.DeliveryAttempts) +
                Number(item.Bounces) +
                Number(item.Complaints) +
                Number(item.Rejects),
              ...item,
            }))
            .sort((a, b) =>
              moment.utc(b.Timestamp).diff(moment.utc(a.Timestamp))
            ),
        });
      } catch (error) {
        setData({
          status: 'error',
          data: error,
        });
      }
    };
    getEmailLogs();
  }, []);

  const columns = [
    {
      field: 'Timestamp',
      headerName: 'Timestamp',
      flex: 1,
      renderCell: (params) => {
        const formattedDate = moment
          .tz(params.value, NONOPWELLS_TIME_ZONE)
          .format('MMMM D, YYYY, h:mm A z');

        return <span>{formattedDate}</span>;
      },
      sortComparator: (v1, v2) => moment.utc(v1).diff(moment.utc(v2)),
    },
    {
      field: 'Send',
      headerName: 'Sent',
      flex: 1,
      sortable: true,
    },
    {
      field: 'DeliveryAttempts',
      headerName: 'Delivery Attempts',
      flex: 1,
      sortable: true,
    },
    { field: 'Bounces', headerName: 'Bounces', flex: 1, sortable: true },
    {
      field: 'Complaints',
      headerName: 'Complaints',
      flex: 1,
      sortable: true,
    },
    { field: 'Rejects', headerName: 'Rejects', flex: 1, sortable: true },
  ];

  return (
    <div>
      <h4
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          fontSize: '1.5rem',
        }}
      >
        Emails within the last two weeks, in 15 minute time periods of activity
      </h4>
      {data.status === 'loading' && <Spinner />}
      {data.status === 'error' && <div>{data.data}</div>}
      {data.status === 'success' && (
        <DataGrid
          rows={data.data || []}
          columns={columns}
          autoHeight
          pageSize={30}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
        />
      )}
    </div>
  );
};

EmailLogs.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.shape({
      defaultValue: PropTypes.bool,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default EmailLogs;
