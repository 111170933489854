import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import {
  TextInput,
  List,
  Datagrid,
  Create,
  FunctionField,
  Edit,
  SimpleForm,
  TextField,
  Filter,
  linkToRecord,
  useNotify,
  DateInput,
  Labeled,
  useRefresh,
} from 'react-admin';
import { CustomPagination } from '../components/pagination';
import { DataGrid } from '@mui/x-data-grid';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DeleteButtonWithConfirmation from 'components/deleteButtonWithConfirmation';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserData } from 'auth/auth-helpers';
import {
  disabledWrapper,
  isUiValidationDisabled,
} from 'now-frontend-shared/helpers/ui-validation-toggle';
import { isFieldRequired } from 'now-shared/validation/company';
import FormSpyForDataProvider from 'components/FormSpyForDataProvider';
import FormDataConsumer from 'components/FormDataConsumer';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import { hasPermissionToEditField } from 'now-shared/validation/historical-listing-validation';
import CsvImportButton from 'components/importCsvButtonNewMexico';
import DropZone from 'now-frontend-shared/components/DropZone';
import {
  getNewMexicoDocuments,
  getPreSignedUrls,
  removeAWSDataFile,
  saveNewMexicoDocuments,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
  deleteNewMexicoDocument,
} from 'store/actions/new-mexico-documents';
import DeleteAllButton from 'components/deleteAllButtonNewMexico';

export const NewMexicoListingsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const mapStateToProps = ({ newMexicoDocuments }) => ({
  AWSData: newMexicoDocuments.AWSData,
  currentDocuments: newMexicoDocuments.currentDocuments,
  AWSDataIsSet: newMexicoDocuments.AWSDataIsSet,
  getPreSignedUrls,
  preSignedUrls: newMexicoDocuments.preSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
});

export const NewMexicoListingsList = compose(connect(mapStateToProps))(
  (props) => {
    const refresh = useRefresh();
    const [tab, setTab] = React.useState('data');
    const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] =
      useState(false);
    const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] =
      useState(false);
    const uploadIsPending =
      dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getNewMexicoDocuments());
    }, [dispatch]);

    const handleDeleteRow = (documentId) => {
      dispatch(deleteNewMexicoDocument({ documentId }));
    };

    const style = {
      padding: '6px 12px',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '0.875rem',
      maxWidth: '264px',
      minWidth: '72px',
      boxSizing: 'border-box',
      minHeight: '48px',
      textAlign: 'center',
      flexShrink: '0',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      lineHeight: '1.75',
      whiteSpace: 'normal',
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      background: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      cursor: 'pointer',
    };
    const active = {
      borderBottom: 'solid #3F51B5 2px',
    };
    return (
      <>
        <div style={{ display: 'flex', gap: '20px' }}>
          <CsvImportButton refresh={refresh} />
          <DeleteAllButton refresh={refresh} />
        </div>
        <h1>New Mexico Listings</h1>
        <div
          style={{
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            marginTop: '20px',
          }}
        >
          <button
            type="button"
            style={{
              ...style,
              ...(tab === 'data' ? active : { borderBottom: 'none' }),
            }}
            onClick={() => setTab('data')}
          >
            Data
          </button>
          <button
            type="button"
            style={{
              ...style,
              ...(tab === 'documents' ? active : { borderBottom: 'none' }),
            }}
            onClick={() => setTab('documents')}
          >
            Documents
          </button>
        </div>

        {tab === 'data' ? (
          <List
            {...props}
            filters={<NewMexicoListingsFilter />}
            sort={{
              field: 'id',
              order: SortDirection.Descending,
            }}
            pagination={<CustomPagination />}
            exporter={false}
            bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
            perPage={50}
          >
            <Datagrid rowClick="edit">
              <TextField source="api" label="API" />
              <TextField source="wellName" label="Well Name" />
              <TextField source="wellFamily" label="Well Family" />
              <TextField source="receivedDate" label="Received Date" />
              <TextField source="approvalDate" label="Approval Date" />
              <TextField source="county" label="County" />
              <TextField source="state" label="State" />
              <TextField source="landingZone" label="Landing Zone" />
              <TextField source="pollingCode" label="Polling Code" />
              <TextField source="surfaceLat" label="Surface Lat" />
              <TextField source="surfaceLong" label="Surface Long" />
              <TextField source="bottomLat" label="Bottom Lat" />
              <TextField source="bottomLong" label="Bottom Long" />
              <TextField source="WIPercent" label="WI Percent" />
              <TextField source="operator" label="Operator" />
              <TextField source="case" label="Case" />
              <TextField source="tvd" label="TVD" />
              <TextField source="afe" label="AFE" />
              <TextField source="owner" label="Owner" />
              <TextField source="notes" label="Notes" />
              <TextField source="document" label="Document" />
            </Datagrid>
          </List>
        ) : (
          <>
            <DropZone
              AWSData={props.AWSData}
              preSignedUrls={props.preSignedUrls}
              savedDocuments={props.currentDocuments}
              setAWSData={setAWSData}
              getPreSignedUrls={getPreSignedUrls}
              removeAWSDataFile={removeAWSDataFile}
              onSetIsProcessingFiles={setDropZoneIsProcessingFile}
              onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
              placeholder="Upload New Mexico documents here."
              isSimpleView
              hideSaved
              maxFiles={10000}
            />
            <button
              style={{
                padding: '10px 15px',
                width: '150px',
                margin: '10px auto',
                cursor: 'pointer',
              }}
              type="button"
              disabled={
                !props.AWSData.find((doc) => !doc.id) || uploadIsPending
              }
              onClick={() =>
                dispatch(
                  saveNewMexicoDocuments({
                    AWSData: props.AWSData,
                  })
                )
              }
              variant="contained"
            >
              <span style={{ fontSize: '14px' }}>Save Uploaded</span>
            </button>
            <DataGrid
              autoHeight
              autoPageSize
              pageSize={50}
              disableColumnMenu
              disableColumnSelector
              rows={props.currentDocuments}
              columns={[
                {
                  field: 'filename',
                  headerName: 'File Name',
                  flex: 1,
                },
                {
                  field: 'key',
                  headerName: 'Key',
                  flex: 1,
                },
                {
                  field: 'case',
                  headerName: 'Case',
                  flex: 1,
                },
                {
                  field: 'actions',
                  headerName: 'Actions',
                  flex: 1,
                  sortable: false,
                  renderCell: (params) => (
                    <DeleteIcon
                      onClick={() => handleDeleteRow(params.row.id)}
                      style={{ cursor: 'pointer', fill: 'red' }}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </>
    );
  }
);

const useNewMexicoListingFormItems = ({
  // eslint-disable-next-line no-unused-vars
  formType,
}) => {
  const userData = useMemo(() => getUserData(), []);

  const hasPermissionToEdit = (values, field) =>
    hasPermissionToEditField(userData, values, field);

  const shouldShowField = useCallback((values, field, form) => {
    const fieldState = form?.getFieldState(field);
    let result;
    if (isUiValidationDisabled()) {
      result = true;
    } else if (fieldState?.dirty) {
      result = true;
    } else if (values[field]) {
      result = true;
    } else {
      result = true;
    }
    return result;
  }, []);

  const shouldDisableField = (values, field, checkCustom) =>
    disabledWrapper(() => {
      let result = false;
      if (!hasPermissionToEdit(values, field)) {
        result = true;
      } else if (checkCustom) {
        result = checkCustom(field, values);
      }
      return result;
    });

  return [
    ...[
      ['api', 'API'],
      ['wellName', 'Well Name'],
      ['wellFamily', 'Well Family'],
      ['county', 'County'],
      ['state', 'State'],
      ['landingZone', 'Landing Zone'],
      ['pollingCode', 'Polling Code'],
      ['surfaceLat', 'Surface Lat'],
      ['surfaceLong', 'Surface Long'],
      ['bottomLat', 'Bottom Lat'],
      ['bottomLong', 'Bottom Long'],
      ['WIPercent', 'WI Percent'],
      ['operator', 'Operator'],
      ['case', 'Case'],
      ['tvd', 'TVD'],
      ['afe', 'AFE'],
      ['owner', 'Owner'],
      ['notes', 'Notes'],
      ['document', 'Document'],
    ].map(([key, value]) =>
      ((field, label) => (
        <FormDataConsumer key={field}>
          {({ formData, form, ...rest }) =>
            shouldShowField(formData, field, form) && (
              <TextInput
                {...rest}
                source={field}
                label={label}
                isRequired={isFieldRequired(formData, field)}
                disabled={shouldDisableField(formData, field)}
              />
            )
          }
        </FormDataConsumer>
      ))(key, value)
    ),
    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('approvalDate', 'Approval Date'),
    ((field, label) => (
      <FormDataConsumer key={field}>
        {({ formData, form, ...rest }) =>
          shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )
        }
      </FormDataConsumer>
    ))('receivedDate', 'Received Date'),
  ];
};

const getSharedFormProps = ({ isForCreate, ...props }) => ({
  ...getStandardFormProps({ isForCreate, ...props }),
  redirect: '/new-mexico-transactions',
});

const NewMexicoListingEditForm = (propsAll) => {
  const { formType, ...props } = propsAll;

  const notify = useNotify();

  return (
    <>
      <Edit {...getStandardCreateEditProps({ notify, ...props })}>
        <SimpleForm {...getSharedFormProps({ ...props })}>
          <FormSpyForDataProvider />
          {useNewMexicoListingFormItems(propsAll)}
          <Labeled label="Created At">
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
            />
          </Labeled>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.createdBy && (
                <Labeled label="Created By">
                  <FunctionField
                    render={(record) =>
                      record.createdBy && (
                        <LinkButton
                          label={getUserFullName(record.createdBy)}
                          buttonColor="clearGreen"
                          path={linkToRecord('/users', record.createdBy.id)}
                        />
                      )
                    }
                    {...rest}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.updatedAt !== formData.createdAt && (
                <Labeled label="Updated At">
                  <DateFieldWithTimeZone
                    {...rest}
                    source="updatedAt"
                    showTime
                    timeZone={NONOPWELLS_TIME_ZONE}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) =>
              formData.updatedBy && (
                <Labeled label="Updated By">
                  <FunctionField
                    render={(record) =>
                      record.updatedBy && (
                        <LinkButton
                          label={getUserFullName(record.updatedBy)}
                          buttonColor="clearGreen"
                          path={linkToRecord('/users', record.updatedBy.id)}
                        />
                      )
                    }
                    {...rest}
                  />
                </Labeled>
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};

const CreateEditComponent = (props) => {
  const { formType, ...propsRest } = props;

  const notify = useNotify();

  const formItems = useNewMexicoListingFormItems(props);

  return formType === 'create' ? (
    <Create
      {...getStandardCreateEditProps({
        notify,
        isForCreate: true,
        ...propsRest,
      })}
    >
      <SimpleForm {...getSharedFormProps({ isForCreate: true, ...propsRest })}>
        <FormSpyForDataProvider />
        {formItems}
      </SimpleForm>
    </Create>
  ) : (
    <NewMexicoListingEditForm {...props} />
  );
};

CreateEditComponent.propTypes = {
  formType: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export const NewMexicoListingCreate = (props) => (
  <CreateEditComponent formType="create" {...props} />
);

export const NewMexicoListingEdit = (props) => (
  <CreateEditComponent formType="edit" {...props} />
);
