import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getAllCompanies: makeAxiosRequest(
    '/companies',
    {
      method: 'get',
    },
    true,
  ),
  findUsersByCompany: companyId => makeAxiosRequest(
    `/companies/${companyId}/users`,
    {
      method: 'get',
    },
    true,
  ),
  getCompanyComplianceInfo: companyId => makeAxiosRequest(
    `/admin/companies/${companyId}/compliance-info`,
    {
      method: 'get',
    },
    true,
  ),
  getCompanyComplianceDocToken: companyId => makeAxiosRequest(
    `/admin/companies/${companyId}/compliance-doc-token`,
    {
      method: 'get',
    },
    true,
  ),
  postCompanyComplianceDocument: companyId => makeAxiosRequest(
    `/companies/${companyId}/company-compliance-documents`,
    {
      method: 'post',
    },
    true,
  ),
  getCompanyComplianceDocument: companyId => makeAxiosRequest(
    `/companies/${companyId}/company-compliance-documents`,
    {
      method: 'get',
    },
    true,
  ),
  deleteCompanyComplianceDocument: (companyId, documentId) => makeAxiosRequest(
    `/companies/${companyId}/company-compliance-documents/${documentId}`,
    {
      method: 'delete',
    },
    true,
  ),
  getPreSignedUrls: params => makeAxiosRequest(
    `/get-s3-pre-signed-urls-for-compliance?${params}`,
    { method: 'get' },
    true,
  ),
  getUserCompany: companyId => makeAxiosRequest(
    `/admin/companies/${companyId}`,
    {
      method: 'get',
    },
    true,
  ),
  getNonOpWellsSignatureDetails: makeAxiosRequest('/nonopwells-signature-details', {
    method: 'get',
  }, true),
  getListOfFullyOnBoardedBuyers: makeAxiosRequest('/companies/get-fully-on-boarded-buyers', {
    method: 'get',
  }, true),
};
