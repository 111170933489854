import Box from '@material-ui/core/Box';
import React, { memo } from 'react';
import {
  Grid,
} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { formatNumberToCurrency } from 'now-shared/helpers/currency-helpers';

const WellsTable = ({ wells, classes }) => (
  <Box className={classes.wellInfoContainer}>
    <div
      className={classes.wellsContainer}
    >
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>Name</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>API #</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>Landing Zone</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>Gross AFE</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>Net AFE</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6 }}>
        <div>Surface Hole</div>
      </Grid>
      <Grid className={classes.bottomSectionHeading} style={{ padding: 6, borderRight: 'unset' }}>
        <div>Bottom Hole</div>
      </Grid>
    </div>
    {wells?.map(well => {
      const {
        wellName,
        wellAPINumber,
        bottomLatitude,
        bottomLongitude,
        surfaceLongitude,
        surfaceLatitude,
        wellGrossAfe,
        wellNetAFE,
        landingZone,
      } = well;
      return (
        <div
          className={classes.wellsContainer}
          key={well.id}
        >
          <Grid item className={classes.bottomSectionHeading}>
            <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
              {wellName || '-'}
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading}>
            <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
              {wellAPINumber || '-'}
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading}>
            <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
              {landingZone?.title || '-'}
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading}>
            <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
              {formatNumberToCurrency(wellGrossAfe) || '-'}
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading}>
            <div style={{ padding: 6 }} className={classes.bottomSectionDescription}>
              {formatNumberToCurrency(wellNetAFE) || '-'}
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading}>
            <div className={classes.bottomSectionDescription}>
              <div style={{ borderBottom: 'solid 2px #E5E5E5' }}>
                <div style={{ paddingLeft: 6 }}>
                  <span className={classes.latLongText}>LAT</span>
                  {surfaceLatitude || '-'}
                </div>
              </div>
              <div style={{ paddingLeft: 6 }}>
                <span className={classes.latLongText}>LNG</span>
                {surfaceLongitude || '-'}
              </div>
            </div>
          </Grid>
          <Grid item className={classes.bottomSectionHeading} style={{ borderRight: 'unset' }}>
            <div className={classes.bottomSectionDescription}>
              <div style={{ borderBottom: 'solid 2px #E5E5E5' }}>
                <div style={{ paddingLeft: 6 }}>
                  <span className={classes.latLongText}>LAT</span>
                  {bottomLatitude || '-'}
                </div>
              </div>
              <div style={{ paddingLeft: 6 }}>
                <span className={classes.latLongText}>LNG</span>
                {bottomLongitude || '-'}
              </div>
            </div>
          </Grid>
        </div>
      );
    })}
  </Box>
);

WellsTable.propTypes = {
  wells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      wellName: PropTypes.string,
      surfaceLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      surfaceLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }).isRequired,
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(WellsTable);
