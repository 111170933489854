import {
  ApprovalStatus,
  getRejectionReasonValidators,
  toStatusName,
  validateHasNoRejectionReason,
} from 'now-shared/validation/approval-status';
import {
  hasValue,
  validateMustNotExist,
  validateField,
} from 'now-shared/validation/validation-rules';

export const getAdminResourceRejectionReasonValidators = (approvalStatusFieldName, canReject) => (values, value) => [
  ...hasValue(value) ? [
    ...(
      canReject(values)
      || toStatusName(values[approvalStatusFieldName]) === ApprovalStatus.Rejected
    )
      ? [
        ...getRejectionReasonValidators(),
      ]
      : [
        validateMustNotExist,
      ],
  ] : [
    validateHasNoRejectionReason(approvalStatusFieldName),
  ],
];

export const createAdminResourceRejectionReasonValidator = (approvalStatusFieldName, canReject) => (
  (_value, values) => (
    validateField({
      fieldName: 'rejectionReason',
      values,
      getValidators: getAdminResourceRejectionReasonValidators(approvalStatusFieldName, canReject),
    })
  )
);
