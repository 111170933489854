import {
  all,
  fork,
  take,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';

// actions
import {
  getAllPermissions,
  getRolePermissions,
  getUserPermissions,
  addPermissionsByRole,
  removePermissionsByRole,
  updateUserPermissions,
  checkSatisfiedRolePermissions,
  updateSatisfiedRolePermissions,
} from '../actions/permissions-actions';

// api methods
import Api from 'api/permissions';

function* ensureGetAllPermissions() {
  try {
    const { data } = yield call(Api.getAllPermissions);
    yield put({ type: getAllPermissions.success, payload: data });
  } catch (err) {
    yield put({ type: getAllPermissions.failure, payload: err, error: true });
  }
}

function* findAdminSitePermissions() {
  // This function filters for permissions that have 'admin-' in their title.
  // These are permissions for admin site endpoints where the url begins with /admin/...
  const permissionsArray = yield select(({ permissions }) => permissions.permissions);
  const adminSitePermissionIds = permissionsArray?.filter(permission => permission.permissionType.name.includes('admin-'))
    ?.map(permission => permission.id);

  return new Set(adminSitePermissionIds);
}

function* ensureGetRolePermissions() {
  try {
    const { data } = yield call(Api.getRolePermissions);

    // Save set of all admin site permissionIds for quick access
    const adminSitePermissionIdsSet = yield findAdminSitePermissions();

    yield put({ type: getRolePermissions.success, payload: { data, adminSitePermissionIdsSet } });
  } catch (err) {
    yield put({ type: getRolePermissions.failure, payload: err, error: true });
  }
}

function* ensureGetUserPermissions({ payload: { userId } }) {
  try {
    const { data } = yield call(Api.getUserPermissions({ userId }));
    yield put({ type: getUserPermissions.success, payload: data });

    // After fetching user permissions, update satisfied role permissions
    yield put({ type: checkSatisfiedRolePermissions.type });
  } catch (err) {
    yield put({ type: getUserPermissions.failure, payload: err, error: true });
  }
}

function* ensureCheckSatisfiedRolePermissions() {
  try {
    const rolesArray = yield select(({ roles }) => roles.roles);
    const rolePermissions = yield select(({ permissions }) => permissions.rolePermissions);
    const userPermissions = yield select(({ permissions }) => permissions.userPermissions);

    let allSatisfiedRolePermissions = [];
    if (rolesArray?.length && rolePermissions?.length && userPermissions?.length) {
      rolesArray.forEach(role => {
        const rolePermissionsToSatisfy = rolePermissions?.filter(rolePermission => rolePermission.roleId === role.id);
        const userPermissionIdsSet = new Set(userPermissions?.map(permission => permission.id));

        const rolePermissionsSatisfied = rolePermissionsToSatisfy.every(
          rolePermission => userPermissionIdsSet.has(rolePermission.permissionId),
        );
        if (rolePermissionsSatisfied) {
          allSatisfiedRolePermissions = allSatisfiedRolePermissions.concat(rolePermissionsToSatisfy);
        }
      });
    }

    yield put({
      type: updateSatisfiedRolePermissions.type,
      payload: allSatisfiedRolePermissions,
    });
  } catch (err) {
    console.error('Permissions sagas checkSatisfiedRolePermissions error: ', err);
  }
}

function* ensureAddPermissionsByRole({ payload: { userId, roleId } }) {
  try {
    const { data } = yield call(Api.addPermissionsByRole({ userId, roleId }));
    yield put({ type: addPermissionsByRole.success, payload: data });

    // After updating user permissions, refetch them
    yield put({ type: getUserPermissions.type, payload: { userId } });
  } catch (err) {
    yield put({ type: addPermissionsByRole.failure, payload: err, error: true });
  }
}

function* ensureRemovePermissionsByRole({ payload: { userId, roleId } }) {
  try {
    const { data } = yield call(Api.removePermissionsByRole({ userId, roleId }));
    yield put({ type: removePermissionsByRole.success, payload: data });

    // After updating user permissions, refetch them
    yield put({ type: getUserPermissions.type, payload: { userId } });
  } catch (err) {
    yield put({ type: removePermissionsByRole.failure, payload: err, error: true });
  }
}

function* ensureUpdateUserPermissions({
  payload: {
    userId,
    permissionIdsToAdd,
    permissionIdsToRemove,
  },
}) {
  try {
    // If the user permissions have changed, update them
    if (permissionIdsToAdd?.length || permissionIdsToRemove?.length) {
      const { data } = yield call(Api.updateUserPermissions({ userId, permissionIdsToAdd, permissionIdsToRemove }));
      yield put({ type: updateUserPermissions.success, payload: data });

      // After updating user permissions, refetch them
      yield put({ type: getUserPermissions.type, payload: { userId } });
    }
  } catch (err) {
    yield put({ type: updateUserPermissions.failure, payload: err, error: true });
  }
}

function* watchGetAllPermissions() {
  yield takeLatest(getAllPermissions.type, ensureGetAllPermissions);
  yield take(getAllPermissions.success);
}

function* watchGetRolePermissions() {
  yield takeLatest(getRolePermissions.type, ensureGetRolePermissions);
  yield take(getRolePermissions.success);
}

function* watchGetUserPermissions() {
  yield takeLatest(getUserPermissions.type, ensureGetUserPermissions);
  yield take(getUserPermissions.success);
}

function* watchCheckSatisfiedRolePermissions() {
  yield takeLatest(checkSatisfiedRolePermissions.type, ensureCheckSatisfiedRolePermissions);
}

function* watchAddPermissionsByRole() {
  yield takeLatest(addPermissionsByRole.type, ensureAddPermissionsByRole);
  yield take(addPermissionsByRole.success);
}

function* watchRemovePermissionsByRole() {
  yield takeLatest(removePermissionsByRole.type, ensureRemovePermissionsByRole);
  yield take(removePermissionsByRole.success);
}

function* watchUpdateUserPermissions() {
  yield takeLatest(updateUserPermissions.type, ensureUpdateUserPermissions);
  yield take(updateUserPermissions.success);
}

export default function* permissionsSagas() {
  yield all([
    fork(watchGetAllPermissions),
    fork(watchGetRolePermissions),
    fork(watchGetUserPermissions),
    fork(watchCheckSatisfiedRolePermissions),
    fork(watchAddPermissionsByRole),
    fork(watchRemovePermissionsByRole),
    fork(watchUpdateUserPermissions),
  ]);
}
