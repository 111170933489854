import { generateFetchOptions } from 'helpers/fetch';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Edit,
  SimpleForm,
  useNotify,
  useRefresh,
  TopToolbar,
  Button,
  ChipField,
  FormDataConsumer,
  Labeled,
  FunctionField,
  linkToRecord,
  TextInput,
  BooleanField,
  addField,
} from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DropZone from 'now-frontend-shared/components/DropZone';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import { DocumentButton } from 'now-frontend-shared/components/DocumentButton';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  ApprovalStatus,
  isRejectionReasonRequired,
} from 'now-shared/validation/approval-status';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import {
  parseServerError, ServerError,
} from 'now-shared/helpers/server-errors';
import FormGetter from 'components/FormGetter';
import { createAdminResourceRejectionReasonValidator } from 'validations/validateRejectionReason';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
} from 'store/actions/edit-buyer-agreement-actions';
import { getAgreementName, getCompanyName, buyerAgreementName } from 'now-shared/helpers/company-helpers';
import { companyAgreementAcceptFormats } from 'now-shared/validation/company';
import { apiBaseUrl, getAuthQueryParamObject } from 'utils/apiMethods';
import defaultAgreementNonPreFilledPdf from 'now-shared/assets/docs/buyers-agreement.pdf';
import {
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
  isPreFilledAgreement,
  oldNonPreFilledBuyerAgreementVersion,
} from 'now-shared/helpers/agreement-helpers';

// TODO: [FEATURE][FLAG] be able to pass the company to the download request so the server can
// generate a PDF pre-filled for that specific customer
export const doGeneratePreFilledAgreement = false;

const agreementName = buyerAgreementName;

const canApprove = record => (
  doesAgreementHaveDocument(record)
  && record.status.title !== ApprovalStatus.Approved
);
const canReject = record => record.status.title !== ApprovalStatus.Rejected;

const BuyerAgreementEditActions = props => {
  const {
    form,
  } = props;
  const notify = useNotify();
  const refresh = useRefresh();

  const onStatusChange = ({ status, rejectionReason = null }) => {
    const options = generateFetchOptions('PUT', { status, rejectionReason });

    fetch(`${process.env.REACT_APP_API_URL}/buyer-agreements/${props.data.id}/change-status`, options).then(async response => {
      if (response.status === 200) {
        notify('Status changed');
        refresh();
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    });
  };

  return (
    <TopToolbar>
      {props.data && canApprove(props.data) && (
        <Button
          label="APPROVE"
          onClick={() => onStatusChange({ status: 'approved' })}
        >
          <CheckIcon />
        </Button>
      )}
      {props.data && canReject(props.data) && (
        <Button
          label="REJECT"
          onClick={() => onStatusChange({
            status: ApprovalStatus.Rejected,
            rejectionReason: form.getFieldState('rejectionReason').value,
          })}
          disabled={!form}
        >
          <HighlightOffIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

const mapStateToProps = ({ editBuyerAgreement }) => ({
  AWSData: editBuyerAgreement.AWSData,
  AWSDataIsSet: editBuyerAgreement.AWSDataIsSet,
  preSignedUrls: editBuyerAgreement.preSignedUrls,
});

export const EditDocument = compose(
  addField,
  connect(mapStateToProps),
)(props => {
  const {
    AWSData,
    AWSDataIsSet,
    dispatch,
    editable,
    input,
    label,
    preSignedUrls,
    record,
  } = props;

  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  const hadCustomDocument = isCustomAgreement(record);
  const hadPreFilledDocument = isPreFilledAgreement(record);
  const hadOldNonPreFilledDocument = isOldNonPreFilledAgreement(record);

  const isTheSavedDocument = useCallback(
    doc => !!record.document && doc.id === record.document.id,
    [record],
  );

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending]);

  const agreementNameParams = useMemo(() => ({
    baseName: agreementName,
    withExtension: true,
  }), []);

  const inputDocuments = useMemo(
    () => {
      let result = [];
      if (input.value) {
        result = [
          {
            id: input.value.id,
            key: input.value.key,
            filename: input.value.filename || getAgreementName({
              ...agreementNameParams,
              isCustom: !isTheSavedDocument(input.value) || hadCustomDocument,
              isPreFilled: isTheSavedDocument(input.value) && hadPreFilledDocument,
              version: hadOldNonPreFilledDocument ? oldNonPreFilledBuyerAgreementVersion : undefined,
            }),
            ...input.value.downloadUrl && {
              downloadUrl: input.value.downloadUrl,
            },
          },
        ];
      }
      return result;
    },
    [
      input.value,
      agreementNameParams,
      hadCustomDocument,
      hadPreFilledDocument,
      hadOldNonPreFilledDocument,
      isTheSavedDocument,
    ],
  );

  const { onChange } = input;

  const recordDocuments = useMemo(
    () => {
      let result = [];
      if (record?.document) {
        result = [
          {
            /**
             * Just use a fake id to indicate that the file is saved
             */
            id: 1,
            key: record.document.key,
            filename: getAgreementName({
              ...agreementNameParams,
              isCustom: hadCustomDocument,
              isPreFilled: hadPreFilledDocument,
              version: hadOldNonPreFilledDocument ? oldNonPreFilledBuyerAgreementVersion : undefined,
            }),
            downloadUrl: record.document.downloadUrl,
          },
        ];
      }
      return result;
    },
    [
      record,
      agreementNameParams,
      hadCustomDocument,
      hadPreFilledDocument,
      hadOldNonPreFilledDocument,
    ],
  );

  const getDocumentKeys = docs => docs.map(doc => doc.key).join(',');

  const [dropzoneDocumentKeys, setDropzoneDocumentKeys] = useState(undefined);

  useEffect(() => {
    dispatch(setAllAWSData(recordDocuments));
    setDropzoneDocumentKeys(getDocumentKeys(recordDocuments));
  }, [dispatch, recordDocuments]);

  const dropzoneKey = useMemo(() => {
    const key = `${record?.document?.key || 'empty'}-${getDocumentKeys(recordDocuments)}`;
    return key;
  }, [record, recordDocuments]);

  const inputDocumentKeys = useMemo(() => getDocumentKeys(inputDocuments), [inputDocuments]);

  useEffect(
    () => {
      if (AWSDataIsSet) {
        setDropzoneDocumentKeys(getDocumentKeys(AWSData));
      }
    },
    [
      AWSData,
      AWSDataIsSet,
    ],
  );

  useEffect(
    () => {
      if (!AWSDataIsSet || dropzoneDocumentKeys === undefined) {
        return;
      }
      if (inputDocumentKeys === dropzoneDocumentKeys) {
        return;
      }
      onChange(AWSData[AWSData.length - 1] || null);
    },
    [
      onChange,
      AWSData,
      AWSDataIsSet,
      dropzoneDocumentKeys,
      inputDocumentKeys,
    ],
  );

  return (
    <LabelLayout
      name="document"
      label={label}
      space="small"
    >
      {editable && record && (
        <React.Fragment
          key={dropzoneKey}
        >
          <DropZone
            AWSData={AWSData}
            preSignedUrls={preSignedUrls}
            savedDocuments={recordDocuments}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            getPreSignedUrlsCustomData={{
              companyId: record.companyId,
            }}
            removeAWSDataFile={removeAWSDataFile}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            accept={companyAgreementAcceptFormats}
            isSimpleView
            maxFiles={1}
          />
        </React.Fragment>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        {!inputDocuments.length && (
          <DocumentButton
            key="defaultAgreement"
            filename={getAgreementName({
              ...agreementNameParams,
              isCustom: false,
              isPreFilled: !hadOldNonPreFilledDocument && doGeneratePreFilledAgreement,
              version: hadOldNonPreFilledDocument ? oldNonPreFilledBuyerAgreementVersion : 'latest',
            })}
            urlGetter={(
              hadOldNonPreFilledDocument
                ? defaultAgreementNonPreFilledPdf
                : (
                  () => queryString.stringifyUrl({
                    url: `${apiBaseUrl}/buyer-agreement-pdf`,
                    query: {
                      // TODO: [SECURITY][FEATURE] find a way to download the file
                      // without having to send the auth token in the URL?
                      ...getAuthQueryParamObject(),
                      ...doGeneratePreFilledAgreement && {
                        companyId: record.companyId,
                      },
                    },
                  })
                )
            )}
          />
        )}
        {!!inputDocuments.length && inputDocuments.map(
          doc => (
            <React.Fragment
              key={doc.key}
            >
              <DocumentButton
                record={doc}
              />
              {(!isTheSavedDocument(doc) || hadCustomDocument) && (
                <Typography variant="subtitle2">
                  Admin Uploaded (
                  <del>Document Uploaded</del>
                  Agreement Created
                  {' - '}
                  <DateFieldWithTimeZone
                    timeZone={NONOPWELLS_TIME_ZONE}
                    /**
                     * TODO: [BUG] NOW-1253 this is not actually when the document was uploaded or saved,
                     * but this is when the agreement record was created in the database. We don't currently
                     * keep track of when the document was uploaded in the database, however that information
                     * could be pulled from S3.
                     */
                    record={record}
                    source="createdAt"
                    variant="subtitle2"
                    showTime
                  />
                  )
                </Typography>
              )}
            </React.Fragment>
          ),
        )}
      </Grid>
    </LabelLayout>
  );
});

export const BuyerAgreementEdit = props => {
  const notify = useNotify();
  const [finalForm, setFinalForm] = useState(undefined);

  const { current: validateRejectionReason }
    = useRef(createAdminResourceRejectionReasonValidator('status', canReject));

  return (
    <Edit
      {...getStandardCreateEditProps({ notify, ...props })}
      actions={<BuyerAgreementEditActions form={finalForm} />}
    >
      <SimpleForm
        {...getStandardFormProps({ ...props })}
      >
        <FormGetter onForm={setFinalForm} />
        <ChipField source="status.title" label="Status" />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            canReject(formData)
            || formData.status.title === ApprovalStatus.Rejected
          ) && (
            <TextInput
              {...rest}
              source="rejectionReason"
              label="Reason for Rejection"
              isRequired={isRejectionReasonRequired(formData, 'status')}
              disabled={
                // TODO: [INTEGRITY][REQUIREMENTS] don't allow changing rejection reason once it
                // has been saved?
                formData.status.title !== ApprovalStatus.Rejected
                && !canReject(formData)
              }
              fullWidth
              multiline
              minRows={1}
              maxRows={8}
              validate={validateRejectionReason}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.approvedBy && (
            <Labeled label={`${formData.status.title === 'approved' ? 'Approved' : 'Rejected'} By`}>
              <FunctionField
                render={record => record.approvedBy && (
                <LinkButton
                  label={getUserFullName(record.approvedBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.approvedBy.id)}
                />
                )}
                {...rest}
              />
            </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.approvedAt && (
            <Labeled label={`${formData.status.title === 'approved' ? 'Approved' : 'Rejected'} At`}>
              <DateFieldWithTimeZone
                {...rest}
                source="approvedAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
            </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.companyId && (
          <Labeled
            label="Company"
          >
            <FunctionField
              render={record => record.companyId && (
                <LinkButton
                  label={getCompanyName(record.company) || record.companyId}
                  buttonColor="clearGreen"
                  path={linkToRecord('/companies', record.companyId)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <EditDocument
              editable={formData.status.title !== ApprovalStatus.Approved}
              source="document"
              label={`${agreementName} Document`}
              {...rest}
            />
          )}
        </FormDataConsumer>
        {/* TODO: [UX][INTEGRITY] if document is changed, show this as false so user knows what to expect when saving? */}
        <BooleanField
          source="wasSignedOnCreation"
          label="Digitally Signed"
        />
        {/* TODO: [UX][INTEGRITY] if document is changed, remove this section so user knows what to expect when saving? */}
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.wasSignedOnCreation && formData.createdBy && (
          <Labeled
            label="Digitally Signed By Customer"
          >
            <FunctionField
              render={record => record.createdBy && (
                <LinkButton
                  label={getUserFullName(record.createdBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.createdBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        {/* TODO: [UX][INTEGRITY] if document is changed, remove this section so user knows what to expect when saving? */}
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.wasSignedOnCreation && formData.createdAt && (
          <Labeled
            label="Customer Digitally Signed At"
          >
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.updatedBy && (
          <Labeled
            label="Updated By"
          >
            <FunctionField
              render={record => record.updatedBy && (
                <LinkButton
                  label={getUserFullName(record.updatedBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.updatedBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.updatedAt && (
          <Labeled
            label="Updated At"
          >
            <DateFieldWithTimeZone
              source="updatedAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.createdBy && (
          <Labeled
            label="Created By"
          >
            <FunctionField
              render={record => record.createdBy && (
                <LinkButton
                  label={getUserFullName(record.createdBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.createdBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.createdAt && (
          <Labeled
            label="Created At"
          >
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
