/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
} from 'recharts';
import { getListingAnalyticsGraphData } from '../../utils/helpers';
import * as colors from '../../themes/colors';

export const AnalyticsBarGraph = ({
  filterByCompany,
  analytics,
  dateRange,
  selectedDate,
  setFilterByCompany,
  setSelectionModel,
  setSelectedDate,
}) => {
  const data = getListingAnalyticsGraphData({ filterByCompany, analytics, dateRange });
  const handleBarClick = date => setSelectedDate(date);

  return (
    <>
      <BarChart
        width={1200}
        height={400}
        data={data}
        margin={{
          top: 50,
          bottom: 50,
        }}
        barSize={40}
      >
        <XAxis
          dataKey="date"
          tickFormatter={
            d => {
              const formattedDate = moment(d).format(dateRange.usingWeeks ? 'dd MM/DD' : 'MM/DD');
              return formattedDate;
            }
          }
          scale="point"
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          allowDecimals={false}
        />
        <Tooltip
          labelFormatter={
            d => {
              const formattedDate = moment(d).format('ddd MM/DD/YY');
              return dateRange.usingWeeks
                ? `Week starting on ${formattedDate}`
                : formattedDate;
            }
          }
        />
        <Legend />
        <Bar
          dataKey="totalViews"
          name="Total Views"
          fill={colors.primaryMain}
        >
          {
            data.map((entry, index) => (
              <Cell
                fill={selectedDate && !moment(entry.date).isSame(selectedDate)
                  ? colors.primaryMainHalfTone
                  : colors.primaryMain}
                key={`cellViews-${index}-${moment(entry.date).format('MM/DD/YYYY')}`}
                id={`totalViews=${entry.totalViews}`}
                name={`totalViews-${moment(entry.date).format('MM/DD/YYYY')}`}
                onClick={() => handleBarClick(entry.date)}
              />
            ))
          }
        </Bar>
        <Bar
          dataKey="totalDownloads"
          name="Total Downloads"
          fill={colors.primaryDark}
        >
          {
            data.map((entry, index) => (
              <Cell
                fill={selectedDate && !moment(entry.date).isSame(selectedDate)
                  ? colors.primaryDarkHalfTone
                  : colors.primaryDark}
                key={`cellDownloads-${index}-${moment(entry.date).format('MM/DD/YYYY')}`}
                id={`totalDownloads=${entry.totalDownloads}`}
                name={`totalDownloads-${moment(entry.date).format('MM/DD/YYYY')}`}
                onClick={() => handleBarClick(entry.date)}
              />
            ))
          }
        </Bar>
      </BarChart>
      {(filterByCompany || selectedDate) && (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px',
        }}
        >
          {selectedDate && (
            <div style={{
              textAlign: 'center',
              flexGrow: 1,
              fontWeight: 500,
            }}
            >
              {`${moment(selectedDate).format('dddd, MMMM D')}`}
            </div>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              onClick={() => {
                setFilterByCompany(null);
                setSelectionModel([]);
                setSelectedDate(null);
              }}
            >
              {filterByCompany ? 'Clear Company' : 'Clear Date'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

AnalyticsBarGraph.propTypes = {
  filterByCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  selectedDate: PropTypes.instanceOf(Date),
  setFilterByCompany: PropTypes.func.isRequired,
  setSelectionModel: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func,
  analytics: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullLegalCompanyName: PropTypes.string.isRequired,
      operatingName: PropTypes.string,
      views: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.instanceOf(Date).isRequired,
      })).isRequired,
      downloads: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.instanceOf(Date).isRequired,
        documentId: PropTypes.number.isRequired,
        downloadGroup: PropTypes.arrayOf(PropTypes.shape({
        })).isRequired,
      })).isRequired,
      hasBid: PropTypes.bool.isRequired,
    })).isRequired,
    totalBids: PropTypes.number.isRequired,
    totalDownloads: PropTypes.number.isRequired,
    totalViews: PropTypes.number.isRequired,
  }).isRequired,
  dateRange: PropTypes.shape({
    usingWeeks: PropTypes.bool.isRequired,
    rightNow: PropTypes.instanceOf(moment).isRequired,
    listingStartTime: PropTypes.instanceOf(moment).isRequired,
    listingEndTime: PropTypes.instanceOf(moment).isRequired,
    startTime: PropTypes.instanceOf(moment).isRequired,
    endTime: PropTypes.instanceOf(moment).isRequired,
    closeTime: PropTypes.instanceOf(moment).isRequired,
    days: PropTypes.number.isRequired,
  }).isRequired,
};

AnalyticsBarGraph.defaultProps = {
  filterByCompany: null,
  selectedDate: null,
  setSelectedDate: () => {},
};
