import React from 'react';
import {
  FormSpy,
} from 'react-final-form';

import { updateDataProviderFormState } from 'providers/dataProvider';

/**
 * Update the data provider form state when the form changes so that the data provider
 * can send only form values that have changed to the server.
 */
const FormSpyForDataProvider = () => (
  <FormSpy
    subscription={{
      initialValues: true,
      dirtyFields: true,
      submitting: true,
      submitFailed: true,
      submitSucceeded: true,
    }}
    onChange={formState => {
      updateDataProviderFormState(formState);
    }}
  />
);

export default FormSpyForDataProvider;
