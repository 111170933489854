import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  getListingAnalytics,
  createCustomAnalytics,
  updateCustomAnalytics,
  deleteCustomAnalytics,
  getCustomListingAnalytics,
  getListingNotification
} from '../actions/listingAnalyticsActions';

// api methods
import Api from 'api/properties';

function* ensureGetListingAnalytics({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.getListingAnalytics(listingId));
    yield put({ type: getListingAnalytics.success, payload: data });
  } catch (err) {
    yield put({ type: getListingAnalytics.failure, err });
  }
}

function* watchGetListingAnalytics() {
  yield takeLatest(getListingAnalytics.type, ensureGetListingAnalytics);
  yield take(getListingAnalytics.success);
}

function* ensureCreateCustomListingAnalytics({ payload }) {
  try {
    const { data, status } = yield call(Api.createCustomAnalytics, { data: payload });
    if (status === 201) {
      yield put({ type: createCustomAnalytics.success, payload: data });
    }
  } catch (err) {
    yield put({ type: createCustomAnalytics.failure, err });
  }
}

function* watchCreateListingAnalytics() {
  yield takeLatest(createCustomAnalytics.type, ensureCreateCustomListingAnalytics);
  yield take(createCustomAnalytics.success);
}

function* ensureGetCustomListingAnalytics({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.getCustomListingAnalytics(listingId));
    yield put({ type: getCustomListingAnalytics.success, payload: data });
  } catch (err) {
    yield put({ type: getCustomListingAnalytics.failure, err });
  }
}

function* watchGetCustomListingAnalytics() {
  yield takeLatest(getCustomListingAnalytics.type, ensureGetCustomListingAnalytics);
  yield take(getCustomListingAnalytics.success);
}

function* ensureUpdateCustomListingAnalytics({ payload }) {
  try {
    const { data, status } = yield call(Api.updateCustomListingAnalytics(payload.id), { data: payload });
    if (status === 200) {
      yield put({ type: updateCustomAnalytics.success, payload: data });
    }
  } catch (err) {
    yield put({ type: updateCustomAnalytics.failure, err });
  }
}

function* watchUpdateListingAnalytics() {
  yield takeLatest(updateCustomAnalytics.type, ensureUpdateCustomListingAnalytics);
  yield take(updateCustomAnalytics.success);
}

function* ensureDeleteCustomListingAnalytics({ payload: { id } }) {
  try {
    const { status } = yield call(Api.deleteCustomListingAnalytics(id));

    if (status === 204) {
      yield put({ type: deleteCustomAnalytics.success, payload: { id } });
    }
  } catch (err) {
    yield put({ type: deleteCustomAnalytics.failure, err });
  }
}

function* watchDeleteListingAnalytics() {
  yield takeLatest(deleteCustomAnalytics.type, ensureDeleteCustomListingAnalytics);
  yield take(deleteCustomAnalytics.success);
}

function* ensureGetListingNotification({ payload: { listingId } }) {
  try {
    const { data } = yield call(Api.getListingNotifications(listingId));
    yield put({ type: getListingNotification.success, payload: data });
  } catch (err) {
    yield put({ type: getListingNotification.failure, err });
  }
}

function* watchListingNotifications() {
  yield takeLatest(getListingNotification.type, ensureGetListingNotification);
  yield take(getListingNotification.success);
}

export default function* listingAnalyticsSagas() {
  yield all([
    fork(watchGetListingAnalytics),
    fork(watchCreateListingAnalytics),
    fork(watchGetCustomListingAnalytics),
    fork(watchUpdateListingAnalytics),
    fork(watchDeleteListingAnalytics),
    fork(watchListingNotifications),
  ]);
}
