/**
 * @readonly
 * @enum {'reviewCompany'}
 */
export const ComplianceTodoType = {
  /**
   * @readonly
   * @type {'reviewCompany'}
   */
  ReviewCompany: 'reviewCompany',
};

/**
 * @readonly
 */
export const ComplianceTodoTypeTitle = {
  /**
   * @readonly
   */
  [ComplianceTodoType.ReviewCompany]: 'Review Company',
};
