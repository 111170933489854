// actions
import * as A from '../actions/buyer-agreement-template-actions';

const initialState = {
  buyerAgreementTemplate: undefined,
  buyerAgreementTemplateIsLoading: false,
};

const reducer = {
  buyerAgreementTemplate(state = initialState, action) {
    switch (action.type) {
      case A.reset.type:
        return initialState;

      case A.getBuyerAgreementTemplate.type:
        return { ...state, buyerAgreementTemplateIsLoading: true };

      case A.getBuyerAgreementTemplate.failure:
        return { ...state, buyerAgreementTemplateIsLoading: false };

      case A.getBuyerAgreementTemplate.success:
        return {
          ...state,
          buyerAgreementTemplate: action.payload,
          buyerAgreementTemplateIsLoading: false,
        };

      default:
        return state;
    }
  },
};

export default reducer;
