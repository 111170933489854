import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  AutocompleteInput,
  TextInput,
  List,
  Datagrid,
  Create,
  FunctionField,
  BooleanInput,
  BooleanField,
  NumberField,
  Edit,
  SimpleForm,
  TextField,
  Filter,
  TabbedForm,
  FormTab,
  Labeled,
  linkToRecord,
  NullableBooleanInput, useRedirect, TopToolbar, Button,
  useNotify,
  EmailField,
  ChipField,
} from 'react-admin';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {
  getCompanyComplianceInfo,
} from 'store/actions/companies-actions';
import queryString from 'query-string';
import { generateFetchOptions } from '../helpers/fetch';
import { CustomPagination } from '../components/pagination';
import ReferenceManyList from '../components/referenceManyList';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DeleteButtonWithConfirmation from 'components/deleteButtonWithConfirmation';
import DeleteButtonWithDialog from 'components/deleteButtonWithDialog';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { getAllStates } from 'store/actions/states-actions';
import EditIcon from '@material-ui/icons/Edit';
import { nonOpWellsDateTime, NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { DataGrid } from '@material-ui/data-grid';
import IconSaveAlt from '@material-ui/icons/SaveAlt';
import AddDocumentIcon from '@material-ui/icons/NoteAdd';
import ComplianceIcon from '@material-ui/icons/Gavel';
import Typography from '@material-ui/core/Typography';
import { DocumentButton } from 'now-frontend-shared/components/DocumentButton';
import { apiBaseUrl } from 'utils/apiMethods';
import Api from 'api/companies';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { getUserData } from 'auth/auth-helpers';
import {
  disabledWrapper,
  isUiValidationDisabled,
  validateWrapper,
} from 'now-frontend-shared/helpers/ui-validation-toggle';
import {
  defaultValuesForCreateCompany,
  fieldsNotForCreate,
  hasPermissionToEditCompanyField,
  isFieldRequired,
  rejectionReasonApprovalStatusField,
  validateAdminUpsertCompany,
} from 'now-shared/validation/company';
import {
  ApprovalStatus,
  getAllApprovalStatuses,
  toStatusName,
} from 'now-shared/validation/approval-status';
import FormSpyForDataProvider from 'components/FormSpyForDataProvider';
import FormDataConsumer from 'components/FormDataConsumer';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  saveCompanyComplianceDocuments,
  getCompanyComplianceDocuments,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
  deleteCompanyComplianceDocument,
} from 'store/actions/company-admin-documents';
import DropZone from 'now-frontend-shared/components/DropZone';
import { SortDirection } from 'now-shared/enums/sort-direction';
import {
  parseServerError,
  ServerError,
} from 'now-shared/helpers/server-errors';
import { buyerAgreementName, sellerAgreementName } from 'now-shared/helpers/company-helpers';
import { viewOnlyListingEditorRoleTitle } from 'now-shared/config/role-constants';

export const CompaniesFilter = props => (
  <Filter {...props}>
    <TextInput label="Company" source="search" alwaysOn />
    <NullableBooleanInput label="Active" source="active" alwaysOn />
    <NullableBooleanInput label="Approved" source="approved" alwaysOn />
  </Filter>
);

const CompanyEditActions = ({ data }) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const resourceMapping = {
    'seller-agreements': 'sellerAgreement',
    'buyer-agreements': 'buyerAgreement',
    'bank-information': 'bankInformation',
  };

  const makeRedirectTo = resource => () => {
    redirect(`/${resource}/${data[resourceMapping[resource]].id}`);
  };

  const makeCompanyFormButton = ({ resourceSingular, formTitle }) => {
    const resourcePlural = resourceSingular === 'bank-information' ? resourceSingular : `${resourceSingular}s`;
    const agreementProperty = resourceMapping[resourcePlural];
    return (
      <Button
        label={`${data[agreementProperty] ? '' : 'Create '}${formTitle}`}
        onClick={(
          data[agreementProperty]
            ? makeRedirectTo(resourcePlural)
            : async () => {
              // eslint-disable-next-line no-alert
              const forSure = window.confirm(
                `Are you sure you want to create a new "${formTitle}" record for this company?`,
              );
              if (!forSure) {
                return;
              }
              const options = generateFetchOptions('POST');

              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/companies/${data.id}/${resourceSingular}`,
                options,
              );
              if (response.status === 201) {
                notify(`${formTitle} created`);
                const result = await response.json();
                redirect(`/${resourcePlural}/${result.id}`);
              } else {
                let message;
                try {
                  message = parseServerError(await response.json()).friendlyMessage;
                } catch (error) {
                  message = ServerError.Generic;
                }
                notify(message, 'warning');
              }
            }
        )}
      >
        {data[agreementProperty] ? (
          <EditIcon />
        ) : (
          <AddDocumentIcon />
        )}
      </Button>
    );
  };

  return (
    <TopToolbar style={{ alignItems: 'center' }}>
      {data && makeCompanyFormButton({ resourceSingular: 'seller-agreement', formTitle: sellerAgreementName })}
      {data && makeCompanyFormButton({ resourceSingular: 'buyer-agreement', formTitle: buyerAgreementName })}
      {data && makeCompanyFormButton({ resourceSingular: 'bank-information', formTitle: 'Bank Details' })}
      {data && (
        <>
          <Button label="Compliance" onClick={() => redirect(`/companies/${data.id}/compliance-doc`)}>
            <ComplianceIcon />
          </Button>
          <DocumentButton
            label="PDF"
            useDirectLink
            urlGetter={
              async () => {
                let accessToken;
                try {
                  ({ data: accessToken } = await Api.getCompanyComplianceDocToken(data.id)());
                } catch (e) {
                  notify('Error getting access token', 'warning');
                  throw e;
                }
                return queryString.stringifyUrl({
                  url: `${apiBaseUrl}/admin/companies/${data.id}/compliance-doc`,
                  query: {
                    [apiAuthTokenQueryParamName]: accessToken,
                  },
                }, {
                  arrayFormat: 'bracket',
                });
              }
            }
          />
        </>
      )}
    </TopToolbar>
  );
};

export const CompanyList = props => (
  <List
    {...props}
    filters={<CompaniesFilter />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <TextField source="fullLegalCompanyName" label="Name" />
      <BooleanField source="active" label="Active" />
      <BooleanField source="approved" label="Approved" />
      <NumberField source="userCount" label="Users" sortable={false} />
      <BooleanField source="hasSellerAgreement" label="Seller's Agreement" />
      <BooleanField source="hasBuyerAgreement" label="Buyer's Agreement" />
      <BooleanField source="hasBankInformation" label="Bank Details" />
      <ChipField source="representativeApprovalStatus" label="R.R. Approval" />
      <ChipField source="supervisorApprovalStatus" label="Supervisor Approval" />
    </Datagrid>
  </List>
);

const useCompanyFormItems = ({
  states,
  formType,
  isLoadingStates,
}) => {
  const userData = useMemo(
    () => getUserData(),
    [],
  );

  const hasPermissionToEdit = (values, field) => hasPermissionToEditCompanyField(
    userData,
    values,
    field,
  );

  const isForCreate = formType === 'create';

  const shouldShowField = useCallback((values, field, form) => {
    const fieldState = form?.getFieldState(field);
    let result;
    if (isUiValidationDisabled()) {
      result = true;
    } else if (fieldState?.dirty) {
      result = true;
    } else if (
      Object.keys(rejectionReasonApprovalStatusField).includes(field)
      && toStatusName(values[rejectionReasonApprovalStatusField[field]]) !== ApprovalStatus.Rejected
    ) {
      result = false;
    } else if (values[field]) {
      result = true;
    } else if (isForCreate && fieldsNotForCreate.includes(field)) {
      result = false;
    } else {
      result = true;
    }

    return result;
  }, [isForCreate]);

  const shouldDisableField = (values, field, checkCustom) => disabledWrapper(() => {
    let result = false;
    if (!hasPermissionToEdit(values, field)) {
      result = true;
    } else if (checkCustom) {
      result = checkCustom(field, values);
    }
    return result;
  });

  return [
    <FormDataConsumer
      key="active"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'active', form) && (
        <BooleanInput
          {...rest}
          source="active"
          label="Active"
          isRequired={isFieldRequired(formData, 'active')}
          disabled={shouldDisableField(formData, 'active')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="fullLegalCompanyName"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'fullLegalCompanyName', form) && (
        <TextInput
          {...rest}
          source="fullLegalCompanyName"
          label="Legal Name"
          isRequired={isFieldRequired(formData, 'fullLegalCompanyName')}
          disabled={shouldDisableField(formData, 'fullLegalCompanyName')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="operatingName"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'operatingName', form) && (
        <TextInput
          {...rest}
          source="operatingName"
          label="Operating Name"
          isRequired={isFieldRequired(formData, 'operatingName')}
          disabled={shouldDisableField(formData, 'operatingName')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="businessStreetAddress"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'businessStreetAddress', form) && (
        <TextInput
          {...rest}
          source="businessStreetAddress"
          label="Business Street Address"
          isRequired={isFieldRequired(formData, 'businessStreetAddress')}
          disabled={shouldDisableField(formData, 'businessStreetAddress')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="businessStreetAddress2"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'businessStreetAddress2', form) && (
        <TextInput
          {...rest}
          source="businessStreetAddress2"
          label="Address Line 2"
          isRequired={isFieldRequired(formData, 'businessStreetAddress2')}
          disabled={shouldDisableField(formData, 'businessStreetAddress2')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="city"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'city', form) && (
        <TextInput
          {...rest}
          source="city"
          label="City"
          isRequired={isFieldRequired(formData, 'city')}
          disabled={shouldDisableField(formData, 'city')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="stateId"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'stateId', form) && (
        <AutocompleteInput
          {...rest}
          source="stateId"
          label="State"
          choices={states}
          allowEmpty
          emptyValue={null}
          emptyText="<none>"
          isRequired={isFieldRequired(formData, 'stateId')}
          disabled={isLoadingStates || shouldDisableField(formData, 'stateId')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="zipCode"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'zipCode', form) && (
        <TextInput
          {...rest}
          source="zipCode"
          label="Zip Code"
          isRequired={isFieldRequired(formData, 'zipCode')}
          disabled={shouldDisableField(formData, 'zipCode')}
        />
      )}
    </FormDataConsumer>,
    ...!isForCreate ? [
      <FunctionField
        key="enableSellerRegistration"
        addLabel={false}
        render={record => (
          <BooleanInput
            source="enableSellerRegistration"
            label="Enable Seller Dashboard View"
            disabled={record.hasSellerAgreement}
          />
        )}
      />,
      <FunctionField
        key="enableBuyerRegistration"
        addLabel={false}
        render={record => (
          <BooleanInput
            source="enableBuyerRegistration"
            label="Enable Buyer Dashboard View"
            disabled={record.hasBuyerAgreement && record.hasBankInformation}
          />
        )}
      />,
      <BooleanField
        key="hasSellerAgreement"
        source="hasSellerAgreement"
        label="Seller's Agreement"
      />,
      <BooleanField
        key="hasBuyerAgreement"
        source="hasBuyerAgreement"
        label="Buyer's Agreement"
      />,
      <BooleanField
        key="hasBankInformation"
        source="hasBankInformation"
        label="Bank Details"
      />,
    ] : [],
    <FormDataConsumer
      key="approved"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'approved', form) && (
        <BooleanInput
          {...rest}
          source="approved"
          label="Approved"
          isRequired={isFieldRequired(formData, 'approved')}
          disabled={shouldDisableField(formData, 'approved')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="complianceNotes"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'complianceNotes', form) && (
        <TextInput
          {...rest}
          source="complianceNotes"
          label="Compliance Notes"
          multiline
          isRequired={isFieldRequired(formData, 'complianceNotes')}
          disabled={shouldDisableField(formData, 'complianceNotes')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="representativeApprovalStatus"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'representativeApprovalStatus', form) && (
        <AutocompleteInput
          {...rest}
          source="representativeApprovalStatus"
          label="Reg. Rep. Approval"
          choices={getAllApprovalStatuses().map(status => ({
            id: status,
            name: status,
          }))}
          allowEmpty
          emptyValue={null}
          emptyText="<none>"
          isRequired={isFieldRequired(formData, 'representativeApprovalStatus')}
          disabled={shouldDisableField(formData, 'representativeApprovalStatus')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="representativeRejectionReason"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'representativeRejectionReason', form) && (
        <TextInput
          {...rest}
          source="representativeRejectionReason"
          label="R.R. Rejection Reason"
          multiline
          isRequired={isFieldRequired(formData, 'representativeRejectionReason')}
          disabled={shouldDisableField(formData, 'representativeRejectionReason')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="supervisorApprovalStatus"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'supervisorApprovalStatus', form) && (
        <AutocompleteInput
          {...rest}
          source="supervisorApprovalStatus"
          label="C. Supervisor Approval"
          choices={getAllApprovalStatuses().map(status => ({
            id: status,
            name: status,
          }))}
          allowEmpty
          emptyValue={null}
          emptyText="<none>"
          isRequired={isFieldRequired(formData, 'supervisorApprovalStatus')}
          disabled={shouldDisableField(formData, 'supervisorApprovalStatus')}
        />
      )}
    </FormDataConsumer>,
    <FormDataConsumer
      key="supervisorRejectionReason"
    >
      {({ formData, form, ...rest }) => shouldShowField(formData, 'supervisorRejectionReason', form) && (
        <TextInput
          {...rest}
          source="supervisorRejectionReason"
          label="C.S. Rejection Reason"
          multiline
          isRequired={isFieldRequired(formData, 'supervisorRejectionReason')}
          disabled={shouldDisableField(formData, 'supervisorRejectionReason')}
        />
      )}
    </FormDataConsumer>,
  ];
};

const getSharedFormProps = ({ isForCreate, ...props }) => ({
  ...getStandardFormProps({ isForCreate, ...props }),
  validate: validateWrapper(validateAdminUpsertCompany),
  redirect: '/companies',
});

function getSteps() {
  return ['Company Details', 'Business Address', 'Entity Description', 'Register Account', 'Beneficial Owner', 'Approved'];
}

const CompanyEditForm = propsAll => {
  const {
    states,
    formType,
    isLoadingStates,
    ...props
  } = propsAll;

  const notify = useNotify();
  const dispatch = useDispatch();

  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending]);

  useEffect(() => {
    if (props.id) {
      dispatch(getCompanyComplianceDocuments({ companyId: props.id }));
    }
  }, [dispatch, props.id]);

  const columns = [
    { field: 'id', headerName: 'Document Id', flex: 1 },
    { field: 'filename', headerName: 'Document Name', flex: 1 },
    {
      field: 'updatedAt', headerName: 'Uploaded At', flex: 1, renderCell: params => `${nonOpWellsDateTime(new Date(params.row.updatedAt)).toFormat('MM / dd / yyyy / t ZZZZ')}`,
    },
    {
      field: 'createdByName', headerName: 'Uploaded By', flex: 1,
    },
    {
      field: 'download', headerName: 'Download', flex: 1, renderCell: params => <a href={params.row.downloadUrl} download target="_blank" style={{ color: '#3f51b5' }} rel="noreferrer"><IconSaveAlt /></a>,
    },
    {
      field: 'Delete', headerName: 'Delete', flex: 1, renderCell: params => <DeleteButtonWithDialog document={params} onDelete={() => deleteCompanyComplianceDocument({ companyId: params?.row?.companyId, documentId: params?.row?.id })} />,
    },
  ];

  return (
    <>
      <Edit
        {...getStandardCreateEditProps({ notify, ...props })}
        actions={<CompanyEditActions />}
      >
        <TabbedForm
          {...getSharedFormProps({ ...props })}
        >
          <FormTab label="Company">
            <FormSpyForDataProvider />
            {useCompanyFormItems(propsAll)}
            <Labeled
              label="Created At"
            >
              <DateFieldWithTimeZone
                source="createdAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
            </Labeled>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.createdBy && (
              <Labeled
                label="Created By"
              >
                <FunctionField
                  render={record => record.createdBy && (
                    <LinkButton
                      label={getUserFullName(record.createdBy)}
                      buttonColor="clearGreen"
                      path={linkToRecord('/users', record.createdBy.id)}
                    />
                  )}
                  { ...rest }
                />
              </Labeled>
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => (formData.updatedAt !== formData.createdAt) && (
              <Labeled label="Updated At">
                <DateFieldWithTimeZone
                  { ...rest }
                  source="updatedAt"
                  showTime
                  timeZone={NONOPWELLS_TIME_ZONE}
                />
              </Labeled>
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.updatedBy && (
              <Labeled
                label="Updated By"
              >
                <FunctionField
                  render={record => record.updatedBy && (
                    <LinkButton
                      label={getUserFullName(record.updatedBy)}
                      buttonColor="clearGreen"
                      path={linkToRecord('/users', record.updatedBy.id)}
                    />
                  )}
                  { ...rest }
                />
              </Labeled>
              )}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Users">
            <ReferenceManyList
              reference="users"
              apiResource="companies"
              {...props}
            >
              <Datagrid rowClick="edit">
                <NumberField source="id" label="ID" />
                <DateFieldWithTimeZone
                  source="createdAt"
                  label="Created"
                  timeZone={NONOPWELLS_TIME_ZONE}
                />
                <BooleanField source="active" label="Active" />
                <BooleanField source="isVerifiedEmail" label="Email Verified" />
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name" />
                <EmailField source="email" label="Email" />
                <BooleanField source="isViewOnlyListingEditor" label={viewOnlyListingEditorRoleTitle} />
                <BooleanField source="isAuthorizedSigner" label="Authorized Signer" />
                <BooleanField source="isAccountManager" label="Account Manager" />
                <TextField source="jobTitle" label="Job Title" />
                <TextField source="approvalStatus.title" label="Approval Status" sortBy="approvalStatus" />
              </Datagrid>
            </ReferenceManyList>
          </FormTab>
        </TabbedForm>
      </Edit>
      {propsAll.location?.pathname?.split('/').length === 3 && (
      <div style={{ marginTop: '100px', width: '100%' }}>
        <Typography variant="h6">
          Company Related Documents
        </Typography>
        <DataGrid
          style={{ margin: '15px 0' }}
          rows={props.currentDocuments}
          columns={columns}
          autoHeight
          autoPageSize
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
        />
        <div style={{
          width: '50%',
          margin: '35px auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        >
          <DropZone
            AWSData={props.AWSData}
            preSignedUrls={props.preSignedUrls}
            savedDocuments={props.currentDocuments}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            removeAWSDataFile={removeAWSDataFile}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            placeholder="Upload any additional compliance related documents here."
            isSimpleView
            hideSaved
          />
          <Button
            disabled={
              !props.AWSData.find(doc => !doc.id)
              || uploadIsPending
            }
            onClick={() => dispatch(saveCompanyComplianceDocuments({ AWSData: props.AWSData, companyId: props.id }))}
            variant="contained"
          >
            <span style={{ fontSize: '14px' }}>Save Uploaded</span>
          </Button>
        </div>
      </div>
      )}
    </>
  );
};

const mapStateToProps = ({ companyAdminDocuments, states, companies }) => ({
  AWSData: companyAdminDocuments.AWSData,
  currentDocuments: companyAdminDocuments.currentDocuments,
  AWSDataIsSet: companyAdminDocuments.AWSDataIsSet,
  getPreSignedUrls,
  preSignedUrls: companyAdminDocuments.preSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
  states: states.states,
  isLoadingStates: states.statesLoading,
  companyData: companies.companyComplianceInfo,
});

const CreateEditComponent = compose(
  connect(mapStateToProps),
)(props => {
  const {
    states,
    formType,
    isLoadingStates,
    ...propsRest
  } = props;

  const dispatch = useDispatch();
  const notify = useNotify();

  useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  const formItems = useCompanyFormItems(props);
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const companyData = useSelector(state => state.companies.companyComplianceInfo);

  useEffect(() => {
    dispatch(getCompanyComplianceInfo({
      companyId: props.id,
    }));
  }, [dispatch, props.id]);

  useEffect(() => {
    if (companyData?.approved) {
      setActiveStep(6);
    } else if (companyData?.isPublicCompany || companyData?.BO25PercentOfCompanyOrMore !== null) {
      setActiveStep(5);
    } else if (companyData?.isPublicCompany !== null) {
      setActiveStep(4);
    } else if (companyData?.optionBestSuitsEntity1 || companyData?.optionBestSuitsEntity2) {
      setActiveStep(3);
    } else if (companyData?.businessStreetAddress) {
      setActiveStep(2);
    } else if (companyData?.fullLegalCompanyName) {
      setActiveStep(1);
    }
  }, [companyData]);

  return formType === 'create' ? (
    <Create
      {...getStandardCreateEditProps({ notify, isForCreate: true, ...propsRest })}
    >
      <SimpleForm
        {...getSharedFormProps({ isForCreate: true, ...propsRest })}
        initialValues={defaultValuesForCreateCompany}
      >
        <FormSpyForDataProvider />
        {formItems}
      </SimpleForm>
    </Create>
  ) : (
    <>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label} data-cy={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CompanyEditForm {...props} />
    </>
  );
});

export const CompanyCreate = props => (
  <CreateEditComponent formType="create" {...props} />
);

export const CompanyEdit = props => (
  <CreateEditComponent formType="edit" {...props} />
);
