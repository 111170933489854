/* eslint-disable no-console */
import React, {
  memo, useEffect, useCallback, useMemo,
} from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import moment from 'moment';

// styled error field
import ErrorField from '../inputs/ErrorField';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// helpers
import { setLocalZone, setOtherZone } from 'now-shared/helpers/time-helpers';

// styles
import styles from './styles';

const DateTimePickerShared = ({
  'data-cy': dataCy,
  input,
  meta,
  minDate,
  maxDate,
  timeZone,
  disabled,
}) => {
  const isError = meta.touched && meta.error;

  const {
    value, onBlur, onChange, ...inputRest
  } = input;

  function convertToCentralTime(inputValue) {
    const dateTime = DateTime.fromJSDate(new Date(inputValue));
    const centralTime = dateTime.setZone('America/Chicago');
    return centralTime.toFormat(
      "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZ '(Central Time)'",
    );
  }

  const translatedValue = useMemo(() => {
    let result;
    if (
      input.value
      && input.value instanceof Date
      && !Number.isNaN(input.value.getTime())
    ) {
      const centralTime = convertToCentralTime(input.value);

      const parsedDate = moment(
        centralTime,
        'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
      );
      const formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm');

      result = formattedDate;
    }
    return result;
  }, [input.value]);

  const translatedMinDate = useMemo(
    () => minDate && (timeZone ? setLocalZone(minDate, timeZone) : minDate),
    [minDate, timeZone],
  );

  const translatedMaxDate = useMemo(
    () => maxDate && (timeZone ? setLocalZone(maxDate, timeZone) : maxDate),
    [maxDate, timeZone],
  );

  const changeDate = useCallback(
    newDate => input.onChange(timeZone ? setOtherZone(newDate, timeZone) : newDate),
    [input, timeZone],
  );

  useEffect(() => {
    if (translatedValue) {
      if (translatedMinDate && translatedValue < translatedMinDate) {
        changeDate(translatedMinDate);
      } else if (translatedMaxDate && translatedValue > translatedMaxDate) {
        changeDate(translatedMaxDate);
      }
    }
  }, [changeDate, translatedMinDate, translatedMaxDate, translatedValue]);

  const handleChange = e => {
    const date = new Date(e.target.value);
    const transformedDate = moment(date, 'MM.DD.YYYY').toDate();
    changeDate(transformedDate);
  };

  const inputContainerStyle = {
    position: 'relative',
    display: 'inline-block',
  };

  const inputStyle = {
    padding: '10px',
    borderRadius: '4px',
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
    fontWeight: '500',
    fontFamily: 'inherit',
    fontSize: '14px',
  };

  return (
    <Grid data-cy={dataCy} container direction="column">
      <div style={inputContainerStyle}>
        <input
          value={translatedValue}
          aria-label="Date and time"
          type="datetime-local"
          locale="en-US"
          style={inputStyle}
          disabled={disabled}
          onBlur={() => onBlur?.()}
          onChange={handleChange}
          {...inputRest}
        />
      </div>

      <ErrorField error={isError ? meta.error : null} />
    </Grid>
  );
};

DateTimePickerShared.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  'data-cy': PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  timeZone: PropTypes.string,
  disabled: PropTypes.bool,
};

DateTimePickerShared.defaultProps = {
  'data-cy': undefined,
  minDate: undefined,
  maxDate: undefined,
  required: false,
  timeZone: undefined,
  disabled: false,
};

export default compose(withStyles(styles), memo)(DateTimePickerShared);
