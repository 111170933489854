import { asyncAction, action } from 'now-frontend-shared/utils/actions';

export const getListingAnalytics = asyncAction('listingAnalytics/GET_ANALYTICS');
export const createCustomAnalytics = asyncAction('listingAnalytics/CREATE_CUSTOM_ANALYTICS');
export const updateCustomAnalytics = asyncAction('listingAnalytics/UPDATE_CUSTOM_ANALYTICS');
export const deleteCustomAnalytics = asyncAction('listingAnalytics/DELETE_CUSTOM_ANALYTICS');
export const getCustomListingAnalytics = asyncAction('listingAnalytics/GET_CUSTOM_ANALYTICS');
export const getListingNotification = asyncAction('listingNotifications/GET_LISTING_NOTIFICATIONS');
// sync actions
export const clearListingAnalyticsState = action('listingAnalytics/CLEAR_STATE');
