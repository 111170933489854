import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, useNotify } from 'react-admin';
import { parseServerError, ServerError } from 'now-shared/helpers/server-errors';
import { generateFetchOptions } from '../../helpers/fetch';
import HandlebarEditor from '../HandleBarEditor';
import styles from './styles';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';

export const EditEmailNotificationModal = ({ title, notificationType }) => {
  const [showDailog, setShowDialog] = useState(false);
  const [content, setContent] = useState('');
  const [dummyTemplateData, updateDummyData] = useState({});

  const notify = useNotify();

  const fetchCurrentEmailNotification = async () => {
    const options = generateFetchOptions('GET');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/custom-notification/get-custom-email-notification/${notificationType}`, options);
    const payload = await response.json();
    if (response.status === 200 && payload && payload.content) {
      setContent(payload.content);
    } else {
      let message;
      try {
        message = parseServerError(await response.json()).friendlyMessage;
      } catch (error) {
        message = ServerError.Generic;
      }
      notify(message, 'warning');
    }
  };

  useEffect(() => {
    if (showDailog) {
      fetchCurrentEmailNotification();
    }
  }, [showDailog]);

  const toggleEditEmailNotification = () => {
    setShowDialog(!showDailog);
  };

  const [isEditor, toggleEditor] = useState(false);

  const [finalTemplate, updateFinalTemplate] = useState('');

  const onSave = async () => {
    const options = generateFetchOptions('PUT', { updatedTemplate: finalTemplate });
    const response = await fetch(`${process.env.REACT_APP_API_URL}/custom-notification/update-custom-email-notification/${notificationType}`, options);
    if (response.status === 200) {
      notify('Successfully updated');
      toggleEditEmailNotification();
    } else {
      let message;
      try {
        message = parseServerError(await response.json()).friendlyMessage;
      } catch (error) {
        message = ServerError.Generic;
      }
      notify(message, 'warning');
    }
  };

  const SendEmail = () => {
    const [testEmail, updateEmail] = useState('');
    const sendEmailHandler = async () => {
      const {
        hasCompan, isAccountManager, isAdminOrCompliancePerson, isSelfVerified, resetPasswordLink,
      } = dummyTemplateData;
      // eslint-disable-next-line no-nested-ternary
      const templateVariables = notificationType === NotificationTypeName.WelcomeEmail
        ? {
          hasCompan, isAccountManager, isAdminOrCompliancePerson, isSelfVerified, resetPasswordLink,
        } : notificationType === NotificationTypeName.PreDashboardRegistrationApproved
          ? { isAccountManager }
          : {};
      const options = generateFetchOptions('POST', {
        template: finalTemplate || content, email: testEmail, ...templateVariables,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/listings/push-custom-test-notification/${notificationType}`, options);
      if (response.status === 204) {
        notify('Email has been sent successfully');
      } else {
        let message;
        try {
          message = parseServerError(await response.json()).friendlyMessage;
        } catch (error) {
          message = ServerError.Generic;
        }
        notify(message, 'warning');
      }
    };

    return (
      <Box style={styles.emailWrapper}>
        <Box style={styles.emailContainer}>
          <input
            onChange={(e) => {
              e.preventDefault();
              updateEmail(e.target.value);
            } }
            value={testEmail}
            style={styles.emailInputField}
            type="email"
            placeholder="Enter test email..."
          />
          <Button variant="outlined" label="Send" onClick={sendEmailHandler} />
        </Box>
      </Box>
    );
  };

  const onCancelHandler = () => {
    toggleEditEmailNotification();
    updateFinalTemplate('');
  };

  return (
    <Box>
      <Dialog
        fullWidth
        open={showDailog}
        maxWidth="md"
        onClose={toggleEditEmailNotification}
      >
        <DialogTitle>
          Edit Email Notification
        </DialogTitle>
        <hr />
        <DialogContent style={styles.dialogContent}>
          {(finalTemplate || content) && (
            <HandlebarEditor
              updateFinalTemplate={updateFinalTemplate}
              isEditor={isEditor}
              content={finalTemplate || content}
              notificationType={notificationType}
              updateDummyDataHandler={updateDummyData}
            />
          )}
          <Box style={{ width: 600, marginTop: 20 }}>
            {!isEditor && <SendEmail />}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button label={isEditor ? 'Preview' : 'Edit'} onClick={() => toggleEditor(!isEditor)} />
          <Button label="Save" onClick={onSave} />
          <Button label="Cancel" onClick={onCancelHandler} />
        </DialogActions>
      </Dialog>
      <Button variant="outlined" color="secondary" label={title || 'Edit Email Notification'} onClick={toggleEditEmailNotification} style={{ margin: 5 }} />
    </Box>
  );
};

EditEmailNotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
};
