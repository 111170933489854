import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getCurrentProperty = asyncAction('cloneListing/GET_CURRENT_PROPERTY');
export const getStates = asyncAction('cloneListing/GET_STATES');
export const getBasins = asyncAction('cloneListing/GET_BASINS');
export const getCounties = asyncAction('cloneListing/GET_COUNTIES');
export const getLandingZones = asyncAction('cloneListing/GET_LANDING_ZONES');
export const getPreSignedUrls = asyncAction('cloneListing/GET_PRE_SIGNED_URLS');

// sync action
export const reset = action('cloneListing/RESET');
export const setBasinId = action('cloneListing/SET_BASIN_ID');
export const setStateId = action('cloneListing/SET_STATE_ID');
export const setAWSData = action('cloneListing/SET_AWS_DATA');
export const removeAWSDataFile = action('cloneListing/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('cloneListing/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('cloneListing/SET_UNLOADED_FILES_EXIST');
