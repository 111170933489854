import validator from 'validator';
import { hasValue } from './validation-rules';

export const validateLatLong = type => (
  value => {
    if (hasValue(value)) {
      if (type === 'latitude' && !validator.isLatLong(`${value},0`)) {
        return 'Must be correct latitude';
      }

      if (type === 'longitude' && !validator.isLatLong(`0,${value}`)) {
        return 'Must be correct longitude';
      }
    }
    return undefined;
  }
);
