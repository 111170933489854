import {
  lighterGrey,
  darkBlue,
  navyBlue,
} from 'now-frontend-shared/themes/colors';

export { midGrey } from 'now-frontend-shared/themes/colors';
export { lightGreen } from 'now-frontend-shared/themes/colors';
export { lightRed } from 'now-frontend-shared/themes/colors';

export const styles = theme => {
  const commonStyles = {
    alignItems: 'center',
    borderColor: lighterGrey,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: '5px',
    height: '44px',
  };

  return {
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '70%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2.5, 2.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '8px',
    },
    sectionHeader: {
      marginLeft: theme.spacing(1),
      fontWeight: 600,
      fontSize: 16,
      color: '#333333',
    },
    sectionHeaderWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),
    },
    searchAndFilterWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    categorySectionsContainer: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'auto',
      overflowY: 'auto',
    },
    categorySectionWrapper: {
      marginBottom: theme.spacing(1.5),
    },
    categoryDivider: {
      background: '#E0E0E0',
      height: '1px',
    },
    categorySectionHeader: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(1),
      fontWeight: 500,
      fontSize: 16,
      color: '#333333',
    },
    permissionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxHeight: 'auto',
    },
    permissionWrapper: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: lighterGrey,
      borderRadius: '5px',
      margin: theme.spacing(1),
      paddingRight: theme.spacing(1.5),
      whiteSpace: 'nowrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      flexGrow: 1,
      '& .MuiInputBase-root': {
        flexGrow: 1,
        width: '95%',
      },
      ...commonStyles,
    },
    selectorFilter: {
      marginLeft: theme.spacing(1.5),
      display: 'flex',
      paddingRight: theme.spacing(1.5),
      textTransform: 'none',
      ...commonStyles,
    },
    selectInput: {
      color: theme.palette.text.primary,
      width: '100%',
      paddingLeft: theme.spacing(1),
    },
    footerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    messagesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    saveButton: {
      float: 'right',
      color: 'white',
      backgroundColor: darkBlue,
      paddingLeft: theme.spacing(1.6),
      paddingRight: theme.spacing(1.6),
      minWidth: '120px',
      whiteSpace: 'nowrap',
    },
    highlightBlue: {
      paddingLeft: theme.spacing(1.25),
      color: navyBlue,
    },
    highlightRed: {
      paddingLeft: theme.spacing(1.25),
      color: 'red',
    },
  };
};
