import { REQUIRED } from './validation-rules';
import { formatNumberToCurrency, maskedAmountToNumber } from '../helpers/currency-helpers';

export default function validateFormattedCurrency(formattedCurrency, options) {
  let result;
  const amount = maskedAmountToNumber(formattedCurrency);
  if (amount !== undefined) {
    if (options?.moreThan !== undefined && amount <= options.moreThan) {
      result = `Must be more than ${options?.noFormat ? options.moreThan : formatNumberToCurrency(options.moreThan)}`;
    } else if (options?.atLeast !== undefined && amount < options.atLeast) {
      result = `Must be at least ${options?.noFormat ? options.atLeast : formatNumberToCurrency(options.atLeast)}`;
    } else if (options?.positive !== undefined && amount <= 0) {
      result = `Must be more than ${options?.noFormat ? 0 : formatNumberToCurrency(0)}`;
    } else if (options?.atMost !== undefined && amount > options.atMost) {
      result = `Must be no more than ${options?.noFormat ? options.atMost : formatNumberToCurrency(options.atMost)}`;
    }
  } else if (options?.required) {
    result = REQUIRED;
  }
  return result;
}
