// actions
import * as A from '../actions/permissions-actions';

const initialState = {
  permissions: [],
  permissionCategories: [],
  permissionsLoading: false,
  permissionsLoaded: false,
  userPermissions: [],
  userPermissionsLoading: false,
  userPermissionsLoaded: false,
  userPermissionsUpdating: false,
  userPermissionsUpdated: false,
  rolePermissions: [],
  rolePermissionsLoading: false,
  rolePermissionsLoaded: false,
  satisfiedRolePermissions: [],
  satisfiedRolePermissionsLoaded: false,
  adminSitePermissionIdsSet: new Set([]),
  error: null,
};

export default {
  permissions(state = initialState, action) {
    switch (action.type) {
      case A.getAllPermissions.type:
        return {
          ...state,
          permissionsLoading: true,
          error: null,
        };

      case A.getAllPermissions.success:
        return {
          ...state,
          permissions: action.payload,
          permissionCategories: action.payload.reduce((acc, permission) => {
            if (!acc.includes(permission.category)) {
              acc.push(permission.category);
            }
            return acc;
          }, []),
          permissionsLoading: false,
          permissionsLoaded: true,
        };

      case A.getAllPermissions.failure:
        return {
          ...state,
          permissionsLoading: false,
          error: action.payload,
        };

      case A.getRolePermissions.type:
        return {
          ...state,
          rolePermissionsLoading: true,
          error: null,
        };

      case A.getRolePermissions.success:
        return {
          ...state,
          rolePermissions: action.payload.data,
          adminSitePermissionIdsSet: action.payload.adminSitePermissionIdsSet,
          rolePermissionsLoading: false,
          rolePermissionsLoaded: true,
        };

      case A.getRolePermissions.failure:
        return {
          ...state,
          rolesLoading: false,
          error: action.payload,
        };

      case A.getUserPermissions.type:
        return {
          ...state,
          userPermissionsLoading: true,
          error: null,
        };

      case A.getUserPermissions.success:
        return {
          ...state,
          userPermissions: action.payload,
          userPermissionsLoading: false,
          userPermissionsLoaded: true,
        };

      case A.getUserPermissions.failure:
        return {
          ...state,
          userPermissionsLoading: false,
          error: action.payload,
        };

      case A.addPermissionsByRole.type:
        return {
          ...state,
          userPermissionsUpdating: true,
          error: null,
        };

      case A.addPermissionsByRole.success:
        return {
          ...state,
          addedPermissions: action.payload,
          userPermissionsUpdating: false,
          userPermissionsUpdated: true,
        };

      case A.addPermissionsByRole.failure:
        return {
          ...state,
          userPermissionsUpdating: false,
          error: action.payload,
        };

      case A.removePermissionsByRole.type:
        return {
          ...state,
          userPermissionsUpdating: true,
          error: null,
        };

      case A.removePermissionsByRole.success:
        return {
          ...state,
          removedPermissions: action.payload,
          userPermissionsUpdating: false,
          userPermissionsUpdated: true,
        };

      case A.removePermissionsByRole.failure:
        return {
          ...state,
          userPermissionsUpdating: false,
          error: action.payload,
        };

      case A.updateUserPermissions.type:
        return {
          ...state,
          userPermissionsUpdating: true,
          error: null,
        };

      case A.updateUserPermissions.success:
        return {
          ...state,
          userPermissionsUpdating: false,
          userPermissionsUpdated: true,
        };

      case A.updateUserPermissions.failure:
        return {
          ...state,
          userPermissionsUpdating: false,
          error: action.payload,
        };

      case A.checkSatisfiedRolePermissions.type:
        return {
          ...state,
          satisfiedRolePermissionsLoaded: false,
        };

      case A.updateSatisfiedRolePermissions.type:
        return {
          ...state,
          satisfiedRolePermissions: action.payload,
          satisfiedRolePermissionsLoaded: true,
        };

      case A.clearState.type:
        return initialState;

      default:
        return state;
    }
  },
};
