import {
  primaryContrast,
  darkBlue,
  navyBlue,
} from 'now-frontend-shared/themes/colors';

export const styles = theme => ({
  sectionContainer: {
    backgroundColor: primaryContrast,
    padding: theme.spacing(2),
  },
  sectionHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionHeader: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: '#333333',
  },
  button: {
    backgroundColor: darkBlue,
    marginRight: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.6),
    paddingRight: theme.spacing(1.6),
  },
  gridRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridColumn: {
    flex: 1,
    maxWidth: '50%',
    padding: theme.spacing(1.25),
    width: { xs: '100%', sm: '50%' },
  },
  divider: {
    background: '#E0E0E0',
    height: '1px',
  },
  dataGrid: {
    border: 'none',
  },
  dataGridColumnHeader: {
    paddingTop: theme.spacing(2),
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: '450',
  },
  highlightBlue: {
    paddingLeft: theme.spacing(1.25),
    color: navyBlue,
  },
  highlightRed: {
    paddingLeft: theme.spacing(1.25),
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
