import { call, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { parseServerError } from 'now-shared/helpers/server-errors';

export default function* ensureNotifications() {
  yield takeEvery(
    e => e.type.includes('/FAILURE'),
    function* logger(action) {
      const data = parseServerError(action.err.response?.data);
      console.error('saga error:', action);
      yield call(toast.error, data.friendlyMessage, { position: 'top-center' });
    },
  );
}
