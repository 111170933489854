export default {

  wellsContainer: {
    width: '100%',
    color: 'white',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    borderRadius: 10,
  },
  wellInfoContainer: {
    zIndex: 0,
    color: 'white',
    borderRadius: '4px 4px 4px 4px',
  },

  bottomSectionHeading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#FFFFFF',
    borderRight: 'solid 2px #E5E5E5',
    borderBottom: 'solid 2px #E5E5E5',
    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },

  bottomSectionDescription: {
    width: '100%',
    fontWeight: 'normal',
    color: 'white',
  },
  wellHeader: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    margin: '10px',
  },
  latLongText: {
    fontSize: 12,
    opacity: 0.5,
    marginRight: 5,
  },
};
