export default {
  mainContainer: {
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    marginBottom: '10px',
  },

  wellsWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #fff',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
  },

  wellsContainer: {
    width: '100%',
    backgroundColor: 'white',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  wellInfoContainer: {
    zIndex: 0,
    marginTop: '5px',
    backgroundColor: '#F9F9F9',
    borderRadius: '4px 4px 4px 4px',
    margin: '0px 0px 14px 0px',
    paddingBottom: '10px',
    border: '1px solid #EBEBEB',
  },

  bottomSectionHeading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#000000',
    borderRight: 'solid 2px #E5E5E5',
    borderBottom: 'solid 2px #E5E5E5',
    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },

  bottomSectionDescription: {
    width: '100%',
    fontWeight: 'normal',
  },
  wellHeader: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    margin: '10px',
  },
  latLongText: {
    fontSize: 12,
    opacity: 0.5,
    marginRight: 5,
  },
};
