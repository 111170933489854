import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  getAllCompanies,
  getCompanyComplianceInfo,
  getUserCompany,
  getNonOpWellsSignatureDetails,
  findUsersByCompany,
  getFullyOnBoardedBuyersList,
} from '../actions/companies-actions';

// api methods
import Api from 'api/companies';

function* ensureGetAllCompanies() {
  try {
    const { data } = yield call(Api.getAllCompanies);
    yield put({ type: getAllCompanies.success, payload: data });
  } catch (err) {
    yield put({ type: getAllCompanies.failure, err });
  }
}

function* watchGetAllCompany() {
  yield takeLatest(getAllCompanies.type, ensureGetAllCompanies);
  yield take(getAllCompanies.success);
}

function* ensurefindUsersByCompany({ payload }) {
  const {
    companyId,
    resolve,
    reject,
  } = payload;
  try {
    const { data } = yield call(Api.findUsersByCompany(companyId));
    yield put({ type: findUsersByCompany.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: findUsersByCompany.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetAllUsersInCompany() {
  yield takeLatest(findUsersByCompany.type, ensurefindUsersByCompany);
  yield take(findUsersByCompany.success);
}

function* ensureGetCompanyComplianceInfo({ payload: { companyId } }) {
  try {
    const { data } = yield call(Api.getCompanyComplianceInfo(companyId));
    yield put({ type: getCompanyComplianceInfo.success, payload: data });
  } catch (err) {
    yield put({ type: getCompanyComplianceInfo.failure, err });
  }
}

function* watchGetCompanyComplianceInfo() {
  yield takeLatest(getCompanyComplianceInfo.type, ensureGetCompanyComplianceInfo);
  yield take(getCompanyComplianceInfo.success);
}

function* ensureGetUserCompany({ payload: { id } }) {
  try {
    const { data } = yield call(Api.getUserCompany(id));
    yield put({ type: getUserCompany.success, payload: data });
  } catch (err) {
    yield put({ type: getUserCompany.failure, err });
  }
}

function* watchGetUserCompany() {
  yield takeLatest(getUserCompany.type, ensureGetUserCompany);
  yield take(getUserCompany.success);
}
function* ensureSignatureDetailsRequested() {
  try {
    const { data } = yield call(Api.getNonOpWellsSignatureDetails);
    yield put({ type: getNonOpWellsSignatureDetails.success, payload: data });
  } catch (err) {
    yield put({ type: getNonOpWellsSignatureDetails.failure, err });
  }
}

function* watchSignatureDetailsRequest() {
  yield takeLatest(getNonOpWellsSignatureDetails.type, ensureSignatureDetailsRequested);
}

function* ensureGetFullyOnBoardedBuyerCompanies() {
  try {
    const { data } = yield call(Api.getListOfFullyOnBoardedBuyers);
    yield put({ type: getFullyOnBoardedBuyersList.success, payload: data });
  } catch (err) {
    yield put({ type: getFullyOnBoardedBuyersList.failure, err });
  }
}

function* watchGetFullyOnBoardedBuyer() {
  yield takeLatest(getFullyOnBoardedBuyersList.type, ensureGetFullyOnBoardedBuyerCompanies);
  yield take(getFullyOnBoardedBuyersList.success);
}

export default function* companiesSagas() {
  yield all([
    fork(watchGetAllCompany),
    fork(watchGetAllUsersInCompany),
    fork(watchGetCompanyComplianceInfo),
    fork(watchGetUserCompany),
    fork(watchSignatureDetailsRequest),
    fork(watchGetFullyOnBoardedBuyer),
  ]);
}
