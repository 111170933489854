import React, {
  useEffect, useState,
} from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { agreementPageSettings } from 'now-shared/helpers/agreement-helpers';
import TipTap from 'now-frontend-shared/components/TipTap';
import Spinner from 'now-frontend-shared/components/Spinner';
import { primaryContrast } from 'now-frontend-shared/themes/colors';
import {
  getSellerAgreementTemplate,
} from 'store/actions/seller-agreement-template-actions';
import { updateDocumentTemplate } from 'store/actions/document-templates-actions';
import { DocumentTemplateTypeName } from 'now-shared/enums/document-template-type';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';

const EditSellerAgreementTemplate = ({
  sellerAgreementTemplate,
  sellerAgreementTemplateIsLoading,
  templateIsSaving,
}) => {
  const dispatch = useDispatch();

  const [tipTapContent, setTipTapContent] = useState(undefined);

  const onLoad = async () => {
    dispatch(getSellerAgreementTemplate());
  };

  useEffect(() => {
    dispatch(getSellerAgreementTemplate());
  }, [dispatch]);

  useEffect(() => {
    if (sellerAgreementTemplate) {
      setTipTapContent(sellerAgreementTemplate.document);
    }
  }, [sellerAgreementTemplate]);

  const onSave = async doc => {
    await new Promise((resolve, reject) => dispatch(
      updateDocumentTemplate({
        templateType: DocumentTemplateTypeName.SellerAgreement,
        content: doc,
        resolve,
        reject,
      }),
    ));
    await onLoad();
  };

  return (
    <>
      <CssBaseline />
      {(sellerAgreementTemplateIsLoading || templateIsSaving) && (
        <Spinner backdrop />
      )}
      <Box
        style={{
          backgroundColor: primaryContrast,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          style={{
            width: agreementPageSettings.width,
            border: '1px solid gray',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            style={{
              paddingTop: agreementPageSettings.margin.top,
              paddingBottom: agreementPageSettings.margin.bottom,
              paddingLeft: agreementPageSettings.margin.left,
              paddingRight: agreementPageSettings.margin.right,
              boxSizing: 'border-box',
            }}
          >
            <Box>
              <b>Latest Saved Version:</b>
              {' '}
              <DateFieldWithTimeZone
                timeZone={NONOPWELLS_TIME_ZONE}
                record={sellerAgreementTemplate}
                source="version"
                variant="subtitle2"
                showTime
              />
            </Box>
            <TipTap
              editable
              content={tipTapContent}
              onSave={onSave}
              onLoad={onLoad}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

EditSellerAgreementTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sellerAgreementTemplate: PropTypes.object,
  sellerAgreementTemplateIsLoading: PropTypes.bool.isRequired,
  templateIsSaving: PropTypes.bool.isRequired,
};

EditSellerAgreementTemplate.defaultProps = {
  sellerAgreementTemplate: undefined,
};

export default compose(
  connect(({ sellerAgreementTemplate, documentTemplates }) => ({
    sellerAgreementTemplate: sellerAgreementTemplate.sellerAgreementTemplate,
    sellerAgreementTemplateIsLoading: sellerAgreementTemplate.sellerAgreementTemplateIsLoading,
    templateIsSaving: documentTemplates.documentTemplateIsSaving,
  })),
)(EditSellerAgreementTemplate);
