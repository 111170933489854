import { HttpStatus } from '../helpers/https-status';
import {
  ApprovalStatus,
  getRejectionReasonValidatorsWithExistsCheck,
  toStatusName,
  validateHasNoRejectionReason,
} from './approval-status';
import {
  createFormValidator,
  hasValue,
  maxCharCount,
  minCharCount,
  PERSON_FIRST_NAME_MAX_LENGTH,
  PERSON_LAST_NAME_MAX_LENGTH,
  phoneNumber,
  REQUIRED,
  trimmed,
  validateIsEmail,
} from './validation-rules';

export const adminOrCompliancePersonAssociatedWithCompanyError = {
  message: 'A user with an admin or compliance role should not have a company role',
  code: HttpStatus.UnprocessableEntity,
};

export const authorizedSignerViewOnlyUserError = {
  message: 'An authorized signer cannot also be a view-only user',
  code: HttpStatus.UnprocessableEntity,
};

export const companyRoleNoCompanyError = {
  message: 'A user with a company role should be associated with a company',
  code: HttpStatus.UnprocessableEntity,
};

export const accountManagerNotAuthorizedSignerError = {
  message: 'An Account Manager must also be an Authorized Signer',
  code: HttpStatus.UnprocessableEntity,
};

export const superAdminNotAdminError = {
  message: 'A Super Admin must also be an Admin',
  code: HttpStatus.UnprocessableEntity,
};

export const requiredForUserWithCompanyError = {
  message: 'Required for a user with a company',
  code: HttpStatus.UnprocessableEntity,
};

export const requiredForAdminOrComplianceUserError = {
  message: 'Required for a user with an admin or compliance role',
  code: HttpStatus.UnprocessableEntity,
};

export const adminRoleFields = [
  'isAdmin',
  'isSuperAdmin',
];

export const isAdminOrSuperAdmin = user => adminRoleFields.some(field => !!user[field]);

export const complianceRoleFields = [
  'isComplianceSupervisor',
  'isRegisteredRepresentative',
];

export const isCompliancePerson = user => complianceRoleFields.some(field => !!user[field]);

export const adminAndComplianceRoleFields = [
  ...adminRoleFields,
  ...complianceRoleFields,
];

export const isAdminOrCompliancePerson = user => adminAndComplianceRoleFields.some(field => !!user[field]);

/**
 * @typedef PotentialViewOnlyUser
 * @property {boolean=} isAdmin
 * @property {boolean=} isSuperAdmin
 * @property {boolean=} isRegisteredRepresentative
 * @property {boolean=} isComplianceSupervisor
 * @property {boolean=} isAuthorizedSigner
 * @property {boolean=} isViewOnlyListingEditor
 */

/**
 * @param {PotentialViewOnlyUser} user
 * @returns {boolean}
 */
export function isViewOnlyUser(user) {
  return (
    !isAdminOrCompliancePerson(user)
    && !user.isAuthorizedSigner
    && !user.isViewOnlyListingEditor
  );
}

export const companyRoleFields = [
  'isAuthorizedSigner',
  'isAccountManager',
  'isViewOnlyListingEditor',
];

export const companyAndCompanyRoleFields = [
  ...companyRoleFields,
  'companyId',
];

export const hasCompanyOrCompanyRole = user => companyAndCompanyRoleFields.some(field => !!user[field]);

// permission to edit user fields

/**
 * Emails should be confirmed by the target user instead of being marked confirmed by an Admin.
 * If a Super Admin needs to mark an email verified for some reason, they can do so after
 * creating the user initially.
 */
export const canSetIsVerifiedEmailOnCreate = false;

/**
 * An account manager not yet signed up should go through the registration process
 * so they can provide all the necessary details for compliance.
 */
export const canSetIsAccountManagerOnCreate = false;

export const defaultValuesForCreateUser = {
  active: true,
};

export const defaultValuesForCreateAdmin = {
  ...defaultValuesForCreateUser,
  isAdmin: true,
};

export const fieldsNotForCreate = [
  ...!canSetIsVerifiedEmailOnCreate ? [
    'isVerifiedEmail',
  ] : [],
  ...!canSetIsAccountManagerOnCreate ? [
    'isAccountManager',
  ] : [],
];

export const fieldsUpsertOnlyBySuperAdmin = [
  'isAdmin',
  'isSuperAdmin',
  'isVerifiedEmail',
];

export const fieldsNotForSelfEdit = [
  'isAdmin',
  'isSuperAdmin',
  'active',
  'isVerifiedEmail',
];

export const validateHasPermissionToEditUserField = (editor, fieldOptional) => (
  _value,
  user,
  fieldFromValidator,
) => {
  const field = fieldFromValidator || fieldOptional;
  let result;

  if (
    result === undefined
    && !editor?.isAdmin
  ) {
    result = {
      message: 'Only an Admin can edit another user',
      code: HttpStatus.Forbidden,
    };
  }

  if (
    result === undefined
    && !editor?.isSuperAdmin
  ) {
    if (
      isAdminOrCompliancePerson(user)
      && (
        !editor
        || editor.id !== user.id
      )
    ) {
      result = {
        message: 'Only a Super Admin can edit another Admin or compliance user',
        code: HttpStatus.Forbidden,
      };
    } else if (fieldsUpsertOnlyBySuperAdmin.includes(field)) {
      result = {
        message: 'Only editable by a Super Admin',
        code: HttpStatus.Forbidden,
      };
    }
  }

  if (
    result === undefined
    && fieldsNotForSelfEdit.includes(field)
    && (
      !editor
      || (editor.id === user.id)
    )
  ) {
    result = {
      message: 'Cannot be edited by user\'s own self',
      code: HttpStatus.Forbidden,
    };
  }

  return result;
};

export const hasPermissionToEditUserField = (editor, user, field) => (
  validateHasPermissionToEditUserField(editor)(undefined, user, field) === undefined
);

// isAdmin

export const validateCanBeAdmin = user => {
  let result;
  if (hasCompanyOrCompanyRole(user)) {
    result = adminOrCompliancePersonAssociatedWithCompanyError;
  }
  return result;
};

export const canBeAdmin = user => validateCanBeAdmin(user) === undefined;

export const validateCanBeNonAdmin = user => {
  let result;
  if (user.isSuperAdmin) {
    result = superAdminNotAdminError;
  }
  return result;
};

export const canBeNonAdmin = user => validateCanBeNonAdmin(user) === undefined;

export const validateIsAdmin = (isAdmin, user) => {
  let result;
  if (isAdmin) {
    result = validateCanBeAdmin(user);
  } else {
    result = validateCanBeNonAdmin(user);
  }
  return result;
};

// isSuperAdmin

export const validateCanBeSuperAdmin = user => {
  let result = validateCanBeAdmin(user);
  if (result === undefined) {
    if (!user.isAdmin) {
      result = superAdminNotAdminError;
    }
  }
  return result;
};

export const canBeSuperAdmin = user => validateCanBeSuperAdmin(user) === undefined;

export const validateCanBeNonSuperAdmin = () => undefined;

export const canBeNonSuperAdmin = () => validateCanBeNonSuperAdmin() === undefined;

export const validateIsSuperAdmin = (isSuperAdmin, user) => {
  let result;
  if (isSuperAdmin) {
    result = validateCanBeSuperAdmin(user);
  } else {
    result = validateCanBeNonSuperAdmin();
  }
  return result;
};

// active

export const validateCanBeActive = () => undefined;

export const canBeActive = user => validateCanBeActive(user) === undefined;

export const validateCanBeNonActive = () => undefined;

export const canBeNonActive = user => validateCanBeNonActive(user) === undefined;

export const validateIsActive = (isActive, user) => {
  let result;
  if (isActive) {
    result = validateCanBeActive(user);
  } else {
    result = validateCanBeNonActive(user);
  }
  return result;
};

// isVerifiedEmail

export const validateCanBeVerifiedEmail = user => {
  let result;
  if (!user.id && !canSetIsVerifiedEmailOnCreate) {
    result = {
      message: 'New user cannot have email marked verified',
      code: HttpStatus.UnprocessableEntity,
    };
  }
  return result;
};

export const canBeVerifiedEmail = user => validateCanBeVerifiedEmail(user) === undefined;

export const validateCanBeNonVerifiedEmail = () => undefined;

export const canBeNonVerifiedEmail = user => validateCanBeNonVerifiedEmail(user) === undefined;

export const validatePaidEsriMaps = () => undefined;

export const validateIsVerifiedEmail = (isVerifiedEmail, user) => {
  let result;
  if (isVerifiedEmail) {
    result = validateCanBeVerifiedEmail(user);
  } else {
    result = validateCanBeNonVerifiedEmail(user);
  }
  return result;
};

// isComplianceSupervisor and isRegisteredRepresentative

export const validateCanBeCompliancePerson = user => {
  let result;
  if (user.isComplianceSupervisor && user.isRegisteredRepresentative) {
    result = {
      message: 'A user cannot have multiple compliance roles',
      code: HttpStatus.UnprocessableEntity,
    };
  } else if (hasCompanyOrCompanyRole(user)) {
    result = {
      message: 'A user with a compliance role should not be associated with a company',
      code: HttpStatus.UnprocessableEntity,
    };
  }
  return result;
};

export const canBeCompliancePerson = user => validateCanBeCompliancePerson(user) === undefined;

export const validateCanBeNonCompliancePerson = () => undefined;

export const canBeNonCompliancePerson = user => validateCanBeNonCompliancePerson(user) === undefined;

export const validateIsCompliancePerson = (value, user) => {
  let result;
  if (value) {
    result = validateCanBeCompliancePerson(user);
  } else {
    result = validateCanBeNonCompliancePerson(user);
  }
  return result;
};

export const validateIsComplianceSupervisor = validateIsCompliancePerson;
export const validateIsRegisteredRepresentative = validateIsCompliancePerson;

// firstName

export const validateUserFirstNameRequired = (firstName, user) => {
  let result;
  if (!firstName) {
    if (user.companyId) {
      result = requiredForUserWithCompanyError;
    } else if (isAdminOrCompliancePerson(user)) {
      result = requiredForAdminOrComplianceUserError;
    }
  }
  return result;
};

export const isUserFirstNameRequired = user => validateUserFirstNameRequired(undefined, user) !== undefined;

// lastName

export const validateUserLastNameRequired = (lastName, user) => {
  let result;
  if (!lastName) {
    if (user.companyId) {
      result = requiredForUserWithCompanyError;
    } else if (isAdminOrCompliancePerson(user)) {
      result = requiredForAdminOrComplianceUserError;
    }
  }
  return result;
};

export const isUserLastNameRequired = user => validateUserLastNameRequired(undefined, user) !== undefined;

// companyId and contact info fields

export const validateCompanyId = (value, values) => {
  let result;
  if (value) {
    if (isAdminOrCompliancePerson(values)) {
      result = adminOrCompliancePersonAssociatedWithCompanyError;
    }
  }
  return result;
};

export const companyRoleRequiredFields = [
  'companyId',
  'department',
  'jobTitle',
  'phoneNumber',
];

export const validateContactInfoRequired = (value, user) => {
  let result;
  if (!value) {
    if (user.companyId) {
      result = requiredForUserWithCompanyError;
    }
  }
  return result;
};

export const isCompanyAndContactInfoRequired = user => validateContactInfoRequired(undefined, user) !== undefined;

// isViewOnlyListingEditor

export const validateCanBeViewOnlyListingEditor = user => {
  let result;
  if (isAdminOrCompliancePerson(user)) {
    result = adminOrCompliancePersonAssociatedWithCompanyError;
  } else if (!user.companyId) {
    result = companyRoleNoCompanyError;
  } else if (user.isAuthorizedSigner) {
    result = authorizedSignerViewOnlyUserError;
  }
  return result;
};

export const canBeViewOnlyListingEditor = user => validateCanBeViewOnlyListingEditor(user) === undefined;

export const validateCanBeNonViewOnlyListingEditor = () => {
  let result;
  return result;
};

export const canBeNonViewOnlyListingEditor = () => validateCanBeNonViewOnlyListingEditor() === undefined;

export const validateIsViewOnlyListingEditor = (isViewOnlyListingEditor, user) => {
  let result;
  if (isViewOnlyListingEditor) {
    result = validateCanBeViewOnlyListingEditor(user);
  } else {
    result = validateCanBeNonViewOnlyListingEditor(user);
  }
  return result;
};

// isAuthorizedSigner

export const validateCanBeAuthorizedSigner = user => {
  let result;
  if (isAdminOrCompliancePerson(user)) {
    result = adminOrCompliancePersonAssociatedWithCompanyError;
  } else if (!user.companyId) {
    result = companyRoleNoCompanyError;
  } else if (user.isViewOnlyListingEditor) {
    result = authorizedSignerViewOnlyUserError;
  }
  return result;
};

export const canBeAuthorizedSigner = user => validateCanBeAuthorizedSigner(user) === undefined;

export const validateCanBeNonAuthorizedSigner = user => {
  let result;
  if (user.isAccountManager) {
    result = accountManagerNotAuthorizedSignerError;
  }
  return result;
};

export const canBeNonAuthorizedSigner = user => validateCanBeNonAuthorizedSigner(user) === undefined;

export const validateIsAuthorizedSigner = (isAuthorizedSigner, user) => {
  let result;
  if (isAuthorizedSigner) {
    result = validateCanBeAuthorizedSigner(user);
  } else {
    result = validateCanBeNonAuthorizedSigner(user);
  }
  return result;
};

// isAccountManager

export const validateCanBeAccountManager = user => {
  let result = validateCanBeAuthorizedSigner(user);

  if (
    result === undefined
    && !user.isAuthorizedSigner
  ) {
    result = accountManagerNotAuthorizedSignerError;
  }

  if (
    result === undefined
    && !user.id
    && !canSetIsAccountManagerOnCreate
  ) {
    result = {
      message: 'New user cannot be set as account manager',
      code: HttpStatus.UnprocessableEntity,
    };
  }

  return result;
};

export const canBeAccountManager = user => validateCanBeAccountManager(user) === undefined;

export const validateCanBeNonAccountManager = () => undefined;

export const canBeNonAccountManager = () => validateCanBeNonAccountManager() === undefined;

export const validateIsAccountManager = (isAccountManager, user) => {
  let result;
  if (isAccountManager) {
    result = validateCanBeAccountManager(user);
  } else {
    result = validateCanBeNonAccountManager(user);
  }
  return result;
};

export const validateFieldIsRequired = (value, values, field) => {
  let result = false;
  if (!hasValue(value)) {
    if ([
      'email',
    ].includes(field)) {
      result = true;
    } else if (field === 'companyId') {
      result = false;
    } else if (companyRoleRequiredFields.includes(field)) {
      result = validateContactInfoRequired(value, values, field);
    } else if (field === 'isAdmin') {
      if (values.isSuperAdmin) {
        result = {
          message: 'Required for a Super Admin',
          code: HttpStatus.UnprocessableEntity,
        };
      }
    } else if (field === 'isAuthorizedSigner') {
      if (values.isAccountManager) {
        result = {
          message: 'Required for an Account Manager',
          code: HttpStatus.UnprocessableEntity,
        };
      }
    } else if (field === 'firstName') {
      result = validateUserFirstNameRequired(value, values);
    } else if (field === 'lastName') {
      result = validateUserLastNameRequired(value, values);
    } else if (field === 'rejectionReason') {
      result = validateHasNoRejectionReason('approvalStatus')(value, values);
    }
  }
  if (result === true) {
    result = {
      message: REQUIRED,
      code: HttpStatus.UnprocessableEntity,
    };
  } else if (result === false) {
    result = undefined;
  }
  return result;
};

export const validateCanRejectUserRegistration = (_value, values) => {
  let result;
  const statusName = toStatusName(values.approvalStatus);
  const allowedStatuses = [
    ApprovalStatus.Pending,
    ApprovalStatus.Approved,
  ];
  if (!allowedStatuses.includes(statusName)) {
    result = `Can only reject when \`approvalStatus\` is one of: ${allowedStatuses.map(status => `'${status}'`).join(', ')}`;
  }
  return result;
};

export const canRejectUserRegistration = values => validateCanRejectUserRegistration(undefined, values) === undefined;

export const isFieldRequired = (values, field) => validateFieldIsRequired(undefined, values, field) !== undefined;

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const adminUpsertUserFieldValidators = {
  email: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      maxCharCount(100),
      validateIsEmail,
    ] : [],
  ],
  active: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsActive,
    ] : [],
  ],
  isVerifiedEmail: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsVerifiedEmail,
    ] : [],
  ],
  paidEsriMaps: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validatePaidEsriMaps,
    ] : [],
  ],
  firstName: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      maxCharCount(PERSON_FIRST_NAME_MAX_LENGTH),
    ] : [],
  ],
  lastName: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      maxCharCount(PERSON_LAST_NAME_MAX_LENGTH),
    ] : [],
  ],
  companyId: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateCompanyId,
    ] : [],
  ],
  department: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY][MIGRATION][CONSISTENCY] define this as a constant and use it to determine db column size
      // Currently the column size is 200 but validation limits the size to 50 characters.
      maxCharCount(50),
    ] : [],
  ],
  jobTitle: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      minCharCount(2),
      // TODO: [REFACTOR][DRY] define this as a constant and use it to determine db column size
      maxCharCount(200),
    ] : [],
  ],
  phoneNumber: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      trimmed,
      phoneNumber,
    ] : [],
  ],
  isViewOnlyListingEditor: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsViewOnlyListingEditor,
    ] : [],
  ],
  isAuthorizedSigner: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsAuthorizedSigner,
    ] : [],
  ],
  isAccountManager: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsAccountManager,
    ] : [],
  ],
  isAdmin: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsAdmin,
    ] : [],
  ],
  isSuperAdmin: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsSuperAdmin,
    ] : [],
  ],
  isRegisteredRepresentative: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsRegisteredRepresentative,
    ] : [],
  ],
  isComplianceSupervisor: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsComplianceSupervisor,
    ] : [],
  ],
  approvalStatus: () => [],
  rejectionReason: getRejectionReasonValidatorsWithExistsCheck('approvalStatus'),
};

export const validateAdminUpsertUser = createFormValidator(adminUpsertUserFieldValidators);
