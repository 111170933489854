import React, { memo } from 'react';
import PropTypes from 'prop-types';

// components
import SelectWrapper from './components/SelectWrapper';
import SelectInput from './components/SelectInput';

// styled error field
import ErrorField from '../inputs/ErrorField';

// select arrow  icon
import { ReactComponent as SelectArrow } from 'now-frontend-shared/assets/icons/select_arrow.svg';

// styles and components from material-ui
import { MenuItem, Grid } from '@material-ui/core';

const Select = ({ input: selectProps, meta, options }) => {
  const isError = meta.touched && meta.error;

  return (
    <Grid container direction="column">
      <SelectWrapper input={<SelectInput />} IconComponent={SelectArrow} {...selectProps}>
        {options.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectWrapper>

      <ErrorField error={isError ? meta.error : null} />
    </Grid>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default memo(Select);
