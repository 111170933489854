import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getPreSignedUrls = asyncAction('editSellerAgreement/GET_PRE_SIGNED_URLS');

// sync action
export const reset = action('editSellerAgreement/RESET');
export const setAllAWSData = action('editSellerAgreement/SET_ALL_AWS_DATA');
export const setAWSData = action('editSellerAgreement/SET_AWS_DATA');
export const removeAWSDataFile = action('editSellerAgreement/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('editSellerAgreement/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('editSellerAgreement/SET_UNLOADED_FILES_EXIST');
