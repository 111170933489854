import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

// actions
import {
  getPreSignedUrls,
} from '../actions/edit-seller-agreement-actions';

// api methods
import Api from 'api/seller-agreements';

function* ensureGetPreSignedUrls({
  payload: {
    filesName,
    filesMd5,
    filesSize,
    companyId,
    resolve,
    reject,
  },
}) {
  try {
    const { data } = yield call(Api.getPreSignedUrls(companyId, { filesName, filesMd5, filesSize }));
    yield put({ type: getPreSignedUrls.success, payload: data });
    if (resolve) {
      resolve(data);
    }
  } catch (err) {
    yield put({ type: getPreSignedUrls.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchGetPreSignedUrls() {
  yield takeLatest(getPreSignedUrls.type, ensureGetPreSignedUrls);
  yield take(getPreSignedUrls.success);
}

export default function* editListingSagas() {
  yield all([
    fork(watchGetPreSignedUrls),
  ]);
}
