import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconSaveAlt from '@material-ui/icons/SaveAlt';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';

export const DocumentButton = ({
  label,
  filename,
  useDirectLink,
  openInNewTab,
  record,
  urlGetter,
}) => {
  const urlStringOrGetter = urlGetter || record?.downloadUrl || undefined;

  const actualFilename = filename || record?.filename;
  const labelOrFilename = label || actualFilename;

  const onClick = async e => {
    if (urlStringOrGetter) {
      let actualUrl = urlStringOrGetter;
      if (typeof urlStringOrGetter === 'function') {
        try {
          actualUrl = await urlStringOrGetter();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error getting URL for DocumentButton');
          // eslint-disable-next-line no-console
          console.error(err);
          return;
        }
      }
      e.preventDefault();
      if (useDirectLink) {
        const a = document.createElement('a');
        a.href = actualUrl;
        // NOTE: this may not always work for renaming the downloaded file
        a.download = actualFilename || true;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (actualFilename) {
        downloadFileAsFilename({
          downloadUrl: actualUrl,
          filename: actualFilename,
        });
      } else if (openInNewTab) {
        window.open(actualUrl, '_blank');
      } else {
        window.location.href = actualUrl;
      }
    }
  };

  return (
    <Button
      onClick={onClick}
      disabled={!urlStringOrGetter}
      style={{ color: '#3f51b5' }}
    >
      <span style={{ marginRight: '5px', textTransform: 'none' }}>{labelOrFilename}</span>
      <IconSaveAlt />
    </Button>
  );
};

DocumentButton.propTypes = {
  label: PropTypes.string,
  openInNewTab: PropTypes.bool,
  record: PropTypes.shape({
    downloadUrl: PropTypes.string,
    filename: PropTypes.string.isRequired,
  }),
  urlGetter: PropTypes.func,
  useDirectLink: PropTypes.bool,
  filename: PropTypes.string,
};

DocumentButton.defaultProps = {
  label: undefined,
  openInNewTab: false,
  record: undefined,
  urlGetter: undefined,
  useDirectLink: false,
  filename: undefined,
};
