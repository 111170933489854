/**
 * NOTE: a change to this value must be accompanied by a database migration
 */
export const documentTemplateTypeNameMaxChars = 100;

/**
 * NOTE: changes to enum values here must be accompanied by a database migration.
 *
 * @readonly
 * @enum {'buyerAgreement'|'sellerAgreement'}
 */
export const DocumentTemplateTypeName = {
  /**
   * @readonly
   * @type {'buyerAgreement'}
   */
  BuyerAgreement: 'buyerAgreement',
  /**
   * @readonly
   * @type {'sellerAgreement'}
   */
  SellerAgreement: 'sellerAgreement',
};
