import axios from 'axios';
import {
  apiAuthTokenQueryParamName,
} from 'now-shared/helpers/auth-helpers';
import { getAuthorizationHeader, getAuthTokenStored } from 'auth/auth-helpers';

// TODO: [REFACTOR][SIMPLIFY] pass in REACT_APP_API_URL without the `admin` path part in it so we
// don't have to do this hacky string replacement to get the base URL.
export const apiBaseUrl = process.env.REACT_APP_API_URL.replace(/\/admin$/, '');

export function getAuthQueryParamObject() {
  return {
    [apiAuthTokenQueryParamName]: getAuthTokenStored(),
  };
}

const httpClient = axios.create({
  baseURL: apiBaseUrl,
  headers: { 'content-type': 'application/json' },
});

/*
 * Function helper returned function that return promise

 * @param method {string} api method (get, put, post, etc...)
 * @param url {string|function} url to api method
 * @param auth {boolean} whether the endpoint requires auth header
 */
export const makeAxiosRequest = (url, method, auth = false) => (
  ({ headers, ...argsRest } = { headers: undefined }) => httpClient({
    url,
    ...method,
    ...(headers || auth) && {
      headers: {
        ...(auth && {
          Authorization: getAuthorizationHeader(),
        }),
        ...headers,
      },
    },
    ...argsRest,
  })
);
