import React, {
  useState, useEffect,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import EsriMap from 'now-frontend-shared/components/EsriMap';

const useStyles = makeStyles({
  mapField: {
    position: 'relative',
    width: '100%',
    height: '600px',
  },
});

const EsriMapField = props => {
  const { record } = props;
  const [wells, setWells] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    const timer = setTimeout(() => {
      setWells(record?.wells?.map(well => ({ ...well, property: record })));
    }, 1000);
    return () => clearTimeout(timer);
  }, [record]);

  return (
    <div className={classes.mapField}>
      <EsriMap wells={wells} isShowWellsTable={record?.id && record?.wells?.length} />
    </div>
  );
};

EsriMapField.propTypes = {
  wells: PropTypes.objectOf(PropTypes.array),
};

export default EsriMapField;
