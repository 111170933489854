// The following wrapper functions are used for testing server-side
// validation by disabling all ui validations and change events
// that control keeping the form in a valid state.

let uiValidationDisabled = false;

export const setUiValidationDisabled = value => {
  uiValidationDisabled = value ?? true;
};

export const isUiValidationDisabled = () => uiValidationDisabled;

/**
 * Wrap this around `disabled={shouldDisable}` Input prop function
 */
export const disabledWrapper = shouldDisable => {
  let result = shouldDisable();
  if (uiValidationDisabled) {
    result = false;
  }
  return result;
};

/**
 * Wrap this around `validate={validate}` Form prop function
 */
export const validateWrapper = validate => {
  let result = validate;
  if (uiValidationDisabled) {
    result = undefined;
  }
  return result;
};

const noOpFunction = () => undefined;

/**
 * Wrap this around `onChange={makeValid}` Input prop function,
 * or around the part of the onChange behavior that updates form values
 * in order to make them valid in response to other form value change(s).
 */
export const makeFormValidWrapper = makeValid => {
  let result = makeValid;
  if (uiValidationDisabled) {
    result = noOpFunction;
  }
  return result;
};
