import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// helper functions
import { getCountdownValue } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const Countdown = ({
  classes,
  endTime,
  withoutLabel,
  withoutAdaptive,
  customStyles,
  onClose,
}) => {
  const [countdown, setCountdown] = useState();

  useEffect(() => {
    let intervalId;

    const countdownValue = getCountdownValue(endTime);
    setCountdown(countdownValue.text);

    intervalId = setInterval(() => {
      const countdownValue = getCountdownValue(endTime);
      setCountdown(countdownValue.text);
      if (countdownValue.timeIsUp) {
        if (onClose) {
          onClose();
        }
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  const renderCountdown = useCallback(() => {
    if (countdown) {
      return withoutLabel ? countdown : `${countdown} left`;
    }
    return '';
  }, [withoutLabel, countdown]);

  const time = useMemo(() => renderCountdown(), [renderCountdown]);

  return (
    <span
      className={
        withoutAdaptive ? classes.countdownWithoutAdaptive : classes.countdown
      }
      style={{ ...customStyles }}
    >
      {time}
    </span>
  );
};

Countdown.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  endTime: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  withoutLabel: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  withoutAdaptive: PropTypes.bool,
  customStyles: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};

Countdown.defaultProps = {
  customStyles: {},
};

export default compose(withStyles(styles))(Countdown);
