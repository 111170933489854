export default {
  mainContainer: {
    marginTop: 10,
    paddingTop: 20,
    background: 'white',
    paddingLeft: 20,
  },

  basinsContainer: {
    background: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },

  aoiTextWrapper: {
    display: 'flex',
    justifyContent: 'start',
    width: '80%',
  },

  aioText: {
    fontSize: 15,
    borderBottom: '1px solid #757575',
  },

  basinRowWraper: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'start',
  },

  basinContainer: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },

  notificationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    marginBottom: '10px',
  },

  basinListContainerOverflow: {
    width: '80%',
    maxHeight: '300px',
    overflow: 'auto',

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #fff',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },

    '@media screen and (max-width: 600px)': {
      maxHeight: '76px',
    },
  },
};
