import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  BooleanField,
  NumberField,
  EmailField,
  TextField,
  Filter,
  TextInput,
  NullableBooleanInput,
  useNotify,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import { CustomPagination } from '../components/pagination';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  shouldIncludeComplianceUsersInAdminsList,
  shouldIncludeSuperAdminsInAdminsList,
} from 'now-shared/config/admin-settings';
import { SortDirection } from 'now-shared/enums/sort-direction';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { toast } from 'react-toastify';
import { generateFetchOptions } from 'helpers/fetch';

export const AdminsFilters = props => (
  <Filter {...props}>
    <TextInput label="Name / Email" source="search" alwaysOn />
    <NullableBooleanInput label="Active" source="active" alwaysOn />
  </Filter>
);

export const AdminList = props => {
  const [checked, setChecked] = useState(null);
  const [settingId, setSettingId] = useState(null);
  const [newElementStyle, setNewElementStyle] = useState({ top: 0, left: 0 });
  const targetElement = document.querySelector('a.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall')
  const notify = useNotify();

  const updatePosition = () => {
    if (targetElement) {
      const targetRect = targetElement.getBoundingClientRect();
      const leftPosition = targetRect.left - 243;
      const topPosition = targetRect.top;

      setNewElementStyle({
        left: `${leftPosition}px`,
        top: `${topPosition}px`,
      });
    }
  };

  useEffect(() => {
    const type = '2FA';
    const get2FA = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/2FA/${type}`, generateFetchOptions('GET'));
      const data = await result.json();
      setChecked(data.status);
      setSettingId(data.id);
    };
    get2FA();

    updatePosition();

    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [targetElement]);

  const onUpdate = async () => {
    setChecked(!checked);

    const options = {
      ...generateFetchOptions('put', {
        status: !checked,
      }),
    };
    await fetch(`${process.env.REACT_APP_API_URL}/2FA/${settingId}`, options);
    notify(checked ? 'Dual factor login disabled' : 'Dual factor login enabled', 'info');
  };
  return (
    <>
      <div style={{ position: 'absolute', zIndex: 100, ...newElementStyle }}>
        <SwitchButton
          checked={checked}
          label={!checked ? 'Enable Dual Factor Login ' : 'Disable  Dual Factor Login '}
          onChangeNotificationsValue={async ({ value }) => {
            try {
              await onUpdate(value);
              setChecked(value);
            } catch (err) {
              toast.error(`There was an error updating the dual factor login: ${err.message}`);
            }
          }}
        />
      </div>
      <List
        {...props}
        filters={<AdminsFilters />}
        sort={{
          field: 'id',
          order: SortDirection.Descending,
        }}
        pagination={<CustomPagination />}
        exporter={false}
        bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
        perPage={50}
      >
        <>
          <Datagrid rowClick="edit">
            <NumberField source="id" label="ID" />
            <DateFieldWithTimeZone
              source="createdAt"
              label="Created"
              timeZone={NONOPWELLS_TIME_ZONE}
            />
            <DateFieldWithTimeZone
              source="lastSignIn"
              label="Last Sign In"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
            />
            <BooleanField source="active" label="Active" />
            <BooleanField source="isVerifiedEmail" label="Email Verified" />
            <FunctionField render={record => getUserFullName(record)} label="Name" sortBy="firstName" />
            <EmailField source="email" label="Email" />
            <BooleanField source="isAdmin" label="Admin" />
            {shouldIncludeSuperAdminsInAdminsList && (
              <BooleanField source="isSuperAdmin" label="Super Admin" />
            )}
            {shouldIncludeComplianceUsersInAdminsList && (
              <BooleanField source="isComplianceSupervisor" label="Compliance Supervisor" />
            )}
            {shouldIncludeComplianceUsersInAdminsList && (
              <BooleanField source="isRegisteredRepresentative" label="Registered Representative" />
            )}
            <TextField source="phoneNumber" label="Phone" />
          </Datagrid>
        </>
      </List>
    </>
  );
};
