import { getAuthorizationHeader } from '../auth/auth-helpers';
import { FetchJSON } from './fetchJson';

export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

  options.headers.set('Authorization', getAuthorizationHeader());

  return FetchJSON(url, options);
};
