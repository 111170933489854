/**
 * Set the react-final-form field to a value
 *
 * @param {*} form
 * @param {string} field
 * @param {*} value
 * @param {'falsy'|'strict'=} mode if 'falsy', try to set the field value
 * to undefined if its initial value was unset and the new value is falsy.
 */
export const setFormFieldValue = (form, field, value, mode = 'falsy') => {
  const fieldState = form.getFieldState(field);
  if (value !== fieldState?.value) {
    // the following will cause issues if you need to distinguish the
    // field value between null, false, '', and undefined.
    if (
      mode === 'falsy'
      && fieldState?.initial === undefined
      && (
        value === null
        || value === undefined
        || value === false
        || value === ''
      )
    ) {
      form.change(field, undefined);
    } else {
      form.change(field, value);
    }
  }
};
