import { getAuthorizationHeader } from '../auth/auth-helpers';

export const generateFetchOptions = (method, body) => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getAuthorizationHeader(),
  },
  method,
  body: JSON.stringify(body),
});
