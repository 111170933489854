import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getAllRoles: makeAxiosRequest('/admin/roles', { method: 'get' }, true),

  createRole: ({ title, type, permissionIdsToAdd }) => makeAxiosRequest(
    '/admin/roles',
    { method: 'post', data: { title, type, permissionIdsToAdd } },
    true,
  ),

  updateRole: ({
    roleId,
    title,
    type,
    permissionIdsToAdd,
    permissionIdsToRemove,
    updateRoleUsersPermissions,
  }) => makeAxiosRequest(
    `/admin/roles/${roleId}`,
    {
      method: 'put',
      data: {
        title,
        type,
        permissionIdsToAdd,
        permissionIdsToRemove,
        updateRoleUsersPermissions,
      },
    },
    true,
  ),

  deleteRole: ({ roleId }) => makeAxiosRequest(
    `/admin/roles/${roleId}`,
    { method: 'delete' },
    true,
  ),
};
