import { NotificationTypeName } from './notifications-type';

const emailTempalateCommonData = {
  salutation: 'John',
  frontendHomeUrl: 'https://nonopwells.com/',
  contactUsLink: 'https://nonopwells.com/contact-us',
};

export const EmailTemplateDummyData = {
  [NotificationTypeName.NewListingInAOI]: {
    ...emailTempalateCommonData,
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.WelcomeEmail]: {
    ...emailTempalateCommonData,
    isSelfVerified: false,
    isAdminOrCompliancePerson: false,
    resetPasswordLink: false,
    hasCompany: false,
    isAccountManager: false,
  },
  [NotificationTypeName.AdminEditedYourListing]: {
    ...emailTempalateCommonData,
    reason: 'Some reason',
  },
  [NotificationTypeName.ListingApproved]: {
    ...emailTempalateCommonData,
    propertyName: 'Test',
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.AuctionEnded]: {
    ...emailTempalateCommonData,
    sellerName: 'Jogn Doe',
    propertyName: 'Test',
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.PropertyIsClosed]: {
    ...emailTempalateCommonData,
    sellerName: 'Jogn Doe',
    propertyName: 'Test',
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.AdminConfirmedWinningBidOnYourListing]: {
    ...emailTempalateCommonData,
    listingName: 'Test',
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.AdminConfirmedYourWinningBid]: { ...emailTempalateCommonData },
  [NotificationTypeName.AdminArchivedYourListing]: {
    ...emailTempalateCommonData,
    sellerName: 'John',
    propertyName: 'Test',
    listingUrl: 'https://nonopwells.com/listings/:id',
  },
  [NotificationTypeName.UserDetailsChanged]: { ...emailTempalateCommonData },
  [NotificationTypeName.SellerAgreementApproved]: {
    ...emailTempalateCommonData,
    sellerName: 'John',
  },
  [NotificationTypeName.SellerAgreementRejected]: {
    ...emailTempalateCommonData,
    sellerName: 'John',
    reason: 'Some reason',
  },
  [NotificationTypeName.BuyerAgreementApproved]: {
    ...emailTempalateCommonData,
    buyerName: 'John',
  },
  [NotificationTypeName.BuyerAgreementRejected]: {
    ...emailTempalateCommonData,
    buyerName: 'John',
    reason: 'Some reason',
  },
  [NotificationTypeName.BidConfirmed]: {
    ...emailTempalateCommonData,
    listingUrl: '#',
    propertyName: 'Property Name',
    endDate: `${Date.now()}`,
  },
  [NotificationTypeName.BankInformationApproved]: {
    ...emailTempalateCommonData,
    buyerName: 'john',
  },

  [NotificationTypeName.ListingHasReceivedBid]: {
    ...emailTempalateCommonData,
    sellerName: 'john',
    listingUrl: '#',
    propertyName: 'Property Name',
    listingBidsUrl: '#',
  },
  [NotificationTypeName.NewListingInAOI]: {
    ...emailTempalateCommonData,
    listingsUrl: '#',
    listings: [{ url: '#link2' }, { url: '#link3' }],
  },
  [NotificationTypeName.ResetPassword]: {
    ...emailTempalateCommonData,
    resetPasswordLink: '#',

  },
  [NotificationTypeName.PreDashboardRegistrationApproved]: {
    ...emailTempalateCommonData,
    isAccountManager: false,
  },

};
