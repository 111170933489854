import queryString from 'query-string';

const query = queryString.parse(window.location.search, { arrayFormat: 'bracket' });

export function boolParamIsSet(value) {
  return value === null || (
    typeof value === 'string' && ['true', 'yes'].some(str => str.startsWith(value.toLowerCase()))
  );
}

export function boolParamIsFalse(value) {
  return typeof value === 'string' && (
    typeof value === 'string' && ['false', 'no'].some(str => str.startsWith(value.toLowerCase()))
  );
}

export function boolParamIsTrue(value) {
  return typeof value === 'string' && (
    typeof value === 'string' && ['true', 'yes'].some(str => str.startsWith(value.toLowerCase()))
  );
}

export function isUsingEsriMaps() {
  return !boolParamIsFalse(query.useEsri);
}

export function isUsingEsriMapLayers() {
  return !boolParamIsFalse(query.useEsriLayers);
}

export function isUsingRegistrationToggles() {
  const baseUrlContainsDev = window.location.hostname.includes('dev.nonopwells.com');
  const baseUrlContainsLocalhost = window.location.host.includes('localhost:3002');
  // If the base url is not dev.nonopwells.com, the toggle feature can only be enabled using the feature-flag.
  if (!baseUrlContainsDev && !baseUrlContainsLocalhost) return boolParamIsTrue(query.useRegistrationToggles);
  return true;
}
