import {
  green, red, white, primaryMain, secondaryMain, grey, primaryDark,
} from 'now-frontend-shared/themes/colors';

export default {
  button: {
    height: '50px',
    width: '150px',
    margin: '10px 0',
    borderRadius: '8px',
    border: 'unset',
    outline: 'none',
    color: grey,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'none',
    backgroundColor: 'inherit',
    boxShadow: 'unset',

    '&:hover': {
      backgroundColor: green,
      color: '#fff',
    },

    '@media screen and (max-width: 600px)': {
      width: '120px',
    },
  },
  white: {
    border: `2px solid ${white}`,
    backgroundColor: white,

    '&:hover': {
      border: 'unset',
      backgroundColor: primaryDark,
    },
  },
  clear: {
    color: white,

    '&:hover': {
      color: 'unset',
      backgroundColor: 'white',
    },
  },
  red: {
    border: `2px solid ${red}`,
    color: red,

    '&:hover': {
      backgroundColor: red,
    },
  },
  green: {
    color: white,
    backgroundColor: green,

    '&:hover': {
      backgroundColor: primaryMain,
    },
  },
  primaryMain: {
    color: white,
    backgroundColor: primaryMain,

    '&:hover': {
      backgroundColor: secondaryMain,
    },
  },
  primaryDark: {
    border: `2px solid ${primaryDark}`,
    color: white,
    backgroundColor: primaryDark,

    '&:hover': {
      border: 'unset',
      color: primaryDark,
      backgroundColor: 'white',
    },
  },
  primaryDarkWithBorder: {
    border: `2px solid ${primaryDark}`,
    color: white,
    backgroundColor: primaryDark,

    '&:hover': {
      color: primaryDark,
      backgroundColor: 'white',
    },
  },
  secondaryMain: {
    color: white,
    backgroundColor: secondaryMain,

    '&:hover': {
      backgroundColor: primaryMain,
    },
  },
  redFilled: {
    color: white,
    backgroundColor: red,

    '&:hover': {
      backgroundColor: green,
    },
  },
  clearGreen: {
    color: green,
    backgroundColor: white,
    border: `2px solid ${green}`,

    '&:hover': {
      color: white,
      backgroundColor: green,
    },
  },
  inherit: {
    border: `2px solid ${grey}`,

    '&:hover': {
      border: `2px solid ${green}`,
      backgroundColor: green,
      color: '#fff',
    },
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    opacity: 0.5,
  },
};
