/* eslint-disable */
import moment from 'moment';
import {
  nonOpWellsDateTime,
  toAuctionEventTime,
} from '../helpers/time-helpers';
import { zipFileMimeTypes } from '../helpers/upload-helpers';
import {
  addError,
  generalRules,
  hasValue,
  validateMustNotExist,
} from './validation-rules';
import { compose } from 'redux';
import { TransactionTypes } from '../enums/transaction-types';
import validateFormattedCurrency from './validateFormattedCurrency';
import { validateLatLong } from './validateLatLong';
import { maskedAmountToNumber } from '../helpers/currency-helpers';

export const acceptFileFormats = [
  // pdf
  'application/pdf',
  '.pdf',
  // excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx',
  'application/vnd.ms-excel',
  '.xls',
  // text and msword files
  'application/msword',
  '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.docx',
  'text/plain',
  '.txt',
  // images
  'image/tiff',
  'image/jpeg',
  ...zipFileMimeTypes,
  '.zip',
];

export function minStartTimeForListing() {
  return toAuctionEventTime(new Date(), 'start').toJSDate();
}

export function defaultStartTimeForListing() {
  return toAuctionEventTime(
    nonOpWellsDateTime().toJSDate(),
    'start'
  ).toJSDate();
}

export function minEndTimeForListing(startTime) {
  return toAuctionEventTime(
    nonOpWellsDateTime(
      startTime ? new Date(startTime) : minStartTimeForListing()
    ).toJSDate(),
    'end'
  ).toJSDate();
}

export function defaultEndTimeForListing(startTime) {
  // 2 days long by default, so if it starts Monday morning it will end Tuesday night
  return toAuctionEventTime(
    nonOpWellsDateTime(
      startTime ? new Date(startTime) : minStartTimeForListing()
    )
      .plus({ days: 1 })
      .toJSDate(),
    'end'
  ).toJSDate();
}

export const listingErrors = {
  beforeMinStartTime: 'Cannot be before today',
  beforeMinEndTime: 'Cannot be before the start time',
  beforeMinEndTimeDefault: 'Cannot be before today',
};

export function listingIsPending({ status: { title } }) {
  return title === 'pending';
}
export function listingIsApproved({ status: { title } }) {
  return title === 'approved';
}

export function listingIsArchived({ archivedAt }) {
  return !!archivedAt;
}

export function listingNotYetApproved({ status: { title } }) {
  return ['draft', 'pending', 'rejected'].includes(title);
}

export function listingNotYetActive(listing) {
  return listingNotYetApproved(listing) || listing.status.title === 'approved';
}

export const listingHasBeenActive = (listing) => !listingNotYetActive(listing);

export const listingHasBeenApproved = (listing) =>
  !listingNotYetApproved(listing);

export function listingNotYetClosed(listing) {
  return listingNotYetActive(listing) || listing.status.title === 'active';
}

export const listingHasBeenClosed = (listing) => !listingNotYetClosed(listing);

export function listingNotYetClosedNorArchived({
  status: { title },
  archivedAt,
}) {
  return (
    !listingIsArchived({ archivedAt }) &&
    listingNotYetClosed({ status: { title } })
  );
}

export const listingHasBeenClosedOrArchived = (listing) =>
  !listingNotYetClosedNorArchived(listing);

export function listingIsActive({ status: { title }, archivedAt }) {
  return !listingIsArchived({ archivedAt }) && title === 'active';
}

export function canRejectListing({ status: { title }, archivedAt }) {
  return (
    !listingIsArchived({ archivedAt }) &&
    ['pending', 'approved'].includes(title)
  );
}

export function canMakeListingADraft({ status: { title }, archivedAt }) {
  return (
    !listingIsArchived({ archivedAt }) &&
    ['pending', 'approved', 'rejected'].includes(title)
  );
}

export function canMakeListingPending({ status: { title }, archivedAt }) {
  return (
    !listingIsArchived({ archivedAt }) &&
    ['draft', 'approved', 'rejected'].includes(title)
  );
}

export function canApproveListing({
  status: { title },
  endTime,
  closedAt,
  archivedAt,
}) {
  const listingEndDate = toAuctionEventTime(
    new Date(endTime),
    'end'
  ).toJSDate();

  const now = moment();
  const canApprove =
    !listingIsArchived({ archivedAt }) &&
    ['pending', 'rejected'].includes(title) &&
    now.isBefore(listingEndDate) &&
    (!closedAt || now.isBefore(closedAt));
  return canApprove;
}

export function canCloseListing({ status: { title }, archivedAt }) {
  return listingIsActive({ status: { title }, archivedAt });
}

export const ALLOWED_LISTING_STATUS_TITLES_FOR_DELETE = [
  'draft',
  'pending',
  'approved',
  'rejected',
  'closed',
  'winner selected',
  'deleted',
];

export function canDeleteListing({ status: { title } }) {
  return ALLOWED_LISTING_STATUS_TITLES_FOR_DELETE.includes(title);
}

export function canArchiveListing({ status: { title }, archivedAt }) {
  return (
    !listingIsArchived({ archivedAt }) &&
    [
      'draft',
      'pending',
      'approved',
      'rejected',
      'closed',
      'winner selected',
      'sold',
      'deleted',
    ].includes(title)
  );
}

export function listingIsClosedIsh({ status: { title } }) {
  return ['closed', 'winner selected'].includes(title);
}

export function canReopenListing({ status: { title }, archivedAt }) {
  return (
    !listingIsArchived({ archivedAt }) &&
    listingIsClosedIsh({ status: { title } })
  );
}

const wellsValidator = (values) => (errors, fieldName) => {
  const { listing, isForAdminPanel, isNotFormSubmission, isDraft } = values;

  const { transactionType } = listing;

  let value = values[fieldName];

  switch (fieldName) {
    case 'landingZone': {
      if (value === undefined) {
        value = values.landingZoneId;
      }
      if (isDraft && !hasValue(value)) return errors;
      return compose(addError(fieldName, generalRules.required(value)))(errors);
    }
    case 'workingInterestPercentage': {
      // parse is needed before since this value comes with a maskType: percentage
      // ex. "3 %"
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
            atMost: 100,
            noFormat: true,
          })
        ),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'netRevenueInterestNumber': {
      // parse is needed before since this value comes with a maskType: percentage
      // ex. "3 %"
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
            atMost: 100,
            noFormat: true,
          })
        ),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'bottomLatitude': {
      const parsedValue = maskedAmountToNumber(value);
      if (!hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, validateLatLong('latitude')(parsedValue))
      )(errors);
    }
    case 'surfaceLatitude': {
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, validateLatLong('latitude')(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'bottomLongitude': {
      const parsedValue = maskedAmountToNumber(value);
      if (!hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isNegativeNumber(parsedValue)),
        addError(fieldName, validateLatLong('longitude')(parsedValue))
      )(errors);
    }
    case 'surfaceLongitude': {
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isNegativeNumber(parsedValue)),
        addError(fieldName, validateLatLong('longitude')(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'wellTotalVerticalDepth': {
      // parse is needed before since this value comes with a maskType: feet
      // ex. "12345 ft"
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isInteger(parsedValue)),
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'wellNetAFE': {
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
          })
        ),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'wellGrossAfe': {
      const parsedValue = maskedAmountToNumber(value);
      if (isDraft && !hasValue(parsedValue)) return errors;
      return compose(
        addError(fieldName, generalRules.isPositiveNumber(parsedValue)),
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
          })
        ),
        addError(fieldName, generalRules.required(parsedValue))
      )(errors);
    }
    case 'wellAPINumber': {
      if (!hasValue(value)) return errors;
      return compose(addError(fieldName, generalRules.isInteger(value)))(
        errors
      );
    }
    case 'wellName': {
      if (isDraft && !hasValue(value)) return errors;
      return compose(
        addError(fieldName, generalRules.mediumFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.trimmed(value)),
        addError(fieldName, generalRules.required(value))
      )(errors);
    }
    case 'minimumBid': {
      const parsedValue = maskedAmountToNumber(value);
      const notApplicable = transactionType === TransactionTypes.Carry;
      if ((notApplicable || isDraft) && !hasValue(parsedValue)) {
        return errors;
      }
      return compose(
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
          })
        ),
        addError(
          fieldName,
          notApplicable && !isNotFormSubmission
            ? // TODO: [CONSISTENCY][MIGRATION] validateMustNotExist() for non form submissions, too.
              // This may require a migration to ensure non applicable min bid/carry are null.
              validateMustNotExist(parsedValue)
            : // value can be null (will be treated like 0)
              undefined
        )
      )(errors);
    }
    case 'minimumBidCarry': {
      const parsedValue = maskedAmountToNumber(value);
      const notApplicable = transactionType === TransactionTypes.Cash;
      if ((notApplicable || isDraft) && !hasValue(parsedValue)) {
        return errors;
      }
      return compose(
        addError(
          fieldName,
          validateFormattedCurrency(parsedValue, {
            atLeast: 0,
            atMost: 100,
            noFormat: true,
          })
        ),
        addError(
          fieldName,
          notApplicable && !isNotFormSubmission
            ? // TODO: [CONSISTENCY][MIGRATION] validateMustNotExist() for non form submissions, too.
              // This may require a migration to ensure non applicable min bid/carry are null.
              validateMustNotExist(parsedValue)
            : // value can be null (will be treated like 0)
              undefined
        )
      )(errors);
    }
    case 'rank': {
      if ((isDraft || !isForAdminPanel) && !hasValue(value)) {
        return errors;
      }
      return compose(
        addError(fieldName, generalRules.isInteger(value)),
        addError(
          fieldName,
          validateFormattedCurrency(value, {
            atLeast: 1,
            noFormat: true,
          })
        ),
        addError(fieldName, generalRules.required(value))
      )(errors);
    }
    default:
      return errors;
  }
};

const wellsValidate = (values) =>
  [
    'landingZone',
    'workingInterestPercentage',
    'netRevenueInterestNumber',
    'surfaceLatitude',
    'bottomLatitude',
    'surfaceLongitude',
    'bottomLongitude',
    'wellTotalVerticalDepth',
    'wellNetAFE',
    'wellGrossAfe',
    'wellAPINumber',
    'wellName',
    'minimumBid',
    'minimumBidCarry',
    'rank',
  ].reduce(wellsValidator(values), {});

const createPropertyValidator = (values) => (errors, fieldName) => {
  const {
    isBackend,
    isNotFormSubmission,
    isForAdminPanel,
    isForCreate,
    isDraft,
    ...listing
  } = values;

  let value = values[fieldName];

  const result = (() => {
    switch (fieldName) {
      case 'projectName':
        return compose(
          addError(fieldName, generalRules.mediumFieldMaxLength(value)),
          addError(fieldName, generalRules.minLength(value)),
          addError(fieldName, generalRules.trimmed(value)),
          addError(fieldName, generalRules.required(value))
        )(errors);

      case 'operatorName':
        if (isDraft && !hasValue(value)) return errors;
        return compose(
          addError(fieldName, generalRules.mediumFieldMaxLength(value)),
          addError(fieldName, generalRules.minLength(value)),
          addError(fieldName, generalRules.trimmed(value)),
          addError(fieldName, generalRules.required(value))
        )(errors);

      case 'state': {
        if (value === undefined) {
          value = values.stateId;
        }
        if (isDraft && !hasValue(value)) return errors;
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      case 'basin': {
        if (value === undefined) {
          value = values.basinId;
        }
        if (isDraft && !hasValue(value)) return errors;
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      case 'county': {
        if (value === undefined) {
          value = values.countyId;
        }
        if (isDraft && !hasValue(value)) return errors;
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      case 'wells': {
        if (isDraft && !hasValue(value)) return errors;
        if (!value?.length && !isDraft) {
          return compose(addError(fieldName, generalRules.required()))(errors);
        }
        const wellsArrayErrors = value?.map((well, wellIndex) =>
          wellsValidate({
            ...well,
            ...(well?.minimumBid === undefined &&
              !isBackend &&
              !isForAdminPanel && {
                minimumBid: values['wellBid-' + wellIndex + '-minimumBid'],
              }),
            ...(well?.minimumBidCarry === undefined &&
              !isBackend &&
              !isForAdminPanel && {
                minimumBidCarry:
                  values['wellBid-' + wellIndex + '-minimumBidCarry'],
              }),
            listing,
            isForAdminPanel,
            isBackend,
            isNotFormSubmission,
            isDraft,
            isForCreate,
          })
        );
        return compose(addError(fieldName, wellsArrayErrors))(errors);
      }

      /**
       * @deprecated
       * TODO: [CLEANUP][MIGRATION] remove this if/when Property.minimumBid column is removed
       */
      case 'minimumBid': {
        const parsedValue = maskedAmountToNumber(value);
        return compose(
          addError(fieldName, validateFormattedCurrency(parsedValue)),
          isForAdminPanel || isForCreate
            ? addError(fieldName, validateMustNotExist(parsedValue))
            : (v) => v
        )(errors);
      }

      /**
       * @deprecated
       * TODO: [CLEANUP][MIGRATION] remove this if/when Property.minimumBidCarry column is removed
       */
      case 'minimumBidCarry': {
        const parsedValue = maskedAmountToNumber(value);
        return compose(
          addError(
            fieldName,
            validateFormattedCurrency(parsedValue, {
              noFormat: true,
            })
          ),
          isForAdminPanel || isForCreate
            ? addError(fieldName, validateMustNotExist(parsedValue))
            : (v) => v
        )(errors);
      }

      case 'startTime': {
        if (isDraft && !hasValue(value)) {
          return errors;
        }
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      case 'endTime': {
        if (isDraft && !hasValue(value)) {
          return errors;
        }
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      case 'isAnonymous':
        if (!hasValue(value)) {
          return errors;
        }
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );

      case 'transactionType': {
        if (isDraft && !hasValue(value)) {
          return errors;
        }
        return compose(addError(fieldName, generalRules.required(value)))(
          errors
        );
      }

      default:
        return errors;
    }
  })();
  return result;
};

export const propertyBasicValidationFields = [
  'projectName',
  'operatorName',
  'manualSellerCompanyName',
  'state',
  'basin',
  'county',
  'wells',
  'minimumBid',
  'minimumBidCarry',
  'startTime',
  'endTime',
  'isAnonymous',
  'transactionType',
];

export const createPropertyValidate = (values) =>
  propertyBasicValidationFields.reduce(createPropertyValidator(values), {});
