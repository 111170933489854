export const apiAuthTokenQueryParamName = 'auth';

/**
 * @typedef {Object} IsUserRegistrationCompleteCompany
 * @property {Object|null=} certification
 */

/**
 * @typedef {Object} IsUserRegistrationCompleteUser
 * @property {IsUserRegistrationCompleteCompany|null=} company
 * @property {boolean=} isAccountManager
 */

/**
 * @param {IsUserRegistrationCompleteUser|null|undefined} user
 * @returns {boolean}
 */
export function isUserRegistrationComplete(user) {
  return (
    !!user?.company && !!user.isAccountManager
  );
}
