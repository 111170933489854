/**
 * This matches the enum as defined in the database for role entities.
 *
 * @readonly
 * @enum {'userSite'|'adminSite'|'notSpecified'}
 */
export const RoleDomain = {
  /**
   * Roles with the userSite role domain type are given user site permissions (usually).
   * @readonly
   */
  UserSite: 'userSite',
  /**
   * Roles with the adminSite role domain type are given admin site permissions (usually).
   * @readonly
   */
  AdminSite: 'adminSite',
  /**
   * Roles with the notSpecified role domain type are given both user and admin site permissions (usually).
   * @readonly
   */
  NotSpecified: 'notSpecified',
};

export function getRoleDomains() {
  // Convert RoleDomain into an array of its values
  const roleDomains = Object.values(RoleDomain);

  return roleDomains;
}

export const sortRolesByDomain = roles => {
  const userSiteRoles = roles.filter(role => role.type === RoleDomain.UserSite);
  const adminSiteRoles = roles.filter(role => [RoleDomain.AdminSite, RoleDomain.NotSpecified].includes(role.type));
  const sortedRoles = [];

  while (userSiteRoles.length || adminSiteRoles.length) {
    if (userSiteRoles.length) {
      // User site roles sorted into even indexes
      sortedRoles.push(userSiteRoles.shift());
    }
    if (adminSiteRoles.length) {
      // Admin site and notSpecified roles sorted into odd indexes
      sortedRoles.push(adminSiteRoles.shift());
    }
  }

  return sortedRoles;
};

export const getRoleIdByTitle = (roles, title) => {
  const getRole = roles?.find(role => role.title === title);
  return getRole ? getRole.id : null;
};

export const getRolePermissionIdsByTitle = (roles, rolePermissions, title) => {
  const rolePermissionsByTitle = rolePermissions?.filter(
    rolePermission => rolePermission.roleId === getRoleIdByTitle(roles, title),
  );

  return rolePermissionsByTitle?.map(rolePermission => rolePermission.permissionId) || [];
};
