export default {
  detailsContainer: {
    margin: '0px 0px 14px 0px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%)',
    transition: 'all 0.05s linear',
    borderRadius: '4px 4px 4px 4px',
    backgroundColor: '#fff',
    width: '100%',
  },
  hr: {
    height: '2px',
    width: '100%',
    backgroundColor: '#E0E0E0',
  },
};
